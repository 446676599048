import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import TelaComplementarSubNivel from './TelaComplementarSubNivel';

const TelaComplementar = ({
  titulo = '',
  subTitulo = '',
  conteudoTela = <></>,
  setMostrarTela,
  corFundo = 'inherit',
  bordaMain = 'border: 2px solid #ddd',
  esconderTelaComplementar = false,
  setEsconderTelaComplementar,
  conteudoTelaSubNivel = <></>,
  subTituloSubNivel = '',
  corFundoSubNivel = 'inherit',
  bordaSubNivel = 'border: 2px solid #ddd'
}) => (
  <>
    <Box className={`formalizacao-tela-complementar ${esconderTelaComplementar ? 'hidden' : ''}`}>
      <Box className="header-tela">
        <IconButton aria-label="Voltar" className="btn-voltar" onClick={() => setMostrarTela(false)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="subtitle1" className="box-titulo-tela">
          <Box className="sub-titulo-tela-complementar">{subTitulo}</Box>
          <Box className="titulo-tela-complementar texto-semi-negrito">{titulo}</Box>
        </Typography>
      </Box>
      <Box className="main-tela-complementar" sx={{ backgroundColor: corFundo, border: bordaMain }}>
        {conteudoTela}
      </Box>
    </Box>

    {esconderTelaComplementar && (
      <TelaComplementarSubNivel
        setEsconderTelaComplementar={setEsconderTelaComplementar}
        subTituloSubNivel={subTituloSubNivel}
        tituloSubNivel={titulo}
        bordaMain={bordaSubNivel}
        corFundo={corFundoSubNivel}
        conteudoTelaSubNivel={conteudoTelaSubNivel}
      />
    )}
  </>
);
export default TelaComplementar;
