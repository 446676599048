import React, { memo, useState } from 'react';
import { Alert, Box, Grid, InputAdornment } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { LoopOutlined } from '@mui/icons-material';
import { focusInput } from '../../../Utils/utils';
import LoaderMain from '../../../Shared/LoaderMain';
import TextFieldMain from '../../../Shared/TextFieldMain';
import { incluirEndereco, alterarEndereco } from '../../../../api/formalizacao';
import ButtonMain from '../../../Shared/ButtonMain';
import { IsEmpty, NotIsEmpty } from '../../../Utils/format';
import SelectMain from '../../../Shared/SelectMain';
import AutocompleteMain from '../../../Shared/AutocompleteMain';
import { consultarCEP } from '../../../../api/apisExternas';

const FormEnderecos = ({
  formDados = {},
  dadosGerais,
  setDadosGerais,
  setAlerta,
  lstTipos,
  handleClose,
  telaPai
}) => {
  const [isPendingSalvar, setPendingSalvar] = useState(false);
  const [isPendingCEP, setPendingCEP] = useState(false);

  const validarCEP = async CEP => {
    const validacao = await consultarCEP(CEP)
      .then(res => (res.erro ? false : true))
      .catch(err => true);
    return validacao;
  };

  const onChangeCep = (event, formProps) => {
    let CEP = event.target.value;
    CEP = NotIsEmpty(CEP) ? CEP.replace(/\D/g, '') : '';
    if (IsEmpty(CEP) || CEP.length !== 8) {
      return false;
    }

    setPendingCEP(true);
    consultarCEP(CEP)
      .then(res => {
        if (res.erro) {
          formProps.setErrors({ cep: 'CEP não encontrado' });
        } else {
          if (res.logradouro) formProps.setFieldValue('rua', res.logradouro);
          if (res.bairro) formProps.setFieldValue('bairro', res.bairro);
          if (res.localidade) formProps.setFieldValue('cidade', res.localidade);
          if (res.uf) {
            const estado = [...lstTipos.estadosBrasileiros].filter(
              tipo => String(tipo._id) === String(res.uf)
            )[0];
            formProps.setFieldValue('uf', estado);
          }
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setPendingCEP(false);
      });
  };

  const submitFormulario = (values, form) => {
    const params = { ...values };
    params.tipo = NotIsEmpty(params.tipo) ? params.tipo : null;
    params.uf = NotIsEmpty(params.uf) && NotIsEmpty(params.uf._id) ? params.uf._id : null;
    params._id = formDados._id ?? null;
    params.cpfcnpj =
      telaPai === 'DadosCliente' ? dadosGerais.informacoes_gerais.cnpj : dadosGerais.cpfcnpj;
    const tipoAcao = Object.keys(formDados).length === 0 ? 'incluido' : 'alterado';
    const incluirAlterarEndereco =
      tipoAcao === 'incluido' ? incluirEndereco : alterarEndereco;

    setPendingSalvar(true);
    incluirAlterarEndereco(params, telaPai)
      .then(res => {
        if (res && res.data) {
          const dadosGeraisTemp = { ...dadosGerais };
          dadosGeraisTemp.enderecos = res.data;
          setDadosGerais(dadosGeraisTemp);
          handleClose();
          setAlerta({
            mensagem: `Endereço ${tipoAcao} com sucesso`,
            tipo: 'success'
          });
        } else {
          console.error('Retorno vazio');
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        }
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingSalvar(false);
        form.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        tipo:
          NotIsEmpty(formDados.dados_tipo) && formDados.dados_tipo._id ? formDados.dados_tipo._id : '',
        uf:
          NotIsEmpty(formDados.dados_uf) && NotIsEmpty(formDados.dados_uf._id)
            ? [...lstTipos.estadosBrasileiros].filter(
                tipo => String(tipo._id) === String(formDados.dados_uf._id)
              )[0]
            : null,
        ...formDados
      }}
      onSubmit={async (values, form) => {
        const errors = {};
        const { dados_tipo, dados_uf, open, ...valores } = values;
        valores.cep = valores.cep.replace(/\D/g, '');

        if (IsEmpty(valores.uf) || IsEmpty(valores.uf._id)) {
          errors.uf = 'UF Obrigatória';
        }

        const camposObrigatorios = [
          { id: 'tipo', msg: 'Tipo Obrigatório' },
          { id: 'cep', msg: 'CEP Obrigatório' },
          { id: 'rua', msg: 'Endereço Obrigatório' },
          { id: 'numero', msg: 'Numero Obrigatório' },
          { id: 'cidade', msg: 'Cidade Obrigatório' },
          { id: 'bairro', msg: 'Bairro Obrigatório' }
        ];
        camposObrigatorios.map(campo => {
          if (IsEmpty(valores[campo.id])) {
            errors[campo.id] = campo.msg;
          }
          return campo;
        });

        if ((await validarCEP(valores.cep)) === false) {
          errors.cep = 'CEP não encontrado, favor fornecer um CEP válido';
        }

        form.setErrors(errors);
        if (Object.keys(errors).length <= 0) {
          submitFormulario(valores, form);
        } else {
          form.setSubmitting(false);
          focusInput(Object.keys(errors)[0]);
        }
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          {isPendingSalvar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Tipo:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.enderecoTipo) && lstTipos.enderecoTipo.length > 0 ? (
                <Field
                  id="tipo"
                  name="tipo"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione o tipo de endereço"
                  displayEmpty
                  displayEmptyLabel="Não definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.enderecoTipo].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.enderecoTipo) && lstTipos.enderecoTipo.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              CEP:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="cep"
                name="cep"
                type="text"
                onChangeInput={onChangeCep}
                component={TextFieldMain}
                fullWidth
                tipoMascara="CEPMask"
                autoComplete="off"
                disabled={isSubmitting || isPendingCEP}
                InputProps={{
                  endAdornment: isPendingCEP ? (
                    <InputAdornment position="end">
                      <LoopOutlined className="rotating" />
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                  maxLength: 9,
                  readOnly: isPendingCEP
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Endereço:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="rua"
                name="rua"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting || isPendingCEP}
                inputProps={{ maxLength: 255, readOnly: isPendingCEP }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Número:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="numero"
                name="numero"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Complemento:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="complemento"
                name="complemento"
                type="text"
                component={TextFieldMain}
                placeholder="(opcional)"
                inputClassName="input-placeholder-main"
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Bairro:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="bairro"
                name="bairro"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting || isPendingCEP}
                inputProps={{ maxLength: 100, readOnly: isPendingCEP }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Cidade:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="cidade"
                name="cidade"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting || isPendingCEP}
                inputProps={{ maxLength: 100, readOnly: isPendingCEP }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              UF:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.estadosBrasileiros) && lstTipos.estadosBrasileiros.length > 0 ? (
                <Field
                  id="uf"
                  name="uf"
                  component={AutocompleteMain}
                  propsInput={{
                    placeholder: 'Selecione o estado'
                  }}
                  disabled={isSubmitting}
                  paramLabel="nome"
                  options={[...lstTipos.estadosBrasileiros]}
                />
              ) : Array.isArray(lstTipos.estadosBrasileiros) &&
                lstTipos.estadosBrasileiros.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ m: '20px 0' }}>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="transparente-com-bordas"
                disabled={isSubmitting}
                onClick={handleClose}
              >
                Cancelar
              </ButtonMain>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="azul-escuro"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Salvar
              </ButtonMain>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default memo(FormEnderecos);
