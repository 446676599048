import React, { memo, useEffect, useState } from 'react';
import { Alert, Box, IconButton, Snackbar, Tab, Tabs, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import DadosCliente from './DadosCliente';
import LoaderMain from '../../Shared/LoaderMain';
import { verificarCliente } from '../../../api/formalizacao';
import { mascararCpfCnpj } from '../../Utils/format';
import ModalCliente from './ModalCliente';
import PessoasCliente from './PessoasCliente';
import Documentos from './Documentos/Documentos';
import GestaoCliente from './GestaoCliente/GestaoCliente';
import TelaComplementar from './TelaComplementar';
import Pareceres from './Pareceres/Pareceres';
import DadosBancariosCliente from './DadosBancariosCliente';
import FormalizacaoContratos from './FormalizacaoContratos';

const Cliente = ({
  cnpj,
  escopoAcesso,
  setMostrarCliente,
  lstResponsaveisFormalizacao,
  codRelacionamentosFormalizacao,
  atualizarTodosDashboards
}) => {
  const [cliente, setCliente] = useState({});
  const [isPending, setPending] = useState(false);
  const [aba, setAba] = useState(1);
  const [alerta, setAlerta] = useState({});
  const [modalClienteDados, setModalClienteDados] = useState({});
  const [subTitulo, setSubTitulo] = useState('');
  const [dadosPareceres, setDadosPareceres] = useState(false);
  const [mostrarTela, setMostrarTela] = useState(false);
  const [esconderTelaComplementar, setEsconderTelaComplementar] = useState(false);
  const escopoCliente =
    escopoAcesso &&
    escopoAcesso.plataforma_formalizacao &&
    escopoAcesso.plataforma_formalizacao.componente
      ? escopoAcesso.plataforma_formalizacao.componente
      : {};

  useEffect(() => {
    setPending(true);
    verificarCliente(cnpj)
      .then(res => {
        if (res) {
          if (res.data && res.data.cod_cliente && res.data.nome_cliente) {
            setCliente(res.data);
          } else setCliente(null);
        } else setCliente(false);
      })
      .catch(err => {
        console.error(err);
        setCliente(false);
      })
      .finally(() => setPending(false));
  }, []);

  const handleChangeAba = (event, newValue) => {
    setAba(newValue);
  };

  const handleCloseAlerta = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlerta({});
  };

  return (
    <Box className="box-cliente" sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      {cliente && cliente.cod_cliente ? (
        <>
          <Box className={`formalizacao-cliente ${mostrarTela ? 'hidden' : ''}`}>
            <Box className="header-tela">
              <IconButton
                aria-label="Voltar"
                className="btn-voltar"
                onClick={() => setMostrarCliente(false)}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="subtitle1" className="titulo-tela">
                {subTitulo}
                {mascararCpfCnpj(String(cliente.cnpj))} - {cliente.nome_cliente}
              </Typography>
            </Box>

            <Box className="main-cliente">
              <Tabs
                className="lista-abas-cliente"
                value={aba}
                onChange={handleChangeAba}
                variant="scrollable"
                scrollButtons="auto"
              >
                {escopoCliente['cliente-gestao'] && (
                  <Tab label="Gestão do cliente" value={1} className="aba-cliente" />
                )}
                {escopoCliente['cliente-dados'] && (
                  <Tab label="Dados do cliente" value={2} className="aba-cliente" />
                )}
                {escopoCliente['cliente-pessoas'] && (
                  <Tab label="Pessoas do cliente" value={3} className="aba-cliente" />
                )}
                {escopoCliente['cliente-dadosbancarios'] && (
                  <Tab label="Dados bancários" value={4} className="aba-cliente" />
                )}
                {escopoCliente['cliente-documentos'] && (
                  <Tab label="Documentos" value={5} className="aba-cliente" />
                )}
                {escopoCliente['cliente-pareceres'] && (
                  <Tab label="Pareceres" value={6} className="aba-cliente" />
                )}
                {escopoCliente['cliente-contratos'] && (
                  <Tab label="Formalização e contratos" value={7} className="aba-cliente" />
                )}
              </Tabs>

              {escopoCliente['cliente-gestao'] && (
                <Box
                  className={`box-aba-cliente ${aba !== 1 ? 'hidden' : ''}`}
                  role="tabpanel"
                  value={1}
                  index={1}
                >
                  <GestaoCliente
                    cod_cliente={cliente.cod_cliente}
                    codStatusCliente={cliente.codStatusCliente}
                    cnpj={cnpj}
                    escopoClienteDados={escopoCliente['cliente-gestao']}
                    setAlerta={setAlerta}
                    setModalClienteDados={setModalClienteDados}
                    lstResponsaveisFormalizacao={lstResponsaveisFormalizacao}
                    codRelacionamentosFormalizacao={codRelacionamentosFormalizacao}
                    setDadosPareceres={setDadosPareceres}
                    handleChangeAba={handleChangeAba}
                    atualizarTodosDashboards={atualizarTodosDashboards}
                  />
                </Box>
              )}
              {escopoCliente['cliente-dados'] && (
                <Box
                  className={`box-aba-cliente ${aba !== 2 ? 'hidden' : ''}`}
                  role="tabpanel"
                  value={2}
                  index={2}
                >
                  <DadosCliente
                    cliente={cliente}
                    escopoClienteDados={escopoCliente['cliente-dados']}
                    setAlerta={setAlerta}
                    setModalClienteDados={setModalClienteDados}
                  />
                </Box>
              )}
              {escopoCliente['cliente-pessoas'] && (
                <Box
                  className={`box-aba-cliente ${aba !== 3 ? 'hidden' : ''}`}
                  role="tabpanel"
                  value={3}
                  index={3}
                >
                  <PessoasCliente
                    cliente={cliente}
                    escopoClientePessoas={escopoCliente['cliente-pessoas']}
                    setAlerta={setAlerta}
                    setModalClienteDados={setModalClienteDados}
                    setSubTitulo={setSubTitulo}
                    setMostrarTela={setMostrarTela}
                  />
                </Box>
              )}
              {escopoCliente['cliente-dadosbancarios'] && (
                <Box
                  className={`box-aba-cliente ${aba !== 4 ? 'hidden' : ''}`}
                  role="tabpanel"
                  value={4}
                  index={4}
                >
                  <DadosBancariosCliente
                    cliente={cliente}
                    escopoDadosBancarios={escopoCliente['cliente-dadosbancarios']}
                    setAlerta={setAlerta}
                    setModalClienteDados={setModalClienteDados}
                    setSubTitulo={setSubTitulo}
                    setMostrarTela={setMostrarTela}
                  />
                </Box>
              )}
              {escopoCliente['cliente-documentos'] && (
                <Box
                  className={`box-aba-cliente ${aba !== 5 ? 'hidden' : ''}`}
                  role="tabpanel"
                  value={5}
                  index={5}
                >
                  {aba === 5 && (
                    <Documentos
                      cliente={cliente}
                      escopoClienteDados={escopoCliente['cliente-documentos']}
                      setAlerta={setAlerta}
                      setModalClienteDados={setModalClienteDados}
                      mostraGrid={aba === 5}
                    />
                  )}
                </Box>
              )}
              {escopoCliente['cliente-pareceres'] && (
                <Box
                  className={`box-aba-cliente ${aba !== 6 ? 'hidden' : ''}`}
                  role="tabpanel"
                  value={6}
                  index={6}
                >
                  <Pareceres
                    cliente={cliente}
                    escopoClienteDados={escopoCliente['cliente-pareceres']}
                    setAlerta={setAlerta}
                    setModalClienteDados={setModalClienteDados}
                    dadosPareceres={dadosPareceres}
                  />
                </Box>
              )}
              {escopoCliente['cliente-contratos'] && (
                <Box
                  className={`box-aba-cliente ${aba !== 7 ? 'hidden' : ''}`}
                  role="tabpanel"
                  value={7}
                  index={7}
                >
                  <FormalizacaoContratos
                    cliente={cliente}
                    cod_cliente={cliente.cod_cliente}
                    escopoClienteDados={escopoCliente['cliente-contratos']}
                    setAlerta={setAlerta}
                    setModalClienteDados={setModalClienteDados}
                    setSubTitulo={setSubTitulo}
                    setMostrarTela={setMostrarTela}
                    operaConnect={cliente.operaConnect}
                    setEsconderTelaComplementar={setEsconderTelaComplementar}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {mostrarTela && (
            <TelaComplementar
              {...mostrarTela}
              setMostrarTela={setMostrarTela}
              esconderTelaComplementar={esconderTelaComplementar}
              setEsconderTelaComplementar={setEsconderTelaComplementar}
            />
          )}
        </>
      ) : cliente === false ? (
        <Alert severity="error">Ocorreu algum erro, favor entrar em contato com o suporte</Alert>
      ) : cliente === null ? (
        <Alert severity="warning">Cliente não encontrado</Alert>
      ) : (
        <></>
      )}
      <ModalCliente {...modalClienteDados} setModalClienteDados={setModalClienteDados} />
      <Snackbar
        open={alerta.mensagem ? true : false}
        autoHideDuration={5000}
        onClose={handleCloseAlerta}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className="snackbar-cliente"
      >
        <Alert onClose={handleCloseAlerta} severity={alerta.tipo ?? 'success'} className="w-100">
          <span className="w-100 texto-semi-negrito font-1rem">{alerta.mensagem ?? ''}</span>
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default memo(Cliente);
