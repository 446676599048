import { Alert, Box, Paper } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { ContactsOutlined, LocationOnOutlined } from '@mui/icons-material';
import InformacoesGerais from './InformacoesGerais';
import LoaderMain from '../../../Shared/LoaderMain';
import Enderecos from '../shared/Enderecos';
import Contatos from '../shared/Contatos';
import { buscarDadosCliente } from '../../../../api/formalizacao';
import BoxPaper from '../shared/BoxPaper';

const DadosCliente = ({
  cliente,
  setAlerta,
  setModalClienteDados,
  escopoClienteDados
}) => {
  const [dadosCliente, setDadosCliente] = useState({});
  const [isPending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);
    buscarDadosCliente(cliente.cod_cliente)
      .then(res => {
        if (res) {
          if (res.data && res.data.informacoes_gerais) {
            setDadosCliente(res.data);
          } else setDadosCliente(null);
        } else {
          setDadosCliente(false);
          console.error('Retorno vazio');
          setAlerta({
            mensagem:
              'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        }
      })
      .catch(err => {
        console.error(err);
        setDadosCliente(false);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => setPending(false));
  }, []);
  return (
    <Box
      className="dados-cliente"
      sx={isPending ? { position: 'relative' } : {}}
    >
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      {dadosCliente && dadosCliente.informacoes_gerais ? (
        <>
          <InformacoesGerais
            dadosGerais={dadosCliente.informacoes_gerais ?? {}}
            dadosCliente={dadosCliente}
            setDadosCliente={setDadosCliente}
            setAlerta={setAlerta}
            setModalClienteDados={setModalClienteDados}
            escopoClienteDados={escopoClienteDados}
          />
          <BoxPaper iconeTitulo={<LocationOnOutlined />} labelTitulo="Endereços da empresa">
            <Enderecos
              dadosEnderecos={dadosCliente.enderecos ?? {}}
              dadosGerais={dadosCliente}
              setDadosGerais={setDadosCliente}
              setAlerta={setAlerta}
              setModalClienteDados={setModalClienteDados}
              escopoEndereco={escopoClienteDados}
              telaPai="DadosCliente"
            />
          </BoxPaper>
          <BoxPaper iconeTitulo={<ContactsOutlined />} labelTitulo="Contatos da empresa">
            <Contatos
              dadosContatos={dadosCliente.contatos ?? {}}
              dadosGerais={dadosCliente}
              setDadosGerais={setDadosCliente}
              setAlerta={setAlerta}
              setModalClienteDados={setModalClienteDados}
              escopoContato={escopoClienteDados}
              telaPai="DadosCliente"
            />
          </BoxPaper>
        </>
      ) : dadosCliente === false ? (
        <Paper elevation={0} sx={{ p: '20px' }}>
          <Alert severity="error">
            Ocorreu algum erro, favor entrar em contato com o suporte
          </Alert>
        </Paper>
      ) : dadosCliente === null ? (
        <Paper elevation={0} sx={{ p: '20px' }}>
          <Alert severity="warning">Cliente não encontrado</Alert>
        </Paper>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default memo(DadosCliente);
