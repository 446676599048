import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
// import TutoriaisIcon from '@mui/icons-material/OndemandVideoOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from './icons/Home';
import DashboardIcon from './icons/Dashboard';
import BorderosIcon from './icons/Borderos';
import InstrucoesIcon from './icons/Instrucoes';
import RelatoriosIcon from './icons/Relatorios';
import RetornoIcon from './icons/Retorno';
import PlataformaCrmIcon from './icons/PlataformaCrm';
import PlataformaRelacionamentosIcon from './icons/PlataformaRelacionamentos';
import { getClienteAtual, setTelaAberta, verificaTelaAberta } from '../../services/authentication/auth';

const direcionarUrlPlataformaCliente = urlExterna => {
  window.location.href = `${process.env.REACT_APP_LINK_PLATAFORMA_CLIENTE}/#/${urlExterna}`;
};

const ListMenuItems = ({
  menuOpen,
  handleDrawerToggle,
  expanded,
  setExpanded,
  handleChangeAccordion,
  escopoAcesso
}) => {
  const navigate = useNavigate();
  const clienteAtual = getClienteAtual();

  const configMenu = [
    {
      id: 'plataforma_cliente_home',
      texto: 'Home',
      icone: <HomeIcon className="sidebar-home-icone" />,
      urlExterna: 'home',
      className: 'btn-home',
      plataforma: 'plataforma_cliente',
      rota: 'home'
    },
    {
      id: 'plataforma_cliente_dashboard',
      texto: 'Dashboard',
      icone: <DashboardIcon />,
      urlExterna: 'dashboard',
      plataforma: 'plataforma_cliente',
      rota: 'dashboard'
    },
    {
      id: 'plataforma_cliente_borderos',
      texto: 'Borderôs',
      icone: <BorderosIcon />,
      urlExterna: 'borderos',
      plataforma: 'plataforma_cliente',
      rota: 'borderos'
    },
    {
      id: 'plataforma_cliente_instrucoes',
      texto: 'Instruções',
      icone: <InstrucoesIcon />,
      urlExterna: 'instrucoes',
      plataforma: 'plataforma_cliente',
      rota: 'instrucoes'
    },
    {
      id: 'plataforma_cliente_relatorios',
      texto: 'Relatórios',
      icone: <RelatoriosIcon />,
      urlExterna: 'relatorios/borderos',
      plataforma: 'plataforma_cliente',
      rota: 'relatorios'
    },
    {
      id: 'plataforma_cliente_retornos',
      texto: 'Retornos',
      icone: <RetornoIcon />,
      urlExterna: 'retornos',
      plataforma: 'plataforma_cliente',
      rota: 'retornos'
    },
    // {
    //   id: 'plataforma_cliente_tutoriais',
    //   texto: 'Tutoriais',
    //   icone: <TutoriaisIcon />,
    //   urlExterna: 'tutoriais/entrada-xml',
    //   className: 'sidebar-icon-tutorial',
    //   plataforma: 'plataforma_cliente',
    //   rota: 'tutoriais'
    // },
    {
      id: 'plataforma_crm_dashboard',
      texto: 'CRM',
      icone: <PlataformaCrmIcon />,
      urlInterna: 'plataforma_crm/dashboard',
      className: 'sidebar-icon-crm',
      plataforma: 'plataforma_crm',
      rota: 'dashboard'
    },
    {
      id: 'plataforma_formalizacao_dashboard',
      texto: 'Formalização',
      icone: <ContentPasteIcon />,
      urlInterna: 'plataforma_formalizacao/dashboard',
      className: 'sidebar-icon-formalizacao',
      plataforma: 'plataforma_formalizacao',
      rota: 'dashboard'
    },
    {
      id: 'plataforma_relacionamento_dashboard',
      texto: 'Relacionamentos',
      icone: <PlataformaRelacionamentosIcon />,
      urlInterna: 'plataforma_relacionamento/dashboard',
      className: 'sidebar-icon-formalizacao',
      plataforma: 'plataforma_relacionamento',
      rota: 'dashboard'
    }
  ];

  const configSubMenuRelatorios = [
    {
      id: 'relatorios_borderos',
      texto: 'Borderôs',
      urlExterna: 'relatorios/borderos',
      plataforma: 'plataforma_cliente',
      rota: 'relatorios/borderos'
    },
    {
      id: 'relatorios_titulos',
      texto: 'Títulos',
      urlExterna: 'relatorios/titulos',
      plataforma: 'plataforma_cliente',
      rota: 'relatorios/titulos'
    },
    {
      id: 'relatorios_sacados',
      texto: 'Concen. Sacados',
      urlExterna: 'relatorios/concentracao-sacados',
      plataforma: 'plataforma_cliente',
      rota: 'relatorios/concentracao-sacados'
    },
    {
      id: 'relatorios_debitos',
      texto: 'Débitos',
      urlExterna: 'relatorios/debitos',
      plataforma: 'plataforma_cliente',
      rota: 'relatorios/debitos'
    }
  ];

  return (
    <Box>
      <Toolbar />
      <Divider />
      <List>
        {configMenu.map(
          config =>
            !(
              clienteAtual &&
              clienteAtual.id === 'todos' &&
              config.id === 'plataforma_cliente_retornos'
            ) && (
              <ListItem key={config.id} disablePadding>
                {escopoAcesso &&
                  escopoAcesso[config.plataforma] && 
                  escopoAcesso[config.plataforma]['rota'] &&
                  escopoAcesso[config.plataforma]['rota'][config.rota] && (
                    <>
                      {config.id === 'plataforma_cliente_relatorios' && menuOpen ? (
                        <Accordion
                          sx={{ boxShadow: 'none', width: '100%' }}
                          expanded={expanded === 'panelRelatorios'}
                          onChange={handleChangeAccordion('panelRelatorios')}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel-header-relatorios"
                            className="accordion-relatorios"
                            sx={{ paddingLeft: '23px' }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: menuOpen ? 3 : 'auto',
                                justifyContent: 'center',
                                height: '30px',
                                color: '#b8bac6'
                              }}
                              className="sidebar-icon"
                            >
                              {config.icone}
                            </ListItemIcon>
                            <ListItemText
                              primary={config.texto}
                              sx={{ overflow: 'hidden', minHeight: '20px' }}
                            />
                          </AccordionSummary>
                          <AccordionDetails sx={{ padding: 0, background: '#f6f6f6' }}>
                            {configSubMenuRelatorios.map(subConfig => (
                              <Box
                                key={subConfig.id}
                                className="itens-relatorios"
                                onClick={() => direcionarUrlPlataformaCliente(subConfig.urlExterna)}
                              >
                                {subConfig.texto}
                              </Box>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <ListItemButton
                          className={`menu-list-item ${config.className} ${verificaTelaAberta(config.urlInterna) ? 'active' : ''}`}
                          disableRipple
                          sx={{
                            minHeight: 48,
                            justifyContent: menuOpen ? 'initial' : 'center',
                            px: 2.5
                          }}
                          onClick={() => {
                            if (config.id === 'plataforma_cliente_relatorios') {
                              setExpanded('panelRelatorios');
                              handleDrawerToggle();
                            } else if (config.urlInterna) {
                              setTelaAberta(`/${config.urlInterna}`);
                              navigate(`/${config.urlInterna}`);
                            } else if (config.urlExterna) {
                              direcionarUrlPlataformaCliente(config.urlExterna);
                            }
                          }}
                          title={config.texto}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: menuOpen ? 3 : 'auto',
                              justifyContent: 'center',
                              height: '30px',
                              color: '#b8bac6'
                            }}
                            className="sidebar-icon"
                          >
                            {config.icone}
                          </ListItemIcon>
                          <ListItemText
                            primary={config.texto}
                            sx={{ overflow: 'hidden', minHeight: '20px' }}
                          />
                        </ListItemButton>
                      )}
                    </>
                  )}
              </ListItem>
            )
        )}
      </List>
    </Box>
  );
};

export default ListMenuItems;
