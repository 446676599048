import API from './api';

export const getRelacionamentosCrm = (tag, mes) =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/relacionamentos`, {
    params: { tag: tag, filtro_meses: mes }
  });

export const getRelacionamentosObservacoesCrm = cpf_cnpj =>
  API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/relacionamentos-observacoes/${cpf_cnpj}`
  );

export const alterarResponsavelRelacionamentos = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/alterar-responsavel-relacionamentos`,
    params
  );

export const postImportarRelacionamentos = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/importar-relacionamentos`,
    params
  );

export const buscaGestaoRelacionamentos = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/gestao-relacionamentos`,
    params
  );

export const getUsuariosPlaformaCrmContato = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/busca-usuarios-contato`);

export const postCriarRelacionamentoObservacoes = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/criar-relacionamentos-observacoes`,
    params
  );

export const postAlterarDataHoraAgendamentoRelacionamentos = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/alterar-data-agendamento`,
    params
  );

export const postAlterarTagRelacionamentos = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/alterar-tag-relacionamento`,
    params
  );

export const getLeadsGrowth = (tag, mes) =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/leads`, {
    params: { tag: tag, filtro_meses: mes }
  });

export const buscaCrmMotivos = motivo =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/motivos`, {
    params: { tipo: motivo }
  });

export const getPlataformas = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/plataformas`);

export const getGerentes = () => API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/gerentes`);

export const postRelacionamentosCrm = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm-relacionamentos/relacionamentos`, params);
