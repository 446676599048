import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { Feed } from '@mui/icons-material';
import DetalhesGarantias from './DetalhesGarantias';

const DetalhesAditivo = ({ escopoClienteDados, setAlerta, setModalClienteDados, lstTipos }) => {
  const [dadosAditivo, setDadosAditivos] = useState({});
  const valorIndefinido = <em className="texto-cor-cinza">-</em>;
  const keyAditivos = 'dados-row-aditivos';

  useEffect(() => {
    buscaDadosAditivos();
  }, []);

  const buscaDadosAditivos = () => {
    const rowAditivos = JSON.parse(sessionStorage.getItem(keyAditivos));
    sessionStorage.removeItem(keyAditivos);
    setDadosAditivos(rowAditivos);
  };

  return (
    <Box className="box-paper-body-detalhes">
      <Box className="texto-negrito font-size-1-3-rem">ADITIVOS</Box>
      <Box>
        <b>ID: </b> {dadosAditivo && dadosAditivo.id ? dadosAditivo.id : valorIndefinido}
      </Box>
      <hr />

      <Grid container spacing={3} className="box-paper-header-detalhes margin-top-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="flex-box-items-direita">
          <Box className="box-icone-paper-header">
            <Feed />
          </Box>
        </Grid>
        <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="titulo">
          Informações do aditivo
        </Grid>
      </Grid>

      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Tipo:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosAditivo && dadosAditivo.NOMETIPOADITIVO ? dadosAditivo.NOMETIPOADITIVO : valorIndefinido}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Inclusão:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosAditivo && dadosAditivo.DTHRINSERT
            ? `${moment(dadosAditivo.DTHRINSERT).format('DD/MM/yyyy')} às ${moment(dadosAditivo.DTHRINSERT).format('HH:mm:ss')} por ${dadosAditivo.NOMEINCLUSAO}`
            : valorIndefinido}
        </Grid>
      </Grid>
      {dadosAditivo && dadosAditivo.DTHRUPDATE && dadosAditivo.NOMEALTERACAO && (
        <Grid container spacing={3} className="pad-10">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
            Última alteração:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {`${moment(dadosAditivo.DTHRUPDATE).format('DD/MM/yyyy')} às ${moment(dadosAditivo.DTHRUPDATE).format('HH:mm:ss')} por ${dadosAditivo.NOMEALTERACAO}`}
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} className="pad-10 margin-bottom-25">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Vigência:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosAditivo && dadosAditivo.VIGENCIA
            ? moment(dadosAditivo.VIGENCIA).format('DD/MM/yyyy')
            : valorIndefinido}
        </Grid>
      </Grid>

      {dadosAditivo && Object.keys(dadosAditivo).length > 0 && (
        <DetalhesGarantias
          escopoClienteDados={escopoClienteDados}
          setAlerta={setAlerta}
          setModalClienteDados={setModalClienteDados}
          lstTipos={lstTipos}
          dadosAditivo={dadosAditivo}
        />
      )}
    </Box>
  );
};
export default memo(DetalhesAditivo);
