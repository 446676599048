import React, { memo, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { focusInput } from '../../../../Utils/utils';
import LoaderMain from '../../../../Shared/LoaderMain';
import ButtonMain from '../../../../Shared/ButtonMain';
import { IsEmpty, NotIsEmpty, unmaskValue } from '../../../../Utils/format';
import RadioGroupMain from '../../../../Shared/RadioGroupMain';
import FieldsPessoaJuridica from './FieldsPessoaJuridica';
import { buscarListasTiposPessoa } from '../../shared/utils';
import { validaCnpj } from '../../../../Utils/validaCnpj';
import { validaCpf } from '../../../../Utils/validaCpf';
import FieldsPessoaFisica from './FieldsPessoaFisica';
import FieldsPoderesAssinaturas from './FieldsPoderesAssinaturas';
import {
  buscarPessoaGeral,
  buscarPessoaPoderes,
  incluirAlterarPessoa
} from '../../../../../api/formalizacao';
import {
  avalista_tipos_assinaturas,
  representante_tipos_assinaturas
} from '../../shared/TiposAssinaturas';

const FormInclusaoPessoa = ({
  cod_cliente,
  cpfCnpjPessoa = '',
  dadosPessoaPoderes = {},
  dadosAdicionais = {},
  setAlerta,
  handleClose,
  lstTipos,
  setLstTipos,
  tipoForm = 'tudo',
  lstDadosGrid = [],
  setLstDadosGrid = false,
  funcaoPosSave = false
}) => {
  const [isPendingSalvar, setPendingSalvar] = useState(false);
  const [isPendingPoderes, setPendingPoderes] = useState(false);
  const [isPending, setPending] = useState(false);
  const [dadosPoderes, setDadosPoderes] = useState(dadosPessoaPoderes);
  const [dadosPessoa, setDadosPessoa] = useState({});
  const [listaTipos, setListaTipos] = useState(lstTipos);
  const [formOpen, seFormOpen] = useState(tipoForm === 'tudo' ? 'dados_basicos' : tipoForm);

  const camposFormulario = {
    PF: [
      { nome: 'nome', padrao: '' },
      { nome: 'dataNascimento', padrao: '' },
      { nome: 'genero', padrao: 'M' },
      { nome: 'codEstadoCivil', padrao: '' },
      { nome: 'codRegimeCasamento', padrao: '' },
      { nome: 'rg', padrao: '' },
      { nome: 'orgaoRg', padrao: '' },
      { nome: 'dataRg', padrao: '' },
      { nome: 'nacionalidade', padrao: '' },
      { nome: 'naturalidade', padrao: '' },
      { nome: 'nomeMae', padrao: '' },
      { nome: 'nomePai', padrao: '' },
      { nome: 'profissao', padrao: '' },
      { nome: 'politicamenteExposta', padrao: 'N' },
      { nome: 'possuiECpf', padrao: 'N' }
    ],
    PJ: [
      { nome: 'nome', padrao: '' },
      { nome: 'fantasia', padrao: '' },
      { nome: 'inscrEstad', padrao: '' },
      { nome: 'nire', padrao: '' },
      { nome: 'dataRegJunta', padrao: '' },
      { nome: 'dataFundacao', padrao: '' },
      { nome: 'codTipoPorteEmpresa', padrao: '' },
      { nome: 'recuperacaoJudicial', padrao: 'N' }
    ]
  };

  useEffect(() => {
    buscarListaTiposPFPJ();
    if (NotIsEmpty(cpfCnpjPessoa)) {
      if (tipoForm === 'tudo' || tipoForm === 'dados_basicos') buscarPessoaDadosGerais(cpfCnpjPessoa);
      else setDadosPessoa({ cpfcnpj: cpfCnpjPessoa });
    } else if (dadosAdicionais && dadosAdicionais.CPFCONJUGE) {
      if (tipoForm === 'tudo' || tipoForm === 'dados_basicos')
        buscarPessoaDadosGerais(dadosAdicionais.CPFCONJUGE);
    }
  }, []);

  const avancarSalvar = (params, form) => {
    if (formOpen === 'poderes_assinaturas' || tipoForm !== 'tudo') {
      submitFormulario(params, form);
    } else {
      seFormOpen('poderes_assinaturas');
      buscarPessoaPoderesAssinaturas(params, form);
    }
  };

  const voltarCancelar = () => {
    if (formOpen === 'dados_basicos' || tipoForm !== 'tudo') {
      handleClose();
    } else {
      seFormOpen('dados_basicos');
    }
  };

  const buscarListaTiposPFPJ = async () => {
    if (
      listaTipos === false ||
      listaTipos.portesEmpresa.length === 0 ||
      listaTipos.estadoCivil.length === 0
    ) {
      setPending(true);
      const listaTiposTemp = await buscarListasTiposPessoa(listaTipos, setLstTipos, 'DadosPessoa', [
        'portesEmpresa',
        'estadoCivil',
        'regimeCasamento'
      ]);
      setListaTipos(listaTiposTemp);
      setPending(false);
    }
  };

  const formatarPayload = dadosForm => {
    const valores = { ...dadosForm };
    valores.cod_cliente = cod_cliente;
    valores.cpfcnpj = valores.cpfcnpj.replace(/\D/g, '');

    // PESSOA FISICA
    if (valores.tipo_pessoa === 'PF') {
      if (NotIsEmpty(valores.dataNascimento)) {
        valores.dataNascimento = moment(valores.dataNascimento, 'DD/MM/YYYY');
        if (valores.dataNascimento.isValid()) {
          valores.dataNascimento = valores.dataNascimento.format('YYYY-MM-DD');
        } else {
          valores.dataNascimento = null;
        }
      }

      if (NotIsEmpty(valores.dataRg)) {
        valores.dataRg = moment(valores.dataRg, 'DD/MM/YYYY');
        if (valores.dataRg.isValid()) {
          valores.dataRg = valores.dataRg.format('YYYY-MM-DD');
        } else {
          valores.dataRg = null;
        }
      }

      // PF NAO POSSUI recuperacaoJudicial, EXCLUI CASO POSSUA
      if (typeof valores.recuperacaoJudicial !== 'undefined') delete valores.recuperacaoJudicial;

      // PF CONJUGE OU PROCURADOR NAO POSSUI possui eCPF, EXCLUI CASO POSSUA
      if (
        dadosAdicionais &&
        dadosAdicionais.tipoCadastro &&
        dadosAdicionais.tipoCadastro !== 'propria' &&
        typeof valores.possuiECpf !== 'undefined'
      )
        delete valores.possuiECpf;

      // PESSOA JURIDICA
    } else {
      if (NotIsEmpty(valores.dataRegJunta)) {
        valores.dataRegJunta = moment(valores.dataRegJunta, 'DD/MM/YYYY');
        if (valores.dataRegJunta.isValid()) {
          valores.dataRegJunta = valores.dataRegJunta.format('YYYY-MM-DD');
        } else {
          valores.dataRegJunta = null;
        }
      }

      if (NotIsEmpty(valores.dataFundacao)) {
        valores.dataFundacao = moment(valores.dataFundacao, 'DD/MM/YYYY');
        if (valores.dataFundacao.isValid()) {
          valores.dataFundacao = valores.dataFundacao.format('YYYY-MM-DD');
        } else {
          valores.dataRegJunta = null;
        }
      }

      // PJ NAO POSSUI politicamenteExposta, EXCLUI CASO POSSUA
      if (typeof valores.politicamenteExposta !== 'undefined') delete valores.politicamenteExposta;

      // PJ NAO POSSUI possui eCPF, EXCLUI CASO POSSUA
      if (typeof valores.possuiECpf !== 'undefined') delete valores.possuiECpf;

      if (typeof valores.recuperacaoJudicial !== 'undefined') {
        valores.rj = valores.recuperacaoJudicial;
        delete valores.recuperacaoJudicial;
      }
    }

    if (typeof valores.bloquearCpfCnpj !== 'undefined') delete valores.bloquearCpfCnpj;

    if (NotIsEmpty(valores.percentual_societario))
      valores.percentual_societario = unmaskValue(valores.percentual_societario);

    return { ...valores };
  };

  const validarDadosBasicos = dadosForm => {
    const errors = {};
    const cpfcnpj = dadosForm.cpfcnpj ? dadosForm.cpfcnpj.replace(/\D/g, '') : '';

    if (IsEmpty(dadosForm.nome)) {
      errors.nome = 'Nome da pessoa obrigatório';
    }

    // PESSOA FISICA
    if (dadosForm.tipo_pessoa === 'PF') {
      if (IsEmpty(cpfcnpj)) {
        errors.cpfcnpj = 'CPF da pessoa obrigatório';
      } else if (!validaCpf(cpfcnpj)) {
        errors.cpfcnpj = 'CPF da pessoa é inválido';
      }

      if (IsEmpty(dadosForm.genero)) errors.genero = 'Gênero da pessoa obrigatório';

      if (NotIsEmpty(dadosForm.dataNascimento)) {
        if (moment(dadosForm.dataNascimento, 'DD/MM/YYYY').isValid() === false) {
          errors.dataNascimento = 'Data da fundação invalida';
        }
      }

      if (NotIsEmpty(dadosForm.dataRg)) {
        if (moment(dadosForm.dataRg, 'DD/MM/YYYY').isValid() === false) {
          errors.dataRg = 'Data da fundação invalida';
        }
      }

      // PESSOA JURIDICA
    } else {
      if (IsEmpty(cpfcnpj)) {
        errors.cpfcnpj = 'CNPJ da pessoa obrigatório';
      } else if (!validaCnpj(cpfcnpj)) {
        errors.cpfcnpj = 'CNPJ da pessoa é inválido';
      }

      if (NotIsEmpty(dadosForm.dataNascimento)) {
        if (moment(dadosForm.dataNascimento, 'DD/MM/YYYY').isValid() === false) {
          errors.dataNascimento = 'Data de nascimento invalida';
        }
      }

      if (NotIsEmpty(dadosForm.dataRg)) {
        if (moment(dadosForm.dataRg, 'DD/MM/YYYY').isValid() === false) {
          errors.dataRg = 'Data do RG invalida';
        }
      }
    }

    return errors;
  };

  const validarPoderesAssinaturas = dadosForm => {
    const errors = {};

    if (
      dadosForm.avalista &&
      dadosForm.avalista === 'S' &&
      dadosForm.avalista_tipos_assinaturas &&
      dadosForm.avalista_tipos_assinaturas.length === 0
    ) {
      errors.avalista_tipos_assinaturas = 'Tipos de assinatura do avalista é obrigatório';
    }

    if (dadosForm.socio && dadosForm.socio === 'S') {
      if (dadosForm.percentual_societario && IsEmpty(dadosForm.percentual_societario))
        errors.percentual_societario = 'Percentual societário do sócio é obrigatório';
      else if (
        dadosForm.percentual_societario &&
        NotIsEmpty(dadosForm.percentual_societario) &&
        parseFloat(unmaskValue(dadosForm.percentual_societario)) > 100
      )
        errors.percentual_societario = 'Percentual societário do sócio deve ser menor ou igual a 100%';
      if (dadosForm.tipo_socio && IsEmpty(dadosForm.tipo_socio))
        errors.tipo_socio = 'Tipo do sócio é obrigatório';
      if (dadosForm.signatario && IsEmpty(dadosForm.signatario))
        errors.signatario = 'Informação sobre o sócio ser ou não signatário é obrigatório';
    }

    if (
      dadosForm.representante &&
      dadosForm.representante === 'S' &&
      dadosForm.representante_tipos_assinaturas &&
      dadosForm.representante_tipos_assinaturas.length === 0
    ) {
      errors.representante_tipos_assinaturas = 'Tipos de assinatura do represente é obrigatório';
    }

    if (
      dadosForm.representante &&
      dadosForm.representante === 'N' &&
      dadosForm.socio &&
      dadosForm.socio === 'N' &&
      dadosForm.avalista &&
      dadosForm.avalista === 'N'
    ) {
      errors.representante = 'Favor escolher ao menos um tipo de pessoa/poder';
      errors.socio = 'Favor escolher ao menos um tipo de pessoa/poder';
      errors.avalista = 'Favor escolher ao menos um tipo de pessoa/poder';
    }
    return errors;
  };

  const submitFormulario = (values, form) => {
    let params = formatarPayload({ ...values });
    if (tipoForm !== 'tudo') params.tipoForm = tipoForm;
    params = { ...dadosPoderes, ...dadosAdicionais, ...params };
    setPendingSalvar(true);
    incluirAlterarPessoa(params)
      .then(res => {
        if (res && res.data) {
          if (funcaoPosSave) funcaoPosSave(res.data);
          setAlerta({
            mensagem: 'Dados da pessoa foram salvos com sucesso',
            tipo: 'success'
          });
          if (res.data.dados_grid && setLstDadosGrid) {
            let lstDadosGridTemp = lstDadosGrid ? [...lstDadosGrid] : [];
            const jaTemNaGrid = lstDadosGrid.filter(row => row.CPFCNPJ === res.data.dados_grid.CPFCNPJ);
            if (jaTemNaGrid.length === 0) {
              lstDadosGridTemp.push(res.data.dados_grid);
            } else {
              lstDadosGridTemp = lstDadosGrid.map(row =>
                row.id === res.data.dados_grid.id ? res.data.dados_grid : row
              );
            }
            setLstDadosGrid(lstDadosGridTemp);
          }
          handleClose();
        } else {
          console.error('res ou res.data está nulo ou indefinido, res:', res);
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        }
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingSalvar(false);
        form.setSubmitting(false);
      });
  };

  const onChangeCpfCnpj = (event, formProps) => {
    let CPFCNPJ = event.target.value;
    CPFCNPJ = NotIsEmpty(CPFCNPJ) ? CPFCNPJ.replace(/\D/g, '') : '';
    if (IsEmpty(CPFCNPJ)) return false;
    if (CPFCNPJ.length <= 11) {
      if (CPFCNPJ.length !== 11) return false;
      else if (!validaCpf(CPFCNPJ)) {
        formProps.setFieldError('cpfcnpj', 'CPF da pessoa é inválido');
        setAlerta({
          mensagem: 'CPF da pessoa é inválido',
          tipo: 'warning'
        });
        return false;
      }
    } else if (CPFCNPJ.length > 11) {
      if (CPFCNPJ.length !== 14) return false;
      else if (!validaCnpj(CPFCNPJ)) {
        formProps.setFieldError('cpfcnpj', 'CNPJ da pessoa é inválido');
        setAlerta({
          mensagem: 'CNPJ da pessoa é inválido',
          tipo: 'warning'
        });
        return false;
      }
    }

    setDadosPoderes({});
    formProps.setFieldValue('avalista', 'N');
    formProps.setFieldValue('socio', 'N');
    formProps.setFieldValue('representante', 'N');

    buscarPessoaDadosGerais(CPFCNPJ, formProps.setFieldValue);
  };

  const buscarPessoaDadosGerais = (CPFCNPJ, setFieldValue = false) => {
    setPending(true);
    buscarPessoaGeral(CPFCNPJ)
      .then(res => {
        const dados = res.data && typeof res.data === 'object' ? res.data : {};
        let preencherCampos = [];
        if (dados && dados.TIPOPESSOA && dados.TIPOPESSOA === 'PF') {
          preencherCampos = camposFormulario.PF;
        } else {
          preencherCampos = camposFormulario.PJ;
        }
        const camposForm = {};
        preencherCampos.map(campo => {
          const valorCampo =
            dados[campo.nome.toUpperCase()] && NotIsEmpty(dados[campo.nome.toUpperCase()])
              ? dados[campo.nome.toUpperCase()]
              : campo.padrao;
          if (typeof setFieldValue === 'function') {
            setFieldValue(campo.nome, valorCampo);
          } else {
            camposForm[campo.nome] = valorCampo;
          }
          return campo;
        });
        if (typeof setFieldValue !== 'function') {
          if (NotIsEmpty(cpfCnpjPessoa)) {
            camposForm.cpfcnpj = cpfCnpjPessoa;
            camposForm.bloquearCpfCnpj = false;
          } else if (dadosAdicionais && dadosAdicionais.CPFCONJUGE) {
            camposForm.cpfcnpj = dadosAdicionais.CPFCONJUGE;
            camposForm.bloquearCpfCnpj = false;
          }
          setDadosPessoa(camposForm);
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const buscarPessoaPoderesAssinaturas = (params, form) => {
    if (dadosPoderes && Object.values(dadosPoderes).length > 0) {
      form.setSubmitting(false);
      return false;
    }

    const cpfcnpj = params.cpfcnpj.replace(/\D/g, '');
    setPendingPoderes(true);
    buscarPessoaPoderes(cod_cliente, cpfcnpj)
      .then(res => {
        const dados = res.data && typeof res.data === 'object' ? res.data : {};
        if (Object.values(dados).length > 0) {
          const poderes = {
            CODCONTRATORESPONSAVELSOLIDARIO: 0,
            CODCONTRATOSOCIODONO: 0,
            CODCONTRATOREPRESENTANTE: 0
          };

          if (dados.avalista && dados.avalista.CODCONTRATORESPONSAVELSOLIDARIO) {
            form.setFieldValue('avalista', 'S');
            poderes.CODCONTRATORESPONSAVELSOLIDARIO = dados.avalista.CODCONTRATORESPONSAVELSOLIDARIO;
            const avalistaAssinaturas = [];
            if (NotIsEmpty(dados.avalista.ASSINACONTRATO) && dados.avalista.ASSINACONTRATO === 'S')
              avalistaAssinaturas.push(avalista_tipos_assinaturas.ASSINACONTRATO);
            if (NotIsEmpty(dados.avalista.ASSINAOPERACAO) && dados.avalista.ASSINAOPERACAO === 'S')
              avalistaAssinaturas.push(avalista_tipos_assinaturas.ASSINAOPERACAO);
            if (NotIsEmpty(dados.avalista.ASSINACCB) && dados.avalista.ASSINACCB === 'S')
              avalistaAssinaturas.push(avalista_tipos_assinaturas.ASSINACCB);
            form.setFieldValue('avalista_tipos_assinaturas', avalistaAssinaturas);
          } else {
            form.setFieldValue('avalista', 'N');
            form.setFieldValue('avalista_tipos_assinaturas', []);
          }

          if (dados.socio && dados.socio.CODCONTRATOSOCIODONO) {
            form.setFieldValue('socio', 'S');
            poderes.CODCONTRATOSOCIODONO = dados.socio.CODCONTRATOSOCIODONO;
            if (NotIsEmpty(dados.socio.TIPOSOCIO))
              form.setFieldValue('tipo_socio', dados.socio.TIPOSOCIO);
            if (NotIsEmpty(dados.socio.PERCENTUALPARTICIPACAO))
              form.setFieldValue(
                'percentual_societario',
                parseFloat(dados.socio.PERCENTUALPARTICIPACAO).toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              );
            if (NotIsEmpty(dados.socio.SIGNATARIO))
              form.setFieldValue('signatario', dados.socio.SIGNATARIO);
          } else {
            form.setFieldValue('socio', 'N');
            form.setFieldValue('tipo_socio', 'SC');
            form.setFieldValue('percentual_societario', '');
            form.setFieldValue('signatario', 'N');
          }

          if (dados.representante && dados.representante.CODCONTRATOREPRESENTANTE) {
            form.setFieldValue('representante', 'S');
            poderes.CODCONTRATOREPRESENTANTE = dados.representante.CODCONTRATOREPRESENTANTE;
            const representanteAssinaturas = [];
            if (
              NotIsEmpty(dados.representante.ASSINATERMOCESSAO) &&
              dados.representante.ASSINATERMOCESSAO === 'S'
            )
              representanteAssinaturas.push(representante_tipos_assinaturas.ASSINATERMOCESSAO);
            if (
              NotIsEmpty(dados.representante.ASSINAPORENDOSSO) &&
              dados.representante.ASSINAPORENDOSSO === 'S'
            )
              representanteAssinaturas.push(representante_tipos_assinaturas.ASSINAPORENDOSSO);
            if (
              NotIsEmpty(dados.representante.EMITEDUPLICATA) &&
              dados.representante.EMITEDUPLICATA === 'S'
            )
              representanteAssinaturas.push(representante_tipos_assinaturas.EMITEDUPLICATA);
            if (
              NotIsEmpty(dados.representante.ASSINASOZINHO) &&
              dados.representante.ASSINASOZINHO === 'S'
            )
              representanteAssinaturas.push(representante_tipos_assinaturas.ASSINASOZINHO);
            form.setFieldValue('representante_tipos_assinaturas', representanteAssinaturas);
          } else {
            form.setFieldValue('representante', 'N');
            form.setFieldValue('representante_tipos_assinaturas', []);
          }

          setDadosPoderes(poderes);
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        form.setSubmitting(false);
        setPendingPoderes(false);
      });
  };

  return (
    <Box>
      {(((NotIsEmpty(cpfCnpjPessoa) || NotIsEmpty(dadosAdicionais.CPFCONJUGE)) && isPending) ||
        isPendingSalvar) && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      {((IsEmpty(cpfCnpjPessoa) && IsEmpty(dadosAdicionais.CPFCONJUGE)) ||
        ((NotIsEmpty(cpfCnpjPessoa) || NotIsEmpty(dadosAdicionais.CPFCONJUGE)) &&
          Object.values(dadosPessoa).length > 0)) && (
        <>
          <Formik
            initialValues={{
              tipo_pessoa:
                dadosPessoa && dadosPessoa.cpfcnpj && dadosPessoa.cpfcnpj.length === 14 ? 'PJ' : 'PF',
              genero: 'M',
              representante: 'N',
              representante_tipos_assinaturas: [],
              avalista: 'N',
              avalista_tipos_assinaturas: [],
              socio: 'N',
              percentual_societario: 0,
              tipo_socio: 'SC',
              signatario: 'N',
              politicamenteExposta: 'N',
              possuiECpf: 'N',
              possuiProcurador: 'N',
              recuperacaoJudicial: 'N',
              ...dadosPessoa,
              ...dadosPessoaPoderes
            }}
            onSubmit={(values, form) => {
              let errors = {};
              if (formOpen === 'dados_basicos') {
                errors = validarDadosBasicos({ ...values });
              } else {
                errors = validarPoderesAssinaturas({ ...values });
              }

              form.setErrors(errors);
              if (Object.keys(errors).length <= 0) {
                avancarSalvar({ ...values }, form);
              } else {
                form.setSubmitting(false);
                focusInput(Object.keys(errors)[0]);
              }
            }}
          >
            {({ values, submitForm, isSubmitting, ...formProps }) => (
              <Form>
                {/* DADOS BASICOS PESSOA - MOSTRA QUANDO FOR FORM DE APENAS DADOS BASICOS, OU QUANDO FOR FORM DE TUDO COM PELO MENOS UM TIPO SELECIONADO */}
                {(tipoForm === 'dados_basicos' || tipoForm === 'tudo') && (
                  <Box sx={formOpen !== 'dados_basicos' ? { display: 'none' } : {}}>
                    <Grid container spacing={3} className="input-line">
                      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                        Tipo de pessoa:
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                        <Field
                          id="tipo_pessoa"
                          name="tipo_pessoa"
                          component={RadioGroupMain}
                          disabled={
                            isSubmitting ||
                            isPendingSalvar ||
                            isPending ||
                            NotIsEmpty(cpfCnpjPessoa) ||
                            (dadosAdicionais &&
                              dadosAdicionais.tipoCadastro &&
                              dadosAdicionais.tipoCadastro === 'conjuge')
                          }
                          row
                          options={[
                            { label: 'Pessoa física (PF)', value: 'PF' },
                            { label: 'Pessoa jurídica (PJ)', value: 'PJ' }
                          ]}
                        />
                      </Grid>
                    </Grid>

                    {values.tipo_pessoa === 'PF' ? (
                      <FieldsPessoaFisica
                        values={values}
                        isPending={isSubmitting || isPendingSalvar || isPending}
                        lstTipos={listaTipos}
                        onChangeCpfCnpj={
                          dadosPessoa && !dadosPessoa.bloquearCpfCnpj ? onChangeCpfCnpj : () => {}
                        }
                        cpfReadOnlyDisabled={dadosPessoa && dadosPessoa.bloquearCpfCnpj}
                        tipoCadastro={
                          dadosAdicionais && dadosAdicionais.tipoCadastro
                            ? dadosAdicionais.tipoCadastro
                            : 'propria'
                        }
                      />
                    ) : (
                      <FieldsPessoaJuridica
                        values={values}
                        isPending={isSubmitting || isPendingSalvar || isPending}
                        lstTipos={listaTipos}
                        onChangeCpfCnpj={
                          dadosPessoa && !dadosPessoa.bloquearCpfCnpj ? onChangeCpfCnpj : () => {}
                        }
                        cnpjReadOnlyDisabled={dadosPessoa && dadosPessoa.bloquearCpfCnpj}
                      />
                    )}
                  </Box>
                )}

                {/* DADOS PODERES E ASSINATURAS */}
                {(tipoForm === 'poderes_assinaturas' || tipoForm === 'tudo') && (
                  <Box sx={formOpen !== 'poderes_assinaturas' ? { display: 'none' } : {}}>
                    <FieldsPoderesAssinaturas
                      values={values}
                      lstTipos={listaTipos}
                      isPending={isSubmitting || isPendingSalvar}
                      isPendingPoderes={isPendingPoderes}
                      tipoCadastro={
                        dadosAdicionais && dadosAdicionais.tipoCadastro
                          ? dadosAdicionais.tipoCadastro
                          : 'propria'
                      }
                    />
                  </Box>
                )}

                {/* AÇÕES */}
                <Grid container spacing={3} sx={{ m: '20px 0' }}>
                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />

                  <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                    <ButtonMain
                      size="medium"
                      tipoBotao="transparente-com-bordas"
                      disabled={isSubmitting || isPendingSalvar || isPending}
                      onClick={voltarCancelar}
                    >
                      {formOpen === 'dados_basicos' || tipoForm !== 'tudo' ? 'Cancelar' : 'Voltar'}
                    </ButtonMain>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                    <ButtonMain
                      size="medium"
                      tipoBotao="azul-escuro"
                      disabled={isSubmitting || isPendingSalvar || isPending}
                      onClick={submitForm}
                    >
                      {formOpen === 'poderes_assinaturas' || tipoForm !== 'tudo' ? 'Salvar' : 'Avançar'}
                    </ButtonMain>
                  </Grid>

                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
};
export default memo(FormInclusaoPessoa);
