import { Alert, Box, FormControlLabel, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { Add, CurrencyExchange, ModeEditOutlined } from '@mui/icons-material';
import LoaderMain from '../../../../Shared/LoaderMain';
import {
  alterarStatusContaEscrow,
  listarContasEscrow,
  listarEmpresasContaEscrow,
  listarProdutosEscrow
} from '../../../../../api/formalizacao';
import LinhaDados from '../../PessoasCliente/DadosPessoa/LinhaDados';
import BoxPaper from '../../shared/BoxPaper';
import { IsEmpty, NotIsEmpty } from '../../../../Utils/format';
import ButtonMain from '../../../../Shared/ButtonMain';
import IOSSwitch from '../../shared/IOSSwitch';
import FormContaEscrow from './FormContaEscrow';
import { PRODUTOS } from '../../../../Shared/Enums/Produtos';

const ContasEscrow = ({
  cod_cliente,
  lstBancosAgencia,
  setAlerta,
  setAlertConfirm,
  setModalClienteDados,
  escopoDadosBancarios
}) => {
  const [lstContasEscrow, setLstContasEscrow] = useState([]);
  const [lstEmpresas, setLstEmpresas] = useState([]);
  const [lstProdutosEscrow, setLstProdutosEscrow] = useState([]);
  const [filtroStatus, setFiltroStatus] = useState(true);
  const [isPending, setPending] = useState(false);

  const valorIndefinido = <em className="texto-cor-cinza">-</em>;

  const camposContaEscrow = [
    { key: 'descrAgencia', label: 'Agência:' },
    { key: 'conta', label: 'Conta:' },
    { key: 'digitoConta', label: 'Digito:' },
    { key: 'contaCTB', label: 'Conta CTB:' },
    { key: 'carteira', label: 'Carteira:' },
    { key: 'fornecedor', label: 'Fornecedor:' },
    { key: 'empresas', label: 'Empresa:' },
    { key: 'nomeProduto', label: 'Produto:' },
    { key: 'inclusao', label: 'Inclusão:' },
    { key: 'alteracao', label: 'Última alteração:' },
    { key: 'descricao', label: 'Descrição:' }
  ];
  useEffect(() => {
    listarEmpresasContaEscrow()
      .then(res => {
        setLstEmpresas(res.data ?? false);
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      });
  }, []);

  useEffect(() => {
    const lstProdutosEscrow = [PRODUTOS.BOLETO_ESCROW, PRODUTOS.ESCROW_SIMPLES];
    listarProdutosEscrow(lstProdutosEscrow)
      .then(res => {
        setLstProdutosEscrow(res.data ?? false);
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      });
  }, []);

  useEffect(() => {
    buscarContasEscrow();
  }, [filtroStatus]);

  const buscarContasEscrow = () => {
    const filtros = { status: filtroStatus ? 'A' : 'I' };
    setPending(true);
    listarContasEscrow(cod_cliente, filtros)
      .then(res => {
        setLstContasEscrow(res.data ?? {});
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => setPending(false));
  };

  const incluirAlterarContaEscrow = params => {
    setModalClienteDados({
      openModal: true,
      tituloModal: NotIsEmpty(params._id) ? 'Alterar Conta Escrow' : 'Incluir Conta Escrow',
      conteudoModal: (
        <FormContaEscrow
          formDados={params}
          cod_cliente={cod_cliente}
          buscarContasEscrow={buscarContasEscrow}
          lstBancosAgencia={lstBancosAgencia}
          lstEmpresas={lstEmpresas}
          lstProdutosEscrow={lstProdutosEscrow}
          setAlerta={setAlerta}
          handleClose={() => setModalClienteDados({})}
        />
      ),
      onClose: false
    });
  };

  const alteraStatusContaEscrow = conta => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: `Deseja realmente ${
        conta.status === 'A' ? 'inativar' : 'ativar'
      } esta conta escrow do cliente?`,
      funcaoConfirmacao: () => {
        const params = {
          cod_cliente: cod_cliente,
          status: conta.status === 'A' ? 'I' : 'A',
          _id: conta._id
        };
        setPending(true);
        alterarStatusContaEscrow(params)
          .then(res => {
            buscarContasEscrow();
            setAlerta({
              mensagem: `Conta ${conta.status === 'A' ? 'Inativada' : 'Ativada'} com sucesso`,
              tipo: 'success'
            });
          })
          .catch(err => {
            setPending(false);
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          });
      }
    });
  };

  return (
    <BoxPaper
      iconeTitulo={<CurrencyExchange />}
      labelTitulo="Contas de cobrança escrow"
      sx={isPending ? { position: 'relative' } : {}}
    >
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      <Box sx={{ mt: '-85px', float: 'left' }}>
        <Typography variant="subtitle1">Filtrar Status:</Typography>
        <FormControlLabel
          control={<IOSSwitch />}
          onClick={e => setFiltroStatus(!filtroStatus)}
          checked={filtroStatus}
          sx={{ ml: '20px', mr: 0 }}
          disabled={isPending}
        />
      </Box>
      {escopoDadosBancarios && escopoDadosBancarios.permite_incluir && (
        <ButtonMain
          size="medium"
          tipoBotao="azul-escuro"
          className="w-auto float-right"
          sx={{ mt: '-70px', mr: '60px' }}
          onClick={() => incluirAlterarContaEscrow({})}
          disabled={
            isPending ||
            lstBancosAgencia === false ||
            lstEmpresas === false ||
            lstProdutosEscrow === false
          }
        >
          Adicionar <Add />
        </ButtonMain>
      )}
      {lstContasEscrow && Object.keys(lstContasEscrow).length > 0 ? (
        lstContasEscrow.map(conta => (
          <Box className="box-registro" key={conta._id}>
            <LinhaDados
              label="Banco:"
              valor={
                conta.descrBanco && NotIsEmpty(conta.descrBanco) ? conta.descrBanco : valorIndefinido
              }
            >
              <Box className="flex-box-items-direita w-100 pad-top-10">
                <FormControlLabel
                  control={<IOSSwitch disabled={!escopoDadosBancarios.permite_alterar} />}
                  onClick={e => alteraStatusContaEscrow(conta)}
                  checked={conta.status === 'A'}
                  sx={{ ml: 0, mr: 0 }}
                  disabled={isPending}
                />
                {escopoDadosBancarios && escopoDadosBancarios.permite_alterar && (
                  <ButtonMain
                    size="small"
                    variant="outlined"
                    className="botao-alterar-excluir"
                    onClick={() => incluirAlterarContaEscrow(conta)}
                    sx={{ ml: '20px' }}
                    disabled={
                      isPending ||
                      lstBancosAgencia === false ||
                      lstEmpresas === false ||
                      lstProdutosEscrow === false
                    }
                  >
                    <ModeEditOutlined />
                  </ButtonMain>
                )}
              </Box>
            </LinhaDados>
            {camposContaEscrow.map(info => (
              <LinhaDados
                key={`${conta._id}_${info.key}`}
                label={info.label}
                valor={
                  conta[info.key] && NotIsEmpty(conta[info.key]) ? conta[info.key] : valorIndefinido
                }
                boxClass={info.key === 'alteracao' && IsEmpty(conta.usuarioAlteracao) ? 'hidden' : ''}
              />
            ))}
          </Box>
        ))
      ) : (
        <Box className="w-100 flex-box items-centro-verticalmente items-centro">
          <Alert severity="info" className="texto-centro">
            Não há contas correntes de cobrança escrow cadastradas.
          </Alert>
        </Box>
      )}
    </BoxPaper>
  );
};
export default memo(ContasEscrow);
