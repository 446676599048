import React from 'react';
import BoxInputMain from './BoxInputMain';
import AutocompleteMainDefault from './AutocompleteMainDefault';

const AutocompleteMain = ({ children, boxMain = true, propsBox = {}, ...props }) => (<>
    {boxMain ? (
      <BoxInputMain {...propsBox}>
        <AutocompleteMainDefault {...props}>
          {children}
        </AutocompleteMainDefault>
      </BoxInputMain>
    ) : (
      <>
        <AutocompleteMainDefault {...props}>
          {children}
        </AutocompleteMainDefault>
      </>
    )}
</>);
export default AutocompleteMain;
