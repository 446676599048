import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { Feed, ModeEditOutlined } from '@mui/icons-material';
import ButtonMain from '../../../Shared/ButtonMain';
import FormContrato from './Formularios/FormContrato';
import { INDENTIFICACAOCERTIFICADORA } from '../../../Shared/Enums/IdentificacaoCertificadora';
import DetalhesGarantias from './DetalhesGarantias';

const DetalhesContrato = ({
  escopoClienteDados,
  setAlerta,
  setModalClienteDados,
  lstTipos,
  dadosContrato,
  codCliente,
  funcaoSucesso
}) => {
  const [dadosDetalhesContrato, setDadosDetalhesContrato] = useState([]);
  const valorIndefinido = <em className="texto-cor-cinza"> - - </em>;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (dadosContrato) {
      setDadosDetalhesContrato(dadosContrato);
    }
  }, [dadosContrato]);

  const modalEditarContratos = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Editar contrato',
      maxWidth: 'md',
      conteudoModal: (
        <FormContrato
          formDados={dadosDetalhesContrato}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          codCliente={codCliente}
          funcaoSucesso={funcaoSucesso}
          setDadosDetalhesContrato={setDadosDetalhesContrato}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  return (
    <Box className="box-paper-body-detalhes">
      <Box className="texto-negrito font-size-1-3-rem">{dadosDetalhesContrato.NOMEEMPRESA ?? '- -'}</Box>
      <Box>
        <b>ID: </b> {dadosDetalhesContrato.id ?? ''}
      </Box>
      <hr />

      <Grid container spacing={3} className="box-paper-header-detalhes margin-top-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="flex-box-items-direita">
          <Box className="box-icone-paper-header">
            <Feed />
          </Box>
        </Grid>
        <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="titulo">
          Informações do contrato
        </Grid>
      </Grid>

      <Grid container spacing={3} className="pad-10 margin-top-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="flex-box-items-direita texto-negrito">
          Formalizada:
        </Grid>
        <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.FORMALIZADA === 'S' ? 'Sim' : 'Não'}
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="flex-box-items-direita pad-0">
          {escopoClienteDados && escopoClienteDados.permite_alterar && (
            <ButtonMain
              size="small"
              variant="outlined"
              className="botao-alterar-excluir"
              onClick={() => modalEditarContratos()}
            >
              <ModeEditOutlined />
            </ButtonMain>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Contrato novo:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.CONTRATONOVO === 'S' ? 'Sim' : 'Não'}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Tipo de contrato:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.NOMETIPOCONTRATO
            ? dadosDetalhesContrato.NOMETIPOCONTRATO
            : valorIndefinido}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Data de assinatura da certificadora:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.DATAASSINATURACERTIFICADORA
            ? moment(dadosDetalhesContrato.DATAASSINATURACERTIFICADORA).format('DD/MM/yyyy')
            : valorIndefinido}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Identificação da certificadora:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {INDENTIFICACAOCERTIFICADORA[dadosDetalhesContrato.INDENTIFICACAOCERTIFICADORA] ? (
            <>{INDENTIFICACAOCERTIFICADORA[dadosDetalhesContrato.INDENTIFICACAOCERTIFICADORA]}</>
          ) : (
            valorIndefinido
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Código do contrato na certificadora:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.CODCONTRATOCERTIFICADORA
            ? dadosDetalhesContrato.CODCONTRATOCERTIFICADORA
            : valorIndefinido}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Aditivos:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.TOTALADITIVOS
            ? dadosDetalhesContrato.TOTALADITIVOS
            : valorIndefinido}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Número mínimo de asinaturas:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.MINIMOASSINATURAS
            ? dadosDetalhesContrato.MINIMOASSINATURAS
            : valorIndefinido}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Data de emissão do contrato:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.DATAEMISSAOCONTRATO
            ? moment(dadosDetalhesContrato.DATAEMISSAOCONTRATO).format('DD/MM/yyyy')
            : valorIndefinido}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="pad-10">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Inclusão:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.DTHRINSERT
            ? `${moment(dadosDetalhesContrato.DTHRINSERT).format('DD/MM/yyyy')} às ${moment(dadosDetalhesContrato.DTHRINSERT).format('HH:mm:ss')} por ${dadosDetalhesContrato.NOMEINCLUSAO}`
            : valorIndefinido}
        </Grid>
      </Grid>
      {dadosDetalhesContrato &&
        dadosDetalhesContrato.DTHRUPDATE &&
        dadosDetalhesContrato.NOMEALTERACAO && (
          <Grid container spacing={3} className="pad-10">
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={3}
              xs={2}
              className="texto-negrito flex-box-items-direita"
            >
              Última alteração:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
              {`${moment(dadosDetalhesContrato.DTHRUPDATE).format('DD/MM/yyyy')} às ${moment(dadosDetalhesContrato.DTHRUPDATE).format('HH:mm:ss')} por ${dadosDetalhesContrato.NOMEALTERACAO}`}
            </Grid>
          </Grid>
        )}
      <Grid container spacing={3} className="pad-10 margin-bottom-25">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="texto-negrito flex-box-items-direita">
          Vigência:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
          {dadosDetalhesContrato && dadosDetalhesContrato.VIGENCIA
            ? moment(dadosDetalhesContrato.VIGENCIA).format('DD/MM/yyyy')
            : valorIndefinido}
        </Grid>
      </Grid>

      <DetalhesGarantias
        escopoClienteDados={escopoClienteDados}
        setAlerta={setAlerta}
        setModalClienteDados={setModalClienteDados}
        lstTipos={lstTipos}
        dadosContrato={dadosContrato}
      />
    </Box>
  );
};
export default memo(DetalhesContrato);
