import React, { memo } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const ModalCliente = ({
  openModal = false,
  tituloModal = '',
  conteudoModal = <></>,
  setModalClienteDados,
  maxWidth = 'lg',
  onClose = true
}) => {
  const handleClose = () => setModalClienteDados({});

  return (
    <Dialog
      open={openModal ?? false}
      fullWidth={true}
      maxWidth={maxWidth}
      keepMounted
      onClose={onClose ? handleClose : () => {}}
      className="modal-cliente"
    >
      <DialogTitle>
        <Box className="borda-bottom-1 borda-cor-principal pad-topbot-5">
          <span className="titulo-modal-info-gerais texto-negrito">{tituloModal}</span>
          <IconButton onClick={handleClose} className="icon-close-alert float-right">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="content-modal-cliente">{conteudoModal}</DialogContent>
    </Dialog>
  );
};
export default memo(ModalCliente);
