import {
  buscarTiposAtividade,
  buscarTiposConstituicao,
  buscarTiposEstadoCivil,
  buscarTiposNaturezaJuridica,
  buscarTiposPorteEmpresa,
  buscarTiposRegimeCasamento,
  buscarTiposRegimeTributario,
  buscarTiposScoreRatings
} from '../../../../api/formalizacao';

export const buscarListasTiposPessoa = async (lstTipos, setLstTipos, telaPai, lstBuscar = []) => {
  const lstTiposTemp = {};
  // BUSCA TIPOS DE CONSTITUIÇÃO
  if (lstBuscar.length === 0 || lstBuscar.includes('constituicao')) {
    lstTiposTemp.constituicao =
      lstTipos === false || lstTipos.constituicao.length === 0
        ? await buscarTiposConstituicao(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.constituicao;
  }
  // BUSCA TIPOS DE ATIVIDADE
  if (lstBuscar.length === 0 || lstBuscar.includes('atividade')) {
    lstTiposTemp.atividade =
      lstTipos === false || lstTipos.atividade.length === 0
        ? await buscarTiposAtividade(telaPai)
            .then(async res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.atividade;
  }
  // BUSCA TIPOS DE NATUREZA JURÍDICA
  if (lstBuscar.length === 0 || lstBuscar.includes('naturezaJuridica')) {
    lstTiposTemp.naturezaJuridica =
      lstTipos === false || lstTipos.naturezaJuridica.length === 0
        ? await buscarTiposNaturezaJuridica(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.naturezaJuridica;
  }
  // BUSCA TIPOS DE REGIME TRIBUTÁRIO
  if (lstBuscar.length === 0 || lstBuscar.includes('regimeTributario')) {
    lstTiposTemp.regimeTributario =
      lstTipos === false || lstTipos.regimeTributario.length === 0
        ? await buscarTiposRegimeTributario(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.regimeTributario;
  }
  // BUSCA TIPOS DE PORTES DE EMPRESA
  if (lstBuscar.length === 0 || lstBuscar.includes('portesEmpresa')) {
    lstTiposTemp.portesEmpresa =
      lstTipos === false || lstTipos.portesEmpresa.length === 0
        ? await buscarTiposPorteEmpresa(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.portesEmpresa;
  }
  // BUSCA TIPOS DE ESTADOS CIVIS
  if (lstBuscar.length === 0 || lstBuscar.includes('estadoCivil')) {
    lstTiposTemp.estadoCivil =
      lstTipos === false || lstTipos.estadoCivil.length === 0
        ? await buscarTiposEstadoCivil(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.estadoCivil;
  }
  // BUSCA TIPOS DE REGIME DE CASAMENTO
  if (lstBuscar.length === 0 || lstBuscar.includes('regimeCasamento')) {
    lstTiposTemp.regimeCasamento =
      lstTipos === false || lstTipos.regimeCasamento.length === 0
        ? await buscarTiposRegimeCasamento(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.regimeCasamento;
  }
  // BUSCA TIPOS DE SCORE RATINGS
  if (telaPai === 'DadosCliente' && lstBuscar.length !== 0 && lstBuscar.includes('scoreRatings')) {
    lstTiposTemp.scoreRatings =
      lstTipos === false || lstTipos.scoreRatings.length === 0
        ? await buscarTiposScoreRatings()
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.scoreRatings;
  }
  setLstTipos(lstTiposTemp);
  return lstTiposTemp;
};

export const formatarValorMonetarioPtBrEmReais = valor => {
  if (valor === undefined || valor === null) {
    return '';
  }

  const valorFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    valor
  );

  return valorFormatado;
};

export const extrairValorFaturamentoAnual = inputString => {
  if (
    inputString &&
    inputString !== undefined &&
    inputString !== null &&
    inputString !== 'SEM EMP DEFINIDA--'
  ) {
    const valorFaturamento = inputString.split('-');
    return formatarValorMonetarioPtBrEmReais(valorFaturamento[1]);
  }

  return 'NÃO INFORMADO!';
};
