import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { Chart } from 'react-google-charts';
import LoaderMain from '../../Shared/LoaderMain';

const LeadsQualificados = () => {
  const [isPending, setIsPending] = useState(false);
  const [dadosChart, setDadosChart] = useState(false);
  const [filtroData, setFiltroData] = useState(30);

  const handleChange = event => {
    setFiltroData(event.target.value);
  };

  const options = {
    title: null,
    width: '100%',
    chartArea: { width: '85%', right: 10, left: 50 },
    legend: {
      position: 'bottom',
      maxLines: 3,
      alignment: 'center',
      textStyle: { fontSize: 12 }
    },
    animation: {
      duration: 1000,
      easing: 'out'
    },
    pointSize: 7,
    series: {
      0: { pointShape: 'circle', color: '#79BCFB' },
      1: { pointShape: 'diamond', color: '#F97A7B' },
      2: { pointShape: 'polygon', color: '#ff6600' },
      3: { pointShape: 'square', color: '#376384' },
      4: { pointShape: 'triangle', color: '#800080' }
    }
  };

  useEffect(() => {
    setIsPending(true);
    setTimeout(() => {
      setIsPending(false);
      setDadosChart([
        ['Dias', 'Agosto', 'Setembro', 'Outubro'],
        ['5', 4000, 6000, 7000],
        ['10', 5500, 7500, 4500],
        ['15', 3100, 5100, 8100],
        ['20', 5100, 4100, 6100],
        ['25', 7300, 6300, 7300],
        ['30', 6500, 7100, 9100]
      ]);
    }, 2500);
  }, []);

  /*
  const buscaliquidezCarteira = () => {

    setIsPending(true);
    postLiquidezCarteira(params)
      .then(res => {
        let arrayDados = [];
        arrayDados.push([...labelsCharts]);
        let lstDados = res && res.data && res.data.lista ? res.data.lista : [];
        let periodo =
          res && res.data && res.data.periodo && res.data.periodo.inicio
            ? {
                inicio: moment(res.data.periodo.inicio).format('MMM[/]YY'),
                final: moment(res.data.periodo.final).format('MMM[/]YY')
              }
            : false;
        lstDados.map(dados => {
          let mesAno =
            dados.dataMesAno !== 'ultimos_meses'
              ? moment(dados.dataMesAno).format('MMM[/]YY')
              : 'Meses anteriores';

          let emDia = parseFloat(dados.emDiaPercentual.toFixed(2));
          let atrasado = parseFloat(dados.atrasadoPercentual.toFixed(2));
          let vencido = parseFloat(dados.vencidoPercentual.toFixed(2));
          let recomprado = parseFloat(dados.recompradoPercentual.toFixed(2));
          let perdido = parseFloat(dados.perdidoPercentual.toFixed(2));

          arrayDados.push([mesAno, emDia, atrasado, vencido, recomprado, perdido]);
          return dados;
        });

        if (lstDados.length > 0) {
          setDadosChart(arrayDados);
        }

        setPeriodoChart(periodo);
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('Liquidez');
      });
  };
  */

  return (
    <Card className="chart-card-qualificados elemento-desabilitado">
      {dadosChart ? (
        <CardContent
          className="ajuste-spin-chart"
          sx={isPending ? { position: 'relative' } : {}}
        >
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container className="grid-label-qualificados">
            <Grid item lg={8} md={8} sm={8} xs={12}>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro pad-5 word-break"
              >
                <span className="font-size-1rem margin-right-10 texto-weight-600">
                  Leads qualificados do Marketing (MQL)
                </span>
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={3} xs={12}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={filtroData}
                onChange={handleChange}
              >
                <MenuItem value={30}>Últimos 30 dias</MenuItem>
                <MenuItem value={90}>Últimos 3 meses</MenuItem>
                <MenuItem value={180}>Últimos 6 meses</MenuItem>
                <MenuItem value={360}>Últimos 12 meses</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Chart
            className="chartLeadsQualificados"
            chartType="LineChart"
            width="100%"
            height="450px"
            data={dadosChart}
            options={options}
            chartLanguage="pt-Br"
          />
        </CardContent>
      ) : (
        <CardContent>
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default LeadsQualificados;
