export const avalista_tipos_assinaturas = {
  ASSINACONTRATO: { _id: 'ASSINACONTRATO', label: 'Assina Contato' },
  ASSINAOPERACAO: { _id: 'ASSINAOPERACAO', label: 'Assina Operação' },
  ASSINACCB: { _id: 'ASSINACCB', label: 'Assina CCB' }
};

export const representante_tipos_assinaturas = {
  ASSINATERMOCESSAO: { _id: 'ASSINATERMOCESSAO', label: 'Assina Termo de Cessão' },
  ASSINAPORENDOSSO: { _id: 'ASSINAPORENDOSSO', label: 'Assina por Endosso' },
  ASSINASOZINHO: { _id: 'ASSINASOZINHO', label: 'Assina Sozinho' },
  EMITEDUPLICATA: { _id: 'EMITEDUPLICATA', label: 'Emite Duplicata' }
};

export const procurador_tipos_assinaturas = {
  ASSINATERMOCESSAO: { _id: 'ASSINATERMOCESSAO', label: 'Assina Termo de Cessão' },
  ASSINAPORENDOSSO: { _id: 'ASSINAPORENDOSSO', label: 'Assina por Endosso' },
  ASSINASOZINHO: { _id: 'ASSINASOZINHO', label: 'Assina Sozinho' },
  EMITEDUPLICATA: { _id: 'EMITEDUPLICATA', label: 'Emite Duplicata' }
};
