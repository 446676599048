import { Box } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import Contratos from './Contratos';
import LoaderMain from '../../../Shared/LoaderMain';
import PerifericosEmpresa from './PerifericosEmpresa';

const FormalizacaoContratos = ({
  cod_cliente,
  setAlerta,
  setModalClienteDados,
  escopoClienteDados,
  setSubTitulo,
  setMostrarTela,
  cliente,
  operaConnect,
  setEsconderTelaComplementar
}) => {
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPending(false);
    }, 500);
  }, []);

  return (
    <Box className="dados-cliente" sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      {!isPending && (
        <>
          <Contratos
            setAlerta={setAlerta}
            setModalClienteDados={setModalClienteDados}
            escopoClienteDados={escopoClienteDados}
            codCliente={cod_cliente}
            setSubTitulo={setSubTitulo}
            setMostrarTela={setMostrarTela}
            cliente={cliente}
            operaConnect={operaConnect}
            setEsconderTelaComplementar={setEsconderTelaComplementar}
          />

          <PerifericosEmpresa codCliente={cod_cliente} setAlerta={setAlerta} />
        </>
      )}
    </Box>
  );
};
export default memo(FormalizacaoContratos);
