import API from './api';

export const getLeadsGrowth = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/leads`, params);

export const postAlterarTagLead = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/alterar-tag-lead`, params);

export const postAlterarDataHoraAgendamentoLead = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/alterar-data-agendamento`, params);

export const buscaCrmMotivos = motivo =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/motivos`, {
    params: { tipo: motivo }
  });

export const buscaFunilVendas = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/funil-vendas`, params);

export const getPlataformas = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/plataformas`);

export const getGerentes = () => API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/gerentes`);

export const getUsuariosPlaformaCrm = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/busca-usuarios`);

export const alterarResponsavelLeads = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/alterar-responsavel-leads`, params);

export const postImportarLeads = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/importar-leads`, params);

export const postDadosEmpresaReceitaFederal = params =>
  API.post(`${process.env.REACT_APP_API_AUTOMACAO_BASE_URL}/receita/consultarEmpresa`, params);

export const getTiposOrigem = params =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/tipos-origem`, params);
