import React from 'react';
import { Box, FormControlLabel, Typography } from '@mui/material';
import 'moment/locale/pt-br';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AccountCircle } from '@mui/icons-material';
import { mascararCpfCnpj } from '../../../Utils/format';
import IOSSwitch from '../shared/IOSSwitch';
import CopyValorTexto from '../../../Shared/CopyValorTexto';

const definicaoDeColunas = (acessarDadosPessoa, alterarStatusPessoa, escopoClienteDados) => {
  const config = [
    {
      field: 'STATUS',
      headerName: 'Status',
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white texto-centro',
      renderCell: params => (
        <FormControlLabel
          control={<IOSSwitch disabled={!escopoClienteDados.permite_alterar} />}
          onClick={e => alterarStatusPessoa(params.row)}
          checked={params.row.STATUS === 'A'}
          sx={{ ml: 0, mr: 0 }}
        />
      )
    },
    {
      field: 'VERIFICACAO',
      headerName: '',
      flex: 1,
      minWidth: 60,
      maxWidth: 60,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      renderCell: params =>
        params.row.VERIFICACAO === 'S' ? (
          <AccountCircle className="texto-cor-preta font-size-2-3rem" />
        ) : (
          <AccountCircle className="texto-cor-preta font-size-2-3rem" />
          // IRÁ VOLTAR EM ALGUM MOMENTO
          // <Error className="texto-warning font-size-1-6rem" />
        )
    },
    {
      field: 'NOME',
      headerName: 'Nome e documento',
      flex: 1,
      minWidth: 150,
      headerClassName: 'font-bold-header',
      cellClassName: 'data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      renderCell: params => (
        <Box className="flex-box-items-esquerda w-100">
          <Box className="display-block w-100">
            <Box className="texto-nowrap-overflow-dotted" title={params.row.NOME}>
              {params.row.NOME}
            </Box>
            <Box
              className="texto-nowrap-overflow-dotted"
              title={mascararCpfCnpj(String(params.row.CPFCNPJ))}
            >
              {mascararCpfCnpj(String(params.row.CPFCNPJ))}              
              {CopyValorTexto({ texto: params.row.CPFCNPJ })}
            </Box>
          </Box>
        </Box>
      )
    },
    {
      field: 'TIPO',
      headerName: 'Tipo',
      flex: 1,
      minWidth: 60,
      maxWidth: 100,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'PPE',
      headerName: 'PPE',
      flex: 1,
      minWidth: 70,
      maxWidth: 100,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'REPRESENTANTE',
      headerName: 'Representante',
      flex: 1,
      minWidth: 100,
      maxWidth: 140,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'AVALISTA',
      headerName: 'Avalista',
      flex: 1,
      minWidth: 90,
      maxWidth: 110,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'SOCIO',
      headerName: 'Sócio',
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'ASSINACONTRATO',
      headerName: 'Assi. contrato',
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'ASSINAOPERACAO',
      headerName: 'Assi. operação',
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'ASSINACCB',
      headerName: 'Assi. CCB',
      flex: 1,
      minWidth: 110,
      maxWidth: 140,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR')
    },
    {
      field: 'abrir_pessoa',
      headerName: '',
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortable: false,
      renderCell: params => (
        <Typography
          className="novas-entradas-abrir"
          variant="h7"
          onClick={() => acessarDadosPessoa(params.row)}
        >
          Editar
          <ChevronRightIcon sx={{ fontSize: '20px', mt: 0 }} />
        </Typography>
      )
    }
  ];
  return config;
};

export default definicaoDeColunas;
