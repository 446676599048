import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, Popover, Radio, RadioGroup } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box } from '@mui/system';

const PopoverOptions = ({
  id = 'popover-padrao',
  labelButton = 'Abrir',
  anchorVertical = 'bottom',
  anchrHorizontal = 'left',
  opcoes = [],
  onChangeRadios = null,
  onChangeOpcao = null,
  opcaoSelecionadaExterna = '',
  setOpcaoSelecionadaExterna = null,
  valorDefault = null,
  prefixIconButton = null,
  posfixIconButton = null,
  classButton = '',
  classPopover = '',
  onPopoverOpen = null,
  onPopoverClose = null,
  abrirFecharPopover = null,
  children,
  ...restProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const initialValue = valorDefault ? valorDefault : opcoes && opcoes.length > 0 ? opcoes[0].valor : null;
  const [valorSelecionado, setValorSelecionado] = useState(initialValue);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState(null);

  useEffect(() => {
    if (valorSelecionado && opcoes && opcoes.length > 0) {
      const opcaoSelecionadaTemp = opcoes.filter(opt => String(opt.valor) === String(valorSelecionado));
      if (opcaoSelecionadaTemp) {
        if (setOpcaoSelecionadaExterna) {
          setOpcaoSelecionadaExterna(opcaoSelecionadaTemp[0]);
        } else {
          setOpcaoSelecionada(opcaoSelecionadaTemp[0]);
          setAnchorEl(null);
        }
        if (onChangeOpcao) onChangeOpcao(opcaoSelecionadaTemp[0]);
      }
    }
  }, [valorSelecionado]);

  useEffect(() => {
    if (setOpcaoSelecionadaExterna && opcaoSelecionadaExterna) {
      setOpcaoSelecionada(opcaoSelecionadaExterna);
      setAnchorEl(null);
    }
  }, [opcaoSelecionadaExterna]);

  const handleOnChangeRadios = event => {
    setValorSelecionado(String(event.target.value));
    if (onChangeRadios) onChangeRadios(event);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (onPopoverOpen) onPopoverOpen(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValorSelecionado(opcaoSelecionada && opcaoSelecionada.valor ? String(opcaoSelecionada.valor) : null);
    if (onPopoverClose) onPopoverClose();
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Button 
        id={`btn_${id}`}
        aria-describedby={`pop_${id}`}
        variant="outlined"
        onClick={handleClick}
        className={`btn-popover-opts ${classButton}`}
      >
        {prefixIconButton ?? ''}
        {opcaoSelecionada && opcaoSelecionada.label
          ? opcaoSelecionada.label
          : labelButton}
        {posfixIconButton ?? ''}
        {open ? (
          <KeyboardArrowUp className="arrow-popover" />
        ) : (
          <KeyboardArrowDown className="arrow-popover" />
        )}
      </Button>
      <Popover
        id={`pop_${id}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorVertical,
          horizontal: anchrHorizontal,
        }}
        sx={{ mr: '15px' }}
      >
        <Box className={`popover-opts ${classButton}`}>
          {opcoes && opcoes.length > 0 && (
            <RadioGroup
              name={id}
              id={id}
              onChange={handleOnChangeRadios}
              value={valorSelecionado}
            >
              {opcoes.map(opt => (
                <FormControlLabel
                  key={opt.valor}
                  value={opt.valor}
                  control={<Radio color="default" />}
                  label={opt.label}
                  className="popover-radio-option"
                />
              ))}
            </RadioGroup>
          )}
          {children ?? ''}
        </Box>
      </Popover>
    </Box>
  );
};
export default PopoverOptions;
