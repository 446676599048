import React, { memo, useState } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import 'moment/locale/pt-br';
import { focusInput } from '../../../../Utils/utils';
import LoaderMain from '../../../../Shared/LoaderMain';
import TextFieldMain from '../../../../Shared/TextFieldMain';
import ButtonMain from '../../../../Shared/ButtonMain';
import { IsEmpty, NotIsEmpty } from '../../../../Utils/format';
import AutocompleteMain from '../../../../Shared/AutocompleteMain';
import RadioGroupMain from '../../../../Shared/RadioGroupMain';
import { alterarContaCorrente, incluirContaCorrente } from '../../../../../api/formalizacao';

const FormContaCorrente = ({
  formDados = {},
  cod_cliente,
  buscarContasCorrente,
  lstBancosAgencia,
  setAlerta,
  handleClose
}) => {
  const [isPendingSalvar, setPendingSalvar] = useState(false);

  let agencias = [];
  if (NotIsEmpty(formDados.codBanco) && NotIsEmpty(formDados.codAgencia)) {
    agencias = [...lstBancosAgencia].filter(banco => String(banco._id) === String(formDados.codBanco))[0]
      .agencias;
  }

  const submitFormulario = (values, form) => {
    const params = { ...values };
    params.codBanco = NotIsEmpty(params.banco) && NotIsEmpty(params.banco._id) ? params.banco._id : null;
    params.codAgencia =
      NotIsEmpty(params.agencia) && NotIsEmpty(params.agencia._id) ? params.agencia._id : null;
    params.digitoAgencia =
      NotIsEmpty(params.agencia) && NotIsEmpty(params.agencia.digitoAgencia)
        ? params.agencia.digitoAgencia
        : null;
    params._id = NotIsEmpty(formDados._id) ? formDados._id : '';
    params.cod_cliente = cod_cliente;

    const funcaoSalvar = IsEmpty(params._id) ? incluirContaCorrente : alterarContaCorrente;
    setPendingSalvar(true);
    funcaoSalvar(params)
      .then(res => {
        handleClose();
        buscarContasCorrente();
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingSalvar(false);
        form.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        banco: NotIsEmpty(formDados.codBanco)
          ? [...lstBancosAgencia].filter(banco => String(banco._id) === String(formDados.codBanco))[0]
          : null,
        agencia:
          NotIsEmpty(formDados.codAgencia) && agencias.length > 0
            ? [...agencias].filter(tipo => String(tipo._id) === String(formDados.codAgencia))[0]
            : null,
        tipoConta: NotIsEmpty(formDados.tipoConta) ? formDados.tipoConta : 'C',
        conta: NotIsEmpty(formDados.conta) ? formDados.conta : '',
        digitoConta: NotIsEmpty(formDados.digitoConta) ? formDados.digitoConta : ''
      }}
      onSubmit={(values, form) => {
        const errors = {};
        const { ...valores } = values;

        if (IsEmpty(valores.banco)) {
          errors.banco = 'Banco é obrigatório';
        }

        if (IsEmpty(valores.agencia)) {
          errors.agencia = 'Agência é obrigatória';
        }

        if (IsEmpty(valores.conta)) {
          errors.conta = 'Nr. da Conta é obrigatório';
        } else if (String(valores.conta).length > 10) {
          errors.conta = 'Nr. da conta não deve conter mais que 10 dígitos';
        }

        if (IsEmpty(valores.digitoConta)) {
          errors.digitoConta = 'Nr. do dígito da Conta é obrigatório';
        } else if (String(valores.digitoConta).length > 3) {
          errors.digitoConta = 'Nr. do dígito da Conta não deve conter mais que 3 dígitos';          
        }

        if (IsEmpty(valores.tipoConta)) {
          errors.tipoConta = 'Tipo é obrigatório';
        }

        form.setErrors(errors);
        if (Object.keys(errors).length <= 0) {
          submitFormulario(valores, form);
        } else {
          form.setSubmitting(false);
          focusInput(Object.keys(errors)[0]);
        }
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          {isPendingSalvar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Banco:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {lstBancosAgencia && Array.isArray(lstBancosAgencia) && lstBancosAgencia.length > 0 ? (
                <Field
                  id="banco"
                  name="banco"
                  component={AutocompleteMain}
                  boxMain={false}
                  propsInput={{
                    placeholder: 'Selecione o banco'
                  }}
                  disabled={isSubmitting ? true : false}
                  paramLabel="nome"
                  options={[...lstBancosAgencia]}
                />
              ) : lstBancosAgencia &&
                Array.isArray(lstBancosAgencia) &&
                lstBancosAgencia.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Agência:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="agencia"
                name="agencia"
                component={AutocompleteMain}
                boxMain={false}
                propsInput={{
                  placeholder: 'Selecione o agência'
                }}
                disabled={isSubmitting ? true : false}
                paramLabel="nome"
                options={
                  values.banco
                    ? values.banco.agencias
                    : [{ _id: '', nome: 'Favor selecionar um banco primeiro' }]
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Conta:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="conta"
                name="conta"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isPendingSalvar}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Dígito:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="digitoConta"
                name="digitoConta"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isPendingSalvar}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Tipo:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="tipoConta"
                name="tipoConta"
                component={RadioGroupMain}
                disabled={isPendingSalvar}
                row
                options={[
                  { label: 'Conta Corrente', value: 'C' },
                  { label: 'Poupança', value: 'P' }
                ]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ m: '20px 0' }}>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="transparente-com-bordas"
                disabled={isSubmitting}
                onClick={handleClose}
              >
                Cancelar
              </ButtonMain>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="azul-escuro"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Salvar
              </ButtonMain>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default memo(FormContaCorrente);
