import React, { memo } from 'react';
import { Box, Button, Collapse, Grid, Paper } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material/';
import ErrorIcon from '@mui/icons-material/Error';
import iconPdf from '../../../../../assets/img/icon-pdf.svg';
import iconWord from '../../../../../assets/img/icon-word.svg';

const BoxPaperDocumentos = ({
  labelTitulo,
  children,
  isPending,
  verContrato,
  rota,
  nome,
  index,
  CamposFaltantes,
  blocoAberto,
  alteraEstadohandleBlocoAbertoIndex
}) => (
  <Paper elevation={0} className="box-paper container-campos-documentos">
    <Grid container spacing={2} className="box-paper-header">
      <Grid
        item
        xl={8}
        lg={8}
        md={8}
        sm={8}
        xs={8}
        className="titulo flex-box-items-esquerda"
        onClick={CamposFaltantes ? () => alteraEstadohandleBlocoAbertoIndex(!blocoAberto, index) : null}
      >
        {labelTitulo}
        <Box className="margin-left-5">
          {CamposFaltantes && (
            <ErrorIcon
              className="font-size-1-6rem texto-warning margin-top-5"
              title="Há itens faltantes neste documento."
            />
          )}
        </Box>
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="titulo flex-box-items-direita">
        <Grid container className="pad-0 flex-box justify-content-flex-end">
          <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className="titulo flex-box-items-direita">
            <Button
              className="btn-ver-contrato margin-right-10"
              onClick={() => verContrato('pdf', rota, nome, index)}
              disabled={isPending}
            >
              <img src={iconPdf} alt="icone" />
            </Button>
            <Button
              className="btn-ver-contrato margin-left-10"
              onClick={() => verContrato('word', rota, nome, index)}
              disabled={isPending}
            >
              <img src={iconWord} alt="icone" />
            </Button>
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
            className="titulo flex-box-items-direita"
            onClick={
              CamposFaltantes ? () => alteraEstadohandleBlocoAbertoIndex(!blocoAberto, index) : null
            }
          >
            {CamposFaltantes &&
              (blocoAberto ? (
                <ExpandLess className="font-size-2-3rem" />
              ) : (
                <ExpandMore className="font-size-2-3rem" />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Collapse in={blocoAberto} className="box-paper-body">
      <Box sx={{ m: '30px 0 0 0' }}>{children}</Box>
    </Collapse>
  </Paper>
);
export default memo(BoxPaperDocumentos);
