import React, { memo, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { focusInput } from '../../../../Utils/utils';
import LoaderMain from '../../../../Shared/LoaderMain';
import ButtonMain from '../../../../Shared/ButtonMain';
import { IsEmpty, NotIsEmpty } from '../../../../Utils/format';
import { buscarListasTiposPessoa } from '../../shared/utils';
import { validaCnpj } from '../../../../Utils/validaCnpj';
import { validaCpf } from '../../../../Utils/validaCpf';
import FieldsPessoaFisica from './FieldsPessoaFisica';
import { buscarPessoaGeral, incluirAlterarTestemunhaCliente } from '../../../../../api/formalizacao';
import TextFieldMain from '../../../../Shared/TextFieldMain';

const FormInclusaoTestemunha = ({
  cod_cliente,
  codTestemunha = '',
  cpfCnpjTestemunha = '',
  dadosAdicionais = {},
  setAlerta,
  handleClose,
  lstTipos,
  setLstTipos,
  lstTestemunhasDadosGrid,
  setLstTestemunhasDadosGrid
}) => {
  const [isPending, setPending] = useState(false);
  const [isPendingSalvar, setPendingSalvar] = useState(false);
  const [dadosPessoa, setDadosPessoa] = useState({});
  const [listaTipos, setListaTipos] = useState(lstTipos);

  useEffect(() => {
    buscarListaTiposPFPJ();
    if (NotIsEmpty(cpfCnpjTestemunha)) {
      buscarPessoaDadosGerais(cpfCnpjTestemunha);
    }
  }, []);

  const buscarListaTiposPFPJ = async () => {
    if (listaTipos === false) {
      setPending(true);
      const listaTiposTemp = await buscarListasTiposPessoa(listaTipos, setLstTipos, 'DadosPessoa', [
        'estadoCivil'
      ]);
      setListaTipos(listaTiposTemp);
      setPending(false);
    }
  };

  const formatarPayload = dadosForm => {
    const valores = { ...dadosForm };
    valores.cod_cliente = cod_cliente;
    valores.codTestemunha = codTestemunha;
    valores.cpfcnpj = valores.cpfcnpj.replace(/\D/g, '');

    if (NotIsEmpty(valores.dataNascimento)) {
      valores.dataNascimento = moment(valores.dataNascimento, 'DD/MM/YYYY');
      if (valores.dataNascimento.isValid()) {
        valores.dataNascimento = valores.dataNascimento.format('YYYY-MM-DD');
      } else {
        valores.dataNascimento = null;
      }
    }

    if (NotIsEmpty(valores.dataRg)) {
      valores.dataRg = moment(valores.dataRg, 'DD/MM/YYYY');
      if (valores.dataRg.isValid()) {
        valores.dataRg = valores.dataRg.format('YYYY-MM-DD');
      } else {
        valores.dataRg = null;
      }
    }

    return { ...valores };
  };

  const validarDadosBasicos = dadosForm => {
    const errors = {};
    const cpfcnpj = dadosForm.cpfcnpj ? dadosForm.cpfcnpj.replace(/\D/g, '') : '';

    if (IsEmpty(dadosForm.nome)) {
      errors.nome = 'Nome da pessoa obrigatório';
    }

    if (IsEmpty(cpfcnpj)) {
      errors.cpfcnpj = 'CPF da pessoa obrigatório';
    } else if (!validaCpf(cpfcnpj)) {
      errors.cpfcnpj = 'CPF da pessoa é inválido';
    }

    if (IsEmpty(dadosForm.genero)) errors.genero = 'Gênero da pessoa obrigatório';

    if (NotIsEmpty(dadosForm.dataNascimento)) {
      if (moment(dadosForm.dataNascimento, 'DD/MM/YYYY').isValid() === false) {
        errors.dataNascimento = 'Data de nascimento invalida';
      }
    }

    if (NotIsEmpty(dadosForm.dataRg)) {
      if (moment(dadosForm.dataRg, 'DD/MM/YYYY').isValid() === false) {
        errors.dataRg = 'Data do RG invalida';
      }
    }

    return errors;
  };

  const submitFormulario = (values, form) => {
    const params = formatarPayload({ ...values });
    setPendingSalvar(true);
    incluirAlterarTestemunhaCliente(params)
      .then(res => {
        if (res && res.data) {
          setAlerta({
            mensagem: 'Dados da testemunha foram salvos com sucesso',
            tipo: 'success'
          });
          if (res.data.dados_grid && setLstTestemunhasDadosGrid) {
            let lstTestemunhasDadosGridTemp = lstTestemunhasDadosGrid
              ? [...lstTestemunhasDadosGrid]
              : [];
            const jaTemNaGrid = lstTestemunhasDadosGridTemp.filter(
              row => parseInt(row.id) === parseInt(res.data.dados_grid.id)
            );
            if (jaTemNaGrid.length === 0) {
              lstTestemunhasDadosGridTemp.push(res.data.dados_grid);
            } else {
              lstTestemunhasDadosGridTemp = lstTestemunhasDadosGrid.map(row =>
                row.id === res.data.dados_grid.id ? res.data.dados_grid : row
              );
            }
            setLstTestemunhasDadosGrid(lstTestemunhasDadosGridTemp);
          }
          handleClose();
        } else {
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        }
      })
      .catch(err => {
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingSalvar(false);
        form.setSubmitting(false);
      });
  };

  const onChangeCpfCnpj = (event, formProps) => {
    let CPFCNPJ = event.target.value;
    CPFCNPJ = NotIsEmpty(CPFCNPJ) ? CPFCNPJ.replace(/\D/g, '') : '';
    if (IsEmpty(CPFCNPJ)) return false;
    if (CPFCNPJ.length <= 11) {
      if (CPFCNPJ.length !== 11) return false;
      else if (!validaCpf(CPFCNPJ)) {
        formProps.setFieldError('cpfcnpj', 'CPF da pessoa é inválido');
        setAlerta({
          mensagem: 'CPF da pessoa é inválido',
          tipo: 'warning'
        });
        return false;
      }
    } else if (CPFCNPJ.length > 11) {
      if (CPFCNPJ.length !== 14) return false;
      else if (!validaCnpj(CPFCNPJ)) {
        formProps.setFieldError('cpfcnpj', 'CNPJ da pessoa é inválido');
        setAlerta({
          mensagem: 'CNPJ da pessoa é inválido',
          tipo: 'warning'
        });
        return false;
      }
    }

    buscarPessoaDadosGerais(CPFCNPJ, formProps.setFieldValue);
  };

  const buscarPessoaDadosGerais = (CPFCNPJ, setFieldValue = false) => {
    setPending(true);
    buscarPessoaGeral(CPFCNPJ)
      .then(res => {
        const dados = res.data && typeof res.data === 'object' ? res.data : {};
        if (Object.values(dados).length > 0) {
          const preencherCampos = [
            'nome',
            'dataNascimento',
            'genero',
            'codEstadoCivil',
            'rg',
            'orgaoRg',
            'dataRg',
            'nacionalidade',
            'naturalidade',
            'nomeMae',
            'nomePai',
            'profissao',
            'politicamenteExposta'
          ];
          const camposForm = {};
          preencherCampos.map(campo => {
            if (dados[campo.toUpperCase()] && NotIsEmpty(dados[campo.toUpperCase()])) {
              if (typeof setFieldValue === 'function') {
                setFieldValue(campo, dados[campo.toUpperCase()]);
              } else {
                camposForm[campo] = dados[campo.toUpperCase()];
              }
            }
            return campo;
          });
          if (typeof setFieldValue !== 'function' && NotIsEmpty(cpfCnpjTestemunha)) {
            camposForm.cpfcnpj = cpfCnpjTestemunha;
            setDadosPessoa(camposForm);
          }
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Box>
      {((NotIsEmpty(cpfCnpjTestemunha) && isPending) || isPendingSalvar) && (
        <LoaderMain tipoLoader="float" tipoImagem="circulo" />
      )}
      {(IsEmpty(cpfCnpjTestemunha) ||
        (NotIsEmpty(cpfCnpjTestemunha) && Object.values(dadosPessoa).length > 0)) && (
        <>
          <Formik
            initialValues={{
              genero: 'M',
              politicamenteExposta: 'N',
              ...dadosPessoa,
              ...dadosAdicionais
            }}
            onSubmit={(values, form) => {
              let errors = {};
              errors = validarDadosBasicos({ ...values });

              if (IsEmpty(values.ordemTestemunha)) {
                errors.ordemTestemunha = 'Favor fornecer a ordem da testemunha';
              }

              form.setErrors(errors);
              if (Object.keys(errors).length <= 0) {
                submitFormulario({ ...values }, form);
              } else {
                form.setSubmitting(false);
                focusInput(Object.keys(errors)[0]);
              }
            }}
          >
            {({ values, submitForm, isSubmitting, ...formProps }) => (
              <Form>
                {/* DADOS BASICOS PESSOA */}
                <FieldsPessoaFisica
                  values={values}
                  isPending={isSubmitting || isPendingSalvar || isPending}
                  lstTipos={listaTipos}
                  onChangeCpfCnpj={IsEmpty(cpfCnpjTestemunha) ? onChangeCpfCnpj : () => {}}
                  cpfReadOnlyDisabled={NotIsEmpty(cpfCnpjTestemunha)}
                />

                {/* ORDEM DA TESTEMUNHA */}
                <Grid container spacing={3} className="input-line">
                  <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                    Ordem da testemunha:
                  </Grid>
                  <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                    <Field
                      id="ordemTestemunha"
                      name="ordemTestemunha"
                      type="number"
                      component={TextFieldMain}
                      fullWidth
                      autoComplete="off"
                      disabled={isPending}
                    />
                  </Grid>
                </Grid>

                {/* AÇÕES */}
                <Grid container spacing={3} sx={{ m: '20px 0' }}>
                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />

                  <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                    <ButtonMain
                      size="medium"
                      tipoBotao="transparente-com-bordas"
                      disabled={isSubmitting || isPendingSalvar || isPending}
                      onClick={handleClose}
                    >
                      Cancelar
                    </ButtonMain>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                    <ButtonMain
                      size="medium"
                      tipoBotao="azul-escuro"
                      disabled={isSubmitting || isPendingSalvar || isPending}
                      onClick={submitForm}
                    >
                      Salvar
                    </ButtonMain>
                  </Grid>

                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
};
export default memo(FormInclusaoTestemunha);
