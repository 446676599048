export const focusInput = input => {
  const elemento = document.getElementById(input);
  if (elemento) {
    setTimeout(() => {
      elemento.focus();
    }, 150);
  }
};

export const focusInputRef = inputRef => {
  setTimeout(() => {
    inputRef.current.focus();
  }, 100);
};
