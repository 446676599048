import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const TelaComplementarSubNivel = ({
  corFundo = 'inherit',
  bordaMain = 'border: 2px solid #ddd',
  setEsconderTelaComplementar,
  conteudoTelaSubNivel = <></>,
  tituloSubNivel = '',
  subTituloSubNivel = ''
}) => (
  <Box className="formalizacao-tela-complementar">
    <Box className="header-tela">
      <IconButton
        aria-label="Voltar"
        className="btn-voltar"
        onClick={() => setEsconderTelaComplementar(false)}
      >
        <ArrowBack />
      </IconButton>
      <Typography variant="subtitle1" className="box-titulo-tela">
        <Box className="sub-titulo-tela-complementar">{subTituloSubNivel}</Box>
        <Box className="titulo-tela-complementar texto-semi-negrito">{tituloSubNivel}</Box>
      </Typography>
    </Box>
    <Box className="main-tela-complementar" sx={{ backgroundColor: corFundo, border: bordaMain }}>
      {conteudoTelaSubNivel}
    </Box>
  </Box>
);
export default TelaComplementarSubNivel;
