export const geraCorHexaDecimal = string => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let corHex = '#';

  for (let j = 0; j < 6; j++) {
    const value = (hash >> (j * 4)) & 0xff;
    corHex += value.toString(16).padStart(2, '0');
  }

  return corHex.substring(0, 7);
};
