import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import 'moment/locale/pt-br';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { mascararCpfCnpj, textToLowerCase } from '../../../Utils/format';
import LoaderMain from '../../../Shared/LoaderMain';
import { getDadosCardGrid } from '../../../../api/formalizacao';

const NovasEntradas = ({ atualizarDashboard, setDadosModalDashboard, setMostrarCliente }) => {
  const [dadosUltimaEntrada, setDadosUltimaEntrada] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [mostrarTodos, setMostrarTodos] = useState(false);
  const msgVazio = 'Não informado';

  useEffect(() => {
    setDadosUltimaEntrada({});
    setIsPending(true);

    const params = { cod_status_card_formalizacao: 1 };

    getDadosCardGrid(params)
      .then(res => {
        const dados = res.data ?? false;

        if (dados && Object.keys(dados).length > 0) {
          setMostrarTodos(Object.keys(dados).length > 1);

          const ultimaEntrada = [...dados].shift();
          setDadosUltimaEntrada(ultimaEntrada);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  }, [atualizarDashboard]);

  const mostrarModalDashBoard = () => {
    const dados = {
      titleModal: 'Entradas',
      cod_status_card_formalizacao: 1,
      buscarDadosCard: true
    };
    setDadosModalDashboard(dados);
  };

  return (
    <Box className="card-novo-lead">
      <Box sx={{ display: 'flex' }}>
        <Box className="ultimos-lead-info w-100 margin-bottom-5">
          <InfoOutlinedIcon />
          <Typography className="texto-cor-principal font-bold-600" variant="h7">
            Nova entrada
          </Typography>
        </Box>
        <Box className="w-100">
          {mostrarTodos && (
            <Typography className="link-ver-todos" variant="h7" onClick={() => mostrarModalDashBoard()}>
              Ver todos
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={isPending ? { position: 'relative' } : {}}>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" percentTop="15%" />}
        <Box className="box-cliente-lead box-novas-entradas">
          {Object.keys(dadosUltimaEntrada).length > 0 ? (
            <Box className="width-box">
              <Typography
                className="text-overflow-ellipsis texto-centro"
                variant="h7"
                sx={{ minWidth: '13%' }}
              >
                {mascararCpfCnpj(String(dadosUltimaEntrada.CNPJ)) ?? msgVazio}
              </Typography>

              <Typography
                className="text-overflow-ellipsis texto-centro"
                variant="h7"
                sx={{ minWidth: '14%' }}
              >
                {dadosUltimaEntrada.CLIENTE ?? msgVazio}
              </Typography>

              <Typography
                className="text-overflow-ellipsis texto-centro"
                variant="h7"
                sx={{ minWidth: '14%' }}
              >
                {dadosUltimaEntrada.PLATAFORMA ?? msgVazio}
              </Typography>

              <Typography
                className="text-overflow-ellipsis texto-centro text-transform-capitalize"
                variant="h7"
                sx={{ minWidth: '14%' }}
              >
                {textToLowerCase(dadosUltimaEntrada.NOMEBROKER) ?? msgVazio}
              </Typography>

              <Typography
                className="text-overflow-ellipsis texto-centro text-transform-capitalize"
                variant="h7"
                sx={{ minWidth: '10%' }}
              >
                {textToLowerCase(dadosUltimaEntrada.NOMERESPONSAVEL) ?? msgVazio}
              </Typography>

              <Typography
                className="text-overflow-ellipsis texto-centro text-transform-capitalize"
                variant="h7"
                sx={{ minWidth: '10%' }}
              >
                {textToLowerCase(dadosUltimaEntrada.TIPOMODALIDADE) ?? msgVazio}
              </Typography>

              <Typography
                className="text-overflow-ellipsis texto-centro flex-box-items-centralizados items-centralizados"
                variant="h7"
                sx={{ minWidth: '10%' }}
                title={moment(dadosUltimaEntrada.DTHRINSERT).format('DD/MM/yyyy HH:mm:ss')}
              >
                <AccessTimeIcon className="font-size-18 margin-right-3" />
                {moment(dadosUltimaEntrada.DTHRINSERT).local().fromNow()}
              </Typography>

              <Typography
                className="text-overflow-ellipsis texto-centro font-bold text-transform-capitalize"
                variant="h7"
                sx={{ minWidth: '10%' }}
                title={dadosUltimaEntrada.TIPO}
              >
                {textToLowerCase(dadosUltimaEntrada.TIPO) ?? msgVazio}
              </Typography>

              <Typography
                className="novas-entradas-abrir"
                variant="h7"
                sx={{ minWidth: '5%' }}
                onClick={() =>
                  setMostrarCliente({
                    cnpj: dadosUltimaEntrada.CNPJ,
                    codRelacionamentosFormalizacao: dadosUltimaEntrada.id
                  })
                }
              >
                Abrir
                <ChevronRightIcon sx={{ fontSize: '20px' }} />
              </Typography>
            </Box>
          ) : (
            <Box className="width-box">
              <Box sx={{ paddingLeft: '10px' }}>Nenhuma entrada encontrada.</Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NovasEntradas;
