export const DOCUMENTOCONTRATO = {
  FIDC: {
    nome: 'Contrato Fidc',
    codEmpresa: 6,
    permitegarantia: true,
    coobrigacao: true,
    connect: 'N'
  },
  SECURITIZADORA_ONE7: {
    nome: 'Contrato Securitizadora',
    codEmpresa: 7,
    permitegarantia: true,
    coobrigacao: true,
    connect: 'N'
  },
  FIDC_CONNECT: {
    nome: 'Contrato Fidc Connect',
    codEmpresa: 6,
    permitegarantia: false,
    coobrigacao: false,
    connect: 'S'
  },
  SECURITIZADORA_ONE7_CONNECT: {
    nome: 'Contrato Securitizadora Connect',
    codEmpresa: 7,
    permitegarantia: false,
    coobrigacao: false,
    connect: 'S'
  }
};
