import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';
import IOSSwitch from '../../shared/IOSSwitch';
import FormDocumentos from '../../Documentos/Formularios/FormDocumentos';
import definicaoDeColunas from './documentos-definicoes-grid';
import LoaderMain from '../../../../Shared/LoaderMain';
import {
  incluirDocumentoPessoaCliente,
  alterarDocumentoPessoaCliente,
  buscaArquivoPessoaFormalizacao,
  getDadosDocumentosPessoaGrid,
  getTiposDocumentos,
  alterarStatusDocumentoPessoa,
  excluirDocumentoPessoa
} from '../../../../../api/formalizacao';
import ButtonMain from '../../../../Shared/ButtonMain';

const Documentos = ({
  cliente,
  lstDados,
  setAlerta,
  setAlertConfirm,
  setDadosAposIncluirAlterar,
  setModalClienteDados,
  escopoClientePessoas
}) => {
  const [isPending, setPending] = useState(false);
  const [lstDadosGrid, setLstDadosGrid] = useState([]);
  const [lstTiposDocumentos, setLstTiposDocumentos] = useState([]);
  const [filtroStatus, setFiltroStatus] = useState(true);

  useEffect(() => {
    // AJUSTADO TEMPORARIAMENTE PARA ATENDER A REGRA DE NEGÓCIO
    cliente.formalizado = true;
    buscaTiposDocumentos();
  }, []);

  useEffect(() => {
    const filStatus = filtroStatus ? 'A' : 'I';
    if (typeof lstDados.documentos !== 'undefined') {
      if (lstDados.documentos.length === 0) {
        setLstDadosGrid([]);
      } else if (lstDados.documentos[0].STATUS === filStatus) {
        setLstDadosGrid(lstDados.documentos);
      } else {
        buscaDocumentosPessoa();
      }
    } else {
      buscaDocumentosPessoa();
    }
  }, [lstDados]);

  useEffect(() => {
    buscaDocumentosPessoa();
  }, [filtroStatus]);

  const buscaDocumentosPessoa = () => {
    const params = {
      cod_cliente: cliente.cod_cliente,
      cpfcnpj: lstDados.cpfcnpj,
      status: filtroStatus ? 'A' : 'I'
    };
    setPending(true);
    getDadosDocumentosPessoaGrid(params)
      .then(res => {
        const lista = res.data && res.data.length > 0 ? res.data : [];
        setLstDadosGrid(lista);
        const lstDadosTemp = { ...lstDados };
        lstDadosTemp.documentos = lista;
        setDadosAposIncluirAlterar(lstDadosTemp);
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  const buscaTiposDocumentos = () => {
    getTiposDocumentos()
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstTiposDocumentos(res.data);
        } else {
          setLstTiposDocumentos([]);
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {});
  };

  const excluirInativarDocumento = dadosRow => {
    if (cliente.formalizado) {
      if (escopoClientePessoas && escopoClientePessoas.permite_alterar) {
        alteraStatusDocumento(dadosRow);
      }
    } else if (escopoClientePessoas && escopoClientePessoas.permite_excluir) {
      excluirDocumento(dadosRow);
    }
  };

  const alteraStatusDocumento = dadosRow => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: `Deseja realmente ${dadosRow.STATUS === 'I' ? 'ativar' : 'inativar'} este documento?`,
      funcaoConfirmacao: () => {
        const params = { id: dadosRow.id, status: dadosRow.STATUS === 'A' ? 'I' : 'A' };
        setPending(true);
        alterarStatusDocumentoPessoa(params)
          .then(() => {
            buscaDocumentosPessoa();
            setAlerta({
              mensagem: 'Status do documento alterado com sucesso!',
              tipo: 'success'
            });
          })
          .catch(() => {
            setPending(false);
            setAlerta({
              mensagem: 'Ocorreu um erro, contate o suporte!',
              tipo: 'error'
            });
          })
          .finally(() => {});
      }
    });
  };

  const excluirDocumento = dadosRow => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: 'Deseja realmente excluir este documento?',
      funcaoConfirmacao: () => {
        setPending(true);
        excluirDocumentoPessoa(dadosRow.id)
          .then(() => {
            buscaDocumentosPessoa();
            setAlerta({
              mensagem: 'Documento excluído com sucesso!',
              tipo: 'success'
            });
          })
          .catch(err => {
            setPending(false);
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu um erro, contate o suporte!',
              tipo: 'error'
            });
          });
      }
    });
  };

  const downloadArquivoPessoa = (idValue, setIsPendingGrid, isPendingGrid) => {
    const pendingTemp = [...isPendingGrid];
    pendingTemp[idValue] = true;
    setIsPendingGrid(pendingTemp);

    buscaArquivoPessoaFormalizacao(idValue)
      .then(res => {
        const link = res.data.url_temporaria;
        const aElement = document.createElement('a');
        const href = link;
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Arquivo não encontrado, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingGrid([]);
      });
  };

  const aposSalvarDocumento = () => {
    if (filtroStatus) {
      buscaDocumentosPessoa();
    } else {
      setFiltroStatus(true);
    }
  };

  const abrirModalDocumentos = (novo = true, dadosDocumento = {}) => {
    setModalClienteDados({
      openModal: true,
      tituloModal: novo ? 'Adicionar documento' : 'Alterar documento',
      maxWidth: 'md',
      conteudoModal: (
        <FormDocumentos
          codCliente={cliente.cod_cliente}
          cpfcnpj={lstDados.cpfcnpj}
          dadosDocumento={dadosDocumento}
          setAlerta={setAlerta}
          lstTiposDocumentos={lstTiposDocumentos}
          handleClose={() => setModalClienteDados({})}
          funcaoSucesso={aposSalvarDocumento}
          apiIncluirDocumento={incluirDocumentoPessoaCliente}
          apiAlterarDocumento={alterarDocumentoPessoaCliente}
        />
      )
    });
  };

  return (
    <Box
      className="documentos-pessoa borda-bottom-2 borda-cor-cinza-claro-5 margin-topbot-25"
      sx={isPending ? { position: 'relative' } : {}}
    >
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      <Grid container spacing={2} sx={{ ml: 0 }}>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={7}
          xs={12}
          className="flex-box-items-direita borda-bottom-2 borda-cor-preta texto-cor-preto font-size-1-15-rem texto-semi-negrito"
        >
          Anexos
        </Grid>
        <Grid item xl={6} lg={6} md={5} sm={2} xs={6} />
        <Grid item xl={2} lg={2} md={3} sm={3} xs={6} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xl={2} lg={3} md={3} sm={4} xs={12} sx={{ p: '17px 0 0 17px !important' }}>
          {cliente.formalizado && (
            <>
              <Typography variant="subtitle1">Filtrar Status:</Typography>
              <FormControlLabel
                id="status_filter"
                control={<IOSSwitch />}
                onClick={e => setFiltroStatus(!filtroStatus)}
                checked={filtroStatus}
                disabled={isPending}
                sx={{ ml: '10px' }}
              />
            </>
          )}
        </Grid>
        <Grid item xl={8} lg={7} md={6} sm={5} xs={1} />
        <Grid item xl={2} lg={2} md={3} sm={3} xs={5}>
          <ButtonMain
            size="medium"
            tipoBotao="azul-escuro"
            onClick={() => abrirModalDocumentos()}
            disabled={isPending}
          >
            Adicionar <Add />
          </ButtonMain>
        </Grid>
      </Grid>
      <DataGrid
        className="data-grid-padrao data-grid-separator-header-hidden"
        columns={definicaoDeColunas(
          excluirInativarDocumento,
          downloadArquivoPessoa,
          abrirModalDocumentos,
          cliente,
          escopoClientePessoas
        )}
        rows={lstDadosGrid ?? []}
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={lstDadosGrid => lstDadosGrid.id}
        autoHeight
        disableColumnMenu
        sx={{ margin: '15px 0 30px 0' }}
        localeText={{
          ...ptBR.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: 'Não há documentos cadastrados'
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'INCLUSAO', sort: 'asc' }]
          }
        }}
        pageSizeOptions={[25, 50, 100]}
      />
    </Box>
  );
};
export default Documentos;
