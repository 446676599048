import React, { memo } from 'react';
import { Box, Grid } from '@mui/material';
import { Field } from 'formik';
import { LoopOutlined } from '@mui/icons-material';
import TextFieldMain from '../../../../Shared/TextFieldMain';
import RadioGroupMain from '../../../../Shared/RadioGroupMain';
import AutocompleteMain from '../../../../Shared/AutocompleteMain';
import {
  avalista_tipos_assinaturas,
  procurador_tipos_assinaturas,
  representante_tipos_assinaturas
} from '../../shared/TiposAssinaturas';

const FieldsPoderesAssinaturas = ({
  formDados = {},
  values,
  lstTipos,
  tipoCadastro = '',
  isPending,
  isPendingPoderes
}) => (
  <>
    {tipoCadastro !== 'procurador' && (
      <>
        {/* AVALISTA - SIM ou NAO */}
        <Grid container spacing={3} className="input-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
            Avalista:
          </Grid>
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={9}
            xs={10}
            className={isPendingPoderes ? 'flex-box-items-esquerda' : ''}
          >
            <Field
              id="avalista"
              name="avalista"
              component={RadioGroupMain}
              disabled={isPending}
              row
              options={[
                { label: 'Sim', value: 'S' },
                { label: 'Não', value: 'N' }
              ]}
            />
            {isPendingPoderes && <LoopOutlined className="rotating" />}
          </Grid>
        </Grid>
        <Box sx={values && values.avalista === 'S' ? {} : { display: 'none' }}>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Tipos de assinaturas do avalista:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                multiple
                id="avalista_tipos_assinaturas"
                name="avalista_tipos_assinaturas"
                component={AutocompleteMain}
                boxMain={false}
                propsInput={{
                  placeholder: 'Selecione a(s) opção(ões)'
                }}
                disabled={isPending}
                options={Object.values(avalista_tipos_assinaturas)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* SÓCIO - SIM ou NAO */}
        <Grid container spacing={3} className="input-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
            Sócio:
          </Grid>
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={9}
            xs={10}
            className={isPendingPoderes ? 'flex-box-items-esquerda' : ''}
          >
            <Field
              id="socio"
              name="socio"
              component={RadioGroupMain}
              disabled={isPending}
              row
              options={[
                { label: 'Sim', value: 'S' },
                { label: 'Não', value: 'N' }
              ]}
            />
            {isPendingPoderes && <LoopOutlined className="rotating" />}
          </Grid>
        </Grid>
        <Box sx={values && values.socio === 'S' ? {} : { display: 'none' }}>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Percentual Societário:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="percentual_societario"
                name="percentual_societario"
                type="text"
                component={TextFieldMain}
                fullWidth
                tipoMascara="number-percentual"
                autoComplete="off"
                disabled={isPending}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Tipo Sócio:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="tipo_socio"
                name="tipo_socio"
                component={RadioGroupMain}
                disabled={isPending}
                row
                options={[
                  { label: 'Sócio', value: 'SC' },
                  { label: 'Dono', value: 'DN' },
                  { label: 'Sócio Dono', value: 'SD' }
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Signatário:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="signatario"
                name="signatario"
                component={RadioGroupMain}
                disabled={isPending}
                row
                options={[
                  { label: 'Sim', value: 'S' },
                  { label: 'Não', value: 'N' }
                ]}
              />
            </Grid>
          </Grid>
        </Box>

        {/* REPRESENTANTE - SIM ou NAO */}
        <Grid container spacing={3} className="input-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
            Representante:
          </Grid>
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={9}
            xs={10}
            className={isPendingPoderes ? 'flex-box-items-esquerda' : ''}
          >
            <Field
              id="representante"
              name="representante"
              component={RadioGroupMain}
              disabled={isPending}
              row
              options={[
                { label: 'Sim', value: 'S' },
                { label: 'Não', value: 'N' }
              ]}
            />
            {isPendingPoderes && <LoopOutlined className="rotating" />}
          </Grid>
        </Grid>
        <Box sx={values && values.representante === 'S' ? {} : { display: 'none' }}>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Tipos de assinaturas do representante:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                multiple
                id="representante_tipos_assinaturas"
                name="representante_tipos_assinaturas"
                component={AutocompleteMain}
                boxMain={false}
                propsInput={{
                  placeholder: 'Selecione a(s) opção(ões)'
                }}
                disabled={isPending}
                options={Object.values(representante_tipos_assinaturas)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* POSSUI PROCURADOR - SIM ou NAO */}
        <Grid container spacing={3} className="input-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
            Possui procurador:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
            <Field
              id="possuiProcurador"
              name="possuiProcurador"
              component={RadioGroupMain}
              disabled={isPending}
              row
              options={[
                { label: 'Sim', value: 'S' },
                { label: 'Não', value: 'N' }
              ]}
            />
          </Grid>
        </Grid>
      </>
    )}
    {tipoCadastro === 'procurador' && (
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Tipos de assinaturas do procurador:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            multiple
            id="procurador_tipos_assinaturas"
            name="procurador_tipos_assinaturas"
            component={AutocompleteMain}
            boxMain={false}
            propsInput={{
              placeholder: 'Selecione a(s) opção(ões)'
            }}
            disabled={isPending}
            options={Object.values(procurador_tipos_assinaturas)}
          />
        </Grid>
      </Grid>
    )}
  </>
);
export default memo(FieldsPoderesAssinaturas);
