import React from 'react';

const Relatorios = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 50">
    <path
      d="M10.7,7.1H21.4v3.6H10.7Zm0,10.7H32.2v3.6H10.7Zm0,5.4H32.2v3.6H10.7Zm0,5.3H32.2v3.6H10.7ZM12.5,50H44.7a1.79,1.79,0,0,0,1.8-1.8V8.9a1.79,1.79,0,0,0-1.8-1.8H36.5L29.9.5a1.76,1.76,0,0,0-.5-.4h-.2c-.2,0-.3-.1-.5-.1H5.3A1.79,1.79,0,0,0,3.5,1.8V41.1a1.79,1.79,0,0,0,1.8,1.8h5.4v5.3A1.79,1.79,0,0,0,12.5,50ZM42.9,10.7V46.5H14.2V42.9H37.4a1.79,1.79,0,0,0,1.8-1.8V10.7ZM33.2,8.8H30.4V6ZM7.1,3.5H26.8v7.2a1.79,1.79,0,0,0,1.8,1.8h7.2V39.3H7.1Z"
      transform="translate(-3.5)"
    />
  </svg>
);
export default Relatorios;
