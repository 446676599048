import React, { memo } from 'react';
import { Typography } from '@mui/material';
import Main from '../Main/Main';

const NotFound = props => (
  <Main title="Página não encontrada" className="tela-dashboard">
    <Typography variant="subtitle1">
      O servidor não pode encontrar o recurso solicitado.
      {props && props.erro ? props.erro : ''}
      Este código de resposta talvez seja o mais famoso devido à frequência com
      que acontece na web.
    </Typography>
  </Main>
);
export default memo(NotFound);
