/* eslint-disable import/no-cycle */
import axios from 'axios';

import { getToken, getTokenRefresh, setToken, setTokenRefresh } from '../services/authentication/auth';
import { RotasNaoProtegidas } from '../components/Shared/Enums/RotasNaoProtegidas';

const api = axios.create({ baseURL: `${process.env.REACT_APP_API_CADASTRO_BASE_URL}` });

const verificaSeExisteRotaNaoAutenticada = rota => {
  let retorno = false;
  RotasNaoProtegidas.map(rotas => {
    if (rota.toLowerCase().indexOf(rotas) >= 0) {
      retorno = true;
    }
    return rotas;
  });
  return retorno;
};

api.interceptors.request.use(
  config => {
    const { url } = config;
    const token = getToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else if (!verificaSeExisteRotaNaoAutenticada(url)) {
      window.location.replace('/force-logout');
      return config;
    }

    return config;
  },
  error => Promise.reject(error)
);

const refreshAccessToken = () => {
  const form = new URLSearchParams();
  form.append('client_id', process.env.REACT_APP_CLIENT_ID);
  form.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
  form.append('grant_type', process.env.REACT_APP_GRANT_TYPE_REFRESH_TOKEN);
  form.append('refresh_token', getTokenRefresh());
  return api
    .post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/login`, form)
    .then(ret => {
      const { access_token, expires_in, refresh_token, refresh_expires_in } = ret.data;
      setToken(access_token, expires_in);
      setTokenRefresh(refresh_token, refresh_expires_in);
      return access_token;
    })
    .catch(() => {
      window.location.replace('/force-logout');
      return Promise.reject();
    });
};

const refreshAndRetryQueue = [];

let isRefreshing = false;

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await refreshAccessToken();
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            api
              .request(config)
              .then(response => resolve(response))
              .catch(err => reject(err));
          });

          refreshAndRetryQueue.length = 0;
          return api(originalRequest);
        } catch (refreshError) {
          window.location.replace('/force-logout');
          return Promise.reject();
        } finally {
          isRefreshing = false;
        }
      }
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }
    return Promise.reject(error);
  }
);

export default api;
