import React from 'react';
import { Typography, Box } from '@mui/material';

const BoxInputMain = ({ tituloClassName, boxClassName, titulo, children }) => (
  <Box className={`${boxClassName ? boxClassName : ''} box-input-padding`}>
    {titulo && (
      <Typography
        variant="subtitle1"
        className={`${tituloClassName ? tituloClassName : ''} texto-cor-cinza-escuro label-padding`}
      >
        {titulo}
      </Typography>
    )}
    {children}
  </Box>
);
export default BoxInputMain;
