import React from 'react';
import { Typography } from '@mui/material';
import BoxInputMain from './BoxInputMain';
import SelectMainDefault from './SelectMainDefault';

const SelectMain = ({ field: { value = '', ...fields }, form: { errors }, ...props }) => {
  const {
    children,
    className,
    erro = '',
    field,
    onChangeSelect,
    onBlurSelect,
    placeholder = '',
    boxMain = true,
    ...restProps
  } = props;
  // quando o valor não for passado, settar como string vazia
  if (!value) {
    value = '';
  }

  const nomeInput = fields.name;
  let erroTexto = errors[nomeInput] ? errors[nomeInput] : '';
  erroTexto = erro !== '' ? erro : erroTexto;
  const erroExiste = erroTexto !== '' ? true : false;

  const onChangeSelectField = event => {
    if (typeof fields.onChange === 'function') {
      fields.onChange(event);
    }
    if (typeof onChangeSelect === 'function') {
      onChangeSelect(event);
    }
  };

  return (<>
    {boxMain ? (
      <BoxInputMain {...restProps}>
        <SelectMainDefault
          {...restProps}
          value={value}
          name={fields.name}
          onChange={onChangeSelectField}
          className={className ? className : ''}
          error={erroExiste}
          displayEmpty
          renderValue={
            value !== '' ? 
              undefined : 
              () => <span className="color-placeholder">{placeholder}</span>
          }
        >
          {children}
        </SelectMainDefault>
        {erroTexto ? <Typography className="texto-danger label-padding">{erroTexto}</Typography> : ''}
      </BoxInputMain>
    ) : (
      <>
        <SelectMainDefault
          {...restProps}
          value={value}
          name={fields.name}
          onChange={onChangeSelectField}
          className={className ? className : ''}
          error={erroExiste}
          renderValue={
            value !== '' ? 
              undefined : 
              () => <span className="color-placeholder">{placeholder}</span>
          }
        >
          {children}
        </SelectMainDefault>
        {erroTexto ? <Typography className="texto-danger label-padding">{erroTexto}</Typography> : ''}
      </>
    )}
</>);
};
export default SelectMain;
