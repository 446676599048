import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ImportExcelInputMain = ({
  setArrayRetornoExcel,
  setNomeArquivoExcel,
  setFormDataFile
}) => {
  const [nomePlanilha, setNomePlanilha] = useState('');
  const [naoPermitido, setNaoPermitido] = useState(false);
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = e => {
    setNaoPermitido(false);
    const arquivo = e.target.value ?? '';

    if (arquivo === '') {
      setNomePlanilha('');
      setArrayRetornoExcel([]);
      return false;
    }

    const nomeArquivo = arquivo.replace(/^.*[\\/]/, '');

    if (nomeArquivo.includes('xls') || nomeArquivo.includes('xlsx')) {
      try {
        setNomePlanilha(nomeArquivo);
        setNomeArquivoExcel(nomeArquivo);
        setFormDataFile(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = e => {
          let data = e.target.result;
          let workbook = XLSX.read(data, { type: 'binary' });
          let sheetName = workbook.SheetNames[0];
          let sheet = workbook.Sheets[sheetName];
          let parsedData = XLSX.utils.sheet_to_json(sheet);

          if (parsedData) {
            setArrayRetornoExcel(parsedData);
          } else {
            setNaoPermitido(true);
          }

          data = null;
          workbook = null;
          sheetName = null;
          sheet = null;
          parsedData = null;
        };
      } catch (e) {
        setNaoPermitido(true);

        console.error('Ocorreu um erro ao importar o arquivo', e);
      }
    } else {
      setNaoPermitido(true);
    }
  };

  return (
    <Box className="box-import-excel">
      <Box className="btn-importar-file" onClick={handleClick}>
        <Box>Clique aqui para enviar seu arquivo</Box>

        <CloudUploadIcon className="icon-upload" />
      </Box>

      <Box className="margin-top-10">
        {nomePlanilha && nomePlanilha !== '' && (
          <>
            <Typography
              variant="h7"
              className="texto-cor-cinza-escuro texto-weight-600"
            >
              <>Arquivo: </>
            </Typography>
            <span>{nomePlanilha}</span>
          </>
        )}
      </Box>

      <Box className="margin-top-10">
        {naoPermitido && (
          <Typography
            variant="h7"
            className="texto-danger-strong texto-weight-600"
          >
            Tipo de arquivo não aceito, ou inválido.
          </Typography>
        )}
      </Box>

      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
    </Box>
  );
};
export default ImportExcelInputMain;
