import API from './api';

export const getEstadosBrasil = () =>
  API.get(`${process.env.REACT_APP_API_BUREAUS_BASE_URL}/ibge/lista-estados`);

export const getMunicipiosBrasil = () =>
  API.get(`${process.env.REACT_APP_API_BUREAUS_BASE_URL}/ibge/lista-cidades`);

export const postCadastrarFormulario = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/formulario-leads`, params);

export const postCadastrarVisitaFormulario = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/visita-formulario-leads`, params);
