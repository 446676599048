import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid, Paper, Typography } from '@mui/material';
import TextFieldMui from '@mui/material/TextField';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Add, Search } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR as DatePtBr } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import definicaoDeColunas from './definicoes-grid';
import LoaderMain from '../../../Shared/LoaderMain';
import {
  alterarStatusDocumento,
  buscaArquivoFormalizacao,
  excluirDocumentoCliente,
  getDadosDocumentosGrid,
  getTiposDocumentos
} from '../../../../api/formalizacao';
import AlertMain from '../../../Shared/AlertMain';
import FormDocumentos from './Formularios/FormDocumentos';

const Documentos = ({ cliente, setAlerta, setModalClienteDados, escopoClienteDados }) => {
  const [isPending, setIsPending] = useState(false);
  const [lstDadosGrid, setLstDadosGrid] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [paginationModelGridLeads, setPaginationModelGridLeads] = useState({
    pageSize: 5,
    page: 0
  });
  const [lstTiposDocumentos, setLstTiposDocumentos] = useState([]);
  const [valueTiposDocumentos, setValueTiposDocumentos] = useState('');
  const [valueStatus, setValueStatus] = useState('A');
  const [inclusaoInicial, setInclusaoInicial] = useState(null);
  const [inclusaoFinal, setInclusaoFinal] = useState(null);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [msgTitleAlert, setMsgTitleAlert] = useState('');
  const [msgAlert, setMsgAlert] = useState('');
  const [dadosAlteracao, setDadosAlteracao] = useState(false);
  const localizacaoBrasil = DatePtBr.components.MuiLocalizationProvider.defaultProps.localeText;

  useEffect(() => {
    // AJUSTADO TEMPORARIAMENTE PARA ATENDER A REGRA DE NEGÓCIO
    cliente.formalizado = true;
    buscaDadosFormalizacaoFiltro();
    bucaTiposDocumentos();
  }, []);

  const buscaDadosFormalizacaoFiltro = () => {
    let dataInicial = null;
    let dataFinal = null;

    if (inclusaoInicial && inclusaoInicial.isValid()) {
      dataInicial = inclusaoInicial.format('YYYY-MM-DD');
    } else {
      setInclusaoInicial(null);
    }

    if (inclusaoFinal && inclusaoFinal.isValid()) {
      dataFinal = inclusaoFinal.format('YYYY-MM-DD');
    } else {
      setInclusaoFinal(null);
    }

    const params = {};
    params.inclusao_inicial = dataInicial;
    params.inclusao_final = dataFinal;
    params.cod_cliente_arquivos_tipo = valueTiposDocumentos;
    params.cod_cliente = cliente.cod_cliente;
    params.status = valueStatus;
    buscarDadosGrid(params);
  };

  const buscarDadosGrid = (params = false) => {
    if (!params) {
      return;
    }

    setIsPending(true);
    getDadosDocumentosGrid(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstDadosGrid(res.data);
        } else {
          setLstDadosGrid([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const bucaTiposDocumentos = () => {
    getTiposDocumentos()
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstTiposDocumentos(res.data);
        } else {
          setLstTiposDocumentos([]);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleOnChangeTiposDocumentos = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueTiposDocumentos(newValue.value);
    } else {
      setValueTiposDocumentos('');
    }
  };

  const handleOnChangeStatus = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueStatus(newValue.value);
    } else {
      setValueStatus('');
    }
  };

  const excluirInativarDocumento = (idValue, event = false) => {
    if (cliente.formalizado) {
      if (escopoClienteDados && escopoClienteDados.permite_alterar) {
        const statusValue = event.target.checked ? 'A' : 'I';
        setDadosAlteracao({ status: statusValue, id: idValue });

        setMsgTitleAlert('Atenção');
        setMsgAlert(`Deseja realmente ${event.target.checked ? 'ativar' : 'inativar'} este documento?`);
        setMostrarAlerta(true);
      }
    } else if (escopoClienteDados && escopoClienteDados.permite_excluir) {
      setDadosAlteracao({ excluir: true, id: idValue });

      setMsgTitleAlert('Atenção');
      setMsgAlert('Deseja realmente excluir este documento?');
      setMostrarAlerta(true);
    }
  };

  const excluirAlterarStatusDocumento = () => {
    if (!dadosAlteracao) {
      return false;
    }
    if (dadosAlteracao.excluir) {
      excluirDocumento();
    } else {
      alteraStatusDocumento();
    }
  };

  const alteraStatusDocumento = () => {
    setIsPending(true);
    alterarStatusDocumento(dadosAlteracao)
      .then(() => {
        buscaDadosFormalizacaoFiltro();
        setAlerta({
          mensagem: 'Status do documento alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        // setIsPending(false);
      });
  };

  const excluirDocumento = () => {
    setIsPending(true);
    excluirDocumentoCliente(dadosAlteracao.id)
      .then(() => {
        buscaDadosFormalizacaoFiltro();
        setAlerta({
          mensagem: 'Documento excluído com sucesso!',
          tipo: 'success'
        });
      })
      .catch(err => {
        setIsPending(false);
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu um erro, contate o suporte!',
          tipo: 'error'
        });
      });
  };

  const downloadArquivo = (idValue, setIsPendingGrid, isPendingGrid) => {
    const pendingTemp = [...isPendingGrid];
    pendingTemp[idValue] = true;
    setIsPendingGrid(pendingTemp);

    buscaArquivoFormalizacao(idValue)
      .then(res => {
        const link = res.data.url_temporaria;
        const aElement = document.createElement('a');
        const href = link;
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Arquivo não encontrado, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingGrid([]);
      });
  };

  const abrirModalDocumentos = (novo = true, dadosDocumento = {}) => {
    setModalClienteDados({
      openModal: true,
      tituloModal: novo ? 'Adicionar documento' : 'Alterar documento',
      maxWidth: 'md',
      conteudoModal: (
        <FormDocumentos
          codCliente={cliente.cod_cliente}
          cpfcnpj={cliente.cnpj}
          dadosDocumento={dadosDocumento}
          setAlerta={setAlerta}
          lstTiposDocumentos={lstTiposDocumentos}
          handleClose={() => setModalClienteDados({})}
          funcaoSucesso={buscaDadosFormalizacaoFiltro}
        />
      )
    });
  };

  const lstStatus = [
    { label: 'Ativo', value: 'A' },
    { label: 'Inativo', value: 'I' }
  ];

  return (
    <Box className="fundo-cor-branca">
      <Paper elevation={0} className="box-paper" sx={isPending ? { position: 'relative' } : {}}>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        <Grid container spacing={2} className="box-paper-header cursor-auto">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={3} className="flex-box-items-direita">
            <Box className="box-icone-paper-header">
              <FolderOpenIcon />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={7} className="titulo flex-box-items-esquerda">
            Documentos do cliente
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className="titulo flex-box-items-direita">
            {escopoClienteDados.permite_incluir && (
              <Button
                className="btn-main"
                onClick={() => abrirModalDocumentos()}
                sx={{ maxWidth: '160px', height: '40px !important' }}
              >
                Adicionar &nbsp; <Add />
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          className="margin-bottom-20 margin-top-30"
          spacing={2}
          sx={{ pr: '10px', alignItems: 'end' }}
        >
          {cliente.formalizado && (
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
              >
                Status:
              </Typography>

              <Autocomplete
                disablePortal
                id="plataformas"
                options={lstStatus || []}
                value={
                  valueStatus && lstStatus.length > 0
                    ? lstStatus.find(option => option.value === valueStatus)
                    : ''
                }
                onChange={handleOnChangeStatus}
                name="plataformas"
                className="select-documentos-formalizacao ajuste-height pad-left-5"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                forcePopupIcon
                freeSolo
                disabled={lstStatus.length === 0}
                renderInput={params => (
                  <TextFieldMui
                    {...params}
                    name="plataformas"
                    placeholder="Todos"
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off'
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              Tipo de documento:
            </Typography>

            <Autocomplete
              disablePortal
              id="TiposDocumentos"
              options={lstTiposDocumentos || []}
              value={
                valueTiposDocumentos && lstTiposDocumentos.length > 0
                  ? lstTiposDocumentos.find(option => option.value === valueTiposDocumentos)
                  : ''
              }
              onChange={handleOnChangeTiposDocumentos}
              name="TiposDocumentos"
              className="select-documentos-formalizacao ajuste-height pad-left-5"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              forcePopupIcon
              freeSolo
              disabled={lstTiposDocumentos && lstTiposDocumentos.length === 0}
              renderInput={params => (
                <TextFieldMui
                  {...params}
                  name="TiposDocumentos"
                  placeholder="Todos"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off'
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              Inclusão:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
              <DatePicker
                name="data_agendamento_ini_filtro"
                className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-formalizacao"
                value={inclusaoInicial}
                maxDate={inclusaoFinal}
                disableFuture
                onChange={dataMoment => setInclusaoInicial(dataMoment)}
                slotProps={{
                  inputAdornment: {
                    position: 'start'
                  },
                  field: {
                    clearable: true,
                    onClear: () => setInclusaoInicial(null)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              Até:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
              <DatePicker
                name="data_agendamento_filtro"
                className="w-100 date-time-picker-intervalo data-intervalo-fim date-picker-formalizacao"
                value={inclusaoFinal}
                minDate={inclusaoInicial}
                onChange={dataMoment => setInclusaoFinal(dataMoment)}
                slotProps={{
                  inputAdornment: {
                    position: 'start'
                  },
                  field: {
                    clearable: true,
                    onClear: () => setInclusaoFinal(null)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            />
            <Button
              className="btn-main"
              onClick={() => buscaDadosFormalizacaoFiltro()}
              sx={{ maxWidth: '140px', height: '43px !important' }}
            >
              Filtrar &nbsp; <Search />
            </Button>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <DataGrid
              className="data-grid-formalizacao-documentos"
              columns={definicaoDeColunas(
                excluirInativarDocumento,
                downloadArquivo,
                abrirModalDocumentos,
                cliente,
                escopoClienteDados
              )}
              rows={lstDadosGrid ?? []}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              autoHeight
              disableColumnMenu
              sx={{ margin: '15px 0 30px 0' }}
              localeText={{
                ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: 'Não há documentos cadastrados'
              }}
              sortingOrder={['desc', 'asc']}
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              pageSizeOptions={[5, 25, 100]}
              paginationModel={paginationModelGridLeads}
              onPaginationModelChange={setPaginationModelGridLeads}
            />
          </Grid>
        </Grid>
      </Paper>

      <AlertMain
        exibeAlerta={mostrarAlerta}
        setExibeAlerta={setMostrarAlerta}
        titleAlerta={msgTitleAlert}
        msgAlerta={msgAlert}
        funcaoConfirmacao={excluirAlterarStatusDocumento}
      />
    </Box>
  );
};

export default Documentos;
