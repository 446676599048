import React, { useEffect } from 'react';
import { Box } from '@mui/material';

export default function ForceLogout() {
  useEffect(() => {
    sessionStorage.clear();
    window.location.replace(process.env.REACT_APP_LINK_PLATAFORMA_CLIENTE);
  }, []);

  return <Box sx={{ bgcolor: '#121b34', height: '1000px' }} />;
}
