import Cookies from 'universal-cookie';
import moment from 'moment';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import MD5 from 'crypto-js/md5';
// eslint-disable-next-line import/no-cycle
import { getEscopo } from '../../api/autenticacao';
import { MODULOS_TAGS } from '../../components/Shared/Enums/ModulosTelasEnum';

export const TOKEN_KEY = 'x-one7-token';
export const USUARIO_LOGADO = 'x-one7-usuario';
export const TOKEN_EXPIRES = 'x-one7-token-expires';
export const TOKEN_KEY_REFRESH = 'x-one7-token-refresh';
export const TOKEN_REFRESH_EXPIRES = 'x-one7-token-refresh-expires';
export const AUTH_COOKIE = 'x-one7-auth-cookie';
export const ESCOPO_COMPONENTES = 'x-one7-escopo-componentes';
export const TELA_DEFAULT = 'x-one7-tela-default';
export const TELA_ABERTA = 'x-one7-tela-aberta';
export const CLIENTE_SELECIONADO = 'x-one7-cliente';

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const getTokenRefresh = () => sessionStorage.getItem(TOKEN_KEY_REFRESH);

export const setToken = (token, expires) => {
  sessionStorage.setItem(TOKEN_KEY, token);
  const expireTimestamp = moment().add(expires, 'seconds').valueOf();
  sessionStorage.setItem(TOKEN_EXPIRES, expireTimestamp);
};

export const setTokenRefresh = (token, expires) => {
  sessionStorage.setItem(TOKEN_KEY_REFRESH, token);
  const expireTimestamp = moment().add(expires, 'seconds').valueOf();
  sessionStorage.setItem(TOKEN_REFRESH_EXPIRES, expireTimestamp);
};

export const removeToken = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_EXPIRES);
};

export const tokenIsExpired = () => {
  const tokenTimestamp = +sessionStorage.getItem(TOKEN_EXPIRES);
  const currentTimestamp = +moment().valueOf();
  return currentTimestamp > tokenTimestamp;
};

export const removeTokenRefresh = () => {
  sessionStorage.removeItem(TOKEN_KEY_REFRESH);
  sessionStorage.removeItem(TOKEN_REFRESH_EXPIRES);
};

export const tokenRefreshIsExpired = () => {
  const tokenTimestamp = +sessionStorage.getItem(TOKEN_REFRESH_EXPIRES);
  const currentTimestamp = +moment().valueOf();
  return currentTimestamp > tokenTimestamp;
};

export const setDadosUsuario = dadosUsuario => {
  sessionStorage.setItem(USUARIO_LOGADO, JSON.stringify(dadosUsuario));
};

export const getDadosUsuario = () => JSON.parse(sessionStorage.getItem(USUARIO_LOGADO));

export const setEscopoComponentes = escopo => {
  sessionStorage.setItem(ESCOPO_COMPONENTES, btoa(JSON.stringify(escopo)));
};

export const getEscopoComponentes = () => {
  let escopoAcesso = sessionStorage.getItem(ESCOPO_COMPONENTES);
  if (escopoAcesso) {
    escopoAcesso = atob(escopoAcesso);
    try {
      return JSON.parse(escopoAcesso);
    } catch (er) {
      return false;
    }
  } else return false;
};

export const setTelaDefault = telaDefault => {
  sessionStorage.setItem(TELA_DEFAULT, btoa(JSON.stringify(telaDefault)));
};

export const setTelaAberta = telaAberta => {
  sessionStorage.setItem(TELA_ABERTA, telaAberta);
};

export const verificaTelaAberta = telaAberta => {
  const telaSession = sessionStorage.getItem(TELA_ABERTA);
  return telaSession && telaSession.includes(telaAberta);
};

export const getTelaDefault = () => {
  let telaDefault = sessionStorage.getItem(TELA_DEFAULT);
  if (telaDefault) {
    telaDefault = atob(telaDefault);
    try {
      return JSON.parse(telaDefault);
    } catch (er) {
      return false;
    }
  } else return false;
};

export const setClienteAtual = cliente => {
  if (cliente) {
    sessionStorage.setItem(CLIENTE_SELECIONADO, JSON.stringify(cliente));
  }
};

export const getClienteAtual = () => JSON.parse(sessionStorage.getItem(CLIENTE_SELECIONADO));

export const autenthicaToken = (token, tokenRefresh, setEscopoAcesso) =>
  new Promise(resolve => {
    if (token && tokenRefresh) {
      const jwtToken = parseJwt(token);
      const jwtTokenRefresh = parseJwt(tokenRefresh);
      if (jwtToken && jwtToken.id && jwtToken.nome && jwtToken.exp && jwtTokenRefresh) {
        const dadosCookie = getDataFromCookie();
        const dadosCliente = getClienteFromCookie();
        setClienteAtual(dadosCliente);
        const fpPromise = FingerprintJS.load();
        // eslint-disable-next-line no-promise-executor-return
        return fpPromise.then(fp => {
          fp.get().then(
            resultado => {
              const dadosFingerprint = resultado && resultado.visitorId ? resultado.visitorId : null;
              const md5Gerado = geraMD5Token(jwtToken.id, dadosFingerprint);
              if (dadosCookie && md5Gerado === dadosCookie) {
                const dadosUsuario = {
                  cod_usuario: jwtToken.id,
                  nome_usuario: jwtToken.nome
                };

                setToken(token, jwtToken.exp);
                setTokenRefresh(tokenRefresh, jwtTokenRefresh.exp);
                setDadosUsuario(dadosUsuario);
                return getEscopo({
                  modulos: [
                    MODULOS_TAGS.PLATAFORMACLIENTE,
                    MODULOS_TAGS.PLATAFORMA_CRM,
                    MODULOS_TAGS.PLATAFORMA_FORMALIZACAO,
                    MODULOS_TAGS.PLATAFORMA_RELACIONAMENTOS
                  ]
                })
                  .then(resEscopo => {
                    // VALIDA SE O RESULTADO DA BUSCA PELOS ESCOPOS É VAZIO
                    if (!(resEscopo.data && Object.keys(resEscopo.data).length > 1)) {
                      return resolve(false);
                    }

                    // VERIFICA SE EXISTE ESCOPO COMPLEMENTAR, CASO POSSUA, FORMATA E SETA O MESMO
                    const escoposModulos = {};
                    Object.keys(resEscopo.data).map(modulo => {
                      if (modulo !== 'telaDefault') {
                        escoposModulos[modulo] = formatarEscopo({
                          ...resEscopo.data[modulo]
                        });
                      }
                      return modulo;
                    });
                    setEscopoAcesso(escoposModulos);
                    setEscopoComponentes(escoposModulos);

                    // CASO EXISTA UMA TELA DEFAULT, SETA A MESMA
                    if (resEscopo.data.telaDefault) setTelaDefault(resEscopo.data.telaDefault);

                    return resolve(true);
                  })
                  .catch(err => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    return resolve(false);
                  });
              } else {
                return resolve(false);
              }
            },
            erro => {
              // eslint-disable-next-line no-console
              console.error(erro);
              return resolve(false);
            }
          );
        });
      } else {
        return resolve(false);
      }
    } else {
      return resolve(false);
    }
  });

export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getDataFromCookie = () => {
  const cookies = new Cookies();
  return cookies.get(AUTH_COOKIE);
};

export const getClienteFromCookie = () => {
  const cookies = new Cookies();
  return cookies.get(CLIENTE_SELECIONADO);
};

export const geraMD5Token = (codUsuario, fingerPrint) => MD5(codUsuario + fingerPrint).toString();

const formatarEscopo = resEscopo => {
  let lstEscopo = resEscopo.perfil ? resEscopo.perfil : [];
  lstEscopo = lstEscopo.concat(resEscopo.complementos ? resEscopo.complementos : []);
  const escopo = {};
  lstEscopo.map(itemEscopo => {
    if (itemEscopo.tipo_chave && itemEscopo.chave) {
      const tipoChave = itemEscopo.tipo_chave.toLowerCase();
      const chave = itemEscopo.chave.toLowerCase();
      if (escopo[tipoChave] === undefined) {
        escopo[tipoChave] = {};
      }
      escopo[tipoChave][chave] = itemEscopo;
    }
    return itemEscopo;
  });
  return escopo;
};
