import moment from 'moment';

const UNICODE_NON_BREAKING_SPACE = String.fromCharCode(160);
const USUAL_SPACE = String.fromCharCode(32);

export const moneySimpleFormat = (value = 0) => {
  if (isNaN(value)) {
    value = 0;
  }

  return String(
    new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    }).format(value)
  ).replace(UNICODE_NON_BREAKING_SPACE, USUAL_SPACE);
};

export const moneyFullFormat = (value = 0, hideSymbol = false) => {
  if (isNaN(value)) {
    value = 0;
  }

  const options = {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: hideSymbol ? 'decimal' : 'currency'
  };

  return String(new Intl.NumberFormat('pt-BR', options).format(value)).replace(
    UNICODE_NON_BREAKING_SPACE,
    USUAL_SPACE
  );
};

export const dateFormat = value => {
  const newDate = value && moment(value, moment.HTML5_FMT.DATE);

  if (newDate && newDate.isValid()) {
    return moment(value, moment.HTML5_FMT.DATE).format('DD/MM/YYYY');
  } else if (value) {
    return 'Data Inválida';
  }

  return '';
};

export const unmaskValue = (maskedValue = 0) => {
  if (!maskedValue) {
    return 0;
  } else if (typeof maskedValue === 'number') {
    return maskedValue;
  } else if (typeof maskedValue === 'string') {
    if (maskedValue.indexOf('.') !== -1 && maskedValue.indexOf(',') === -1) {
      return parseFloat(maskedValue.toString().replace(/\D/g, ''));
    }

    return parseFloat(maskedValue.toString().replace(/\D/g, '') || 0, 10) / 100;
  }
};

export const maskCMC7 = value => {
  if (!value) {
    return '';
  }

  value = value.replace(/[/\D*]/g, ''); // removendo mascara
  if (value && (value.length === 30 || value.length === 34)) {
    const regexCmc7 = /(\d{8})(\d{10})(\d{12})/;
    return value.replace(regexCmc7, '<$1<$2>$3Ç');
  }
  return '';
};

export const retornaValorComMascara = (val, mask) => {
  if (typeof val === 'undefined' || val === null || val === '') {
    return '';
  }
  if (typeof mask === 'undefined' || mask === null || mask === '') {
    return val;
  }
  let maskared = '';
  let k = 0;

  for (let i = 0; i <= mask.length - 1; i++) {
    if (mask[i] === '#') {
      if (typeof val[k] !== 'undefined') {
        maskared += val[k++];
      }
    } else if (typeof mask[i] !== 'undefined') {
      maskared += mask[i];
    }
  }

  return maskared;
};

export const mascararCpfCnpj = cpfCnpj =>
  retornaValorComMascara(cpfCnpj, cpfCnpj.length <= 11 ? '###.###.###-##' : '##.###.###/####-##');

export const IsEmpty = valor => valor === undefined || valor === '' || valor === null || valor === 0;

export const textCaptalize = string => {
  if (IsEmpty(string)) {
    return string;
  }

  const text = string.ToLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const textToLowerCase = string => {
  if (IsEmpty(string)) {
    return string;
  }

  return string.toLowerCase();
};

export const NotIsEmpty = valor => !IsEmpty(valor);
