import React, { memo, useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { FaceOutlined, Groups } from '@mui/icons-material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import LoaderMain from '../../../Shared/LoaderMain';
import definicaoDeColunas from './definicoes-grid';
import definicaoDeColunasTestemunhas from './testemunhas-definicoes-grid';
import DadosPessoa from './DadosPessoa';
import { mascararCpfCnpj } from '../../../Utils/format';
import {
  getDadosPessoasGrid,
  getDadosTestemunhasGrid,
  inativarAtivarPessoaCliente,
  inativarAtivarTestemunhaCliente
} from '../../../../api/formalizacao';
import ButtonMain from '../../../Shared/ButtonMain';
import BoxPaper from '../shared/BoxPaper';
import FormInclusaoPessoa from './Formularios/FormInclusaoPessoa';
import IOSSwitch from '../shared/IOSSwitch';
import FormInclusaoTestemunha from './Formularios/FormInclusaoTestemunha';
import AlertMain from '../../../Shared/AlertMain';

const PessoasCliente = ({
  cliente,
  setAlerta,
  setMostrarTela,
  escopoClientePessoas,
  setModalClienteDados
}) => {
  const [isPending, setPending] = useState(false);
  const [isPendingTestemunhas, setPendingTestemunhas] = useState(false);
  const [lstDadosGrid, setLstDadosGrid] = useState([]);
  const [filtroStatus, setFiltroStatus] = useState(true);
  const [lstTestemunhasDadosGrid, setLstTestemunhasDadosGrid] = useState([]);
  const [filtroStatusTestemunhas, setFiltroStatusTestemunhas] = useState(true);
  const [lstTipos, setLstTipos] = useState(false);
  const [alertConfirm, setAlertConfirm] = useState({});

  useEffect(() => {
    buscarListaPessoas();
  }, [filtroStatus]);

  const buscarListaPessoas = () => {
    const filStatus = filtroStatus ? 'A' : 'I';
    setPending(true);
    getDadosPessoasGrid(cliente.cod_cliente, filStatus)
      .then(res => {
        const lstGrid = res.data && typeof res.data === 'object' ? Object.values(res.data) : [];
        if (lstGrid.length > 0) {
          setLstDadosGrid(lstGrid);
        } else {
          setLstDadosGrid([]);
        }
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  useEffect(() => {
    const filStatus = filtroStatusTestemunhas ? 'A' : 'I';
    setPendingTestemunhas(true);
    getDadosTestemunhasGrid(cliente.cod_cliente, filStatus)
      .then(res => {
        const lstGrid = res.data && typeof res.data === 'object' ? Object.values(res.data) : [];
        if (lstGrid.length > 0) {
          setLstTestemunhasDadosGrid(lstGrid);
        } else {
          setLstTestemunhasDadosGrid([]);
        }
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingTestemunhas(false);
      });
  }, [filtroStatusTestemunhas]);

  const acessarDadosPessoa = dados => {
    setMostrarTela({
      titulo: `${mascararCpfCnpj(String(cliente.cnpj))} - ${cliente.nome_cliente}`,
      subTitulo: (
        <>
          <Typography component="span" className="link-texto" onClick={() => setMostrarTela(false)}>
            Pessoas da empresa
          </Typography>
          <span>{` > ${dados.NOME}`}</span>
        </>
      ),
      conteudoTela: (
        <DadosPessoa
          {...dados}
          cliente={cliente}
          setAlerta={setAlerta}
          setAlertConfirm={setAlertConfirm}
          escopoClientePessoas={escopoClientePessoas}
          setModalClienteDados={setModalClienteDados}
          voltarAtualizarGridPessoas={() => {
            buscarListaPessoas();
            setMostrarTela(false);
          }}
        />
      )
    });
  };

  const novaPessoa = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Adicionar pessoa/poderes',
      conteudoModal: (
        <FormInclusaoPessoa
          cod_cliente={cliente.cod_cliente}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          setLstTipos={setLstTipos}
          handleClose={() => setModalClienteDados({})}
          lstDadosGrid={lstDadosGrid}
          setLstDadosGrid={setLstDadosGrid}
        />
      ),
      onClose: false
    });
  };

  const novaTestemunha = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Adicionar testemunha',
      conteudoModal: (
        <FormInclusaoTestemunha
          cod_cliente={cliente.cod_cliente}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          setLstTipos={setLstTipos}
          handleClose={() => setModalClienteDados({})}
          lstTestemunhasDadosGrid={lstTestemunhasDadosGrid}
          setLstTestemunhasDadosGrid={setLstTestemunhasDadosGrid}
        />
      ),
      onClose: false
    });
  };

  const acessarDadosTestemunha = dados => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Alterar testemunha',
      conteudoModal: (
        <FormInclusaoTestemunha
          cod_cliente={cliente.cod_cliente}
          codTestemunha={dados.id}
          cpfCnpjTestemunha={dados.CPFCNPJ}
          dadosAdicionais={{ ordemTestemunha: dados.ORDEMTESTEMUNHA }}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          setLstTipos={setLstTipos}
          handleClose={() => setModalClienteDados({})}
          lstTestemunhasDadosGrid={lstTestemunhasDadosGrid}
          setLstTestemunhasDadosGrid={setLstTestemunhasDadosGrid}
        />
      ),
      onClose: false
    });
  };

  const alterarStatusPessoa = dadosRow => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: `Deseja realmente ${dadosRow.STATUS === 'I' ? 'ativar' : 'inativar'} esta pessoa do cliente?`,
      funcaoConfirmacao: () => {
        const params = {
          cod_cliente: cliente.cod_cliente,
          STATUS: dadosRow.STATUS === 'A' ? 'I' : 'A',
          CPFCNPJ: dadosRow.CPFCNPJ
        };
        setPending(true);
        inativarAtivarPessoaCliente(params)
          .then(res => {
            setFiltroStatus(!filtroStatus);
            setAlerta({
              mensagem: `Pessoa ${dadosRow.STATUS === 'A' ? 'Inativada' : 'Ativada'} com sucesso`,
              tipo: 'success'
            });
          })
          .catch(err => {
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          })
          .finally(() => {
            setPending(false);
          });
      }
    });
  };

  const alterarStatusTestemunha = dadosRow => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: `Deseja realmente ${dadosRow.STATUS === 'I' ? 'ativar' : 'inativar'} esta testemunha do cliente?`,
      funcaoConfirmacao: () => {
        const params = {
          cod_cliente: cliente.cod_cliente,
          STATUS: dadosRow.STATUS === 'A' ? 'I' : 'A',
          codTestemunha: dadosRow.id
        };
        setPendingTestemunhas(true);
        inativarAtivarTestemunhaCliente(params)
          .then(res => {
            setFiltroStatusTestemunhas(!filtroStatusTestemunhas);
            setAlerta({
              mensagem: `Testemunha ${dadosRow.STATUS === 'A' ? 'Inativada' : 'Ativada'} com sucesso`,
              tipo: 'success'
            });
          })
          .catch(err => {
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          })
          .finally(() => {
            setPendingTestemunhas(false);
          });
      }
    });
  };

  return (
    <Box className="pessoas-cliente">
      {/* GRID DE PESSOAS DO CLIENTE */}
      <BoxPaper
        iconeTitulo={<Groups />}
        labelTitulo="Dados cadastrais das pessoas da empresa (sócios, responsáveis solidários...)"
        collapse={false}
      >
        <Box sx={isPending ? { position: 'relative' } : {}}>
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container spacing={1}>
            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <Typography variant="subtitle1">Filtrar Status:</Typography>
              <FormControlLabel
                id="status_filter"
                control={<IOSSwitch />}
                onClick={e => setFiltroStatus(!filtroStatus)}
                checked={filtroStatus}
                sx={{ ml: '10px' }}
              />
            </Grid>
          </Grid>
          <DataGrid
            className="data-grid-padrao data-grid-separator-header-hidden"
            columns={definicaoDeColunas(acessarDadosPessoa, alterarStatusPessoa, escopoClientePessoas)}
            rows={lstDadosGrid ?? []}
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            getRowId={lstDadosGrid => lstDadosGrid.id}
            autoHeight
            disableColumnMenu
            sx={{ margin: '15px 0 30px 0' }}
            localeText={{
              ...ptBR.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: 'Não há pessoas cadastradas'
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'NOME', sort: 'asc' }]
              }
            }}
            pageSizeOptions={[25, 50, 100]}
          />
          <Grid container spacing={2} className="box-paper-header">
            <Grid item xl={4} lg={4} md={3} sm={2} xs={1} className="pad-0" />
            <Grid item xl={8} lg={8} md={9} sm={10} xs={11} className="pad-0">
              <ButtonMain
                size="medium"
                tipoBotao="azul-escuro"
                classesAdicional="w-auto"
                onClick={novaPessoa}
                sx={{ mt: { xl: '-80px', lg: '-80px', sm: '0', xs: '0' } }}
              >
                Adicionar +
              </ButtonMain>
            </Grid>
          </Grid>
        </Box>
      </BoxPaper>

      {/* GRID DE TESTEMUNHAS DO CLIENTE */}
      <BoxPaper iconeTitulo={<FaceOutlined />} labelTitulo="Testemunhas">
        <Box sx={isPendingTestemunhas ? { position: 'relative' } : {}}>
          {isPendingTestemunhas && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container spacing={1}>
            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <Typography variant="subtitle1">Filtrar Status:</Typography>
              <FormControlLabel
                id="status_filter"
                control={<IOSSwitch />}
                onClick={e => setFiltroStatusTestemunhas(!filtroStatusTestemunhas)}
                checked={filtroStatusTestemunhas}
                sx={{ ml: '10px' }}
              />
            </Grid>
          </Grid>
          <DataGrid
            className="data-grid-padrao data-grid-separator-header-hidden"
            columns={definicaoDeColunasTestemunhas(
              acessarDadosTestemunha,
              alterarStatusTestemunha,
              escopoClientePessoas
            )}
            rows={lstTestemunhasDadosGrid ?? []}
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            getRowId={lstTestemunhasDadosGrid => lstTestemunhasDadosGrid.id}
            autoHeight
            disableColumnMenu
            sx={{ margin: '15px 0 30px 0' }}
            localeText={{
              ...ptBR.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: 'Não há testemunhas cadastradas'
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'NOME', sort: 'asc' }]
              }
            }}
            pageSizeOptions={[25, 50, 100]}
          />
          <Grid container spacing={2} className="box-paper-header">
            <Grid item xl={4} lg={4} md={3} sm={2} xs={1} className="pad-0" />
            <Grid item xl={8} lg={8} md={9} sm={10} xs={11} className="pad-0">
              <ButtonMain
                size="medium"
                tipoBotao="azul-escuro"
                classesAdicional="w-auto"
                onClick={novaTestemunha}
                sx={{ mt: { xl: '-80px', lg: '-80px', sm: '0', xs: '0' } }}
              >
                Adicionar +
              </ButtonMain>
            </Grid>
          </Grid>
        </Box>
      </BoxPaper>

      <AlertMain
        exibeAlerta={alertConfirm && alertConfirm.show}
        setExibeAlerta={setAlertConfirm}
        titleAlerta={alertConfirm && alertConfirm.title}
        msgAlerta={alertConfirm && alertConfirm.msg}
        funcaoConfirmacao={alertConfirm && alertConfirm.funcaoConfirmacao}
      />
    </Box>
  );
};
export default memo(PessoasCliente);
