import React, { useEffect, useState } from 'react';
import { Alert, Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { NotIsEmpty, mascararCpfCnpj } from '../../../../Utils/format';
import ButtonMain from '../../../../Shared/ButtonMain';
import LoaderMain from '../../../../Shared/LoaderMain';
import { getDadosPessoa, incluirConjugeComoAvalista } from '../../../../../api/formalizacao';
import PoderesAssinaturas from './PoderesAssinaturas';
import DadosBasicos from './DadosBasicos';
import Documentos from './Documentos';
import DadosEnderecos from './DadosEnderecos';
import DadosContatos from './DadosContatos';
import FormInclusaoPessoa from '../Formularios/FormInclusaoPessoa';
import GerarPerifericosCliente from '../Modal/GerarPerifericosCliente';
import CopyValorTexto from '../../../../Shared/CopyValorTexto';

const DadosPessoa = ({
  cliente,
  NOME,
  CPFCNPJ,
  escopoClientePessoas,
  setModalClienteDados,
  setAlerta,
  setAlertConfirm,
  voltarAtualizarGridPessoas,
  ...dadosRow
}) => {
  const [isPending, setPending] = useState(false);
  const [lstDadosPessoa, setLstDadosPessoa] = useState({});
  const [lstDadosConjuge, setLstDadosConjuge] = useState({});
  const [lstDadosProcurador, setLstDadosProcurador] = useState({});
  const [lstDados, setLstDados] = useState({});
  const [lstTipos, setLstTipos] = useState(false);
  // NAO MUDAR ESTE ZERO INICIAL, POIS DEVE SEMPRE ABRIR INICIAL OS DADOS DA PESSOA
  const [abaAtiva, mudarAba] = useState(0);
  const abas = ['propria', 'conjuge', 'procurador'];

  useEffect(() => {
    const params = {
      cod_cliente: cliente.cod_cliente,
      CPFCNPJ
    };
    if (abas[abaAtiva] === 'propria') {
      if (Object.values(lstDadosPessoa).length === 0) {
        buscarDadosPessoa({ ...params, tipoBusca: 'propria' }, setLstDadosPessoa);
      } else {
        setLstDados(lstDadosPessoa);
      }
    } else if (abas[abaAtiva] === 'conjuge') {
      if (Object.values(lstDadosConjuge).length === 0) {
        buscarDadosPessoa({ ...params, tipoBusca: 'conjuge' }, setLstDadosConjuge);
      } else {
        setLstDados(lstDadosConjuge);
      }
    } else if (abas[abaAtiva] === 'procurador') {
      if (Object.values(lstDadosProcurador).length === 0) {
        buscarDadosPessoa({ ...params, tipoBusca: 'procurador' }, setLstDadosProcurador);
      } else {
        setLstDados(lstDadosProcurador);
      }
    }
  }, [abaAtiva]);

  const buscarDadosPessoa = (params, funcaoSetDados) => {
    setPending(true);
    getDadosPessoa(params)
      .then(res => {
        const cpfCnpjPessoa =
          res.data && res.data.dadosBasicos && res.data.dadosBasicos.CPFCNPJ
            ? res.data.dadosBasicos.CPFCNPJ
            : null;
        const dados = res.data ? { ...res.data, cpfcnpj: cpfCnpjPessoa, dadosRow: { ...dadosRow } } : {};
        funcaoSetDados(dados);
        setLstDados(dados);
      })
      .catch(err => {
        console.error(err);
        funcaoSetDados({});
        setLstDados({});
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  const setDadosAposIncluirAlterar = listaDados => {
    setLstDados(listaDados);
    if (abas[abaAtiva] === 'propria') setLstDadosPessoa(listaDados);
    else if (abas[abaAtiva] === 'conjuge') setLstDadosConjuge(listaDados);
    else if (abas[abaAtiva] === 'procurador') setLstDadosProcurador(listaDados);
  };

  const trocarAba = (event, newValue) => {
    mudarAba(newValue);
  };

  const alterarPessoa = (tipoForm, dadosPessoaPoderes = {}) => {
    const acao =
      lstDados && lstDados.dadosBasicos && lstDados.dadosBasicos.CPFCNPJ ? 'Alterar ' : 'Incluir ';
    const dadosAdicionais = { tipoCadastro: abas[abaAtiva], cpfCnpjPessoa: CPFCNPJ };
    if (dadosRow && NotIsEmpty(dadosRow.CPFCONJUGE)) dadosAdicionais.CPFCONJUGE = dadosRow.CPFCONJUGE;
    setModalClienteDados({
      openModal: true,
      tituloModal: acao + abas[abaAtiva],
      conteudoModal: (
        <FormInclusaoPessoa
          cpfCnpjPessoa={
            lstDados && lstDados.dadosBasicos && lstDados.dadosBasicos.CPFCNPJ
              ? lstDados.dadosBasicos.CPFCNPJ
              : ''
          }
          dadosPessoaPoderes={dadosPessoaPoderes}
          tipoForm={tipoForm}
          cod_cliente={cliente.cod_cliente}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          setLstTipos={setLstTipos}
          handleClose={() => setModalClienteDados({})}
          dadosAdicionais={dadosAdicionais}
          funcaoPosSave={result => {
            const params = {
              cod_cliente: cliente.cod_cliente,
              CPFCNPJ
            };
            if (abas[abaAtiva] === 'propria') {
              buscarDadosPessoa({ ...params, tipoBusca: 'propria' }, setLstDadosPessoa);
            } else if (abas[abaAtiva] === 'conjuge') {
              buscarDadosPessoa({ ...params, tipoBusca: 'conjuge' }, setLstDadosConjuge);
            } else if (abas[abaAtiva] === 'procurador') {
              buscarDadosPessoa({ ...params, tipoBusca: 'procurador' }, setLstDadosProcurador);
            }
          }}
        />
      ),
      onClose: false
    });
  };

  const cadastrarConjugeAvalista = () => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: 'Deseja realmente cadastrar o cônjuge como avalista',
      funcaoConfirmacao: () => {
        const params = {
          cod_cliente: cliente.cod_cliente,
          cpfAvalista: lstDados.dadosBasicos.CPFCNPJ,
          cpfConjugeAvalista: CPFCNPJ
        };
        setPending(true);
        incluirConjugeComoAvalista(params)
          .then(res => {
            if (res && res.data && res.data.msg) {
              setAlerta({
                mensagem: res.data.msg,
                tipo: 'warning'
              });
              if (res.data.retornar) {
                voltarAtualizarGridPessoas();
              }
            } else {
              setAlerta({
                mensagem: 'Cônjuge incluído como avalista com sucesso!',
                tipo: 'success'
              });
              voltarAtualizarGridPessoas();
            }
          })
          .catch(err => {
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          })
          .finally(() => {
            setPending(false);
          });
      }
    });
  };

  const modalGerarContratos = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Periféricos',
      maxWidth: 100,
      conteudoModal: (
        <GerarPerifericosCliente
          codCliente={cliente.cod_cliente}
          cpfCnpj={CPFCNPJ}
          handleClose={() => setModalClienteDados({})}
          setAlerta={setAlerta}
        />
      )
    });
  };

  return (
    <Box className="formalizacao-dados-pessoa" sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      {/* HEADER TELA */}
      <Box className="header-tela">
        <Grid container>
          <Grid item xl={10} lg={10} md={9} sm={8} xs={7}>
            <Box className="titulo-pessoa texto-semi-negrito">{NOME}</Box>
            <Box className="sub-titulo-pessoa">
              CPF: {mascararCpfCnpj(String(CPFCNPJ))}
              {NotIsEmpty(CPFCNPJ) ? CopyValorTexto({ texto: CPFCNPJ }) : valorIndefinido}
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={5} className="flex-box-items-direita">
            <ButtonMain
              size="medium"
              tipoBotao="azul-escuro"
              classesAdicional="w-auto"
              onClick={() => modalGerarContratos()}
            >
              Gerar periféricos
            </ButtonMain>
          </Grid>
        </Grid>
      </Box>

      {/* ABAS PESSOAS */}
      {lstDadosPessoa && Object.values(lstDadosPessoa).length > 0 && lstDadosPessoa.dadosBasicos && (
        <Grid container className="margin-topbot-25 items-centro-verticalmente">
          <Grid item xl={7} lg={8} md={9} sm={11} xs={12}>
            <Tabs
              value={parseInt(abaAtiva)}
              onChange={trocarAba}
              variant="fullWidth"
              className="hide-tabs-indicator preencher-fundo-selected tabs-border-radius"
            >
              <Tab
                item={0}
                className="borda-1 font-bold sem-uppercase texto-cor-cinza-3 disabled-opaco"
                icon={
                  <>
                    <Check className="font-size-1-1-rem margin-neg-top-2 margin-right-5 font-bold selected-cor-principal" />
                    <Box className="flex-box-wrap flex-direction-column">
                      <Typography
                        variant="subtitle1"
                        className="font-size-1-rem texto-centro selected-cor-principal line-height-1 font-bold"
                      >
                        Dados pessoa do cliente
                      </Typography>
                    </Box>
                  </>
                }
              />
              <Tab
                item={1}
                className="borda-1 font-bold sem-uppercase texto-cor-cinza-3 disabled-opaco"
                disabled={!(lstDadosPessoa.dadosBasicos.CASADO && CPFCNPJ.length <= 11)}
                icon={
                  <>
                    <Check className="font-size-1-1-rem margin-neg-top-2 margin-right-5 font-bold selected-cor-principal" />
                    <Box className="flex-box-wrap flex-direction-column">
                      <Typography
                        variant="subtitle1"
                        className="font-size-1-rem texto-centro selected-cor-principal line-height-1 font-bold"
                      >
                        Dados cônjuge
                      </Typography>
                    </Box>
                  </>
                }
              />

              <Tab
                item={2}
                className="borda-1 font-bold sem-uppercase texto-cor-cinza-3 disabled-opaco"
                disabled={!lstDadosPessoa.dadosBasicos.POSSUI_PROCURADOR}
                icon={
                  <>
                    <Check className="font-size-1-1-rem margin-neg-top-2 margin-right-5 font-bold selected-cor-principal" />
                    <Box className="flex-box-wrap flex-direction-column">
                      <Typography
                        variant="subtitle1"
                        className="font-size-1-rem texto-centro selected-cor-principal line-height-1 font-bold"
                      >
                        Dados procurador
                      </Typography>
                    </Box>
                  </>
                }
              />
            </Tabs>
          </Grid>
        </Grid>
      )}

      {lstDados && Object.values(lstDados).length > 0 ? (
        <>
          {/* PODERES E ASSINATURAS */}
          {(abas[abaAtiva] === 'propria' || (abas[abaAtiva] === 'procurador' && lstDados.cpfcnpj)) && (
            <>
              <PoderesAssinaturas
                {...lstDados}
                alterarPessoa={alterarPessoa}
                escopoClientePessoas={escopoClientePessoas}
                tipoCadastro={abas[abaAtiva]}
              />
              <Box className="borda-bottom-2 borda-cor-cinza-claro-5 margin-topbot-15" />
            </>
          )}

          {/* DADOS BASICOS */}
          <DadosBasicos
            {...lstDados}
            alterarPessoa={alterarPessoa}
            cadastrarConjugeAvalista={cadastrarConjugeAvalista}
            abaAtiva={abas[abaAtiva]}
            escopoClientePessoas={escopoClientePessoas}
          />

          {lstDados.cpfcnpj && (
            <>
              {/* DOCUMENTOS */}
              <Documentos
                cliente={cliente}
                lstDados={lstDados}
                abaAtiva={abas[abaAtiva]}
                setAlerta={setAlerta}
                setAlertConfirm={setAlertConfirm}
                setDadosAposIncluirAlterar={setDadosAposIncluirAlterar}
                setModalClienteDados={setModalClienteDados}
                escopoClientePessoas={escopoClientePessoas}
              />

              {/* ENDERECOS */}
              <DadosEnderecos
                lstDados={lstDados}
                abaAtiva={abas[abaAtiva]}
                setAlerta={setAlerta}
                setDadosAposIncluirAlterar={setDadosAposIncluirAlterar}
                setModalClienteDados={setModalClienteDados}
                escopoClientePessoas={escopoClientePessoas}
              />

              {/* CONTATOS */}
              <DadosContatos
                lstDados={lstDados}
                abaAtiva={abas[abaAtiva]}
                setAlerta={setAlerta}
                setDadosAposIncluirAlterar={setDadosAposIncluirAlterar}
                setModalClienteDados={setModalClienteDados}
                escopoClientePessoas={escopoClientePessoas}
              />
            </>
          )}
        </>
      ) : (
        <Grid container>
          <Grid item xl={4} lg={4} md={4} sm={3} xs={3} />
          <Grid item xl={5} lg={5} md={5} sm={5} xs={6} className="flex-box-items-esquerda">
            <Alert severity="info" className="w-auto">
              Nenhuma informação encontrada
            </Alert>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default DadosPessoa;
