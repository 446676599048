import React, { memo, useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextFieldMui from '@mui/material/TextField';
import LoaderMain from '../../../../Shared/LoaderMain';
import { alterarDocumentoCliente, incluirDocumentoCliente } from '../../../../../api/formalizacao';
import ButtonMain from '../../../../Shared/ButtonMain';
import { NotIsEmpty, textToLowerCase } from '../../../../Utils/format';

const FormDocumentos = ({
  codCliente,
  cpfcnpj,
  dadosDocumento,
  setAlerta,
  handleClose,
  lstTiposDocumentos,
  funcaoSucesso,
  apiIncluirDocumento = incluirDocumentoCliente,
  apiAlterarDocumento = alterarDocumentoCliente
}) => {
  const [isPending, setPending] = useState(false);
  const [valueTiposDocumentos, setValueTiposDocumentos] = useState('');
  const [valueEnviaFundo, setValueEnviaFundo] = useState('');
  const [nomeArquivo, setNomeArquivo] = useState(false);
  const [mostraArquivoAnexado, setMostraArquivoAnexado] = useState(false);
  const [liberarBtnSalvar, setLiberarBtnSalvar] = useState(false);
  const [arquivoNaoPermitido, setArquivoNaoPermitido] = useState(false);
  const [nomeArquivoAnexado, setNomeArquivoAnexado] = useState(false);
  const [formDataFile, setFormDataFile] = useState(false);
  const [incluirDocumento, setIncluirDocumento] = useState(false);
  const [gerarPdfReceita, setGerarPdfReceita] = useState(false);
  const [gerarPdfRelVisitas, setGerarPdfRelVisitas] = useState(false);
  const [checked, setChecked] = useState(false);
  const [mostraCheckBox, setMostraCheckBox] = useState(false);
  const [mostraCheckBoxRelVisitas, setMostraCheckBoxRelVisitas] = useState(false);
  const hiddenFileInput = useRef(null);
  const extensionsAccepted = [
    '.xlsx',
    '.xls',
    '.pdf',
    '.doc',
    '.docx',
    '.txt',
    '.jpg',
    '.rem',
    '.xml',
    '.zip',
    '.rar'
  ];

  useEffect(() => {
    if (dadosDocumento && Object.keys(dadosDocumento).length > 0) {
      if (NotIsEmpty(dadosDocumento.ENVIARFUNDO)) {
        setValueEnviaFundo(dadosDocumento.ENVIARFUNDO);
      }

      if (NotIsEmpty(dadosDocumento.CODCLIENTEARQUIVOSTIPO)) {
        setValueTiposDocumentos(dadosDocumento.CODCLIENTEARQUIVOSTIPO);
      }

      if (NotIsEmpty(dadosDocumento.NOMEARQUIVO)) {
        setNomeArquivo(dadosDocumento.NOMEARQUIVO);
        setMostraArquivoAnexado(true);
      }
    } else {
      setIncluirDocumento(true);
    }
  }, [dadosDocumento]);

  useEffect(() => {
    if (incluirDocumento) {
      if (
        (gerarPdfReceita && NotIsEmpty(valueTiposDocumentos) && NotIsEmpty(valueEnviaFundo)) ||
        (NotIsEmpty(valueTiposDocumentos) && NotIsEmpty(valueEnviaFundo) && formDataFile)
      ) {
        setLiberarBtnSalvar(true);
      } else if (
        (gerarPdfRelVisitas && NotIsEmpty(valueTiposDocumentos) && NotIsEmpty(valueEnviaFundo)) ||
        (NotIsEmpty(valueTiposDocumentos) && NotIsEmpty(valueEnviaFundo) && formDataFile)
      ) {
        setLiberarBtnSalvar(true);
      } else {
        setLiberarBtnSalvar(false);
      }
    } else if (NotIsEmpty(valueTiposDocumentos) && NotIsEmpty(valueEnviaFundo)) {
      setLiberarBtnSalvar(true);
    } else {
      setLiberarBtnSalvar(false);
    }
  }, [valueTiposDocumentos, valueEnviaFundo, formDataFile, gerarPdfReceita, gerarPdfRelVisitas]);

  const submitFormulario = () => {
    if (!liberarBtnSalvar) {
      return;
    }

    setPending(true);

    if (incluirDocumento) {
      const params = new FormData();
      params.append('cod_cliente_arquivos_tipo', valueTiposDocumentos);
      params.append('enviar_fundo', valueEnviaFundo);
      params.append('cod_cliente', codCliente);
      params.append('arquivo', formDataFile);

      if (NotIsEmpty(cpfcnpj)) {
        params.append('cpfcnpj', cpfcnpj);
      }

      if (gerarPdfReceita) {
        params.append('gerar_pdf', true);
      }

      if (gerarPdfRelVisitas) {
        params.append('gerar_pdf_relatorio_visitas', true);
      }

      apiIncluirDocumento(params)
        .then(() => {
          funcaoSucesso();
          handleClose();
          setAlerta({
            mensagem: 'Documento importado com sucesso!',
            tipo: 'success'
          });
        })
        .catch(() => {
          setAlerta({
            mensagem: 'Ocorreu um erro, contate o suporte!',
            tipo: 'error'
          });
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      const params = {
        cod_cliente_arquivos_tipo: valueTiposDocumentos,
        enviar_fundo: valueEnviaFundo,
        cod_arquivo: dadosDocumento.id
      };

      if (NotIsEmpty(cpfcnpj)) params.cpfcnpj = cpfcnpj;

      apiAlterarDocumento(params)
        .then(() => {
          funcaoSucesso();
          handleClose();
          setAlerta({
            mensagem: 'Documento alterado com sucesso!',
            tipo: 'success'
          });
        })
        .catch(() => {
          setAlerta({
            mensagem: 'Ocorreu um erro, contate o suporte!',
            tipo: 'error'
          });
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  const handleOnChangeTiposDocumentos = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueTiposDocumentos(newValue.value);
      const siglaCartaoCNPJ = 'CPJ'; // SIGLA REFRENTE AO CODIGO DO TIPO DE DOCUMENTO = CARTAO CNPJ
      const siglaRelVisitas = 'VIS'; // SIGLA REFRENTE AO CODIGO DO TIPO DE DOCUMENTO = RELATÓRIOS VISITAS
      setMostraCheckBox(newValue.sigla === siglaCartaoCNPJ);
      setMostraCheckBoxRelVisitas(newValue.sigla === siglaRelVisitas);
      setNomeArquivoAnexado('');
      setGerarPdfReceita(false);
      setGerarPdfRelVisitas(false);
      setFormDataFile(false);
      setChecked(false);
      if (hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.value) {
        hiddenFileInput.current.value = '';
      }
    } else {
      setValueTiposDocumentos('');
    }
  };

  const handleOnChangeEnviaFundo = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueEnviaFundo(newValue.value);
    } else {
      setValueEnviaFundo('');
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const getFileExtension = filename =>
    filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();

  const handleFileUpload = e => {
    setArquivoNaoPermitido(false);
    const arquivo = e.target.value ?? '';

    if (arquivo === '') {
      setNomeArquivoAnexado('');
      setFormDataFile(false);
      return false;
    }

    const nomeArquivo = arquivo.replace(/^.*[\\/]/, '');
    const extension = getFileExtension(nomeArquivo);

    if (extensionsAccepted.indexOf(`.${extension}`) > -1) {
      try {
        setNomeArquivoAnexado(nomeArquivo);
        setFormDataFile(e.target.files[0]);
      } catch (e) {
        setArquivoNaoPermitido(true);
      }
    } else {
      setArquivoNaoPermitido(true);
    }
  };

  const lstConfirmacao = [
    { label: 'Sim', value: 'S' },
    { label: 'Não', value: 'N' }
  ];

  const handleChangeCheckBox = event => {
    setChecked(event.target.checked);
    setGerarPdfReceita(event.target.checked);
  };

  const handleChangeCheckBoxRelVisitas = event => {
    setChecked(event.target.checked);
    setGerarPdfRelVisitas(event.target.checked);
  };

  return (
    <Box sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      <Grid
        container
        className="margin-bottom-20 margin-top-10"
        spacing={2}
        sx={{
          pr: '10px',
          alignItems: {
            xs: 'end',
            sm: 'end',
            md: 'center',
            lg: 'center',
            xl: 'center'
          }
        }}
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            textAlign: {
              md: 'right',
              lg: 'right',
              xl: 'right'
            }
          }}
        >
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5 texto-negrito"
          >
            Tipo de documento:
          </Typography>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Autocomplete
            disablePortal
            disableClearable
            autoSelect
            blurOnSelect
            id="TiposDocumentos"
            options={lstTiposDocumentos || []}
            value={
              valueTiposDocumentos && lstTiposDocumentos.length > 0
                ? lstTiposDocumentos.find(option => option.value === valueTiposDocumentos)
                : ''
            }
            onChange={handleOnChangeTiposDocumentos}
            name="TiposDocumentos"
            className="select-documentos-formalizacao ajuste-height pad-left-5"
            isOptionEqualToValue={(option, value) => option.value === value.value}
            forcePopupIcon
            freeSolo
            disabled={lstTiposDocumentos && lstTiposDocumentos.length === 0}
            error="erro"
            renderInput={params => (
              <TextFieldMui
                {...params}
                name="TiposDocumentos"
                placeholder="Escolha o tipo de documento"
                autoComplete="off"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off'
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            textAlign: {
              md: 'right',
              lg: 'right',
              xl: 'right'
            }
          }}
        >
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5 texto-negrito ma"
          >
            Enviar para Fundo:
          </Typography>
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Autocomplete
            disablePortal
            disableClearable
            autoSelect
            blurOnSelect
            id="enviaFundo"
            options={lstConfirmacao || []}
            value={
              valueEnviaFundo && lstConfirmacao.length > 0
                ? lstConfirmacao.find(option => option.value === valueEnviaFundo)
                : ''
            }
            onChange={handleOnChangeEnviaFundo}
            name="enviaFundo"
            className="select-documentos-formalizacao ajuste-height pad-left-5"
            isOptionEqualToValue={(option, value) => option.value === value.value}
            forcePopupIcon
            freeSolo
            disabled={lstConfirmacao.length === 0}
            renderInput={params => (
              <TextFieldMui
                {...params}
                name="enviaFundo"
                placeholder="Escolha uma opção"
                autoComplete="off"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off'
                }}
              />
            )}
          />
        </Grid>

        {mostraCheckBox && (
          <>
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} />

            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <FormControlLabel
                value="end"
                className="pad-left-5"
                control={<Checkbox checked={checked} onChange={handleChangeCheckBox} />}
                label="Gerar PDF pela consulta da receita"
                labelPlacement="end"
              />
            </Grid>
          </>
        )}

        {mostraCheckBoxRelVisitas && (
          <>
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} />

            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <FormControlLabel
                value="end"
                className="pad-left-5"
                control={<Checkbox checked={checked} onChange={handleChangeCheckBoxRelVisitas} />}
                label="Gerar PDF através do relatório do crédito"
                labelPlacement="end"
              />
            </Grid>
          </>
        )}

        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            textAlign: {
              md: 'right',
              lg: 'right',
              xl: 'right'
            }
          }}
        >
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5 texto-negrito ma"
          >
            Arquivo:
          </Typography>
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          {mostraArquivoAnexado ? (
            <Box className="icone-arquivo-download-documentos">
              <Box className="icon">
                <AttachFileIcon sx={{ fontSize: '20px' }} />
              </Box>
              <Box className="text">{textToLowerCase(nomeArquivo)}</Box>
            </Box>
          ) : (
            <>
              {gerarPdfReceita && !mostraArquivoAnexado ? (
                <Box className="margin-left-5 texto-centro pad-20 borda-1 border-radius-4 borda-cor-cinza-claro-4">
                  <>
                    O arquivo <b>PDF</b> irá ser gerado após a consulta na receita.
                  </>
                </Box>
              ) : gerarPdfRelVisitas && !mostraArquivoAnexado ? (
                <Box className="margin-left-5 texto-centro pad-20 borda-1 border-radius-4 borda-cor-cinza-claro-4">
                  <>
                    O arquivo <b>PDF</b> irá ser gerado após consultar relatórios do crédito.
                  </>
                </Box>
              ) : (
                <Box>
                  <Box className="btn-importar-file">
                    {nomeArquivoAnexado && nomeArquivoAnexado !== '' ? (
                      <Box
                        className="icone-arquivo-download-documentos cursor-pointer"
                        onClick={handleClick}
                      >
                        <Box className="icon">
                          <AttachFileIcon sx={{ fontSize: '20px' }} />
                        </Box>
                        <Box className="text">{textToLowerCase(nomeArquivoAnexado)}</Box>
                      </Box>
                    ) : (
                      <Box className="importar-documento" onClick={handleClick}>
                        Nenhum arquivo carregado
                        <Box className="link-importar">Procurar arquivos</Box>
                      </Box>
                    )}
                  </Box>

                  <input
                    type="file"
                    accept={extensionsAccepted.join()}
                    onChange={handleFileUpload}
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                  />

                  <Box className="margin-top-10">
                    {arquivoNaoPermitido && (
                      <Typography variant="h7" className="texto-danger-strong texto-weight-600">
                        Tipo de arquivo não aceito, ou inválido.
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xl={2} lg={2} md={2} />
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <ButtonMain
            size="medium"
            tipoBotao="transparente-com-bordas"
            disabled={isPending}
            onClick={handleClose}
          >
            Cancelar
          </ButtonMain>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <ButtonMain
            size="medium"
            tipoBotao="azul-escuro"
            disabled={isPending || !liberarBtnSalvar}
            onClick={() => submitFormulario()}
            sx={{ color: 'white !important' }}
          >
            Salvar
          </ButtonMain>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
      </Grid>
    </Box>
  );
};
export default memo(FormDocumentos);
