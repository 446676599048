import React, { memo, useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import LoaderMain from '../../../../Shared/LoaderMain';
import ButtonMain from '../../../../Shared/ButtonMain';

const LimitesGarantias = ({ handleClose, lstGarantias = [] }) => {
  const [isPending, setIsPending] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setIsPending(true);
    setTimeout(() => {
      setIsPending(false);
    }, 500);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={isPending ? { position: 'relative', minHeight: '220px' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      <Tabs value={value} onChange={handleChange}>
        {lstGarantias.map((tab, index) => (
          <Tab
            key={`tab-garantia-${index}`}
            value={index}
            label={tab[0].NOMEEMPRESA}
            className="text-transform-none texto-negrito"
          />
        ))}
      </Tabs>

      <Box p={3}>
        {lstGarantias.map(
          (tab, index) =>
            value === index && (
              <Box key={`box-garantia-${index}`}>
                {tab.map((garantia, idx) => (
                  <Grid
                    container
                    spacing={3}
                    key={`grid-garantia-${index}-${idx}`}
                    sx={{ marginBottom: '15px' }}
                  >
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="texto-direita texto-negrito"
                    >
                      Tipo de garantia:
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className="camel-case">
                      {garantia.NOMETIPOSGARANTIA.toLowerCase()}
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="texto-direita texto-negrito"
                    >
                      Risco/Limite:
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                      {`R$ ${parseFloat(garantia.GARANTIA).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="texto-direita texto-negrito margin-bottom-25"
                    >
                      Observação:
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className="margin-bottom-25">
                      {garantia.OBSERVACAO}
                    </Grid>
                  </Grid>
                ))}

                <Grid container spacing={3} className="margin-top-10 display-none">
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <ButtonMain
                      size="medium"
                      tipoBotao="transparente-com-bordas"
                      disabled={isPending}
                      onClick={handleClose}
                    >
                      Cancelar
                    </ButtonMain>
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
                    <ButtonMain size="medium" tipoBotao="azul-escuro" disabled={isPending}>
                      Incluir garantia {tab[0].NOMEEMPRESA}
                    </ButtonMain>
                  </Grid>
                </Grid>
              </Box>
            )
        )}
      </Box>

      {lstGarantias && !isPending && lstGarantias.length <= 0 && (
        <Box className="texto-center w-100">
          <b>Nenhum garantias encontrado!</b>
        </Box>
      )}
    </Box>
  );
};
export default memo(LimitesGarantias);
