import React, { memo, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from 'moment';
import 'moment/locale/pt-br';
import LoaderMain from '../../../../Shared/LoaderMain';
import { getBuscaHistoricoResponsaveis } from '../../../../../api/formalizacao';
import { textToLowerCase } from '../../../../Utils/format';

const HistoricoResponsaveis = ({ codRelacionamentosFormalizacao }) => {
  const [isPending, setPending] = useState(false);
  const [lstHistoricoResponsaveis, setLstHistoricoResponsaveis] = useState([]);

  useEffect(() => {
    buscaDadosHistoricoResponsaveis();
  }, []);

  const buscaDadosHistoricoResponsaveis = () => {
    setPending(true);

    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao
    };

    getBuscaHistoricoResponsaveis(params)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
          const dados = res.data;
          setLstHistoricoResponsaveis(dados);
        } else {
          setLstHistoricoResponsaveis([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Box sx={isPending ? { position: 'relative', minHeight: '220px' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      <Grid container className="margin-bottom-10">
        {lstHistoricoResponsaveis.map((dado, index) => (
          <Grid key={index} item xl={12} lg={12} md={12} sm={12} xs={12} className="grid-time-line">
            <Box className="box-time-line">
              <Box className="nome-user">{textToLowerCase(dado.usuario_alteracao)}</Box>
              <Box className="box-alteracao">
                {dado.usuario_anterior === '' ? 'incluiu' : 'alterou'} o <b> responsável </b>
              </Box>
              <Box>{`${moment(dado.data).format('DD/MM/yyyy')} às ${moment(dado.data).format('HH:mm:ss')}`}</Box>
            </Box>
            <Box className="box-time-line-alteracao">
              {dado.usuario_anterior === '' ? (
                <b>Não atribuído</b>
              ) : (
                <>
                  <AccountCircleIcon className="icon-user" />{' '}
                  <b className="camel-case">{textToLowerCase(dado.usuario_anterior)}</b>
                </>
              )}
              <ArrowForwardIcon className="icon-forward" />
              <AccountCircleIcon className="icon-user" />{' '}
              <b className="camel-case">{textToLowerCase(dado.usuario_atual)}</b>
            </Box>
          </Grid>
        ))}
      </Grid>

      {lstHistoricoResponsaveis && !isPending && lstHistoricoResponsaveis.length <= 0 && (
        <Box className="texto-center w-100">
          <b>Nenhum registro encontrado!</b>
        </Box>
      )}
    </Box>
  );
};
export default memo(HistoricoResponsaveis);
