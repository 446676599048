/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import 'moment/locale/pt-br';
import TextFieldMain from '../../Shared/TextFieldMain';
import { validaTelefone } from '../../Utils/validaTelefone';
import Strip from '../../Utils/strip';
import LoaderMain from '../../Shared/LoaderMain';
import { postAlterarTagRelacionamentos } from '../../../api/relacionamentos';
import { focusInput } from '../../Utils/utils';

const EditarRelacionamentos = ({
  dadosCadastroRelacionamentos,
  cancelarEdicaoRelacionamentos,
  funcaoSucesso
}) => {
  const [isPending, setIsPending] = useState(false);

  const cadastrarFormulario = (values, form) => {
    setIsPending(true);

    const newValues = {};
    // eslint-disable-next-line no-underscore-dangle
    newValues.id = dadosCadastroRelacionamentos._id;
    newValues.nome = values.nome ?? null;
    newValues.email = values.email ?? null;
    newValues.telefone = Strip(values.telefone) ?? null;
    newValues.whatsapp = Strip(values.whatsapp) ?? null;

    postAlterarTagRelacionamentos(newValues)
      .then(() => {
        dadosCadastroRelacionamentos.nome = values.nome ?? null;
        dadosCadastroRelacionamentos.email = values.email ?? null;
        dadosCadastroRelacionamentos.telefone = Strip(values.telefone) ?? null;
        dadosCadastroRelacionamentos.whatsapp = Strip(values.whatsapp) ?? null;

        funcaoSucesso();
      })
      .catch(() => {})
      .finally(() => {
        form.setSubmitting(false);
        setIsPending(false);
      });
  };

  return (
    <CardContent className="">
      <Typography variant="h6" className="texto-cor-cinza-escuro texto-weight-600">
        Dados dos Relacionamentos
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: '10px 0 0 0' }}>
          <Formik
            initialValues={{
              cnpj: dadosCadastroRelacionamentos.cpf_cnpj ?? '',
              nome: dadosCadastroRelacionamentos.nome ?? '',
              email: dadosCadastroRelacionamentos.email ?? '',
              telefone: dadosCadastroRelacionamentos.telefone
                ? String(dadosCadastroRelacionamentos.telefone).substring(0, 11)
                : '',
              whatsapp: dadosCadastroRelacionamentos.whatsapp
                ? String(dadosCadastroRelacionamentos.whatsapp).substring(0, 11)
                : ''
            }}
            onSubmit={(values, form) => {
              const errors = {};

              if (values.nome && values.nome.length > 0) {
                const input_nome = values.nome.trim();
                if (!input_nome.includes(' ')) {
                  errors.nome = 'Preencha nome e sobrenome';
                }
              }

              if (values.email && values.email.length > 0) {
                const result = /\S+@\S+\.\S+/;
                const input_email = values.email.trim();
                if (!result.test(values.email) || input_email.includes(' ')) {
                  errors.email = 'Email inválido';
                }
              }

              if (values.telefone) {
                const celPhone = Strip(values.telefone);
                const tel = Strip(celPhone);
                const ddd = parseInt(tel.substring(0, 2), 10);

                if (ddd <= 10 || ddd >= 100) {
                  errors.telefone = 'DDD inválido';
                }

                if (!validaTelefone(celPhone)) {
                  errors.telefone = 'Telefone inválido';
                }
              }

              if (values.whatsapp) {
                const celPhone = Strip(values.whatsapp);
                const tel = Strip(celPhone);
                const ddd = parseInt(tel.substring(0, 2), 10);

                if (ddd <= 10 || ddd >= 100) {
                  errors.whatsapp = 'DDD inválido';
                }

                if (!validaTelefone(celPhone)) {
                  errors.whatsapp = 'WhatsApp inválido';
                }
              }

              form.setErrors(errors);

              if (Object.keys(errors).length <= 0) {
                cadastrarFormulario(values, form);
              } else {
                form.setSubmitting(false);
                focusInput(Object.keys(errors)[0]);
              }
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Grid container>
                  {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Nome:
                    </Typography>
                    <Field
                      component={TextField}
                      type="text"
                      className="input-field"
                      name="nome"
                      id="nome"
                      fullWidth
                      autoComplete="off"
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Email:
                    </Typography>
                    <Field
                      component={TextField}
                      type="email"
                      className="input-field"
                      name="email"
                      id="email"
                      fullWidth
                      autoComplete="off"
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Telefone:
                    </Typography>
                    <Field
                      component={TextFieldMain}
                      type="text"
                      name="telefone"
                      id="telefone"
                      inputClassName="input-field input-field-main"
                      autoComplete="none"
                      tipoMascara="phoneMask"
                      fullWidth
                      inputProps={{ maxLength: 15 }}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      WhatsApp:
                    </Typography>
                    <Field
                      component={TextFieldMain}
                      type="text"
                      name="whatsapp"
                      id="whatsapp"
                      inputClassName="input-field input-field-main"
                      autoComplete="none"
                      tipoMascara="phoneMask"
                      fullWidth
                      inputProps={{ maxLength: 15 }}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  {isSubmitting && <LinearProgress />}

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="btn-outline-main margin-top-30"
                      disabled={isSubmitting}
                      onClick={() => cancelarEdicaoRelacionamentos(false)}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="btn-main margin-top-30"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default EditarRelacionamentos;
