import React, { memo, useEffect, useState } from 'react';
import { Alert, Box, Grid, InputAdornment } from '@mui/material';
import { Field } from 'formik';
import { LoopOutlined } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR as DatePtBr } from '@mui/x-date-pickers/locales';
import TextFieldMain from '../../../../Shared/TextFieldMain';
import LoaderMain from '../../../../Shared/LoaderMain';
import RadioGroupMain from '../../../../Shared/RadioGroupMain';
import SelectMain from '../../../../Shared/SelectMain';

const FieldsPessoaFisica = ({
  values,
  isPending,
  lstTipos,
  onChangeCpfCnpj = () => {},
  cpfReadOnlyDisabled = false,
  tipoCadastro = ''
}) => {
  const [valueDataNiver, setValueDataNiver] = useState(null);
  const [valueDataEmissao, setValueDataEmissao] = useState(null);
  const localizacaoBrasil = DatePtBr.components.MuiLocalizationProvider.defaultProps.localeText;

  useEffect(() => {
    if (values && values.dataNascimento) {
      const dataFormatada = moment(values.dataNascimento, 'DD/MM/YYYY');
      setValueDataNiver(dataFormatada);
    }

    if (values && values.dataRg) {
      const valueData = moment(values.dataRg, 'DD/MM/YYYY');
      setValueDataEmissao(valueData);
    }
  }, []);

  useEffect(() => {
    if (valueDataNiver && valueDataNiver.isValid()) {
      values.dataNascimento = valueDataNiver.format('DD/MM/YYYY');
    } else {
      values.dataNascimento = null;
    }

    if (valueDataEmissao && valueDataEmissao.isValid()) {
      values.dataRg = valueDataEmissao.format('DD/MM/YYYY');
    } else {
      values.dataRg = null;
    }
  }, [valueDataNiver, valueDataEmissao]);

  return (
    <>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          CPF
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="cpfcnpj"
            name="cpfcnpj"
            type="text"
            component={TextFieldMain}
            onChangeInput={onChangeCpfCnpj}
            fullWidth
            autoComplete="off"
            tipoMascara="CPFMask"
            disabled={isPending || cpfReadOnlyDisabled}
            InputProps={{
              endAdornment: isPending ? (
                <InputAdornment position="end">
                  <LoopOutlined className="rotating" />
                </InputAdornment>
              ) : (
                <></>
              ),
              maxLength: 14,
              readOnly: cpfReadOnlyDisabled
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Nome
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="nome"
            name="nome"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Data de nascimento:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
            <DatePicker
              name="data_junta"
              className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
              value={valueDataNiver}
              onChange={dataMoment => setValueDataNiver(dataMoment)}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                field: {
                  clearable: true,
                  onClear: () => setValueDataNiver(null)
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Gênero:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="genero"
            name="genero"
            component={RadioGroupMain}
            disabled={isPending}
            row
            options={[
              { label: 'Masculino', value: 'M' },
              { label: 'Feminino', value: 'F' }
            ]}
          />
        </Grid>
      </Grid>
      {tipoCadastro !== 'conjuge' && (
        <Grid container spacing={3} className="input-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
            Estado civil:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
            {Array.isArray(lstTipos.estadoCivil) && lstTipos.estadoCivil.length > 0 ? (
              <Field
                id="codEstadoCivil"
                name="codEstadoCivil"
                type="text"
                boxMain={false}
                component={SelectMain}
                placeholder="Selecione o estado civil"
                displayEmpty
                displayEmptyLabel="Não Definido"
                autoComplete="off"
                disabled={isPending}
                opcoes={[...lstTipos.estadoCivil].map(tipo => ({
                  label: tipo.nome,
                  valor: tipo._id
                }))}
              />
            ) : Array.isArray(lstTipos.estadoCivil) && lstTipos.estadoCivil.length === 0 ? (
              <Alert severity="error" className="loader-box">
                Ocorreu algum erro, favor entrar em contato com o suporte
              </Alert>
            ) : (
              <Box className="loader-box">
                <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      {tipoCadastro !== 'conjuge' && (
        <Grid container spacing={3} className="input-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
            Regime de casamento:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
            {Array.isArray(lstTipos.regimeCasamento) && lstTipos.regimeCasamento.length > 0 ? (
              <Field
                id="codRegimeCasamento"
                name="codRegimeCasamento"
                type="text"
                boxMain={false}
                component={SelectMain}
                placeholder="Selecione o regime de casamento"
                displayEmpty
                displayEmptyLabel="Não Definido"
                autoComplete="off"
                disabled={isPending}
                opcoes={[...lstTipos.regimeCasamento].map(tipo => ({
                  label: tipo.nome,
                  valor: tipo._id
                }))}
              />
            ) : Array.isArray(lstTipos.regimeCasamento) && lstTipos.regimeCasamento.length === 0 ? (
              <Alert severity="error" className="loader-box">
                Ocorreu algum erro, favor entrar em contato com o suporte
              </Alert>
            ) : (
              <Box className="loader-box">
                <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          RG:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="rg"
            name="rg"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Órgão expedidor:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="orgaoRg"
            name="orgaoRg"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 6 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Data de emissão:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
            <DatePicker
              name="dataRg"
              className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
              value={valueDataEmissao}
              onChange={dataMoment => setValueDataEmissao(dataMoment)}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                field: {
                  clearable: true,
                  onClear: () => setValueDataEmissao(null)
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Nacionalidade:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="nacionalidade"
            name="nacionalidade"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Naturalidade:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="naturalidade"
            name="naturalidade"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Nome da mãe:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="nomeMae"
            name="nomeMae"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Nome do pai:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="nomePai"
            name="nomePai"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Profissão:
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="profissao"
            name="profissao"
            type="text"
            component={TextFieldMain}
            fullWidth
            autoComplete="off"
            disabled={isPending}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="input-line">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
          Pessoa Politicamente Exposta (PPE):
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
          <Field
            id="politicamenteExposta"
            name="politicamenteExposta"
            component={RadioGroupMain}
            disabled={isPending}
            row
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' }
            ]}
          />
        </Grid>
      </Grid>
      {tipoCadastro === 'propria' && (
        <Grid container spacing={3} className="input-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
            Possui eCPF:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
            <Field
              id="possuiECpf"
              name="possuiECpf"
              component={RadioGroupMain}
              disabled={isPending}
              row
              options={[
                { label: 'Sim', value: 'S' },
                { label: 'Não', value: 'N' }
              ]}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default memo(FieldsPessoaFisica);
