import React from 'react';

const Retorno = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path d="M15.3 18H2.7A2.7 2.7 0 0 1 0 15.3V.9A.9.9 0 0 1 .9 0h12.6a.9.9 0 0 1 .9.9v10.8H18v3.6a2.7 2.7 0 0 1-2.7 2.7zM14 13v2a1 1 0 0 0 2 0v-2h-2zm-1 3V2H2v13.125c0 .483.41.875.917.875H13zM4 4h7v2H4V4zm0 4h7v2H4V8zm-1 4h5v2H3v-2z" />
  </svg>
);
export default Retorno;
