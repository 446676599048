import React, { memo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import BoxPaper from './BoxPaper';
import LoaderMain from '../../../Shared/LoaderMain';
import { DOCUMENTOPERIFERICO } from '../../../Shared/Enums/DocumentosPerifericos';
import { getBuscaPerifericos } from '../../../../api/formalizacao';
import BoxPaperDocumentos from '../PessoasCliente/DadosPessoa/BoxPaperDocumentos';
import CamposInvalidosPerifericos from '../PessoasCliente/DadosPessoa/CamposInvalidosPerifericos';
import ButtonMain from '../../../Shared/ButtonMain';

const PerifericosEmpresa = ({ codCliente, setAlerta }) => {
  const [isPending, setIsPending] = useState(false);

  const [CamposFaltantes, setCamposFaltantes] = useState(
    Array(Object.keys(DOCUMENTOPERIFERICO)).fill(false)
  );
  const [TipoArquivoSelecionado, setTipoArquivoSelecionado] = useState(
    Array(Object.keys(DOCUMENTOPERIFERICO)).fill(false)
  );
  const [blocoAberto, setBlocoAberto] = useState(Array(Object.keys(DOCUMENTOPERIFERICO)).fill(false));

  const alteraEstadoCamposFaltantesIndex = (index, value) => {
    setCamposFaltantes(prevState => {
      if (prevState[index] === value) {
        return prevState;
      }
      const newArray = [...prevState];
      newArray[index] = value;
      return newArray;
    });
  };

  const alteraEstadoTipoArquivoSelecionadoIndex = (index, value) => {
    setTipoArquivoSelecionado(prevState => {
      if (prevState[index] === value) {
        return prevState;
      }
      const newArray = [...prevState];
      newArray[index] = value;
      return newArray;
    });
  };

  const alteraEstadohandleBlocoAbertoIndex = (value, index) => {
    setBlocoAberto(prevState => {
      if (prevState[index] === value) {
        return prevState;
      }
      const newArray = [...prevState];
      newArray[index] = value;
      return newArray;
    });
  };

  const resetarCampoFaltante = index => {
    const novoValor = [...CamposFaltantes];
    novoValor[index] = false;
    setCamposFaltantes(novoValor);
  };

  const resetarTipoArquivoSelecionado = index => {
    const novoValor = [...TipoArquivoSelecionado];
    novoValor[index] = false;
    setTipoArquivoSelecionado(novoValor);
  };

  const verContrato = (tipoArquivo, rota, nomeDocumento, index, geracaoForcada = false) => {
    setIsPending(true);
    const params = {
      codCliente: codCliente,
      validaDocumento: !geracaoForcada ? true : false,
      tipoArquivo: tipoArquivo,
      salvarDocumento: false
    };

    getBuscaPerifericos(params, rota)
      .then(res => {
        const dados = res.data;
        const base64regex = /^[A-Za-z0-9+/=]+\s*$/;
        if (typeof dados === 'string' && base64regex.test(dados)) {
          if (tipoArquivo === 'pdf') {
            const binaryString = atob(dados);
            const blob = new Blob(
              [new Uint8Array(binaryString.length).map((_, i) => binaryString.charCodeAt(i))],
              { type: 'application/pdf' }
            );
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          } else if (tipoArquivo === 'word') {
            const fileName = `${nomeDocumento}.docx`;
            downloadWordDocument(dados, fileName);
          }
        } else if (dados.data) {
          alteraEstadoCamposFaltantesIndex(index, dados.data);
          alteraEstadoTipoArquivoSelecionadoIndex(index, tipoArquivo);
          alteraEstadohandleBlocoAbertoIndex(true, index);
        }
        if (geracaoForcada) {
          alteraEstadohandleBlocoAbertoIndex(false, index);
          resetarCampoFaltante(index);
          resetarTipoArquivoSelecionado(index);
        }
      })
      .catch(err => {
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  function downloadWordDocument(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
  return (
    <BoxPaper iconeTitulo={<FileCopy />} labelTitulo="Periféricos">
      <Box className="">
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

        <Box>
          {Object.values(DOCUMENTOPERIFERICO).map((obj, index) => {
            const { nome, rota, tipoPeriferico } = obj;
            if (tipoPeriferico === 'empresa') {
              return (
                <BoxPaperDocumentos
                  key={index}
                  iconeTitulo={<FileCopy />}
                  labelTitulo={nome}
                  verContrato={verContrato}
                  rota={rota}
                  nome={nome}
                  index={index}
                  CamposFaltantes={CamposFaltantes[index]}
                  blocoAberto={blocoAberto[index]}
                  alteraEstadohandleBlocoAbertoIndex={alteraEstadohandleBlocoAbertoIndex}
                >
                  {CamposFaltantes[index] && (
                    <Box container className="margin-bottom-10 items-centro-verticalmente pad-20 ">
                      <>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <CamposInvalidosPerifericos
                            CamposFaltantes={CamposFaltantes[index]}
                            setCamposFaltantes={setCamposFaltantes}
                            indexDocPeriferico={index}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={3}
                          sm={4}
                          xs={5}
                          className="flex-box-items-esquerda"
                        >
                          <ButtonMain
                            size="medium"
                            tipoBotao="azul-escuro"
                            classesAdicional="w-auto margin-top-15"
                            onClick={() =>
                              verContrato(TipoArquivoSelecionado[index], rota, nome, index, true)
                            }
                          >
                            Gerar mesmo assim
                          </ButtonMain>
                        </Grid>
                      </>
                    </Box>
                  )}
                </BoxPaperDocumentos>
              );
            }
            return null;
          })}
        </Box>
      </Box>
    </BoxPaper>
  );
};
export default memo(PerifericosEmpresa);
