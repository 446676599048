import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import TextFieldMui from '@mui/material/TextField';
import { DateRange } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoaderMain from '../../Shared/LoaderMain';
import PopoverOptions from '../../Shared/PopoverOptions';
import { buscaFunilVendas } from '../../../api/crm';

const StatusLead = ({
  setDadosModalDashboard,
  atualizarDashboard,
  setDadosCardsLeads,
  lstUsuariosCrm
}) => {
  const [isPending, setIsPending] = useState(false);
  const [cardsLeads, setCardsLeads] = useState([]);
  const [usuarioCrmFiltro, setUsuarioCrmFiltro] = useState(null);

  const opcoesSelect = [
    { label: 'Últimos 30 dias', valor: 1 },
    { label: 'Últimos 3 meses', valor: 3 },
    { label: 'Últimos 6 meses', valor: 6 },
    { label: 'Últimos 12 meses', valor: 12 },
    { label: 'Intervalor Personalizado', valor: 'intervalo' } // MANTER ESTE ITEM SEMPRE POR ULTIMO NO ARRAY
  ];
  const [periodo, setPeriodo] = useState(opcoesSelect[0]);
  const [periodoInicio, setPeriodoInicio] = useState(null);
  const [periodoFinal, setPeriodoFinal] = useState(null);
  const [opcaoPeriodoSelecionada, setOpcaoPeriodoSelecionada] = useState(null);

  useEffect(() => {
    buscarFunilVendas();
  }, [atualizarDashboard]);

  const buscarFunilVendas = filter => {
    const params = {
      filtro_meses:
        filter && filter.periodo ? filter.periodo.valor : periodo.valor,
      cod_usuario_reserva:
        filter && filter.usuariosReserva
          ? filter.usuariosReserva
          : usuarioCrmFiltro
    };

    if (params.filtro_meses === 'intervalo') {
      params.intervalo_periodo = {
        inicio: periodoInicio.format('YYYY-MM-DD'),
        final: periodoFinal.format('YYYY-MM-DD')
      };
    }

    if (params.filtro_meses === 'intervalo') {
      params.intervalo_periodo = {
        inicio: periodoInicio.format('YYYY-MM-DD'),
        final: periodoFinal.format('YYYY-MM-DD')
      };
    }

    setIsPending(true);

    buscaFunilVendas(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          const dadosLeadsTemp = [];
          const lstNomesTagsTemp = {};
          let indexPrincipal = 0;
          res.data.map((dados, index) => {
            if (index !== 0 && index % 5 === 0) indexPrincipal++;
            if (typeof dadosLeadsTemp[indexPrincipal] === 'undefined')
              dadosLeadsTemp[indexPrincipal] = [];
            dadosLeadsTemp[indexPrincipal].push(dados);
            lstNomesTagsTemp[dados.tag] = dados;
            return dados;
          });
          setCardsLeads(dadosLeadsTemp);
          setDadosCardsLeads(lstNomesTagsTemp);
        } else {
          setCardsLeads([]);
          setDadosCardsLeads({});
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleAplicarFiltroPeriodo = () => {
    if (opcaoPeriodoSelecionada.valor === 'intervalo') {
      if (
        periodoInicio === null ||
        (periodoInicio && periodoInicio.isValid() === false)
      ) {
        setPeriodoInicio(null);
        return false;
      }
      if (
        periodoFinal === null ||
        (periodoFinal && periodoFinal.isValid() === false)
      ) {
        setPeriodoFinal(null);
        return false;
      }
    } else {
      setPeriodoInicio(null);
      setPeriodoFinal(null);
    }
    setPeriodo(opcaoPeriodoSelecionada);
    buscarFunilVendas({ periodo: opcaoPeriodoSelecionada });
  };

  const handleOnChangeUsuarioCrmFiltro = (ev, newValues) => {
    const valores =
      newValues && newValues.length > 0
        ? newValues.map(item => item.value)
        : [];
    setUsuarioCrmFiltro(valores);
    buscarFunilVendas({ usuariosReserva: valores });
  };

  const mostrarModalDashBoard = dadosLead => {
    const dados = {};
    dados.titleModal = (dadosLead && dadosLead.descricao) ?? 'Leads';
    dados.buscarLeads = dadosLead.tag;
    dados.cod_usuario_reserva = usuarioCrmFiltro;
    dados.filtro_meses = periodo.valor;

    if (dados.filtro_meses === 'intervalo') {
      dados.intervalo_periodo = {
        inicio: periodoInicio.format('YYYY-MM-DD'),
        final: periodoFinal.format('YYYY-MM-DD')
      };
    }

    if (dadosLead && dadosLead.permite_acoes) {
      setDadosModalDashboard(dados);
    }
  };

  return (
    <Card className="chart-card status-lead">
      <CardContent
        className="ajuste-spin-chart ajuste-card-lead"
        sx={isPending ? { position: 'relative' } : {}}
      >
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        <Grid
          container
          className="margin-bottom-20"
          spacing={2}
          sx={{ pr: '10px' }}
        >
          <Grid item lg={5} md={5} sm={4} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-7 texto-weight-600"
            >
              Funil de vendas
            </Typography>
          </Grid>

          <Grid item lg={4} md={4} sm={5} xs={12}>
            <Autocomplete
              multiple
              size="small"
              className="autocomplete-multiple-small"
              options={lstUsuariosCrm || []}
              value={
                usuarioCrmFiltro &&
                usuarioCrmFiltro.length > 0 &&
                lstUsuariosCrm.length > 0
                  ? lstUsuariosCrm.filter(option =>
                      usuarioCrmFiltro.includes(option.value)
                    )
                  : []
              }
              onChange={handleOnChangeUsuarioCrmFiltro}
              limitTags={1}
              disabled={lstUsuariosCrm.length === 0}
              disablePortal
              renderInput={params => (
                <TextFieldMui
                  {...params}
                  name="usuariosCrm"
                  placeholder="Filtre o responsável"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off'
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={12} className="texto-direita">
            <PopoverOptions
              id="filtro_periodo"
              opcoes={opcoesSelect}
              labelButton="Selecione o Periodo"
              prefixIconButton={<DateRange className="icon-periodo" />}
              setOpcaoSelecionadaExterna={setOpcaoPeriodoSelecionada}
              opcaoSelecionadaExterna={periodo}
              classButton="text-transform-none items-centralizados-esquerda"
            >
              <Box>
                {opcaoPeriodoSelecionada &&
                  opcaoPeriodoSelecionada.valor &&
                  opcaoPeriodoSelecionada.valor === 'intervalo' && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Grid
                        container
                        spacing={0}
                        sx={{
                          minWidth: '355px',
                          maxWidth: '355px',
                          mt: '10px'
                        }}
                      >
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                          <DatePicker
                            label="De"
                            name="data_agendamento_ini_filtro"
                            className="w-100 date-time-picker-intervalo data-intervalo-ini"
                            value={periodoInicio}
                            maxDate={periodoFinal}
                            onChange={dataMoment =>
                              setPeriodoInicio(dataMoment)
                            }
                            slotProps={{
                              inputAdornment: {
                                position: 'start'
                              },
                              field: {
                                clearable: true,
                                onClear: () => setPeriodoInicio(null)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                          <DatePicker
                            label="Até"
                            name="data_agendamento_filtro"
                            className="w-100 date-time-picker-intervalo data-intervalo-fim"
                            value={periodoFinal}
                            minDate={periodoInicio}
                            onChange={dataMoment => setPeriodoFinal(dataMoment)}
                            slotProps={{
                              inputAdornment: {
                                position: 'start'
                              },
                              field: {
                                clearable: true,
                                onClear: () => setPeriodoFinal(null)
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  )}
                <Button
                  className="btn-cor-padrao w-100 text-transform-none font-size-1-rem"
                  size="medium"
                  onClick={() => handleAplicarFiltroPeriodo()}
                  sx={{ height: 'auto !important', mt: '15px' }}
                >
                  Aplicar
                </Button>
              </Box>
            </PopoverOptions>
          </Grid>
        </Grid>

        <Box className="box-principal-group overflow-x">
          {cardsLeads.map((blocoCards, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} className="box-principal-status-lead">
              {blocoCards.map(card => (
                <Box key={card.tag} className="box-card-status-lead">
                  <Box
                    className={`box-status-card ${card.cor_card} ${
                      card.permite_acoes ? 'cursor-pointer' : ''
                    }`}
                    onClick={() => mostrarModalDashBoard(card)}
                  >
                    <Grid container>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={5}
                        xs={5}
                        style={{ height: '25px' }}
                      >
                        <Box
                          className="cor-status-card"
                          style={{ borderColor: card.color }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={8}
                        md={8}
                        sm={7}
                        xs={7}
                        className="texto-direita"
                      />
                      <Grid
                        className="descricao-status"
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <span className="quantidade-status">
                          {String(card.quantidade).padStart(2, '0')}
                        </span>
                        <span className="nome-status">{card.descricao}</span>
                        <hr />
                        <Box className="valor-status">
                          {parseFloat(card.porcentagem).toLocaleString(
                            'pt-BR',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )}
                          <span>%</span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
              {/* Criado um loop abaixo para criar caixas vazias que irão complementar a linha, pois precisa sempre ter 5 caixas, para manter o tamanho igual das caixas */}
              {blocoCards.length < 5 &&
                (() => {
                  const boxs = [];
                  for (let i = 0; i < 5 - blocoCards.length; i++) {
                    boxs.push(<Box key={i} className="box-card-status-lead" />);
                  }
                  return boxs;
                })()}
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatusLead;
