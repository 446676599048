import React, { memo, useState } from 'react';
import moment from 'moment';
import { Chip, Grid } from '@mui/material';
import { Business, ModeEditOutlined } from '@mui/icons-material';
import { NotIsEmpty, mascararCpfCnpj } from '../../../Utils/format';
import BoxPaper from '../shared/BoxPaper';
import ButtonMain from '../../../Shared/ButtonMain';
import FormInformacoesGerais from './Formularios/FormInformacoesGerais';
import LoaderMain from '../../../Shared/LoaderMain';
import { buscarListasTiposPessoa } from '../shared/utils';
import CopyValorTexto from '../../../Shared/CopyValorTexto';

const InformacoesGerais = ({
  dadosGerais,
  escopoClienteDados,
  dadosCliente,
  setDadosCliente,
  setAlerta,
  setModalClienteDados
}) => {
  const [lstTipos, setLstTipos] = useState(false);
  const valorIndefinido = <em className="texto-cor-cinza">-</em>;

  const alterarInfoGeraisCliente = async () => {
    let listasTipos = lstTipos;
    if (
      lstTipos === false ||
      lstTipos.constituicao.length === 0 ||
      lstTipos.atividade.length === 0 ||
      lstTipos.naturezaJuridica.length === 0 ||
      lstTipos.regimeTributario.length === 0
    ) {
      setModalClienteDados({
        openModal: true,
        tituloModal: 'Informações gerais do cliente',
        conteudoModal: <LoaderMain tipoLoader="float" tipoImagem="circulo" />
      });
      listasTipos = await buscarListasTiposPessoa(lstTipos, setLstTipos, 'DadosCliente', [
        'constituicao',
        'atividade',
        'naturezaJuridica',
        'regimeTributario',
        'portesEmpresa',
        'scoreRatings'
      ]);
    }

    setModalClienteDados({
      openModal: true,
      tituloModal: 'Informações gerais do cliente',
      conteudoModal: (
        <FormInformacoesGerais
          formDados={dadosGerais}
          dadosCliente={dadosCliente}
          setDadosCliente={setDadosCliente}
          setAlerta={setAlerta}
          lstTipos={listasTipos}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  return (
    <>
      <BoxPaper iconeTitulo={<Business />} labelTitulo="Informações gerais do cliente">
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            CNPJ:
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="info-valor">
            {mascararCpfCnpj(String(dadosGerais.cnpj))}
            {NotIsEmpty(dadosGerais.cnpj)
              ? CopyValorTexto({ texto: dadosGerais.cnpj })
              : valorIndefinido}
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="flex-box-items-direita pad-0">
            {escopoClienteDados && escopoClienteDados.permite_alterar && (
              <ButtonMain
                size="small"
                variant="outlined"
                className="botao-alterar-excluir"
                onClick={alterarInfoGeraisCliente}
              >
                <ModeEditOutlined />
              </ButtonMain>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Empresa:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {dadosGerais.nome}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Nome fantasia:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.nome_fantasia) ? dadosGerais.nome_fantasia : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Inscrição estadual:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.inscricao_estadual)
              ? dadosGerais.inscricao_estadual
              : valorIndefinido}
            {NotIsEmpty(dadosGerais.inscricao_estadual)
              ? CopyValorTexto({ texto: dadosGerais.inscricao_estadual })
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Inscrição municipal:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.inscricao_municipal)
              ? dadosGerais.inscricao_municipal
              : valorIndefinido}
            {NotIsEmpty(dadosGerais.inscricao_municipal)
              ? CopyValorTexto({ texto: dadosGerais.inscricao_municipal })
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            NIRE:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.nire) ? dadosGerais.nire : valorIndefinido}
            {NotIsEmpty(dadosGerais.nire)
              ? CopyValorTexto({ texto: dadosGerais.nire })
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Data de registro na junta:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.data_junta_orig)
              ? moment(dadosGerais.data_junta_orig).format('DD/MM/yyyy')
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Fundação:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.fundacao_orig)
              ? moment(dadosGerais.fundacao_orig).format('DD/MM/yyyy')
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Constituição:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {dadosGerais.dados_constituicao && NotIsEmpty(dadosGerais.dados_constituicao.nome)
              ? dadosGerais.dados_constituicao.nome
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Natureza jurídica:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {dadosGerais.dados_natureza_juridica && NotIsEmpty(dadosGerais.dados_natureza_juridica.nome)
              ? dadosGerais.dados_natureza_juridica.nome
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Atividade:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {dadosGerais.dados_atividade && NotIsEmpty(dadosGerais.dados_atividade.nome)
              ? dadosGerais.dados_atividade.nome
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Capital social:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.capital_social) && !isNaN(dadosGerais.capital_social)
              ? `R$ ${parseFloat(dadosGerais.capital_social).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Regime tributário:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {dadosGerais.dados_regime_tributario && NotIsEmpty(dadosGerais.dados_regime_tributario.nome)
              ? dadosGerais.dados_regime_tributario.nome
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Porte da Empresa:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {dadosGerais.dados_porte_empresa && NotIsEmpty(dadosGerais.dados_porte_empresa.nome)
              ? dadosGerais.dados_porte_empresa.nome
              : valorIndefinido}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="info-line">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
            Rating:
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
            {NotIsEmpty(dadosGerais.rating) ? dadosGerais.rating : valorIndefinido}
          </Grid>
        </Grid>
        {dadosGerais.recuperacao_judicial && (
          <Grid container spacing={3} className="info-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label" />
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
              <Chip label="Recuperação Judicial" className="badge-preto" />
            </Grid>
          </Grid>
        )}
      </BoxPaper>
    </>
  );
};
export default memo(InformacoesGerais);
