import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Chart } from 'react-google-charts';
import LoaderMain from '../../Shared/LoaderMain';

const MotivosPerda = () => {
  const [dadosChart, setDadosChart] = useState(false);
  const [isPending, setIsPending] = useState([
    ['Motivos', 'Top'],
    ['Curioso', 0],
    ['Documentação', 0],
    ['Fechou com concorrente', 0],
    ['Indisponibilidade de datas', 0],
    ['Fora do perfil', 0]
  ]);

  const setDadosIniciais = () => [
    ['Motivos', 'Top'],
    ['Curioso', 25],
    ['Documentação', 20],
    ['Fechou com concorrente', 18],
    ['Indisponibilidade de datas', 15],
    ['Fora do perfil', 8]
  ];

  const options = {
    title: null,
    width: '100%',
    height: '100px',
    chartArea: { width: '85%', right: 10, left: 40 },
    seriesType: 'bars',
    colors: ['#F97A7B'],
    legend: { position: 'none' },
    animation: {
      duration: 1000,
      easing: 'out'
    }
  };

  useEffect(() => {
    // setIsPending(true);
    setTimeout(() => {
      setIsPending(false);
      setDadosChart(setDadosIniciais);
    }, 2500);
  }, []);

  /*
  const buscaDadosChart = () => {
    setIsPending(true);
    setValorTotal(0);
    setValorTotalPeriodo(0);
    setDadosChart(setDadosIniciais);
    postTitulosVencidos(params)
      .then(res => {
        let arrayDados = [];
        arrayDados.push([
          'dia',
          'Valores vencidos por data',
          { role: 'annotation' },
          'Ticket médio',
          { role: 'annotation' }
        ]);
        let valorTotal = res.data && res.data.valorTotal ? parseFloat(res.data.valorTotal) : 0;
        let valorTotalPer =
          res.data && res.data.valorTotalPeriodo ? parseFloat(res.data.valorTotalPeriodo) : 0;
        let lstTitulos = res.data && res.data.titulos ? res.data.titulos : [];

        lstTitulos.map(dados => {
          let dia = moment(dados.dataVencimento).format('DD[/]MMM');
          let valor = parseInt(dados.valor);
          let ticket = valor / parseInt(dados.qtd);
          ticket = parseInt(ticket);
          let ticketFormatado = ticket.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });

          arrayDados.push([dia, valor, valor, ticket, ticketFormatado]);
          return dados;
        });

        if (valorTotal > 0) {
          setValorTotal(valorTotal);
        }

        if (valorTotalPer > 0) {
          setValorTotalPeriodo(valorTotalPer);
        }

        if (lstTitulos.length > 0) {
          setDadosChart(arrayDados);
        }
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
      });
  };
  */

  return (
    <Card className="chart-card-perdas elemento-desabilitado">
      {dadosChart && (
        <CardContent
          className="ajuste-spin-chart"
          sx={isPending ? { position: 'relative' } : {}}
        >
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Typography
            variant="subtitle1"
            className="label-chart texto-cor-cinza-escuro pad-5"
          >
            <span className="font-size-1rem texto-weight-600 white-space-nowrap">
              Top 5 motivos de perda
            </span>
          </Typography>
          <Chart
            className="chartMotivosPerda"
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={dadosChart}
            options={options}
            chartLanguage="pt-Br"
          />
        </CardContent>
      )}
    </Card>
  );
};

export default MotivosPerda;
