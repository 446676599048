import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ContasCorrente from './ContasCorrente';
import ContasEscrow from './ContasEscrow';
import { listarBancosAgencias } from '../../../../api/formalizacao';
import AlertMain from '../../../Shared/AlertMain';

const DadosBancariosCliente = ({
  cliente,
  setAlerta,
  setModalClienteDados,
  escopoDadosBancarios
}) => {
  const [lstBancosAgencia, setLstBancosAgencia] = useState(false);
  const [alertConfirm, setAlertConfirm] = useState({});

  useEffect(() => {
    listarBancosAgencias(cliente.cod_cliente)
      .then(res => {
        setLstBancosAgencia(res.data ?? false);
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      });
  }, []);

  return (
    <Box className="dados-bancarios">
      <ContasCorrente
        cod_cliente={cliente.cod_cliente}
        lstBancosAgencia={lstBancosAgencia}
        setAlerta={setAlerta}
        setAlertConfirm={setAlertConfirm}
        setModalClienteDados={setModalClienteDados}
        escopoDadosBancarios={escopoDadosBancarios}
      />
      <ContasEscrow
        cod_cliente={cliente.cod_cliente}
        lstBancosAgencia={lstBancosAgencia}
        setAlerta={setAlerta}
        setAlertConfirm={setAlertConfirm}
        setModalClienteDados={setModalClienteDados}
        escopoDadosBancarios={escopoDadosBancarios}
      />

      <AlertMain
        exibeAlerta={alertConfirm && alertConfirm.show}
        setExibeAlerta={setAlertConfirm}
        titleAlerta={alertConfirm && alertConfirm.title}
        msgAlerta={alertConfirm && alertConfirm.msg}
        funcaoConfirmacao={alertConfirm && alertConfirm.funcaoConfirmacao}
      />
    </Box>
  );
};
export default memo(DadosBancariosCliente);
