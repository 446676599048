import React, { memo, useState } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { focusInput } from '../../../Utils/utils';
import { IsEmpty, NotIsEmpty } from '../../../Utils/format';
import LoaderMain from '../../../Shared/LoaderMain';
import TextFieldMain from '../../../Shared/TextFieldMain';
import { incluirContato, alterarContato } from '../../../../api/formalizacao';
import ButtonMain from '../../../Shared/ButtonMain';
import SelectMain from '../../../Shared/SelectMain';
import CheckBoxMain from '../../../Shared/CheckBoxMain';
import AutocompleteMain from '../../../Shared/AutocompleteMain';

const FormContatos = ({
  formDados = {},
  dadosGerais,
  setDadosGerais,
  setAlerta,
  lstTipos,
  handleClose,
  telaPai
}) => {
  const [isPendingSalvar, setPendingSalvar] = useState(false);

  const submitFormulario = (values, form) => {
    const params = { ...values };
    params.tipo = NotIsEmpty(params.tipo) ? params.tipo : null;
    params.tipo_email_identificacao_envio = NotIsEmpty(params.tipo_email_identificacao_envio)
      ? params.tipo_email_identificacao_envio.map(identEnvio => identEnvio._id)
      : null;
    params._id = formDados._id ?? null;
    params.cpfcnpj =
      telaPai === 'DadosCliente' ? dadosGerais.informacoes_gerais.cnpj : dadosGerais.cpfcnpj;
    const tipoAcao = Object.keys(formDados).length === 0 ? 'incluido' : 'alterado';
    const incluirAlterarContato =
      tipoAcao === 'incluido' ? incluirContato : alterarContato;

    setPendingSalvar(true);
    incluirAlterarContato(params, telaPai)
      .then(res => {
        if (res && res.data) {
          const dadosGeraisTemp = { ...dadosGerais };
          dadosGeraisTemp.contatos = res.data;
          setDadosGerais(dadosGeraisTemp);
          handleClose();
          setAlerta({
            mensagem: `Contato ${tipoAcao} com sucesso`,
            tipo: 'success'
          });
        } else {
          console.error('Retorno vazio');
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        }
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingSalvar(false);
        form.setSubmitting(false);
      });
  };

  const tipoEmailIdentificacaoEnvio =
    NotIsEmpty(formDados.dados_email) && NotIsEmpty(formDados.dados_email.identificacoes_envio)
      ? formDados.dados_email.identificacoes_envio.map(identEnvio => String(identEnvio._id))
      : [];

  return (
    <Formik
      initialValues={{
        tipo:
          NotIsEmpty(formDados.dados_tipo) && formDados.dados_tipo._id ? formDados.dados_tipo._id : '',
        telefone: formDados.dados_telefone
          ? (formDados.dados_telefone.ddd ?? '') + (formDados.dados_telefone.fone ?? '')
          : '',
        email: formDados.dados_email ? formDados.dados_email.email ?? '' : '',
        tipo_email_identificacao_envio: [...lstTipos.emailTiposIdentificacaoEnvio].filter(tipo =>
          tipoEmailIdentificacaoEnvio.includes(String(tipo._id))
        ),
        envia_email:
          NotIsEmpty(formDados.dados_email) && typeof formDados.dados_email.envia == 'boolean'
            ? formDados.dados_email.envia
            : false,
        ramal:
          NotIsEmpty(formDados.dados_telefone) && formDados.dados_telefone.ramal
            ? formDados.dados_telefone.ramal
            : '',
        ...formDados
      }}
      onSubmit={(values, form) => {
        const errors = {};
        const { open, dados_tipo, dados_telefone, dados_email, ...valores } = values;
        valores._id_email =
          formDados.dados_email && formDados.dados_email._id ? formDados.dados_email._id : null;
        valores._id_telefone =
          formDados.dados_telefone && formDados.dados_telefone._id ? formDados.dados_telefone._id : null;

        let camposObrigatorios = [
          { id: 'tipo', msg: 'Tipo do Contato Obrigatório' },
          { id: 'nome', msg: 'Nome do Contato Obrigatório' }
        ];
        // Obrigatorios apenas para Cliente, Pessoa não é obrigatorio
        if (telaPai === 'DadosCliente') {
          camposObrigatorios = [
            ...camposObrigatorios,
            { id: 'telefone', msg: 'Telefone do Contato Obrigatório' },
            { id: 'email', msg: 'E-mail Obrigatório' }
          ];
        }
        camposObrigatorios.map(campo => {
          if (IsEmpty(valores[campo.id])) {
            errors[campo.id] = campo.msg;
          }
          return campo;
        });

        form.setErrors(errors);
        if (Object.keys(errors).length <= 0) {
          submitFormulario(valores, form);
        } else {
          form.setSubmitting(false);
          focusInput(Object.keys(errors)[0]);
        }
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          {isPendingSalvar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Tipo:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.contatoTipo) && lstTipos.contatoTipo.length > 0 ? (
                <Field
                  id="tipo"
                  name="tipo"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione o tipo de contato"
                  displayEmpty
                  displayEmptyLabel="Não definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.contatoTipo].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.contatoTipo) && lstTipos.contatoTipo.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Nome:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="nome"
                name="nome"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Telefone:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="telefone"
                name="telefone"
                type="text"
                component={TextFieldMain}
                fullWidth
                tipoMascara="phoneMask"
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Ramal:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="ramal"
                name="ramal"
                type="text"
                component={TextFieldMain}
                placeholder="(opcional)"
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              E-mail:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="email"
                name="email"
                type="email"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input" />
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                controlLabelCheckbox="Pode enviar e-mail?"
                id="envia_email"
                name="envia_email"
                component={CheckBoxMain}
                checked={values.envia_email ? true : false}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} className={`input-line ${values.envia_email ? '' : 'hidden'}`}>
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Identificar envio:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.emailTiposIdentificacaoEnvio) &&
              lstTipos.emailTiposIdentificacaoEnvio.length > 0 ? (
                <Field
                  multiple
                  id="tipo_email_identificacao_envio"
                  name="tipo_email_identificacao_envio"
                  component={AutocompleteMain}
                  boxMain={false}
                  propsInput={{
                    placeholder: 'Selecione o tipo de identificação de envio'
                  }}
                  disabled={isSubmitting ? true : false}
                  paramLabel="nome"
                  options={[...lstTipos.emailTiposIdentificacaoEnvio]}
                />
              ) : Array.isArray(lstTipos.emailTiposIdentificacaoEnvio) &&
                lstTipos.emailTiposIdentificacaoEnvio.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ m: '20px 0' }}>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="transparente-com-bordas"
                disabled={isSubmitting}
                onClick={handleClose}
              >
                Cancelar
              </ButtonMain>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="azul-escuro"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Salvar
              </ButtonMain>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default memo(FormContatos);
