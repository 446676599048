/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Typography
} from '@mui/material';
import TextFieldMui from '@mui/material/TextField';
import { DataGrid, GridToolbarQuickFilter, ptBR } from '@mui/x-data-grid';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Close, ArrowBack, EditOutlined, Save, Search } from '@mui/icons-material';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IsEmpty, mascararCpfCnpj } from '../../Utils/format';
import definicaoDeColunas from './definicoes-grid';
import LoaderMain from '../../Shared/LoaderMain';
import AlertMain from '../../Shared/AlertMain';
import {
  alterarResponsavelLeads,
  buscaCrmMotivos,
  getLeadsGrowth,
  postAlterarDataHoraAgendamentoLead,
  postAlterarTagLead
} from '../../../api/crm';
import AlertMotivoMain from '../../Shared/AlertMotivoMain';
import EncaminharGerente from './EncaminharGerente';
import ObservacoesLead from './ObservacoesLead';
import { getDadosUsuario } from '../../../services/authentication/auth';
import 'moment/locale/pt-br';
import { geraCorHexaDecimal } from '../../Utils/geraCorHexaDecimal';
import ImportarLeads from './ImportarLeads';
import EditarLead from './EditarLead';
// eslint-disable-next-line import/order
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// eslint-disable-next-line import/order
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import WhatsAppContact from '../../Shared/WhatsAppContact';

const ModalDashboard = ({
  dadosModalDashboard,
  setDadosModalDashboard,
  atualizarTodosDashboards,
  dadosCardsLeads,
  lstUsuariosCrm,
  lstTiposOrigem
}) => {
  const [isPending, setIsPending] = useState(false);
  const [lstDadosGrid, setLstDadosGrid] = useState(false);
  const [lstDadosGridCompleto, setLstDadosGridCompleto] = useState(false);
  const [lstVerCadastro, setLstVerCadastro] = useState([]);
  const [statusLead, setStatusLead] = useState('');
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaMotivo, setMostrarAlertaMotivo] = useState(false);
  const [mostrarImportarLeads, setMostrarImportarLeads] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [lstOpcoesMotivos, setLstOpcoesMotivos] = useState([]);
  const [lstOpcoesMotivosFiltro, setLstOpcoesMotivosFiltro] = useState([]);
  const [mostraFiltroMotivo, setMostraFiltroMotivo] = useState(false);
  const [btnVoltar, setBtnVoltar] = useState(false);
  const [modalEncaminharGerente, setModalEncaminharGerente] = useState(false);
  const [valorObservacaoLead, setValorObservacaoLead] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [mostraComboUsuarios, setMostraComboUsuarios] = useState([]);
  const [lstMarcarComo, setLstMarcarComo] = useState([]);
  const [valueUsuario, setValueUsuario] = useState('');
  const [openAlert, setOpenAlert] = useState(true);
  const [msgAlerta, setMsgAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('success');
  const [permiteAlteracaoLead, setPermiteAlteracaoLead] = useState(false);
  const [alteracaoUsuarioLead, setAlteracaoUsuarioLead] = useState(false);
  const [dataHoraAgendamento, setDataHoraAgendamento] = useState(null);
  const [permiteAlteracaoUsuarioLead, setPermiteAlteracaoUsuarioLead] = useState([
    'novo',
    'leads_ploomes',
    'standby'
  ]);
  const [paginationModelGridLeads, setPaginationModelGridLeads] = useState({
    pageSize: 5,
    page: 0
  });
  const [filtroAgendamento, setFiltroAgendamento] = useState('TODOS');
  const [filtroDataAgendaIni, setFiltroDataAgendaIni] = useState(null);
  const [filtroDataAgendaFim, setFiltroDataAgendaFim] = useState(null);
  const [usuarioCrmFiltro, setUsuarioCrmFiltro] = useState(null);
  const [motivoCrmFiltro, setMotivoCrmFiltro] = useState('');
  const dadosUsuario = getDadosUsuario();
  const [tipoOrigemFiltro, setTipoOrigemFiltro] = useState(null);

  useEffect(() => {
    setIsPending(true);
    setLstDadosGrid(dadosModalDashboard.grid ?? false);
    setLstDadosGridCompleto(dadosModalDashboard.grid ?? false);
    setLstVerCadastro(dadosModalDashboard.verCadastro ?? false);
    setIsPending(false);

    if (dadosModalDashboard === false) {
      setStatusLead('');
      setSelectedRows([]);
      setBtnVoltar(false);
      setMsgAlerta(false);
      setOpenAlert(false);
      setValorObservacaoLead('');
      setPermiteAlteracaoLead(false);
      setAlteracaoUsuarioLead(false);
      setModalEncaminharGerente(false);
      setMostraFiltroMotivo(false);
      setPaginationModelGridLeads({
        pageSize: 5,
        page: 0
      });
    }

    if (dadosModalDashboard && dadosModalDashboard.buscarLeads) {
      buscarLeads({
        cod_usuario_reserva: dadosModalDashboard.cod_usuario_reserva ?? null
      });

      if (permiteAlteracaoUsuarioLead.includes(dadosModalDashboard.buscarLeads)) {
        setAlteracaoUsuarioLead(true);
      } else {
        setAlteracaoUsuarioLead(false);
      }
    }

    if (dadosModalDashboard && dadosModalDashboard.verCadastro) {
      setAlteracaoUsuarioLead(true);
    }

    setFiltroAgendamento('TODOS');
    setFiltroDataAgendaIni(null);
    setFiltroDataAgendaFim(null);
    setUsuarioCrmFiltro(dadosModalDashboard.cod_usuario_reserva ?? null);
    setMotivoCrmFiltro('');
    setTipoOrigemFiltro('');
  }, [dadosModalDashboard]);

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      setMostraComboUsuarios(true);
    } else {
      setMostraComboUsuarios(false);
      setValueUsuario('');
    }
  }, [selectedRows]);

  const buscaCrmMotivosFiltro = () => {
    setIsPending(true);
    buscaCrmMotivos(dadosModalDashboard.buscarLeads)
      .then(res => {
        if (res.data && res.data.length > 0) {
          const arrayMotivos = [];
          res.data.map(dados => {
            arrayMotivos.push({ label: dados.descricao, value: dados.tag });
            return dados;
          });
          setLstOpcoesMotivosFiltro(arrayMotivos);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  useEffect(() => {
    if (mostraFiltroMotivo) {
      buscaCrmMotivosFiltro();
    }
  }, [mostraFiltroMotivo]);

  const mostrarMotivosFiltro = () => {
    if (!mostraFiltroMotivo) {
      setMostraFiltroMotivo(true);
    }
  };

  useEffect(() => {
    if (dadosCardsLeads && Object.values(dadosCardsLeads).length > 0) {
      const lstPermiteAlterar = [];
      Object.values(dadosCardsLeads).map(card => {
        if (card.permite_editar) {
          lstPermiteAlterar.push(card.tag);
        }
        return card;
      });
      setPermiteAlteracaoUsuarioLead(lstPermiteAlterar);
    }
  }, [dadosCardsLeads]);

  useEffect(() => {
    if (lstVerCadastro && lstVerCadastro.data_hora_agendamento) {
      setDataHoraAgendamento(moment(lstVerCadastro.data_hora_agendamento));
    } else {
      setDataHoraAgendamento(null);
    }
  }, [lstVerCadastro]);

  useEffect(() => {
    if (
      dadosCardsLeads &&
      Object.values(dadosCardsLeads).length > 0 &&
      lstVerCadastro &&
      lstVerCadastro.tag &&
      dadosCardsLeads[lstVerCadastro.tag] &&
      dadosCardsLeads[lstVerCadastro.tag].permite_troca_manual
    ) {
      const lstMarcarComoTemp = [];
      Object.values(dadosCardsLeads).map(card => {
        if (card.permite_troca_manual && card.tag !== lstVerCadastro.tag) {
          lstMarcarComoTemp.push({ label: card.descricao, value: card.tag });
        }
        return card;
      });
      setLstMarcarComo(lstMarcarComoTemp);
    }
  }, [lstVerCadastro, dadosCardsLeads]);

  useEffect(() => {
    setFiltroDataAgendaIni(null);
    setFiltroDataAgendaFim(null);
  }, [filtroAgendamento]);

  const buscarLeads = (filtro = null) => {
    setIsPending(true);
    const params = {
      tag: dadosModalDashboard.buscarLeads,
      filtro_meses: dadosModalDashboard.filtro_meses,
      intervalo_periodo: dadosModalDashboard.intervalo_periodo ?? null
    };
    getLeadsGrowth(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstDadosGridCompleto(res.data);
          if (filtro) {
            filtrarGridLeads({ ...filtro, lstDadosGridCompleto: res.data });
          } else {
            setLstDadosGrid(res.data);
          }
        } else {
          setLstDadosGrid([]);
          setLstDadosGridCompleto([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const quickSearchToolbar = () => (
    <Box sx={{ p: 0.5, pb: 0 }}>
      <GridToolbarQuickFilter className="search-grid" />
    </Box>
  );

  const handleChange = event => {
    const { value } = event.target;
    setStatusLead(value);
    setIsPending(true);
    buscaCrmMotivos(value)
      .then(res => {
        if (res.data && res.data.length > 0) {
          const arrayMotivos = [];
          res.data.map(dados => {
            arrayMotivos.push({ label: dados.descricao, value: dados.tag });
            return dados;
          });
          setLstOpcoesMotivos(arrayMotivos);
          setMostrarAlertaMotivo(true);
        } else {
          alterarTagLead(event.target.value);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const alterarTagLead = novaTag => {
    const params = {
      id: lstVerCadastro._id,
      tag: novaTag
    };

    enviaPostTagLead(params);
  };

  const alterarTagLeadMotivo = (tag_motivo, observacao) => {
    if (tag_motivo && tag_motivo !== '') {
      const params = {
        id: lstVerCadastro._id,
        tag: statusLead,
        tag_motivo: tag_motivo,
        observacao: observacao
      };

      enviaPostTagLead(params);
    } else {
      setStatusLead('');
    }
  };

  const enviaPostTagLead = params => {
    postAlterarTagLead(params)
      .then(() => {
        setDadosModalDashboard(false);
        atualizarTodosDashboards();
      })
      .catch(() => {})
      .finally(() => {});
  };

  const clickMostrarCadastroLead = params => {
    setSelectedRows([]);
    setBtnVoltar(true);
    setLstVerCadastro(params.row ?? false);
  };

  const clickVoltarGridLeads = () => {
    setBtnVoltar(false);
    setLstVerCadastro(false);
  };

  const mostraEncaminharGerente = idLeadAberto => {
    dadosModalDashboard.observacao_lead = lstVerCadastro.observacao_lead;
    dadosModalDashboard.titleModal = 'Encaminhar para gerente';
    dadosModalDashboard.id = idLeadAberto;
    dadosModalDashboard.nome = lstVerCadastro.nome;
    dadosModalDashboard.razaoSocial = lstVerCadastro.razao_social;
    dadosModalDashboard.cnpj = lstVerCadastro.cnpj;

    setBtnVoltar(false);
    setLstDadosGrid(false);
    setLstDadosGridCompleto(false);
    setLstVerCadastro(false);
    setModalEncaminharGerente(true);
  };

  const onChangeObservacaoLead = ev => {
    setValorObservacaoLead(ev.target.value);
  };

  const cadastrarFormulario = (values, form) => {
    const nome = (dadosUsuario && dadosUsuario.nome_usuario) ?? '';
    let observacoesLeads = [];

    if (
      lstVerCadastro &&
      lstVerCadastro.observacao_lead &&
      Object.keys(lstVerCadastro.observacao_lead).length > 0
    ) {
      observacoesLeads = lstVerCadastro.observacao_lead;
    }

    observacoesLeads.unshift({
      nome_usuario: nome,
      data_insert: moment().format('DD/MM/YYYY HH:mm:ss'),
      observacao: valorObservacaoLead,
      cor: geraCorHexaDecimal(nome)
    });

    const newValues = {};
    newValues.id = lstVerCadastro._id;
    newValues.observacao_lead = observacoesLeads;

    postAlterarTagLead(newValues)
      .then(() => {
        mostrarMensagemAlerta('Observação cadastrada com sucesso!');
        setValorObservacaoLead('');
        lstVerCadastro.observacao_lead = observacoesLeads;
      })
      .catch(() => {})
      .finally(() => {
        form.resetForm();
        form.setSubmitting(false);
      });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const handleOnChangeUsuariosCrmUnico = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueUsuario(newValue.value);
      lstVerCadastro.cod_usuario_reserva = newValue.value;
    } else {
      setValueUsuario('');
    }

    const arrayLead = [lstVerCadastro._id];

    alteraResponsaveisLead(newValue.value, newValue.label, arrayLead);
    setSelectedRows([]);
  };

  const handleOnChangeUsuariosCrm = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueUsuario(newValue.value);
    } else {
      setValueUsuario('');
    }
    const leads = selectedRows;

    alteraResponsaveisLead(newValue.value, newValue.label, leads);
    setSelectedRows([]);
  };

  const handleOnChangeUsuarioCrmFiltro = (ev, newValues) => {
    const valores = newValues && newValues.length > 0 ? newValues.map(item => item.value) : [];
    setUsuarioCrmFiltro(valores);
  };

  const handleOnChangeMotivoCrmFiltro = (ev, newValue) => {
    if (newValue && newValue.value) {
      setMotivoCrmFiltro(newValue.value);
    } else {
      setMotivoCrmFiltro('');
    }
  };

  const handleOnChangeTipoOrigemFiltro = (ev, newValues) => {
    const valores = newValues && newValues.length > 0 ? newValues.map(item => item.value) : [];
    setTipoOrigemFiltro(valores);
  };

  const alteraResponsaveisLead = (codResponsavel, nomeResponsavel, leadsAlteracao) => {
    setIsPending(true);

    const params = {
      leads: leadsAlteracao,
      cod_usuario_reserva: codResponsavel,
      nome_usuario_reserva: nomeResponsavel
    };

    alterarResponsavelLeads(params)
      .then(() => {
        if (lstDadosGrid) {
          buscarLeads();
        } else {
          setIsPending(false);
        }

        mostrarMensagemAlerta('Responsável salvo com sucesso!');
      })
      .catch(() => {})
      .finally(() => {});
  };

  const finalizaImportacaoLeads = tipo => {
    if (!tipo) {
      mostrarMensagemAlerta(
        'Leads importados com sucesso! \nEm alguns instantes os dados cadastrais dos leads serão automaticamente preenchidos com informações da receita.'
      );
      buscarLeads();
      atualizarTodosDashboards();
    } else {
      mostrarMensagemAlerta('Arquivo excel já importado!', 'warning');
    }
  };

  const alteracaoLeadSucesso = () => {
    mostrarMensagemAlerta('Lead alterado com sucesso!');
    setPermiteAlteracaoLead(false);
    atualizarTodosDashboards();
  };

  const mostrarMensagemAlerta = (msg, tipo = '') => {
    tipo = tipo ? tipo : 'success';
    setTipoAlerta(tipo);
    if (msg) {
      setMsgAlerta(msg);
      setOpenAlert(true);
    }
  };

  const permiteEditarLead = () => {
    setPermiteAlteracaoLead(!permiteAlteracaoLead);
  };

  const filtrarGridLeads = (dados = {}) => {
    let filDataAgendaIni = null;
    if (filtroDataAgendaIni && filtroDataAgendaIni.isValid() === false) {
      setFiltroDataAgendaIni(null);
    } else if (filtroDataAgendaIni) {
      filDataAgendaIni = filtroDataAgendaIni.format('YYYY-MM-DD');
    }

    let filDataAgendaFim = null;
    if (filtroDataAgendaFim && filtroDataAgendaFim.isValid() === false) {
      filDataAgendaFim = null;
      setFiltroDataAgendaFim(null);
    } else if (filtroDataAgendaFim) {
      filDataAgendaFim = filtroDataAgendaFim.format('YYYY-MM-DD');
    }

    const lstCodUsuariosReserva = dados.cod_usuario_reserva
      ? dados.cod_usuario_reserva
      : usuarioCrmFiltro;
    let usuarioReservaNaoAtribuido = false;
    let lstUsuariosCrmFiltro = [];
    if (!IsEmpty(lstCodUsuariosReserva) && lstCodUsuariosReserva.length !== 0) {
      lstUsuariosCrmFiltro = lstCodUsuariosReserva.filter(user => {
        if (user === 'NAO_ATRIBUIDOS') {
          usuarioReservaNaoAtribuido = true;
          return false;
        } else {
          return true;
        }
      });
    }

    const dadosGridCompleto = dados.lstDadosGridCompleto
      ? dados.lstDadosGridCompleto
      : [...lstDadosGridCompleto];
    const lstDadosGridTemp = dadosGridCompleto.filter(dados => {
      // INICIALIZACAO VARIAVEL FILTER
      let validacaoFiltro = true;

      // --- FILTRO MOTIVO -------------------
      if (!IsEmpty(motivoCrmFiltro)) {
        if (motivoCrmFiltro !== 'NAO_ATRIBUIDOS') {
          validacaoFiltro = validacaoFiltro && dados.tag_motivo === motivoCrmFiltro;
        } else {
          validacaoFiltro = validacaoFiltro && IsEmpty(dados.tag_motivo);
        }
      }

      // --- FILTRO RESPONSAVEL --------------
      if (!IsEmpty(lstCodUsuariosReserva) && lstCodUsuariosReserva.length !== 0) {
        validacaoFiltro =
          validacaoFiltro &&
          (lstUsuariosCrmFiltro.includes(parseInt(dados.cod_usuario_reserva)) ||
            (usuarioReservaNaoAtribuido && IsEmpty(dados.cod_usuario_reserva)));
      }

      // --- FILTRO TIPO IMPORTACAO --------------
      if (!IsEmpty(tipoOrigemFiltro) && tipoOrigemFiltro.length !== 0) {
        validacaoFiltro = validacaoFiltro && tipoOrigemFiltro.includes(dados.origem_lead);
      }

      // --- FILTRO DATAS AGENDAMENTOS -------
      if (filtroAgendamento === 'SIM') {
        validacaoFiltro = validacaoFiltro && !IsEmpty(dados.data_hora_agendamento);

        if (!IsEmpty(filDataAgendaIni) || !IsEmpty(filDataAgendaFim)) {
          const dataAgendamento = moment(dados.data_hora_agendamento).format('YYYY-MM-DD');

          if (!IsEmpty(filDataAgendaIni) && IsEmpty(filDataAgendaFim)) {
            // after - antes de...
            validacaoFiltro =
              validacaoFiltro &&
              (moment(dataAgendamento).isAfter(filDataAgendaIni) ||
                dataAgendamento === filDataAgendaIni);
          } else if (IsEmpty(filDataAgendaIni) && !IsEmpty(filDataAgendaFim)) {
            // before - depois de...
            validacaoFiltro =
              validacaoFiltro &&
              (moment(dataAgendamento).isBefore(filDataAgendaFim) ||
                dataAgendamento === filDataAgendaFim);
          } else if (!IsEmpty(filDataAgendaIni) && !IsEmpty(filDataAgendaFim)) {
            // between - entre...
            validacaoFiltro =
              validacaoFiltro &&
              (moment(dataAgendamento).isBetween(filDataAgendaIni, filDataAgendaFim) ||
                dataAgendamento === filDataAgendaIni ||
                dataAgendamento === filDataAgendaFim);
          }
        }
      } else if (filtroAgendamento === 'NAO') {
        validacaoFiltro = validacaoFiltro && IsEmpty(dados.data_hora_agendamento);
      }
      return validacaoFiltro;
    });
    setLstDadosGrid(lstDadosGridTemp);
  };

  const salvarAgendamento = () => {
    if (dataHoraAgendamento && dataHoraAgendamento.isValid() === false) {
      mostrarMensagemAlerta('Data/Hora inválida, favor fornecer uma data/hora valida', 'error');
      return false;
    }
    const params = {
      id: lstVerCadastro._id,
      data_hora_agendamento: dataHoraAgendamento ? dataHoraAgendamento.format() : null
    };
    setIsPending(true);
    postAlterarDataHoraAgendamentoLead(params)
      .then(() => {
        buscarLeads();
        mostrarMensagemAlerta('Agendamento salvo com sucesso!');
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const msgVazio = 'Não informado';
  const msgTitleAlert = 'Confirmar Lead qualificado para Pré-vendas';
  const msgAlert =
    'Esta ação confirma que as informações cadastradas se enquadram no perfil de clientes One7. Você quer mesmo continuar?';

  return (
    <Dialog
      open={dadosModalDashboard !== false}
      fullWidth={true}
      className="modal-dashboard"
      maxWidth={lstDadosGrid && btnVoltar === false ? 'xl' : 'lg'}
      keepMounted
      onClose={() => setDadosModalDashboard(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="borda-bottom-2-gray pad-top-10">
          <Box sx={{ marginTop: '-15px', height: '35px' }}>
            {btnVoltar && !permiteAlteracaoLead && (
              <IconButton
                onClick={() => clickVoltarGridLeads()}
                className="float-left"
                sx={{ fontSize: '13px', marginLeft: '-10px' }}
              >
                <ArrowBack /> Voltar
              </IconButton>
            )}
            <IconButton onClick={() => setDadosModalDashboard(false)} className="float-right">
              <Close />
            </IconButton>
          </Box>
          <Box className="margin-top-10">
            <Typography variant="h5" className="texto-cor-principal texto-negrito margin-bottom-5">
              {dadosModalDashboard.titleModal}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        {lstDadosGrid && btnVoltar === false ? (
          <>
            <Box className="borda-1-width borda-solida borda-cor-cinza-claro-2 border-radius-4 pad-15 margin-top-10 margin-bottom-10">
              <Box className="texto-cor-cinza-escuro texto-weight-600 label-sobreposto margin-bottom-10">
                <Typography variant="h7">Filtro</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                  <Typography variant="h7" className="texto-cor-cinza-escuro texto-weight-600">
                    Responsável:
                  </Typography>

                  <Autocomplete
                    multiple
                    size="small"
                    className="autocomplete-multiple-small"
                    options={lstUsuariosCrm || []}
                    value={
                      usuarioCrmFiltro && usuarioCrmFiltro.length > 0 && lstUsuariosCrm.length > 0
                        ? lstUsuariosCrm.filter(option => usuarioCrmFiltro.includes(option.value))
                        : []
                    }
                    onChange={handleOnChangeUsuarioCrmFiltro}
                    limitTags={2}
                    disabled={lstUsuariosCrm.length === 0}
                    disablePortal
                    renderInput={params => (
                      <TextFieldMui
                        {...params}
                        name="usuariosCrm"
                        placeholder="Filtre o responsável"
                        autoComplete="off"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off'
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xl={5} lg={5} md={8} sm={12} xs={12}>
                  <Typography variant="h7" className="texto-cor-cinza-escuro texto-weight-600">
                    Tipo Origem:
                  </Typography>
                  <Autocomplete
                    multiple
                    size="small"
                    className="autocomplete-multiple-small"
                    options={lstTiposOrigem || []}
                    value={
                      tipoOrigemFiltro && tipoOrigemFiltro.length > 0 && lstTiposOrigem.length > 0
                        ? lstTiposOrigem.filter(option => tipoOrigemFiltro.includes(option.value))
                        : []
                    }
                    onChange={handleOnChangeTipoOrigemFiltro}
                    limitTags={2}
                    disabled={lstTiposOrigem.length === 0}
                    disablePortal
                    renderInput={params => (
                      <TextFieldMui
                        {...params}
                        name="usuariosCrm"
                        placeholder="Filtre o responsável"
                        autoComplete="off"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off'
                        }}
                      />
                    )}
                  />
                </Grid>

                {mostraFiltroMotivo && (
                  <Grid item xl={5} lg={5} md={4} sm={12} xs={12}>
                    <Typography variant="h7" className="texto-cor-cinza-escuro texto-weight-600">
                      Motivo:
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="motivo_crm_filtro"
                      options={lstOpcoesMotivosFiltro || []}
                      value={
                        motivoCrmFiltro && lstOpcoesMotivosFiltro.length > 0
                          ? lstOpcoesMotivosFiltro.find(option => option.value === motivoCrmFiltro)
                          : ''
                      }
                      onChange={handleOnChangeMotivoCrmFiltro}
                      name="motivosCrmFiltro"
                      size="small"
                      className="autocomplete-multiple-small"
                      isOptionEqualToValue={(option, value) =>
                        option === value || value === undefined || value === '' || value === null
                      }
                      disabled={lstOpcoesMotivosFiltro.length === 0}
                      renderInput={params => (
                        <TextFieldMui
                          {...params}
                          name="motivosCrmFiltro"
                          placeholder="Escolha o motivo"
                          autoComplete="off"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off'
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}

                {dadosModalDashboard &&
                  dadosCardsLeads &&
                  dadosCardsLeads[dadosModalDashboard.buscarLeads] &&
                  dadosCardsLeads[dadosModalDashboard.buscarLeads].permitir_agendamento && (
                    <Grid item xl={11} lg={11} md={10} sm={12} xs={12}>
                      <Typography variant="h7" className="texto-cor-cinza-escuro texto-weight-600">
                        Agendado:
                      </Typography>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={5}
                          sm={12}
                          xs={12}
                          className={`borda-solida borda-cor-principal-2 pad-left-10 border-radius-4${
                            filtroAgendamento !== 'TODOS' && filtroAgendamento !== 'NAO' ? '-left' : ''
                          }`}
                          sx={{
                            borderBottomWidth: {
                              md: '1px',
                              sm: '0',
                              xs: '0'
                            },
                            borderTopWidth: '1px',
                            borderRightWidth: '1px',
                            borderLeftWidth: '1px'
                          }}
                        >
                          <FormControl sx={{ mt: '1px' }}>
                            <RadioGroup
                              row
                              name="agendamento_filtro"
                              value={filtroAgendamento}
                              onChange={event => setFiltroAgendamento(event.target.value)}
                            >
                              <FormControlLabel
                                value="SIM"
                                control={<Radio className="radio-principal" />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value="NAO"
                                control={<Radio className="radio-principal" />}
                                label="Não"
                              />
                              <FormControlLabel
                                value="TODOS"
                                control={<Radio className="radio-principal" />}
                                label="Todos"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
                          {filtroAgendamento !== 'TODOS' && filtroAgendamento !== 'NAO' && (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <Grid container spacing={0}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                  <DatePicker
                                    label="Data Agenda Inicio"
                                    name="data_agendamento_ini_filtro"
                                    className="w-100 date-time-picker-agenda data-agenda-filtro-ini"
                                    value={filtroDataAgendaIni}
                                    maxDate={filtroDataAgendaFim}
                                    onChange={dataMoment => setFiltroDataAgendaIni(dataMoment)}
                                    slotProps={{
                                      inputAdornment: {
                                        position: 'start'
                                      },
                                      field: {
                                        clearable: true,
                                        onClear: () => setFiltroDataAgendaIni(null)
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                  <DatePicker
                                    label="Data Agenda Fim"
                                    name="data_agendamento_filtro"
                                    className="w-100 date-time-picker-agenda data-agenda-filtro-fim"
                                    value={filtroDataAgendaFim}
                                    minDate={filtroDataAgendaIni}
                                    onChange={dataMoment => setFiltroDataAgendaFim(dataMoment)}
                                    slotProps={{
                                      inputAdornment: {
                                        position: 'start'
                                      },
                                      field: {
                                        clearable: true,
                                        onClear: () => setFiltroDataAgendaFim(null)
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </LocalizationProvider>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                <Grid item xl={1} lg={1} md={2} sm={12} xs={12}>
                  <Button
                    className="btn-filtrar-lead texto-direita texto-nowrap-overflow-dotted"
                    onClick={() => filtrarGridLeads()}
                    sx={{ mt: '23px' }}
                  >
                    <Search /> Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                sx={{
                  marginBottom: '-50px',
                  position: 'relative',
                  zIndex: '3',
                  alignItems: 'end',
                  height: '75px'
                }}
              >
                <Grid item lg={6} md={4} />
                {dadosModalDashboard && dadosModalDashboard.buscarLeads === 'standby' && (
                  <Grid item lg={3} md={4} sm={6} xs={12} />
                )}
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  {mostraComboUsuarios && (
                    <>
                      <Typography variant="h7" className="texto-cor-cinza-escuro texto-weight-600">
                        Atribuir Responsável:
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="usuariosCrm"
                        options={lstUsuariosCrm || []}
                        value={
                          valueUsuario && lstUsuariosCrm.length > 0
                            ? lstUsuariosCrm.find(option => option.value === valueUsuario)
                            : ''
                        }
                        onChange={handleOnChangeUsuariosCrm}
                        name="usuariosCrm"
                        className="select-status-lead ajuste-height"
                        disableClearable
                        isOptionEqualToValue={(option, value) =>
                          option === value || value === undefined || value === '' || value === null
                        }
                        disabled={lstUsuariosCrm.length === 0}
                        renderInput={params => (
                          <TextFieldMui
                            {...params}
                            name="usuariosCrm"
                            placeholder="Escolha o responsável"
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off'
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  className="pad-right-left-15 padding-zero-mobile"
                >
                  {dadosModalDashboard &&
                    dadosCardsLeads &&
                    dadosCardsLeads[dadosModalDashboard.buscarLeads] &&
                    dadosCardsLeads[dadosModalDashboard.buscarLeads].permite_importar_leads && (
                      <Button
                        className="btn-primary"
                        onClick={() => setMostrarImportarLeads(true)}
                        sx={{ marginBottom: '2px' }}
                      >
                        Importar Leads <AddIcon />
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Box>
            <DataGrid
              className="data-grid-leads data-grid-separator-header-hidden data-grid-borderless"
              columns={definicaoDeColunas(
                dadosCardsLeads[dadosModalDashboard.buscarLeads],
                mostrarMotivosFiltro
              )}
              rows={lstDadosGrid}
              onRowClick={clickMostrarCadastroLead}
              checkboxSelection={alteracaoUsuarioLead}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              getRowId={lstDadosGrid => lstDadosGrid._id}
              autoHeight
              disableColumnMenu
              sx={{ margin: '15px 0 30px 0' }}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              sortingOrder={['desc', 'asc']}
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              pageSizeOptions={[5, 25, 100]}
              paginationModel={paginationModelGridLeads}
              onPaginationModelChange={setPaginationModelGridLeads}
              slots={{ toolbar: quickSearchToolbar }}
              onRowSelectionModelChange={newRowSelectionModel => {
                setSelectedRows(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRows}
            />
          </>
        ) : (
          <></>
        )}

        {lstVerCadastro && !permiteAlteracaoLead && (
          <Grid container>
            <Grid className="borda-bottom-2-main" item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                {lstVerCadastro.nome ?? msgVazio}
              </Typography>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box className="margin-top-15">
                <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                  Empresa:
                </Typography>
                <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 word-break">
                  {lstVerCadastro.razao_social ?? msgVazio}
                </Typography>
              </Box>
              <Box className="margin-top-5">
                <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                  CNPJ:
                </Typography>
                <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                  {mascararCpfCnpj(lstVerCadastro.cnpj ?? '')}
                </Typography>
              </Box>
              <Box className="margin-top-5">
                <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                  Situação Receita Federal:
                </Typography>
                <Typography
                  variant="h7"
                  className={`texto-cor-cinza-escuro pad-5 camel-case ${
                    lstVerCadastro.situacao === 'ATIVA'
                      ? 'badge-primary'
                      : lstVerCadastro.situacao === 'pendente'
                        ? 'badge-warning'
                        : 'badge-danger'
                  } `}
                >
                  {(lstVerCadastro.situacao ?? msgVazio).toLowerCase()}
                </Typography>
              </Box>
              {lstVerCadastro.etapa_cnpj && (
                <Box className="margin-top-5">
                  <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                    Situação do CNPJ:
                  </Typography>
                  <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 first-letter-upper">
                    {lstVerCadastro.etapa_cnpj.charAt(0).toUpperCase() +
                      lstVerCadastro.etapa_cnpj.substring(1).toLowerCase()}
                  </Typography>
                </Box>
              )}

              {lstVerCadastro.duplicado && (
                <Box className="margin-top-5">
                  <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                    Lead duplicado:
                  </Typography>
                  <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 first-letter-upper">
                    O lead possui mais de um contato.
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid className="margin-top-10" container>
                <Grid item xl={6} lg={6} md={6} sm={5} xs={12} sx={{ padding: '5px' }}>
                  {lstVerCadastro &&
                    dadosCardsLeads &&
                    dadosCardsLeads[lstVerCadastro.tag] &&
                    dadosCardsLeads[lstVerCadastro.tag].permite_encaminhar_gerente && (
                      <Button
                        className="btn-main"
                        onClick={() => mostraEncaminharGerente(lstVerCadastro._id)}
                      >
                        Encaminhar para gerente
                      </Button>
                    )}
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={5} xs={9} sx={{ padding: '5px' }}>
                  {lstVerCadastro &&
                    dadosCardsLeads &&
                    dadosCardsLeads[lstVerCadastro.tag] &&
                    dadosCardsLeads[lstVerCadastro.tag].permite_troca_manual &&
                    lstMarcarComo &&
                    lstMarcarComo.length > 0 && (
                      <FormControl fullWidth>
                        <InputLabel id="select-label">Marcar como</InputLabel>
                        <Select
                          labelId="select-label"
                          id="select-status-lead"
                          value={statusLead}
                          label="Marcar como"
                          className="select-status-lead"
                          onChange={handleChange}
                        >
                          {lstMarcarComo.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={3} sx={{ padding: '5px' }}>
                  {lstVerCadastro &&
                    dadosCardsLeads &&
                    dadosCardsLeads[lstVerCadastro.tag] &&
                    dadosCardsLeads[lstVerCadastro.tag].permite_editar && (
                      <Button className="btn-editar-lead" onClick={() => permiteEditarLead()}>
                        <EditOutlined />
                      </Button>
                    )}
                </Grid>
                <Grid item xl={5} lg={6} md={6} sm={5} xs={12} sx={{ padding: '5px' }}>
                  <Box className="margin-top-10 texto-direita">
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Responsável:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={7} lg={6} md={6} sm={7} xs={12} sx={{ padding: '5px' }}>
                  <Autocomplete
                    disablePortal
                    id="usuariosCrmCadastro"
                    className="select-status-lead ajuste-height"
                    options={lstUsuariosCrm || []}
                    value={
                      lstVerCadastro.cod_usuario_reserva && lstUsuariosCrm.length > 0
                        ? lstUsuariosCrm.find(
                            option => option.value === lstVerCadastro.cod_usuario_reserva
                          )
                        : ''
                    }
                    onChange={handleOnChangeUsuariosCrmUnico}
                    name="usuariosCrmCadastro"
                    disableClearable
                    isOptionEqualToValue={(option, value) =>
                      option === value || value === undefined || value === '' || value === null
                    }
                    disabled={lstUsuariosCrm.length === 0 || !alteracaoUsuarioLead}
                    renderInput={params => (
                      <TextFieldMui
                        {...params}
                        name="usuariosCrmCadastro"
                        placeholder="Escolha o responsável"
                        autoComplete="off"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off'
                        }}
                      />
                    )}
                  />
                </Grid>
                {lstVerCadastro &&
                  dadosCardsLeads &&
                  dadosCardsLeads[lstVerCadastro.tag] &&
                  dadosCardsLeads[lstVerCadastro.tag].permitir_agendamento && (
                    <>
                      <Grid item xl={5} lg={6} md={6} sm={5} xs={12} sx={{ padding: '5px' }}>
                        <Box className="margin-top-10 texto-direita">
                          <Typography
                            variant="h7"
                            className="texto-cor-cinza-escuro pad-5 texto-weight-600"
                          >
                            Agendamento:
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={7} lg={6} md={6} sm={7} xs={12} sx={{ padding: '5px' }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePicker
                            label="Data/Hora Agendamento"
                            name="agendamento"
                            className="w-100 date-time-picker-agenda"
                            value={dataHoraAgendamento}
                            onChange={dataMoment => setDataHoraAgendamento(dataMoment)}
                            slotProps={{
                              inputAdornment: { position: 'start' },
                              field: {
                                clearable: true,
                                onClear: () => setDataHoraAgendamento(null)
                              },
                              textField: {
                                InputProps: {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        title="Salvar Agendamento"
                                        color="primary"
                                        onClick={salvarAgendamento}
                                      >
                                        <Save />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>

            <Grid className="borda-bottom-2-main" item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                className="texto-cor-cinza-escuro pad-5 texto-weight-600 margin-top-15"
              >
                Contato
              </Typography>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid className="margin-top-15 margin-bottom-35 word-break" container>
                <Grid item xl={3} lg={3} md={4} sm={12} xs={12} sx={{ padding: '5px' }}>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      E-mail:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      {lstVerCadastro.email ?? msgVazio}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12} sx={{ padding: '5px' }}>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Telefone:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      {lstVerCadastro.telefone ?? msgVazio}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12} sx={{ padding: '5px' }}>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      WhatsApp:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      <WhatsAppContact message="" number={lstVerCadastro.whatsapp ?? ''} />
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12} sx={{ padding: '5px' }}>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      UF:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      {lstVerCadastro.estado ?? msgVazio}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={3} lg={3} md={4} sm={6} xs={12} sx={{ padding: '5px' }}>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Cidade:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      {lstVerCadastro.cidade ?? msgVazio}
                    </Typography>
                  </Box>
                </Grid>

                <Grid className="borda-bottom-2-main" item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h6"
                    className="texto-cor-cinza-escuro pad-5 texto-weight-600 margin-top-15"
                  >
                    Mais informações
                  </Typography>
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={4} xs={12} sx={{ padding: '5px' }}>
                  <Box className="margin-top-15">
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Origem do Lead:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      {lstVerCadastro.origem_lead ?? msgVazio}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={4} xs={12} sx={{ padding: '5px' }}>
                  <Box className="margin-top-15">
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Tempo de fundação da empresa:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      {lstVerCadastro.tempo_empresa ?? msgVazio}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={4} xs={12} sx={{ padding: '5px' }}>
                  <Box className="margin-top-15">
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Faturamento mensal:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5">
                      {lstVerCadastro.faturamento ?? msgVazio}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  className="margin-top-20 borda-bottom-2-main"
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                />

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="margin-top-30">
                  <Formik
                    initialValues={{
                      observacao_lead: ''
                    }}
                    onSubmit={(values, form) => {
                      cadastrarFormulario(values, form);
                    }}
                  >
                    {({ submitForm, isSubmitting }) => (
                      <Form>
                        <Grid container>
                          <Grid item xl={10} lg={10} md={10} sm={9} xs={12} sx={{ padding: '5px' }}>
                            <Field
                              component={TextField}
                              type="text"
                              className="input-field"
                              placeholder="Adicione as informações mais importantes como data, horário, local e contatos"
                              name="observacao_lead"
                              id="observacao_lead"
                              autoComplete="off"
                              disabled={isSubmitting}
                              multiline
                              rows={2}
                              onChange={onChangeObservacaoLead}
                              value={valorObservacaoLead}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                            <Button
                              className="btn-main margin-top-20"
                              disabled={isSubmitting || !valorObservacaoLead}
                              onClick={submitForm}
                            >
                              Salvar Observação
                            </Button>
                          </Grid>

                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <ObservacoesLead lstObservacoesLead={lstVerCadastro.observacao_lead} />
                          </Grid>

                          {isSubmitting && <LinearProgress />}
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {permiteAlteracaoLead && (
          <EditarLead
            dadosCadastroLead={lstVerCadastro}
            setDadosModalDashboard={setDadosModalDashboard}
            cancelarEdicaoLead={setPermiteAlteracaoLead}
            funcaoSucesso={alteracaoLeadSucesso}
          />
        )}

        {modalEncaminharGerente && (
          <EncaminharGerente
            dadosModalDashboard={dadosModalDashboard}
            setDadosModalDashboard={setDadosModalDashboard}
            atualizarTodosDashboards={atualizarTodosDashboards}
          />
        )}
      </DialogContent>

      <AlertMain
        exibeAlerta={mostrarAlerta}
        setExibeAlerta={setMostrarAlerta}
        titleAlerta={msgTitleAlert}
        msgAlerta={msgAlert}
        funcaoConfirmacao={alterarTagLead}
      />

      <AlertMotivoMain
        exibeAlertaMotivo={mostrarAlertaMotivo}
        setExibeAlertaMotivo={setMostrarAlertaMotivo}
        funcaoConfirmacao={alterarTagLeadMotivo}
        lstOpcoes={lstOpcoesMotivos}
      />

      <ImportarLeads
        exibeImportarLeads={mostrarImportarLeads}
        setExibeImportarLeads={setMostrarImportarLeads}
        funcaoConfirmacao={finalizaImportacaoLeads}
        lstUsuariosCrm={lstUsuariosCrm}
        tagLead={dadosModalDashboard.buscarLeads}
        lstTiposOrigem={lstTiposOrigem}
      />

      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseAlert} severity={tipoAlerta} sx={{ width: '100%' }}>
          {msgAlerta}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ModalDashboard;
