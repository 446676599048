import React from 'react';
import one7Logo from '../../assets/img/one7-logo.png';

const LoaderMain = ({
  fullScreen = false,
  tipoLoader = 'bloco',
  tipoImagem = 'logo',
  bgTransparencia = '0.9',
  tamanho = 'lg',
  percentTop = false
}) => {
  const CirculoLoader = ({ tipo }) => {
    const widHeig =
      tamanho === 'xs'
        ? 10
        : tamanho === 'sm'
          ? 20
          : tamanho === 'md'
            ? 30
            : 40;
    const styleCirculo = {
      width: `${widHeig}px`,
      height: `${widHeig}px`,
      backgroundColor: 'transparent',
      borderRadius: '100%',
      borderTop: '5px solid #5AD4FF',
      borderBottom: '5px solid #ACE9FF',
      borderLeft: '5px solid #ACE9FF',
      borderRight: '5px solid #ACE9FF'
    };
    if (tipo !== 'apenas_loader') {
      styleCirculo.position = 'absolute';
      styleCirculo.zIndex = 1204;
    }
    if (tipo === 'float') {
      styleCirculo.zIndex = 7;
      styleCirculo.top = percentTop ? percentTop : '45%';
      styleCirculo.left = '45%';
    }
    return <div style={styleCirculo} className="spin-rotation" />;
  };

  const LogoLoader = ({ tipo }) => {
    const widHeig =
      tamanho === 'xs'
        ? 45
        : tamanho === 'sm'
          ? 60
          : tamanho === 'md'
            ? 80
            : 100;
    const margin =
      tamanho === 'xs' ? 4 : tamanho === 'sm' ? 13 : tamanho === 'md' ? 23 : 33;
    const border =
      tamanho === 'xs' ? 3 : tamanho === 'sm' ? 4 : tamanho === 'md' ? 5 : 5;
    const styleBox = {
      width: `${widHeig}px`,
      height: `${widHeig}px`,
      backgroundColor: '#D9E0F2',
      borderRadius: '100%'
    };
    let zIndexLogo = 1204;
    if (tipo === 'float') {
      styleBox.position = 'absolute';
      styleBox.top = percentTop ? percentTop : '20%';
      styleBox.left = '45%';
      styleBox.zIndex = 8;
      zIndexLogo = 8;
    }
    return (
      <div style={styleBox}>
        <div
          style={{
            width: `${widHeig - border * 2}px`,
            height: `${widHeig - border * 2}px`,
            backgroundColor: 'transparent',
            borderRadius: '100%',
            borderTop: '3px solid #1A284D',
            borderBottom: '3px solid #FFF',
            borderLeft: '3px solid #FFF',
            borderRight: '3px solid #FFF',
            marginLeft: `${border}px`,
            marginTop: `${border}px`,
            position: 'absolute',
            zIndex: 7
          }}
          className="spin-rotation"
        />
        <div
          style={{
            width: `${widHeig - 16}px`,
            backgroundColor: 'transparent',
            zIndex: 8,
            position: 'absolute',
            marginLeft: `${border + 5}px`,
            marginTop: `${margin}px`
          }}
        >
          <img
            className="logoOne7"
            src={one7Logo}
            width={widHeig - 16}
            alt="one7 logo"
            style={{ zIndex: zIndexLogo }}
          />
        </div>
      </div>
    );
  };

  const BoxFullScreen = () => (
    <div
      style={{
        backgroundColor: `rgba(255, 255, 255, ${bgTransparencia})`,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 6
      }}
    />
  );

  const BoxBlocoLoader = ({ children }) => (
    <div
      style={{
        backgroundColor: '#fff',
        width: '100%',
        height: 'calc(100vh - 170px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 7
      }}
    >
      {children}
    </div>
  );

  const BoxFloatingLoader = ({ children }) => (
    <div
      className="loading-pai"
      style={{
        width: '100%',
        zIndex: 7
      }}
    >
      <div
        style={{
          backgroundColor: `rgba(255, 255, 255, ${bgTransparencia})`,
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 7
        }}
      />
      {children}
    </div>
  );
  return (
    <>
      {fullScreen && <BoxFullScreen />}
      {tipoLoader === 'bloco' ? (
        <BoxBlocoLoader>
          {tipoImagem === 'logo' ? (
            <LogoLoader tipo={tipoLoader} />
          ) : (
            <CirculoLoader tipo={tipoLoader} />
          )}
        </BoxBlocoLoader>
      ) : tipoLoader === 'apenas_loader' ? (
        <>
          {tipoImagem === 'logo' ? (
            <LogoLoader tipo={tipoLoader} />
          ) : (
            <CirculoLoader tipo={tipoLoader} />
          )}
        </>
      ) : (
        <BoxFloatingLoader>
          {tipoImagem === 'logo' ? (
            <LogoLoader tipo={tipoLoader} />
          ) : (
            <CirculoLoader tipo={tipoLoader} />
          )}
        </BoxFloatingLoader>
      )}
    </>
  );
};
export default LoaderMain;
