/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import TextFieldMui from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Search } from '@mui/icons-material';
import { ptBR } from '@mui/x-date-pickers/locales';
import LoaderMain from '../../../Shared/LoaderMain';
import { buscaDadosStatusCards } from '../../../../api/formalizacao';
import { StatusFormalizacao } from '../../../Shared/Enums/StatusFormalizacao';

const StatusCards = ({
  setDadosModalDashboard,
  atualizarDashboard,
  setDadosCardsLeads,
  lstResponsaveisFormalizacao,
  lstTiposPpc
}) => {
  const [isPending, setIsPending] = useState(false);
  const [cardsStatus, setCardsStatus] = useState([]);
  const [valueResponsavel, setValueResponsavel] = useState('');
  const [valueTiposPpc, setValueTiposPpc] = useState('');
  const [periodoInicio, setPeriodoInicio] = useState(null);
  const [periodoFinal, setPeriodoFinal] = useState(null);
  const localizacaoBrasil = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;
  const [filtrosExecutados, setFiltrosExecutados] = useState(false);
  const [nomeClienteValue, setNomeClienteValue] = useState('');
  const [cnpjValue, setValueCNPJ] = useState('');

  useEffect(() => {
    buscaDadosStatus();
  }, [atualizarDashboard]);

  const parametrizaFiltros = () => {
    let dataInicial = null;
    let dataFinal = null;

    if (periodoInicio && periodoInicio.isValid()) {
      dataInicial = periodoInicio.format('YYYY-MM-DD');
    } else {
      setPeriodoInicio(null);
    }

    if (periodoFinal && periodoFinal.isValid()) {
      dataFinal = periodoFinal.format('YYYY-MM-DD');
    } else {
      setPeriodoFinal(null);
    }

    return {
      cod_responsavel: valueResponsavel ?? null,
      cod_tipo_ppc: valueTiposPpc ?? null,
      data_inicial: dataInicial,
      data_final: dataFinal,
      nome_cliente: nomeClienteValue ?? null,
      cnpj: cnpjValue ? cnpjValue.replace(/\D/g, '') : ''
    };
  };

  const buscaDadosStatus = () => {
    setIsPending(true);
    const params = parametrizaFiltros();
    setFiltrosExecutados(params);

    buscaDadosStatusCards(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          const dadosLeadsTemp = [];
          const lstNomesTagsTemp = {};
          let indexPrincipal = 0;
          res.data.map((dados, index) => {
            if (index !== 0 && index % 5 === 0) indexPrincipal++;
            if (typeof dadosLeadsTemp[indexPrincipal] === 'undefined')
              dadosLeadsTemp[indexPrincipal] = [];
            dadosLeadsTemp[indexPrincipal].push(dados);
            lstNomesTagsTemp[dados.tag] = dados;
            return dados;
          });
          setCardsStatus(dadosLeadsTemp);
          setDadosCardsLeads(lstNomesTagsTemp);
        } else {
          setCardsStatus([]);
          setDadosCardsLeads({});
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const mostrarModalDashBoard = dadosCard => {
    const dados = {};

    dados.titleModal = (dadosCard && dadosCard.DESCRICAO) ?? 'Status';
    dados.cod_status_card_formalizacao = dadosCard.CODSTATUSCARDFORMALIZACAO;

    if (filtrosExecutados) {
      const filtros = filtrosExecutados;
      dados.cod_responsavel = filtros.cod_responsavel;
      dados.cod_tipo_ppc = filtros.cod_tipo_ppc;
      dados.data_inicial = filtros.data_inicial;
      dados.data_final = filtros.data_final;
      dados.nome_cliente = filtros.nome_cliente ?? '';
      dados.cnpj = filtros.cnpj ?? '';
    }

    dados.buscarDadosCard = true;
    setDadosModalDashboard(dados);
  };

  const handleOnChangeResponsaveis = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueResponsavel(newValue.value);
    } else {
      setValueResponsavel('');
    }
  };

  const handleOnChangeTiposPpc = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueTiposPpc(newValue.value);
    } else {
      setValueTiposPpc('');
    }
  };

  const handleChangeNomeCliente = event => {
    const { value } = event.target;
    setNomeClienteValue(value);
  };

  const handleChangeCnpj = event => {
    const { value } = event.target;
    const cnpj = formatCNPJ(value);
    setValueCNPJ(cnpj ?? '');
  };

  const formatCNPJ = value => {
    let cleaned = value.replace(/\D/g, '');

    if (cleaned.length < 1) {
      return '';
    }

    if (cleaned.length > 14) {
      cleaned = cleaned.substring(0, 14);
    }

    let formatted = '';
    for (let i = 0; i < cleaned.length; i++) {
      if (i === 2 || i === 5) {
        formatted += '.';
      } else if (i === 8) {
        formatted += '/';
      } else if (i === 12) {
        formatted += '-';
      }
      formatted += cleaned[i];
    }
    return formatted;
  };

  return (
    <Card className="chart-card status-lead status-cards-formalizacao">
      <CardContent
        className="ajuste-spin-chart ajuste-card-lead"
        sx={isPending ? { position: 'relative' } : {}}
      >
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        <Grid container className="margin-bottom-20" spacing={2} sx={{ pr: '10px' }}>
          <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              CNPJ:
            </Typography>

            <TextFieldMui
              sx={{ marginLeft: '5px !important', paddingRight: '5px' }}
              className="input-formalizacao w-100"
              variant="outlined"
              value={cnpjValue}
              placeholder="Digite o CNPJ"
              onChange={handleChangeCnpj}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              Cliente:
            </Typography>

            <TextFieldMui
              sx={{ marginLeft: '5px !important', paddingRight: '5px' }}
              className="input-formalizacao w-100"
              variant="outlined"
              value={nomeClienteValue}
              placeholder="Digite o nome"
              onChange={handleChangeNomeCliente}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="pad-left-5 texto-cor-cinza-escuro margin-bottom-5"
            >
              Responsável:
            </Typography>

            <Autocomplete
              disablePortal
              id="responsaveisFormalizacao"
              options={lstResponsaveisFormalizacao || []}
              value={
                valueResponsavel && lstResponsaveisFormalizacao.length > 0
                  ? lstResponsaveisFormalizacao.find(option => option.value === valueResponsavel)
                  : ''
              }
              onChange={handleOnChangeResponsaveis}
              name="responsaveisFormalizacao"
              className="select-responsaveis-formalizacao ajuste-height pad-left-5"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              forcePopupIcon
              freeSolo
              disabled={lstResponsaveisFormalizacao && lstResponsaveisFormalizacao.length === 0}
              renderInput={params => (
                <TextFieldMui
                  {...params}
                  name="responsaveisFormalizacao"
                  placeholder="Todos"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off'
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              Tipo:
            </Typography>

            <Autocomplete
              disablePortal
              id="tipoFormalizacao"
              options={lstTiposPpc || []}
              value={
                valueTiposPpc && lstTiposPpc.length > 0
                  ? lstTiposPpc.find(option => option.value === valueTiposPpc)
                  : ''
              }
              onChange={handleOnChangeTiposPpc}
              name="tipoFormalizacao"
              className="select-responsaveis-formalizacao ajuste-height pad-left-5"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              forcePopupIcon
              freeSolo
              disabled={lstTiposPpc && lstTiposPpc.length === 0}
              renderInput={params => (
                <TextFieldMui
                  {...params}
                  name="tipoFormalizacao"
                  placeholder="Todos"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off'
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              Data de início:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
              <DatePicker
                name="data_agendamento_ini_filtro"
                className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-formalizacao"
                value={periodoInicio}
                maxDate={periodoFinal}
                disableFuture
                onChange={dataMoment => setPeriodoInicio(dataMoment)}
                slotProps={{
                  inputAdornment: {
                    position: 'start'
                  },
                  field: {
                    clearable: true,
                    onClear: () => setPeriodoInicio(null)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
            >
              Até:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
              <DatePicker
                name="data_agendamento_filtro"
                className="w-100 date-time-picker-intervalo data-intervalo-fim date-picker-formalizacao"
                value={periodoFinal}
                minDate={periodoInicio}
                onChange={dataMoment => setPeriodoFinal(dataMoment)}
                slotProps={{
                  inputAdornment: {
                    position: 'start'
                  },
                  field: {
                    clearable: true,
                    onClear: () => setPeriodoFinal(null)
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <Button
              className="btn-main btn-filtrar-cards pad-5tb-10lr"
              onClick={() => buscaDadosStatus()}
              sx={{ maxWidth: '140px' }}
            >
              Filtrar &nbsp; <Search />
            </Button>
          </Grid>
        </Grid>

        <Box className="box-principal-group overflow-x">
          {cardsStatus.map((blocoCards, index) => (
            <Box key={`${index}_${index + 1}`} className="box-principal-status-lead">
              {blocoCards.map(card => (
                <Box key={card.ORDEM} className="box-card-status-lead">
                  <Box
                    className="box-status-card cursor-pointer"
                    sx={{ maxHeight: '110px' }}
                    onClick={() => mostrarModalDashBoard(card)}
                  >
                    <Grid container>
                      <Grid item lg={4} md={4} sm={5} xs={5} style={{ height: '25px' }}>
                        <Box className="cor-status-card" style={{ borderColor: card.COR }} />
                      </Grid>
                      <Grid item lg={8} md={8} sm={7} xs={7} className="texto-direita">
                        {card.CODSTATUSCARDFORMALIZACAO === StatusFormalizacao.CONCLUIDOS &&
                          filtrosExecutados &&
                          !filtrosExecutados.data_inicial &&
                          !filtrosExecutados.data_final && (
                            <div className="pill-3-meses" title="3 últimos meses">
                              3 meses
                            </div>
                          )}
                      </Grid>
                      <Grid
                        className="descricao-status text-overflow-ellipsis"
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <span className="quantidade-status">{String(card.TOTAL)}</span>
                        <span className="nome-status" title={card.DESCRICAO}>
                          {card.DESCRICAO}
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
              {/* Criado um loop abaixo para criar caixas vazias que irão complementar a linha, pois precisa sempre ter 5 caixas, para manter o tamanho igual das caixas */}
              {blocoCards.length < 5 &&
                (() => {
                  const boxs = [];
                  for (let i = 0; i < 5 - blocoCards.length; i++) {
                    boxs.push(<Box key={i} className="box-card-status-lead" />);
                  }
                  return boxs;
                })()}
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatusCards;
