import React, { useState } from 'react';
import { Box, CssBaseline, useTheme, useMediaQuery } from '@mui/material';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';

const Main = ({ className, children, escopoAcesso, ...props }) => {
  const { window } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(70);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerWidth(menuOpen ? 70 : 220);
    setMenuOpen(!menuOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Navbar
        handleDrawerToggle={handleDrawerToggle}
        menuOpen={menuOpen}
        onlySmallScreen={onlySmallScreen}
      />

      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        menuOpen={menuOpen}
        drawerWidth={drawerWidth}
        window={window}
        escopoAcesso={escopoAcesso}
      />

      <Box
        className="margin-top-60 font-family-ibm-plex"
        sx={{
          flexGrow: 1,
          p: 4,
          bgcolor: '#f5f5f5',
          overflowX: 'hidden'
        }}
      >
        <main className={className}>{children}</main>
      </Box>
    </Box>
  );
};

export default Main;
