import React from 'react';
import { Box, FormControlLabel, Typography } from '@mui/material';
import 'moment/locale/pt-br';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NotIsEmpty, mascararCpfCnpj } from '../../../Utils/format';
import IOSSwitch from '../shared/IOSSwitch';
import CopyValorTexto from '../../../Shared/CopyValorTexto';

const definicaoDeColunasTestemunhas = (
  acessarDadosTestemunha,
  alterarStatusTestemunha,
  escopoClienteDados
) => {
  const config = [
    {
      field: 'STATUS',
      headerName: 'Status',
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white texto-centro',
      renderCell: params => (
        <FormControlLabel
          control={<IOSSwitch disabled={!escopoClienteDados.permite_alterar} />}
          onClick={e => alterarStatusTestemunha(params.row)}
          checked={params.row.STATUS === 'A'}
          sx={{ ml: 0, mr: 0 }}
        />
      )
    },
    {
      field: 'NOME',
      headerName: 'Nome',
      flex: 1,
      minWidth: 300,
      headerClassName: 'font-bold-header',
      cellClassName: 'data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      renderCell: params => (
        <Box className="texto-nowrap-overflow-dotted" title={params.row.NOME}>
          {params.row.NOME}
        </Box>
      )
    },
    {
      field: 'CPFCNPJ',
      headerName: 'Documento',
      flex: 1,
      minWidth: 200,
      headerClassName: 'font-bold-header',
      cellClassName: 'data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      renderCell: params => (
        <Box
          className="texto-nowrap-overflow-dotted"
          title={mascararCpfCnpj(String(params.row.CPFCNPJ))}
        >
          {mascararCpfCnpj(String(params.row.CPFCNPJ))}
          {NotIsEmpty(params.row.CPFCNPJ)
            ? CopyValorTexto({ texto: params.row.CPFCNPJ })
            : valorIndefinido}
        </Box>
      )
    },
    {
      field: 'ORDEMTESTEMUNHA',
      headerName: 'Testemunha',
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      renderCell: params => <>Testemunha {params.row.ORDEMTESTEMUNHA}</>
    },
    {
      field: 'abrir_testemunha',
      headerName: '',
      flex: 1,
      minWidth: 200,
      maxWidth: 200,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-white',
      sortable: false,
      renderCell: params => (
        <Typography
          className="novas-entradas-abrir"
          variant="h7"
          onClick={() => acessarDadosTestemunha(params.row)}
        >
          Editar
          <ChevronRightIcon sx={{ fontSize: '20px', mt: 0 }} />
        </Typography>
      )
    }
  ];
  return config;
};

export default definicaoDeColunasTestemunhas;
