import React, { memo, useEffect, useState } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR as DatePtBr } from '@mui/x-date-pickers/locales';
import { focusInput } from '../../../../Utils/utils';
import LoaderMain from '../../../../Shared/LoaderMain';
import TextFieldMain from '../../../../Shared/TextFieldMain';
import ButtonMain from '../../../../Shared/ButtonMain';
import { IsEmpty, NotIsEmpty, unmaskValue } from '../../../../Utils/format';
import SelectMain from '../../../../Shared/SelectMain';
import { TIPOSGARANTIAS } from '../../../../Shared/Enums/TiposGarantias';
import { alterarDadosGarantia, incluirDadosGarantia } from '../../../../../api/formalizacao';

const FormGarantia = ({
  formDados = {},
  setAlerta,
  lstTipos,
  handleClose,
  dadosContrato,
  dadosAditivo,
  funcaoSucesso = false
}) => {
  const [isPendingSalvar, setPendingSalvar] = useState(false);
  const [valueData, setValueData] = useState(null);
  const [valueValidade, setValueValidade] = useState(null);
  const [dataAlertErro, setDataAlertErro] = useState(false);
  const localizacaoBrasil = DatePtBr.components.MuiLocalizationProvider.defaultProps.localeText;

  useEffect(() => {
    if (formDados) {
      if (formDados.DATA) {
        const data = moment(formDados.DATA);
        setValueData(data);
      }

      if (formDados.VALIDADE) {
        const dataValidade = moment(formDados.VALIDADE);
        setValueValidade(dataValidade);
      }
    }
  }, []);

  const submitFormulario = (values, form) => {
    const params = { ...values };
    params.cod_cliente_formalizacao_aditivos = dadosAditivo && dadosAditivo.id ? dadosAditivo.id : null;
    params.cod_tipo_garantia = NotIsEmpty(params.cod_tipo_garantia) ? params.cod_tipo_garantia : null;
    params.cod_cliente_formalizacao = dadosContrato && dadosContrato.id ? dadosContrato.id : null;
    params.status_imovel = NotIsEmpty(params.status_imovel) ? params.status_imovel : null;
    params.id = formDados && formDados.id ? formDados.id : null;
    params.valor = unmaskValue(params.valor_imovel);
    params.valor_leilao = unmaskValue(params.valor_leilao);

    if (valueData && valueData.isValid()) {
      params.data = valueData.format('YYYY-MM-DD');
    } else {
      setValueData(null);
    }

    if (valueValidade && valueValidade.isValid()) {
      params.validade = valueValidade.format('YYYY-MM-DD');
    } else {
      setValueValidade(null);
    }

    setPendingSalvar(true);

    if (formDados && formDados.id) {
      alterarDadosGarantia(params)
        .then(() => {
          if (funcaoSucesso) {
            funcaoSucesso();
          }

          handleClose();
          setAlerta({
            mensagem: 'Garantia alterada com sucesso!',
            tipo: 'success'
          });
        })
        .catch(err => {
          console.error(err);
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        })
        .finally(() => {
          setPendingSalvar(false);
          form.setSubmitting(false);
        });
    } else {
      incluirDadosGarantia(params)
        .then(() => {
          if (funcaoSucesso) {
            funcaoSucesso();
          }

          handleClose();
          setAlerta({
            mensagem: 'Garantia cadastrada com sucesso!',
            tipo: 'success'
          });
        })
        .catch(err => {
          console.error(err);
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        })
        .finally(() => {
          setPendingSalvar(false);
          form.setSubmitting(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        cod_categoria: NotIsEmpty(formDados.CATEGORIA) ? formDados.CATEGORIA : '',
        cod_tipo_garantia: NotIsEmpty(formDados.CODTIPOSGARANTIA) ? formDados.CODTIPOSGARANTIA : '',
        valor_imovel: NotIsEmpty(formDados.VALOR) ? formDados.VALOR : '',
        matricula: NotIsEmpty(formDados.MATRICULA) ? formDados.MATRICULA : '',
        cartorio: NotIsEmpty(formDados.CARTORIO) ? formDados.CARTORIO : '',
        status_imovel: NotIsEmpty(formDados.STATUSIMOVEL) ? formDados.STATUSIMOVEL : '',
        observacao: NotIsEmpty(formDados.OBSERVACAO) ? formDados.OBSERVACAO : '',
        valor_leilao: NotIsEmpty(formDados.VALORLEILAO) ? formDados.VALORLEILAO : '',
        descricao_imovel: NotIsEmpty(formDados.DESCRICAOIMOVEL) ? formDados.DESCRICAOIMOVEL : '',
        ...formDados
      }}
      onSubmit={(values, form) => {
        const errors = {};
        const { ...valores } = values;

        if (IsEmpty(valores.cod_categoria)) {
          errors.cod_categoria = 'Escolha uma categoria';
        }

        if (IsEmpty(valores.cod_tipo_garantia)) {
          errors.cod_tipo_garantia = 'Escolha um tipo de garantia';
        }

        if (valores.cod_tipo_garantia === TIPOSGARANTIAS.IMOVEL) {
          if (IsEmpty(valores.valor_imovel)) {
            errors.valor_imovel = 'Preencha o valor do imóvel';
          }

          if (IsEmpty(valores.matricula)) {
            errors.matricula = 'Preencha a matrícula';
          }

          if (IsEmpty(valores.cartorio)) {
            errors.cartorio = 'Preencha o cartório';
          }

          if (IsEmpty(valores.status_imovel)) {
            errors.status_imovel = 'Escolha o status do imóvel';
          }

          if (IsEmpty(valores.valor_leilao)) {
            errors.valor_leilao = 'Preencha o valor do imóvel estimado pelo leilão';
          }

          if (IsEmpty(valores.descricao_imovel)) {
            errors.descricao_imovel = 'Preencha a descrição do imóvel';
          }
        }

        let erroDatas = false;
        if (IsEmpty(valueData)) {
          errors.data = 'erro';
          erroDatas = {
            campo: 'data',
            msg: 'Preencha a data'
          };
        } else if (IsEmpty(valueValidade)) {
          errors.validade = 'erro';
          erroDatas = {
            campo: 'validade',
            msg: 'Preencha a validade'
          };
        }

        setDataAlertErro(erroDatas);
        form.setErrors(errors);
        if (Object.keys(errors).length <= 0) {
          submitFormulario(valores, form);
        } else {
          form.setSubmitting(false);
          focusInput(Object.keys(errors)[0]);
        }
      }}
    >
      {({ values, submitForm, isSubmitting, setErrors }) => (
        <Form>
          {isPendingSalvar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Categoria:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.categorias) && lstTipos.categorias.length > 0 ? (
                <Field
                  id="cod_categoria"
                  name="cod_categoria"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione uma opção"
                  displayEmpty
                  displayEmptyLabel="Não Definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.categorias].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.categorias) && lstTipos.categorias.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Tipo de garantia:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.tiposGarantia) && lstTipos.tiposGarantia.length > 0 ? (
                <Field
                  id="cod_tipo_garantia"
                  name="cod_tipo_garantia"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione uma opção"
                  displayEmpty
                  displayEmptyLabel="Não Definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.tiposGarantia].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.tiposGarantia) && lstTipos.tiposGarantia.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Valor do imóvel:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="valor_imovel"
                name="valor_imovel"
                type="text"
                component={TextFieldMain}
                fullWidth
                tipoMascara="number-money"
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 18 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Valor estimado pelo leilão:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="valor_leilao"
                name="valor_leilao"
                type="text"
                component={TextFieldMain}
                fullWidth
                tipoMascara="number-money"
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 18 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Matrícula:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="matricula"
                name="matricula"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Cartório:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="cartorio"
                name="cartorio"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Data:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                <DatePicker
                  name="data"
                  id="data"
                  className={`
                    w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato 
                    ${dataAlertErro && dataAlertErro.campo === 'data' ? 'error-border' : ''}
                  `}
                  value={valueData}
                  disabled={isSubmitting}
                  onChange={dataMoment => {
                    setValueData(dataMoment);
                    setDataAlertErro(false);
                  }}
                  slotProps={{
                    inputAdornment: {
                      position: 'start'
                    },
                    field: {
                      clearable: true,
                      onClear: () => setValueData(null)
                    }
                  }}
                />
              </LocalizationProvider>
              {dataAlertErro && dataAlertErro.campo === 'data' && (
                <Box className="texto-danger margin-top-5">{dataAlertErro.msg}</Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Validade:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                <DatePicker
                  name="validade"
                  id="validade"
                  className={`
                    w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato 
                    ${dataAlertErro && dataAlertErro.campo === 'validade' ? 'error-border' : ''}
                  `}
                  value={valueValidade}
                  disabled={isSubmitting}
                  onChange={dataMoment => {
                    setValueValidade(dataMoment);
                    setDataAlertErro(false);
                  }}
                  slotProps={{
                    inputAdornment: {
                      position: 'start'
                    },
                    field: {
                      clearable: true,
                      onClear: () => setValueValidade(null)
                    }
                  }}
                  onError={newError => setErrors(newError)}
                />
              </LocalizationProvider>
              {dataAlertErro && dataAlertErro.campo === 'validade' && (
                <Box className="texto-danger margin-top-5">{dataAlertErro.msg}</Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Status do imóvel:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.statusImovel) && lstTipos.statusImovel.length > 0 ? (
                <Field
                  id="status_imovel"
                  name="status_imovel"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione uma opção"
                  displayEmpty
                  displayEmptyLabel="Não Definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.statusImovel].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.statusImovel) && lstTipos.statusImovel.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">

              Descrição do imóvel:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="descricao_imovel"
                name="descricao_imovel"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Observação:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="observacao"
                name="observacao"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ m: '20px 0' }}>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="transparente-com-bordas"
                disabled={isSubmitting}
                onClick={handleClose}
              >
                Cancelar
              </ButtonMain>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="azul-escuro"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {formDados && formDados.id ? 'Alterar' : 'Adicionar'}
              </ButtonMain>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default memo(FormGarantia);
