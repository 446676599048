/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import EsteiraProposta from './EsteiraProposta';
import { useEffectAfterMount } from '../../Utils/userEffectAfterMount';
import LoaderMain from '../../Shared/LoaderMain';
import ButtonMain from '../../Shared/ButtonMain';
import { EtapasPropostas } from '../../Shared/Enums/EtapasPropostas';
import { GerenteAvatar } from '../../Shared/GerenteAvatar';
import { ClienteNomeCnpj } from '../../Shared/ClienteNomeCnpj';

const PropostasEsteira = ({ parentAbaAtiva }) => {
  const lstEtapas = Object.values(EtapasPropostas);
  const etapasTotaisIniciais = {};
  lstEtapas.map(etp => {
    etapasTotaisIniciais[etp.etapa] = 0;
    return etp;
  });

  const [abaAtiva, mudarAba] = useState(0);
  const [filtrarEtapa, setFiltrarEtapa] = useState(false);
  const [buscarHistoricoAtivo, setBuscarHistoricoAtivo] = useState(false);
  const [showHistoricoAtivo, setShowHistoricoAtivo] = useState(false);
  const [lstPropostasEsteira, setLstPropostasEsteira] = useState([]);
  const [lstPropEstCompleta, setLstPropEstCompleta] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [mostrarGerente, setMostrarGerente] = useState(true);
  const [qtdMostrar, setQtdMostrar] = useState(10);
  const [etapasTotais, setEtapasTotais] = useState(etapasTotaisIniciais);
  // const [propostaCliente, setPropostaCliente] = useState(false);
  // const [mostraErro, setMostraErro] = useState(false);
  // const location = useLocation();

  const lstTiposPropostas = {
    cadastro_novo: 'Cadastro Novo',
    renovacao: 'Renovação',
    reativacao: 'Reativação',
    one7_mais_credito: 'One7MaisCrédito'
  };

  const arrayPropostas = [
    {
      codPPC: 28270,
      cnpjCliente: '85062001000113',
      codUserGerente: 100002,
      estadoAtual: 'FIM',
      dthrInsert: '2023-09-21 08:37:47',
      slaComercial: 60,
      tipoOrigemProposta: 'one7_mais_credito',
      dthrInsertFmt: '21/09/2023 08:37:47',
      diasParaExpirar: 6,
      dataHoje: '2023-11-14',
      situacaoPPC: 'D',
      dataInsert: '2023-09-21',
      nomeCliente: 'EMPRESA 89',
      codCliente: 244190,
      nomeGerente: 'Jessica borges',
      nomeUnidadeNegocio: '',
      nomePlataforma: 'REGIONAL',
      tipoProposta: 'one7_mais_credito',
      dthrStatusConcluido: null,
      slaEtapas: {
        RESERVADO: 60,
        PROPONDO: 60,
        ANALISE_NEGOCIO: 5,
        BACK_OFFICE: 5,
        GERENTE_PLATAFORMA: 5,
        CREDITO: 5,
        COMITE: 5,
        FORMALIZACAO: 20
      },
      etapaAtual: 'FORMALIZACAO',
      esteira: [
        {
          etapa: 'LEADS',
          titulo: 'Leads',
          data: '19/09/2023',
          hora: '08:37:47',
          dataInsert: '2023-09-19',
          periodoDias: 1,
          estados: [],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'PREVENDA',
          titulo: 'Pré venda',
          data: '20/09/2023',
          hora: '08:37:47',
          dataInsert: '2023-09-20',
          periodoDias: 1,
          estados: [],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'RESERVADO',
          titulo: 'Reservado',
          data: '21/09/2023',
          hora: '08:37:47',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28270,
              siglaEstado: 'RES',
              nomeEstado: 'RESERVADA',
              status: 'I',
              dthrInsert: '2023-09-21 08:37:47',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:37:47',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'PROPONDO',
          titulo: 'Proposta',
          data: '21/09/2023',
          hora: '08:38:17',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28270,
              siglaEstado: 'PRO',
              nomeEstado: 'PROPONDO',
              status: 'I',
              dthrInsert: '2023-09-21 08:38:17',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:38:17',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'CREDITO',
          titulo: 'Cr\u00e9dito',
          data: '21/09/2023',
          hora: '08:38:17',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28270,
              siglaEstado: 'CRE',
              nomeEstado: 'CREDITO ENTRADA',
              status: 'I',
              dthrInsert: '2023-09-21 08:38:17',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:38:17',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28270,
              siglaEstado: 'CAU',
              nomeEstado: 'AUDITORIA',
              status: 'I',
              dthrInsert: '2023-09-21 08:40:29',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:40:29',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28270,
              siglaEstado: 'CPI',
              nomeEstado: 'PERFORMAR INFORMA\u00c7\u00c3O',
              status: 'I',
              dthrInsert: '2023-09-21 08:40:49',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:40:49',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28270,
              siglaEstado: 'CAF',
              nomeEstado: 'ANALISE FINANCEIRA',
              status: 'I',
              dthrInsert: '2023-09-21 08:41:06',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:41:06',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28270,
              siglaEstado: 'CAP',
              nomeEstado: 'ANALISE DE PRODUTO',
              status: 'I',
              dthrInsert: '2023-09-21 08:41:28',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:41:28',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28270,
              siglaEstado: 'FCR',
              nomeEstado: 'FINALIZADO CREDITO',
              status: 'I',
              dthrInsert: '2023-09-21 08:42:16',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:42:16',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: 'icred'
        },
        {
          etapa: 'COMITE',
          titulo: 'Comit\u00ea',
          data: '21/09/2023',
          hora: '08:42:16',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28270,
              siglaEstado: 'COM',
              nomeEstado: 'COMITE',
              status: 'I',
              dthrInsert: '2023-09-21 08:42:16',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:42:16',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: 'icomite'
        },
        {
          etapa: 'FORMALIZACAO',
          titulo: 'Formaliza\u00e7\u00e3o',
          data: '21/09/2023',
          hora: '08:43:44',
          estados: [
            {
              codPPC: 28270,
              siglaEstado: 'FIM',
              nomeEstado: 'FINALIZADA',
              status: 'A',
              dthrInsert: '2023-09-21 08:43:44',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:43:44',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: false,
          periodoDias: 54,
          slaAtrasado: true,
          direcionar: 'condicoes_operacionais'
        },
        {
          etapa: 'CONCLUIDO',
          titulo: 'Conclu\u00eddo',
          etapaNaoAtingida: true,
          direcionar: 'condicoes_operacionais'
        }
      ],
      slaTotal: 45
    },
    {
      codPPC: 28271,
      cnpjCliente: '85062001000113',
      codUserGerente: 100002,
      estadoAtual: 'FIM',
      dthrInsert: '2023-09-21 08:37:47',
      slaComercial: 60,
      tipoOrigemProposta: 'one7_mais_credito',
      dthrInsertFmt: '21/09/2023 08:37:47',
      diasParaExpirar: 6,
      dataHoje: '2023-11-14',
      situacaoPPC: 'D',
      dataInsert: '2023-09-21',
      nomeCliente: 'EMPRESA 89',
      codCliente: 244190,
      nomeGerente: 'Jessica borges',
      nomeUnidadeNegocio: '',
      nomePlataforma: 'REGIONAL',
      tipoProposta: 'one7_mais_credito',
      dthrStatusConcluido: null,
      slaEtapas: {
        RESERVADO: 60,
        PROPONDO: 60,
        ANALISE_NEGOCIO: 5,
        BACK_OFFICE: 5,
        GERENTE_PLATAFORMA: 5,
        CREDITO: 5,
        COMITE: 5,
        FORMALIZACAO: 20
      },
      etapaAtual: 'BACK_OFFICE',
      esteira: [
        {
          etapa: 'LEADS',
          titulo: 'Leads',
          data: '19/09/2023',
          hora: '08:37:47',
          dataInsert: '2023-09-19',
          periodoDias: 1,
          estados: [],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'PREVENDA',
          titulo: 'Pré venda',
          data: '20/09/2023',
          hora: '08:37:47',
          dataInsert: '2023-09-20',
          periodoDias: 1,
          estados: [],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'RESERVADO',
          titulo: 'Reservado',
          data: '21/09/2023',
          hora: '08:37:47',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28271,
              siglaEstado: 'RES',
              nomeEstado: 'RESERVADA',
              status: 'I',
              dthrInsert: '2023-09-21 08:37:47',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:37:47',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'PROPONDO',
          titulo: 'Proposta',
          data: '21/09/2023',
          hora: '08:38:17',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28271,
              siglaEstado: 'PRO',
              nomeEstado: 'PROPONDO',
              status: 'I',
              dthrInsert: '2023-09-21 08:38:17',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:38:17',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: null
        },
        {
          etapa: 'CREDITO',
          titulo: 'Cr\u00e9dito',
          data: '21/09/2023',
          hora: '08:38:17',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28271,
              siglaEstado: 'CRE',
              nomeEstado: 'CREDITO ENTRADA',
              status: 'I',
              dthrInsert: '2023-09-21 08:38:17',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:38:17',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28271,
              siglaEstado: 'CAU',
              nomeEstado: 'AUDITORIA',
              status: 'I',
              dthrInsert: '2023-09-21 08:40:29',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:40:29',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28271,
              siglaEstado: 'CPI',
              nomeEstado: 'PERFORMAR INFORMA\u00c7\u00c3O',
              status: 'I',
              dthrInsert: '2023-09-21 08:40:49',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:40:49',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28271,
              siglaEstado: 'CAF',
              nomeEstado: 'ANALISE FINANCEIRA',
              status: 'I',
              dthrInsert: '2023-09-21 08:41:06',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:41:06',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28271,
              siglaEstado: 'CAP',
              nomeEstado: 'ANALISE DE PRODUTO',
              status: 'I',
              dthrInsert: '2023-09-21 08:41:28',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:41:28',
              diasInseridoAteHoje: 54
            },
            {
              codPPC: 28271,
              siglaEstado: 'FCR',
              nomeEstado: 'FINALIZADO CREDITO',
              status: 'I',
              dthrInsert: '2023-09-21 08:42:16',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:42:16',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: 'icred'
        },
        {
          etapa: 'COMITE',
          titulo: 'Comit\u00ea',
          data: '21/09/2023',
          hora: '08:42:16',
          dataInsert: '2023-09-21',
          periodoDias: 0,
          estados: [
            {
              codPPC: 28271,
              siglaEstado: 'COM',
              nomeEstado: 'COMITE',
              status: 'I',
              dthrInsert: '2023-09-21 08:42:16',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:42:16',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: true,
          slaAtrasado: false,
          direcionar: 'icomite'
        },
        {
          etapa: 'FORMALIZACAO',
          titulo: 'Formaliza\u00e7\u00e3o',
          data: '21/09/2023',
          hora: '08:43:44',
          estados: [
            {
              codPPC: 28271,
              siglaEstado: 'FIM',
              nomeEstado: 'FINALIZADA',
              status: 'A',
              dthrInsert: '2023-09-21 08:43:44',
              dataInsert: '2023-09-21',
              dthrInsertFmt: '21/09/2023 08:43:44',
              diasInseridoAteHoje: 54
            }
          ],
          finalizado: false,
          periodoDias: 54,
          slaAtrasado: true,
          direcionar: 'condicoes_operacionais'
        },
        {
          etapa: 'CONCLUIDO',
          titulo: 'Conclu\u00eddo',
          etapaNaoAtingida: true,
          direcionar: 'condicoes_operacionais'
        }
      ],
      slaTotal: 45
    }
  ];

  const buscarPropostasEsteiras = () => {
    setIsPending(true);
    let lstDados = arrayPropostas;
    const etapaEsteira = 'TODAS';

    setLstPropostasEsteira(lstDados);
    setLstPropEstCompleta(lstDados);

    if (etapaEsteira === 'TODAS') {
      const etapasTotaisTemp = { ...etapasTotaisIniciais };
      lstDados = lstDados.map(ppc => {
        etapasTotaisTemp[ppc.etapaAtual]++;
        ppc.totalPeriodos = 0;
        ppc.esteira = ppc.esteira.map(etapa => {
          if (etapa.periodoDias)
            ppc.totalPeriodos += parseInt(etapa.periodoDias);
          return etapa;
        });
        return ppc;
      });
      etapasTotaisTemp['TODAS'] = lstDados.length;
      setEtapasTotais(etapasTotaisTemp);
    }
    setQtdMostrar(10);

    // let filtro = filtroDashboard ? filtroDashboard : {};
    // let etapaEsteira = filtrarEtapa !== false ? filtrarEtapa : 'TODAS';
    // let params = {
    //   etapaEsteira: etapaEsteira,
    //   ativos_historico: buscarHistoricoAtivo,
    //   ...filtro
    // };

    // setMostraErro(false);
    // setIsPending(true);
    // postListarPropostasEsteira(params)
    //   .then(res => {
    //     let lstDados = res && res.data ? res.data : [];
    //     setLstPropostasEsteira(lstDados);
    //     setLstPropEstCompleta(lstDados);
    //     if (etapaEsteira === 'TODAS') {
    //       var etapasTotaisTemp = { ...etapasTotaisIniciais };
    //       lstDados = lstDados.map(ppc => {
    //         etapasTotaisTemp[ppc.etapaAtual]++;
    //         ppc.totalPeriodos = 0;
    //         ppc.esteira = ppc.esteira.map(etapa => {
    //           if (etapa.periodoDias)
    //             ppc.totalPeriodos += parseInt(etapa.periodoDias);
    //           return etapa;
    //         });
    //         return ppc;
    //       });
    //       etapasTotaisTemp['TODAS'] = lstDados.length;
    //       setEtapasTotais(etapasTotaisTemp);
    //     }
    //     setQtdMostrar(10);
    //   })
    //   .catch(err => {
    //     setLstPropostasEsteira(false);
    //     setMostraErro(err.response);
    //   })
    //   .finally(() => {
    setIsPending(false);
    //   });
  };

  const trocarAba = newValue => {
    const etapa = lstEtapas[newValue] ? lstEtapas[newValue].etapa : 0;
    mudarAba(newValue);
    setFiltrarEtapa(etapa);
  };

  useEffect(() => {
    buscarPropostasEsteiras();
    setShowHistoricoAtivo(false);
    setMostrarGerente(true);
  }, []);

  useEffect(() => {
    if (filtrarEtapa === false) {
      return;
    }

    if (filtrarEtapa === 'TODAS') {
      buscarPropostasEsteiras();
    } else {
      setQtdMostrar(10);
      setLstPropostasEsteira(
        [...lstPropEstCompleta].filter(prop => prop.etapaAtual === filtrarEtapa)
      );
    }
  }, [filtrarEtapa]);

  useEffect(() => setQtdMostrar(10), [parentAbaAtiva]);

  useEffectAfterMount(() => {
    if (showHistoricoAtivo) {
      if (filtrarEtapa === 'TODAS') {
        buscarPropostasEsteiras();
      } else {
        trocarAba(0);
      }
    }
  }, [buscarHistoricoAtivo]);

  return (
    <Card className="chart-card-pipeline elemento-desabilitado">
      <CardContent className="ajuste-spin-chart">
        <Grid container className="items-centro-verticalmente">
          <Grid item xl={9} lg={8} md={7} sm={12} xs={12}>
            <Typography
              variant="h6"
              className="texto-cor-cinza-escuro pad-5 texto-weight-600"
            >
              Visão geral - Propostas
            </Typography>
          </Grid>
          <Grid item xl={3} lg={4} md={5} sm={12} xs={12}>
            <Box className="fundo-cor-azul-branco border-radius-8 pad-15">
              <Typography className="font-size-1 font-bold texto-cor-cinza-escuro margin-bottom-10">
                Legenda situação da proposta:
              </Typography>

              <Grid container>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-info border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Dentro do prazo
                  </Typography>
                </Grid>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-warning-strong border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Fora do prazo
                  </Typography>
                </Grid>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-success border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Apto a Operar
                  </Typography>
                </Grid>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-danger border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Inapto a Operar
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box sx={isPending ? { position: 'relative' } : {}}>
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          {/* FILTRO POR ETAPAS */}
          <Tabs
            value={parseInt(abaAtiva)}
            onChange={(event, newValue) => trocarAba(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            className="tabs-etapas-propostas borda-bottom-1 borda-cor-cinza-claro-2 margin-top-25 margin-bottom-15"
          >
            {lstEtapas.map((step, index) => (
              <Tab
                key={step.etapa}
                title={step.titulo}
                icon={
                  <>
                    <Box className="badge-padrao badge-cor-padrao badge-selecionavel margin-right-5">
                      {String(
                        etapasTotais[step.etapa] ? etapasTotais[step.etapa] : 0
                      ).padStart(2, '0')}
                    </Box>
                    <span className="texto-semi-negrito font-size-080 texto-cor-cinza-escuro texto-hidden-overflow">{` ${step.titulo}`}</span>
                  </>
                }
                sx={{ minWidth: 'fit-content', flex: 1, padding: '0 3px' }}
                item={index}
                className="sem-uppercase"
              />
            ))}
          </Tabs>
          {showHistoricoAtivo && (
            <Box className="texto-direita">
              <FormControlLabel
                control={
                  <Switch
                    checked={buscarHistoricoAtivo}
                    onChange={() =>
                      setBuscarHistoricoAtivo(!buscarHistoricoAtivo)
                    }
                    name="historicoAtivo"
                  />
                }
                title="Mostrar todos os ativos independente de já ter operado, ou indeferido ou ainda em formalização"
                label="Mostrar Todos os Ativos"
              />
            </Box>
          )}

          {/* <AlertErroMain
          exibeErro={mostraErro}
          escondeErro={setMostraErro}
          classes="margin-bottom-10"
        /> */}

          {/* LISTA */}
          {lstPropostasEsteira !== false ? (
            lstPropostasEsteira.length > 0 ? (
              lstPropostasEsteira.slice(0, qtdMostrar).map(proposta => (
                <Box
                  key={proposta.codPPC}
                  className="fundo-cor-cinza border-radius-8 pad-topbot-15 pad-right-left-10 margin-bottom-15"
                >
                  <Grid container>
                    <Grid
                      xl={mostrarGerente ? 2 : 1}
                      lg={mostrarGerente ? 3 : 2}
                      md={mostrarGerente ? 3 : 2}
                      sm={mostrarGerente ? 5 : 4}
                      xs={12}
                      item
                      container
                    >
                      {mostrarGerente && (
                        <Grid
                          xl={3}
                          lg={3}
                          md={4}
                          sm={6}
                          xs={5}
                          item
                          className="flex-box-items-centralizados"
                        >
                          {GerenteAvatar({
                            value: proposta.nomeGerente,
                            row: proposta
                          })}
                        </Grid>
                      )}
                      <Grid
                        xl={mostrarGerente ? 9 : 12}
                        lg={mostrarGerente ? 9 : 12}
                        md={mostrarGerente ? 8 : 12}
                        sm={mostrarGerente ? 6 : 12}
                        xs={mostrarGerente ? 7 : 12}
                        item
                        className="flex-box items-centro-verticalmente conteudo-centro texto-esquerda flex-box-wrap"
                      >
                        {ClienteNomeCnpj({
                          value: proposta.nomeCliente,
                          row: { cnpjCliente: proposta.cnpjCliente }
                        })}
                        <Box className="margin-top-5">
                          {lstTiposPropostas[proposta.tipoProposta] && (
                            <Box
                              className="badge-padrao-2 fundo-cor-principal texto-cor-branca margin-right-5"
                              title={
                                proposta.dthrInsertFmt
                                  ? `Iniciada em ${proposta.dthrInsertFmt.replace(
                                      ' ',
                                      ' ás '
                                    )}`
                                  : ''
                              }
                            >
                              {lstTiposPropostas[proposta.tipoProposta]}
                            </Box>
                          )}
                          {proposta.etapaAtual !== 'CONCLUIDO' &&
                            proposta.slaExpiracao && (
                              <Box className="badge-padrao-2 fundo-cor-cinza-claro-3 borda-cor-cinza-claro-3 texto-cor-cinza-escuro margin-right-5">
                                {proposta.slaExpiracao >= 0
                                  ? `Expiração: ${proposta.slaExpiracao} dias`
                                  : `Expirado á ${Math.abs(
                                      proposta.slaExpiracao
                                    )} dias`}
                              </Box>
                            )}
                          <Box className="badge-padrao-2 fundo-cor-cinza-claro-3 borda-cor-cinza-claro-3 texto-cor-cinza-escuro">
                            {`Total: ${
                              proposta.totalPeriodos
                                ? proposta.totalPeriodos
                                : 0
                            } dias`}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      xl={mostrarGerente ? 10 : 11}
                      lg={mostrarGerente ? 9 : 10}
                      md={mostrarGerente ? 9 : 10}
                      sm={mostrarGerente ? 7 : 8}
                      xs={12}
                      item
                    >
                      <Box className="flex-box-items-centralizados items-centralizados-esquerda">
                        <EsteiraProposta
                          proposta={proposta}
                          // setPropostaCliente={setPropostaCliente}
                          // setMostraErro={setMostraErro}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))
            ) : (
              <div className="texto-cor-cinza-claro margin-top-60 pad-left-30">
                <h2>Nenhuma proposta encontrada</h2>
              </div>
            )
          ) : (
            <div className="error">
              <h1>Ops!</h1>
              <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
            </div>
          )}
          {lstPropostasEsteira.length - qtdMostrar > 0 && (
            <Grid container>
              <Grid item xl={5} lg={5} md={4} sm={2} xs={0} />
              <Grid item xl={2} lg={2} md={4} sm={5} xs={12}>
                <ButtonMain
                  type="button"
                  tipoBotao="transparente-com-bordas-principal"
                  onClick={() => setQtdMostrar(qtdMostrar + 10)}
                  disabled={isPending}
                >
                  <Box>
                    <Box className="display-inline-block">Carregar Mais</Box>
                    <Box className="posicao-absoluta badge-padrao fundo-cor-principal texto-cor-branca display-inline-block">
                      {String(lstPropostasEsteira.length - qtdMostrar).padStart(
                        2,
                        '0'
                      )}
                    </Box>
                  </Box>
                </ButtonMain>
              </Grid>
            </Grid>
          )}
        </Box>

        {/* {propostaCliente && (
        <Redirect
          to={{
            pathname: '/authentication/change-client',
            state: { cliente: propostaCliente, location }
          }}
        />
      )} */}
      </CardContent>
    </Card>
  );
};

export default PropostasEsteira;
