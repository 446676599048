/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import TextFieldMui from '@mui/material/TextField';
import 'moment/locale/pt-br';
import { getEstadosBrasil, getMunicipiosBrasil } from '../../../api/cadastroExterno';
import TextFieldMain from '../../Shared/TextFieldMain';
import { validaTelefone } from '../../Utils/validaTelefone';
import Strip from '../../Utils/strip';
import LoaderMain from '../../Shared/LoaderMain';
import { postAlterarTagLead } from '../../../api/crm';
import { focusInput } from '../../Utils/utils';

const arrayFaturamentos = [
  'Menos de 360 mil por mês',
  'Entre 360 mil e 1 milhão por mês',
  'Entre 1 milhão e 2,5 milhões por mês',
  'Entre 2,5 milhões e 5 milhões por mês',
  'Entre 5 milhões e 12 milhões por mês',
  'Mais que 12 milhões por mês'
];

const faturamento = [
  { label: arrayFaturamentos[0], value: arrayFaturamentos[0] },
  { label: arrayFaturamentos[1], value: arrayFaturamentos[1] },
  { label: arrayFaturamentos[2], value: arrayFaturamentos[2] },
  { label: arrayFaturamentos[3], value: arrayFaturamentos[3] },
  { label: arrayFaturamentos[4], value: arrayFaturamentos[4] },
  { label: arrayFaturamentos[5], value: arrayFaturamentos[5] }
];

const arrayTempoFundacao = ['Menos de 1 ano', 'De 1 a 2 anos', 'Mais de 2 anos'];

const tempo_fundacao = [
  { label: arrayTempoFundacao[0], value: arrayTempoFundacao[0] },
  { label: arrayTempoFundacao[1], value: arrayTempoFundacao[1] },
  { label: arrayTempoFundacao[2], value: arrayTempoFundacao[2] }
];

const EditarLead = ({ dadosCadastroLead, cancelarEdicaoLead, funcaoSucesso }) => {
  const [valueEstado, setValueEstado] = useState('');
  const [nomeEstado, setNomeEstado] = useState('');
  const [siglaEstado, setSiglaEstado] = useState('');
  const [valueCidade, setValueCidade] = useState('');
  const [nomeCidade, setNomeCidade] = useState('');
  const [valueTempoEmpresa, setValueTempoEmpresa] = useState('');
  const [valueFaturamento, setValueFaturamento] = useState('');
  const [estadosBrasileiros, setEstadosBrasileiros] = useState([]);
  const [municipiosBrasileiros, setMunicipiosBrasileiros] = useState([]);
  const [municipiosFiltradosUF, setMunicipiosFiltradosUF] = useState([]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    getMunicipiosBrasil()
      .then(res => {
        setMunicipiosBrasileiros(res.data);
      })
      .catch(() => {})
      .finally(() => {});
    getEstadosBrasil()
      .then(res => {
        const newArray = res.data.map(uf => ({
          value: uf.id,
          label: uf.nome,
          sigla: uf.sigla
        }));
        newArray.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          } else {
            return true;
          }
        });
        setEstadosBrasileiros(newArray);
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setIsPending(true);
    if (dadosCadastroLead) {
      setValueTempoEmpresa(dadosCadastroLead.tempo_empresa);
      setValueFaturamento(dadosCadastroLead.faturamento);
      setValueEstado(dadosCadastroLead.id_uf);

      if (dadosCadastroLead.id_uf && municipiosBrasileiros) {
        let newArray = [];
        newArray = filtraMunicipiosPorUF(dadosCadastroLead.id_uf);
        setMunicipiosFiltradosUF(newArray);
        setValueCidade(dadosCadastroLead.id_cidade);
        setNomeCidade(dadosCadastroLead.cidade);
      }
    }

    setIsPending(false);
  }, [dadosCadastroLead, municipiosBrasileiros]);

  const handleOnChangeEstado = (ev, newValue) => {
    let newArray = [];
    setValueCidade('');
    setMunicipiosFiltradosUF([]);

    if (newValue && newValue.value) {
      setValueEstado(newValue.value);
      setNomeEstado(newValue.label);
      setSiglaEstado(newValue.sigla);
      newArray = filtraMunicipiosPorUF(newValue.value);
    } else {
      setValueEstado('');
    }

    setMunicipiosFiltradosUF(newArray);
  };

  const filtraMunicipiosPorUF = UF => {
    const newArray = [];
    municipiosBrasileiros.map(mun => {
      if (mun.microrregiao.mesorregiao.UF.id === UF) {
        newArray.push({
          value: mun.id,
          label: mun.nome
        });
      }
      return mun;
    });

    return newArray;
  };

  const handleOnChangeCidade = (ev, newValue) => {
    setValueCidade(newValue && newValue.value ? newValue.value : '');
    setNomeCidade(newValue && newValue.label ? newValue.label : '');
  };

  const handleOnChangeTempoEmpresa = (ev, newValue) => {
    setValueTempoEmpresa(newValue && newValue.value ? newValue.value : '');
  };

  const handleOnChangeFaturamento = (ev, newValue) => {
    setValueFaturamento(newValue && newValue.value ? newValue.value : '');
  };

  const msgErro = 'Preencha este campo';

  const cadastrarFormulario = (values, form) => {
    setIsPending(true);

    const newValues = {};
    // eslint-disable-next-line no-underscore-dangle
    newValues.id = dadosCadastroLead._id;
    newValues.faturamento = valueFaturamento ?? null;
    newValues.tempo_empresa = valueTempoEmpresa ?? null;
    newValues.nome = values.nome ?? null;
    newValues.email = values.email ?? null;
    newValues.telefone = Strip(values.telefone) ?? null;
    newValues.whatsapp = Strip(values.whatsapp) ?? null;
    newValues.origem_lead = values.origem_lead ?? null;
    newValues.url_origem = values.url_origem ?? null;
    newValues.razao_social = values.razao_social ?? null;

    if (dadosCadastroLead && dadosCadastroLead.id_uf !== valueEstado) {
      newValues.uf = siglaEstado;
      newValues.estado = nomeEstado;
      newValues.id_uf = valueEstado;
    }

    if (
      dadosCadastroLead &&
      ((dadosCadastroLead.id_cidade && dadosCadastroLead.id_cidade !== valueCidade) ||
        !dadosCadastroLead.id_cidade)
    ) {
      newValues.cidade = nomeCidade;
      newValues.id_cidade = valueCidade;
    }

    postAlterarTagLead(newValues)
      .then(() => {
        dadosCadastroLead.faturamento = valueFaturamento ?? null;
        dadosCadastroLead.tempo_empresa = valueTempoEmpresa ?? null;
        dadosCadastroLead.nome = values.nome ?? null;
        dadosCadastroLead.email = values.email ?? null;
        dadosCadastroLead.telefone = Strip(values.telefone) ?? null;
        dadosCadastroLead.whatsapp = Strip(values.whatsapp) ?? null;
        dadosCadastroLead.origem_lead = values.origem_lead ?? null;
        dadosCadastroLead.url_origem = values.url_origem ?? null;
        dadosCadastroLead.razao_social = values.razao_social ?? null;

        if (dadosCadastroLead && dadosCadastroLead.id_uf !== valueEstado) {
          dadosCadastroLead.uf = siglaEstado;
          dadosCadastroLead.estado = nomeEstado;
          dadosCadastroLead.id_uf = valueEstado;
        }

        if (
          dadosCadastroLead &&
          ((dadosCadastroLead.id_cidade && dadosCadastroLead.id_cidade !== valueCidade) ||
            !dadosCadastroLead.id_cidade)
        ) {
          dadosCadastroLead.cidade = nomeCidade;
          dadosCadastroLead.id_cidade = valueCidade;
        }

        funcaoSucesso();
      })
      .catch(() => {})
      .finally(() => {
        form.setSubmitting(false);
        setIsPending(false);
      });
  };

  return (
    <CardContent className="">
      <Typography variant="h6" className="texto-cor-cinza-escuro texto-weight-600">
        Dados da empresa
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: '10px 0 0 0' }}>
          <Formik
            initialValues={{
              cnpj: dadosCadastroLead.cnpj ?? '',
              razao_social: dadosCadastroLead.razao_social ?? '',
              estado: dadosCadastroLead.estado ?? '',
              cidade: dadosCadastroLead.cidade ?? '',
              nome: dadosCadastroLead.nome ?? '',
              email: dadosCadastroLead.email ?? '',
              telefone: dadosCadastroLead.telefone
                ? String(dadosCadastroLead.telefone).substring(0, 11)
                : '',
              whatsapp: dadosCadastroLead.whatsapp
                ? String(dadosCadastroLead.whatsapp).substring(0, 11)
                : '',
              tempo_empresa: dadosCadastroLead.tempo_empresa ?? '',
              faturamento: dadosCadastroLead.faturamento ?? '',
              origem_lead: dadosCadastroLead.origem_lead ?? '',
              url_origem: dadosCadastroLead.url_origem ?? ''
            }}
            onSubmit={(values, form) => {
              const errors = {};

              if (!values.razao_social) {
                errors.razao_social = msgErro;
              }

              if (values.nome && values.nome.length > 0) {
                const input_nome = values.nome.trim();
                if (!input_nome.includes(' ')) {
                  errors.nome = 'Preencha nome e sobrenome';
                }
              }

              if (values.email && values.email.length > 0) {
                const result = /\S+@\S+\.\S+/;
                const input_email = values.email.trim();
                if (!result.test(values.email) || input_email.includes(' ')) {
                  errors.email = 'Email inválido';
                }
              }

              if (values.telefone) {
                const celPhone = Strip(values.telefone);
                const tel = Strip(celPhone);
                const ddd = parseInt(tel.substring(0, 2), 10);

                if (ddd <= 10 || ddd >= 100) {
                  errors.telefone = 'DDD inválido';
                }

                if (!validaTelefone(celPhone)) {
                  errors.telefone = 'Telefone inválido';
                }
              }

              if (values.whatsapp) {
                const celPhone = Strip(values.whatsapp);
                const tel = Strip(celPhone);
                const ddd = parseInt(tel.substring(0, 2), 10);

                if (ddd <= 10 || ddd >= 100) {
                  errors.whatsapp = 'DDD inválido';
                }

                if (!validaTelefone(celPhone)) {
                  errors.whatsapp = 'WhatsApp inválido';
                }
              }

              form.setErrors(errors);

              if (Object.keys(errors).length <= 0) {
                cadastrarFormulario(values, form);
              } else {
                form.setSubmitting(false);
                focusInput(Object.keys(errors)[0]);
              }
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Grid container>
                  {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      CNPJ:
                    </Typography>
                    <Field
                      type="text"
                      name="cnpj"
                      titulo="CPF"
                      id="cnpj"
                      inputClassName="input-field input-field-main"
                      component={TextFieldMain}
                      autoComplete="off"
                      tipoMascara="CNPJMask"
                      inputProps={{ maxLength: 18 }}
                      disabled={true}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Razão social:
                    </Typography>
                    <Field
                      component={TextField}
                      type="text"
                      className="input-field"
                      fullWidth
                      name="razao_social"
                      id="razao_social"
                      autoComplete="off"
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    {dadosCadastroLead && dadosCadastroLead.estado ? (
                      <>
                        <Typography
                          variant="h7"
                          className="texto-cor-cinza-escuro pad-5 texto-weight-600"
                        >
                          Estado:
                        </Typography>
                        <Field
                          type="text"
                          name="estado"
                          id="estado"
                          inputClassName="input-field input-field-main"
                          component={TextFieldMain}
                          autoComplete="off"
                          disabled={true}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h7"
                          className="texto-cor-cinza-escuro pad-5 texto-weight-600"
                        >
                          Estado:
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="estado"
                          className="combo-box-mui"
                          options={estadosBrasileiros || []}
                          value={
                            valueEstado && estadosBrasileiros.length > 0
                              ? estadosBrasileiros.find(option => option.value === valueEstado)
                              : ''
                          }
                          onChange={handleOnChangeEstado}
                          name="estado"
                          disableClearable
                          isOptionEqualToValue={(option, value) =>
                            option === value || value === undefined || value === '' || value === null
                          }
                          disabled={
                            isSubmitting ||
                            estadosBrasileiros.length === 0 ||
                            municipiosBrasileiros.length === 0
                          }
                          renderInput={params => (
                            <TextFieldMui
                              {...params}
                              name="estado"
                              autoComplete="off"
                              error={valueEstado === false}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'none'
                              }}
                            />
                          )}
                        />
                        {valueEstado === false && <div className="texto-erro-combo-box">{msgErro}</div>}
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    {dadosCadastroLead && dadosCadastroLead.cidade ? (
                      <>
                        <Typography
                          variant="h7"
                          className="texto-cor-cinza-escuro pad-5 texto-weight-600"
                        >
                          Cidade:
                        </Typography>
                        <Field
                          type="text"
                          name="cidade"
                          id="cidade"
                          inputClassName="input-field input-field-main"
                          component={TextFieldMain}
                          autoComplete="off"
                          disabled={true}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h7"
                          className="texto-cor-cinza-escuro pad-5 texto-weight-600"
                        >
                          Cidade:
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="cidade"
                          className="combo-box-mui"
                          options={municipiosFiltradosUF || []}
                          value={
                            valueCidade && municipiosFiltradosUF.length > 0
                              ? municipiosFiltradosUF.find(option => option.value === valueCidade)
                              : ''
                          }
                          onChange={handleOnChangeCidade}
                          name="cidade"
                          disableClearable
                          isOptionEqualToValue={(option, value) =>
                            option === value || value === undefined || value === '' || value === null
                          }
                          disabled={
                            isSubmitting ||
                            estadosBrasileiros.length === 0 ||
                            municipiosBrasileiros.length === 0 ||
                            municipiosFiltradosUF.length === 0
                          }
                          renderInput={params => (
                            <TextFieldMui
                              {...params}
                              name="cidade"
                              error={valueCidade === false}
                              required={valueCidade === false}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'none'
                              }}
                            />
                          )}
                        />
                        {valueCidade === false && <div className="texto-erro-combo-box">{msgErro}</div>}
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Nome:
                    </Typography>
                    <Field
                      component={TextField}
                      type="text"
                      className="input-field"
                      name="nome"
                      id="nome"
                      fullWidth
                      autoComplete="off"
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Email:
                    </Typography>
                    <Field
                      component={TextField}
                      type="email"
                      className="input-field"
                      name="email"
                      id="email"
                      fullWidth
                      autoComplete="off"
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Telefone:
                    </Typography>
                    <Field
                      component={TextFieldMain}
                      type="text"
                      name="telefone"
                      id="telefone"
                      inputClassName="input-field input-field-main"
                      autoComplete="none"
                      tipoMascara="phoneMask"
                      fullWidth
                      inputProps={{ maxLength: 15 }}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      WhatsApp:
                    </Typography>
                    <Field
                      component={TextFieldMain}
                      type="text"
                      name="whatsapp"
                      id="whatsapp"
                      inputClassName="input-field input-field-main"
                      autoComplete="none"
                      tipoMascara="phoneMask"
                      fullWidth
                      inputProps={{ maxLength: 15 }}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Tempo de fundação da empresa:
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="tempo_empresa"
                      className="combo-box-mui"
                      options={tempo_fundacao || []}
                      value={
                        valueTempoEmpresa && tempo_fundacao.length > 0
                          ? tempo_fundacao.find(option => option.value === valueTempoEmpresa)
                          : ''
                      }
                      onChange={handleOnChangeTempoEmpresa}
                      name="tempo_empresa"
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option === value || value === undefined || value === '' || value === null
                      }
                      disabled={isSubmitting}
                      renderInput={params => (
                        <TextFieldMui
                          {...params}
                          name="tempo_empresa"
                          error={valueTempoEmpresa === false}
                          required={valueTempoEmpresa === false}
                          autoComplete="off"
                        />
                      )}
                    />
                    {valueTempoEmpresa === false && (
                      <div className="texto-erro-combo-box">{msgErro}</div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Faturamento mensal:
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="faturamento"
                      className="combo-box-mui"
                      options={faturamento || []}
                      value={
                        valueFaturamento && faturamento.length > 0
                          ? faturamento.find(option => option.value === valueFaturamento)
                          : ''
                      }
                      onChange={handleOnChangeFaturamento}
                      name="faturamento"
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option === value || value === undefined || value === '' || value === null
                      }
                      disabled={isSubmitting}
                      renderInput={params => (
                        <TextFieldMui
                          {...params}
                          name="faturamento"
                          error={valueFaturamento === false}
                          required={valueFaturamento === false}
                          autoComplete="off"
                        />
                      )}
                    />
                    {valueFaturamento === false && <div className="texto-erro-combo-box">{msgErro}</div>}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-esquerda"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      Origem:
                    </Typography>
                    <Field
                      component={TextFieldMain}
                      type="text"
                      name="origem_lead"
                      id="origem_lead"
                      inputClassName="input-field input-field-main"
                      autoComplete="off"
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="texto-esquerda grid-encaminhar-direita"
                  >
                    <Typography variant="h7" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                      URL origem / Nome arquivo:
                    </Typography>
                    <Field
                      component={TextFieldMain}
                      type="text"
                      name="url_origem"
                      id="url_origem"
                      inputClassName="input-field input-field-main"
                      autoComplete="off"
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  {isSubmitting && <LinearProgress />}

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="btn-outline-main margin-top-30"
                      disabled={isSubmitting}
                      onClick={() => cancelarEdicaoLead(false)}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="btn-main margin-top-30"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default EditarLead;
