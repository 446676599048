import React, { memo, useState } from 'react';
import { Box, Button, Collapse, Grid, Paper } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material/';
import ArticleIcon from '@mui/icons-material/Article';

const BoxPaper = ({
  iconeTitulo,
  labelTitulo,
  children,
  mostrarBotoes = false,
  funcaoIncluirContratos = false,
  funcaoGerarContratos = false,
  textButton = 'Adicionar',
  isPending
}) => {
  const [blocoAberto, setBlocoAberto] = useState(false);

  return (
    <Paper elevation={0} className="box-paper">
      <Grid container spacing={2} className="box-paper-header">
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={3}
          xs={6}
          className="flex-box-items-direita"
          onClick={() => setBlocoAberto(!blocoAberto)}
        >
          <Box className="box-icone-paper-header">{iconeTitulo}</Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={5}
          md={4}
          sm={4}
          xs={6}
          className="titulo flex-box-items-esquerda"
          onClick={() => setBlocoAberto(!blocoAberto)}
        >
          {labelTitulo}
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={5} xs={12}>
          <Grid container className="pad-0">
            {mostrarBotoes ? (
              <>
                {!funcaoGerarContratos && <Grid item xl={3} lg={3} md={3} sm={3} xs={3} />}

                {funcaoIncluirContratos && (
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="titulo flex-box-items-direita"
                  >
                    <Button
                      className="btn-main btn-incluir-contrato"
                      onClick={() => funcaoIncluirContratos()}
                      disabled={isPending}
                    >
                      {textButton}
                    </Button>
                  </Grid>
                )}

                {funcaoGerarContratos && (
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    className="titulo flex-box-items-direita"
                  >
                    <Button
                      className="btn-ver-contrato"
                      onClick={() => funcaoGerarContratos()}
                      disabled={isPending}
                    >
                      <ArticleIcon />
                    </Button>
                  </Grid>
                )}
              </>
            ) : (
              <Grid
                item
                xl={9}
                lg={9}
                md={9}
                sm={9}
                xs={9}
                onClick={() => setBlocoAberto(!blocoAberto)}
              />
            )}
            <Grid
              item
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={3}
              className="titulo flex-box-items-direita"
              onClick={() => setBlocoAberto(!blocoAberto)}
            >
              {blocoAberto ? (
                <ExpandLess className="font-size-2-3rem" />
              ) : (
                <ExpandMore className="font-size-2-3rem" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={blocoAberto} className="box-paper-body">
        <Box sx={{ m: '30px 0 0 0' }}>{children}</Box>
      </Collapse>
    </Paper>
  );
};
export default memo(BoxPaper);
