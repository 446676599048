import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';

const AlertMain = ({
  exibeAlerta = false,
  setExibeAlerta,
  titleAlerta = 'Informação',
  msgAlerta = '',
  funcaoConfirmacao = false,
  funcaoCancelar = false
}) => {
  if (typeof funcaoConfirmacao !== 'function') {
    return false;
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    if (typeof funcaoCancelar === 'function') {
      funcaoCancelar();
    }
    setExibeAlerta(false);
  };

  const handleOnclick = () => {
    funcaoConfirmacao();
    setExibeAlerta(false);
  };

  return (
    <Dialog open={exibeAlerta} onClose={handleClose} className="alert-main">
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal font-bold font-size-1-1-rem">
        {titleAlerta}
        <IconButton onClick={handleClose} className="icon-close-alert float-right">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="texto-cor-principal">
          {msgAlerta}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="btn-outline-main" onClick={handleClose}>
          Cancelar
        </Button>
        <Button className="btn-main" onClick={handleOnclick}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertMain;
