export const StatusFormalizacao = {
  ENTRADAS: 1,
  CONFERENCIADEDOCUMENTOS: 2,
  PENDENTEDOCUMENTO: 3,
  CADASTROEMISSAO: 4,
  PENDENTEASSINATURA: 5,
  BIZAGI: 6,
  AGUARDANDOAPROVACAOFUNDO: 7,
  CONTAESCROW: 8,
  VADU: 9,
  BLOQUEADOS: 10,
  CONCLUIDOS: 11
};
