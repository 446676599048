import React, { memo, useState } from 'react';
import { Alert, Box, Chip, Grid } from '@mui/material';
import {
  Add,
  CheckCircleOutline,
  DeleteOutlined,
  ErrorOutline,
  ModeEditOutlined
} from '@mui/icons-material';
import ButtonMain from '../../../Shared/ButtonMain';
import FormContatos from './FormContatos';
import LoaderMain from '../../../Shared/LoaderMain';
import { NotIsEmpty, retornaValorComMascara } from '../../../Utils/format';
import {
  buscarTiposEmailIdentificacaoEnvio,
  buscarTiposContatoEmpresa,
  inativarContato,
  alterarContatoPrincipal
} from '../../../../api/formalizacao';
import DialogConfirmacao from '../../../Shared/DialogConfirmacao';

const Contatos = ({
  dadosContatos,
  dadosGerais,
  setDadosGerais,
  setAlerta,
  setModalClienteDados,
  escopoContato,
  telaPai
}) => {
  const [isPending, setPending] = useState(false);
  const [lstTipos, setLstTipos] = useState(false);
  const [confirmacaoModal, setConfirmacaoModal] = useState(false);
  const valorIndefinido = <em className="texto-cor-cinza">-</em>;

  const buscarListasTipos = async () => {
    const lstTiposTemp = {};
    // BUSCA TIPOS DE EMAIL
    lstTiposTemp.emailTiposIdentificacaoEnvio =
      lstTipos === false || lstTipos.emailTiposIdentificacaoEnvio.length === 0
        ? await buscarTiposEmailIdentificacaoEnvio(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.emailTiposIdentificacaoEnvio;
    // BUSCA TIPOS DE CONTATO
    lstTiposTemp.contatoTipo =
      lstTipos === false || lstTipos.contatoTipo.length === 0
        ? await buscarTiposContatoEmpresa(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.contatoTipo;
    setLstTipos(lstTiposTemp);
    return lstTiposTemp;
  };

  const alterarIncluirContato = async dadosContatoEscolhido => {
    let listasTipos = lstTipos;
    if (
      lstTipos === false ||
      lstTipos.emailTiposIdentificacaoEnvio.length === 0 ||
      lstTipos.contatoTipo.length === 0
    ) {
      setModalClienteDados({
        openModal: true,
        tituloModal: 'Contatos da empresa',
        conteudoModal: <LoaderMain tipoLoader="float" tipoImagem="circulo" />
      });
      listasTipos = await buscarListasTipos();
    }

    setModalClienteDados({
      openModal: true,
      tituloModal: 'Contatos da empresa',
      conteudoModal: (
        <FormContatos
          formDados={dadosContatoEscolhido}
          dadosGerais={dadosGerais}
          setDadosGerais={setDadosGerais}
          setAlerta={setAlerta}
          lstTipos={listasTipos}
          handleClose={() => setModalClienteDados({})}
          telaPai={telaPai}
        />
      )
    });
  };

  const excluirContato = _id => {
    setConfirmacaoModal({
      mensagem: 'Deseja realmente excluir este contato?',
      funcaoConfirmar: () => {
        setConfirmacaoModal(false);
        const cpfcnpj =
          telaPai === 'DadosCliente' ? dadosGerais.informacoes_gerais.cnpj : dadosGerais.cpfcnpj;
        setPending(true);
        inativarContato(cpfcnpj, _id, telaPai)
          .then(res => {
            if (res && res.data && res.data.principal) {
              setAlerta({
                mensagem: 'Este contato é o principal, por isso ele não pode ser excluído!',
                tipo: 'warning'
              });
            } else if (res && res.data) {
              const dadosGeraisTemp = { ...dadosGerais };
              dadosGeraisTemp.contatos = res.data;
              setDadosGerais(dadosGeraisTemp);
              setAlerta({
                mensagem: 'Contato excluído com sucesso',
                tipo: 'success'
              });
            } else {
              console.error('Retorno vazio');
              setAlerta({
                mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
                tipo: 'error'
              });
            }
          })
          .catch(err => {
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          })
          .finally(() => {
            setPending(false);
          });
      },
      funcaoCancelar: () => setConfirmacaoModal(false)
    });
  };

  const selecionarPrincipal = _id => {
    const params = {
      _id: _id,
      cpfcnpj: telaPai === 'DadosCliente' ? dadosGerais.informacoes_gerais.cnpj : dadosGerais.cpfcnpj
    };
    setConfirmacaoModal({
      mensagem: 'Deseja realmente definir este contato como principal?',
      funcaoConfirmar: () => {
        setConfirmacaoModal(false);
        setPending(true);
        alterarContatoPrincipal(params, telaPai)
          .then(res => {
            if (res && res.data) {
              const dadosGeraisTemp = { ...dadosGerais };
              dadosGeraisTemp.contatos = res.data;
              setDadosGerais(dadosGeraisTemp);
              setAlerta({
                mensagem: 'Conato principal alterado com sucesso',
                tipo: 'success'
              });
            } else {
              console.error('Retorno vazio');
              setAlerta({
                mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
                tipo: 'error'
              });
            }
          })
          .catch(err => {
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          })
          .finally(() => {
            setPending(false);
          });
      },
      funcaoCancelar: () => setConfirmacaoModal(false)
    });
  };

  return (
    <>
      {telaPai === 'DadosPessoa' && escopoContato && escopoContato.permite_incluir && (
        <Grid container spacing={3} sx={{ mt: '-55px', pb: '15px' }}>
          <Grid item xl={10} lg={10} md={9} sm={8} xs={6} className="pad-0" />
          <Grid item xl={2} lg={2} md={3} sm={4} xs={6} className="pad-0">
            <ButtonMain size="medium" tipoBotao="azul-escuro" onClick={() => alterarIncluirContato({})}>
              Adicionar <Add />
            </ButtonMain>
          </Grid>
        </Grid>
      )}
      {dadosContatos && dadosContatos.length > 0 ? (
        dadosContatos.map(contato => (
          <Box key={contato._id} className="box-registro" sx={isPending ? { position: 'relative' } : {}}>
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Tipo
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="info-valor">
                {contato.dados_tipo && NotIsEmpty(contato.dados_tipo.nome)
                  ? contato.dados_tipo.nome
                  : valorIndefinido}
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="flex-box-items-direita pad-0">
                {escopoContato && escopoContato.permite_alterar && (
                  <ButtonMain
                    size="small"
                    variant="outlined"
                    className="botao-alterar-excluir"
                    onClick={() => alterarIncluirContato(contato)}
                  >
                    <ModeEditOutlined />
                  </ButtonMain>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Nome:
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="info-valor">
                {NotIsEmpty(contato.nome) ? contato.nome : valorIndefinido}
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="flex-box-items-direita pad-0">
                {escopoContato && escopoContato.permite_excluir && (
                  <ButtonMain
                    size="small"
                    variant="outlined"
                    className="botao-alterar-excluir"
                    onClick={() => excluirContato(contato._id)}
                  >
                    <DeleteOutlined />
                  </ButtonMain>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Telefone:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {contato.dados_telefone ? (
                  <>
                    {retornaValorComMascara(
                      (contato.dados_telefone.ddd ?? '  ') + (contato.dados_telefone.fone ?? ''),
                      contato.dados_telefone.fone.length === 8 ? '(##) ####-####' : '(##) #####-####'
                    )}
                  </>
                ) : (
                  valorIndefinido
                )}
              </Grid>
            </Grid>
            {contato.dados_telefone && contato.dados_telefone.ramal && (
              <Grid container spacing={3} className="info-line">
                <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                  Ramal:
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                  {contato.dados_telefone.ramal}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                E-mail:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {contato.dados_email ? contato.dados_email.email : valorIndefinido}
              </Grid>
            </Grid>
            {contato.dados_email && (
              <Grid container spacing={3} className="info-line">
                <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label" />
                <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                  {contato.dados_email && contato.dados_email.envia ? (
                    <Box className="texto-cor-complementar flex-box-items-centralizados texto-negrito">
                      <CheckCircleOutline className="icone-in-text-left" /> E-mail liberado para envio
                    </Box>
                  ) : (
                    <Chip
                      label={
                        <span className="flex-box-items-centralizados texto-negrito">
                          <ErrorOutline className="icone-in-text-left" /> Não enviar para esse e-mail
                        </span>
                      }
                      className="badge-cor-cinza"
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {contato.dados_email &&
              contato.dados_email.envia &&
              contato.dados_email.identificacoes_envio && (
                <Grid container spacing={3} className="info-line">
                  <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                    Identificação do envio:
                  </Grid>
                  <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="info-valor texto-negrito">
                    {contato.dados_email.identificacoes_envio
                      .map(identEnvio => identEnvio.nome)
                      .join(', ')}
                  </Grid>
                </Grid>
              )}
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label" />
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {contato.principal ? (
                  <Chip label="Principal" className="badge-cor-suporte" />
                ) : (
                  <ButtonMain
                    tipoBotao="transparente-com-bordas-preto"
                    className="w-auto"
                    onClick={() => selecionarPrincipal(contato._id)}
                  >
                    Definir como principal
                  </ButtonMain>
                )}
              </Grid>
            </Grid>
          </Box>
        ))
      ) : (
        <Grid container>
          <Grid item xl={4} lg={4} md={4} sm={3} xs={3} />
          <Grid item xl={5} lg={5} md={5} sm={5} xs={6} className="flex-box-items-esquerda">
            <Alert severity="info" className="w-auto">
              Nenhum contato cadastrado.
            </Alert>
          </Grid>
        </Grid>
      )}

      {telaPai === 'DadosCliente' && escopoContato && escopoContato.permite_incluir && (
        <Grid container spacing={3} className="margin-top-10">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} />
          <Grid item xl={2} lg={2} md={3} sm={4} xs={8} className="info-valor">
            <ButtonMain size="medium" tipoBotao="azul-escuro" onClick={() => alterarIncluirContato({})}>
              Adicionar <Add />
            </ButtonMain>
          </Grid>
          <Grid item xl={6} lg={6} md={5} sm={5} xs={2} />
        </Grid>
      )}

      {confirmacaoModal && <DialogConfirmacao {...confirmacaoModal} />}
    </>
  );
};
export default memo(Contatos);
