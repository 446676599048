import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Chart } from 'react-google-charts';
import LoaderMain from '../../Shared/LoaderMain';

const CanaisTrafego = () => {
  const [dadosChart, setDadosChart] = useState([
    ['Canais', 'Percentual'],
    ['', 0],
    ['', 0],
    ['', 0]
  ]);
  const [totalDados, setTotalDados] = useState(0);
  const [percentualSelected, setPercentualSelected] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [rowMouseOver, setRowMouseOver] = useState(false);

  const options = {
    title: null,
    pieHole: 0.5,
    height: '450px',
    legend: {
      position: 'top',
      maxLines: 3,
      alignment: 'center',
      textStyle: { fontSize: 14 }
    },
    pieSliceText: 'none',
    enableInteractivity: true,
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    },
    colors: ['#4ABDBE', '#FF6786', '#FFC784', '#376384', '#7C7F98']
  };

  const eventos = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        google.visualization.events.addListener(chart, 'onmouseover', e => {
          let { row } = e;
          row += 1;
          setRowMouseOver(row);
        });
        google.visualization.events.addListener(chart, 'onmouseout', () => {
          setRowMouseOver(-1);
        });
      }
    }
  ];

  useEffect(() => {
    setIsPending(true);
    setTimeout(() => {
      setIsPending(false);
      setTotalDados(100);
      setDadosChart([
        ['Canais', 'Percentual'],
        ['www.google.com', 12.5],
        ['one7.app', 12.5],
        ['modulus.one7.app', 12.5],
        ['one7.com.vc', 12.2],
        ['Outros', 51.8]
      ]);
    }, 2500);
  }, []);

  useEffect(() => {
    if (rowMouseOver === false) {
      return;
    }

    if (rowMouseOver >= 0 && typeof dadosChart[rowMouseOver] !== 'undefined') {
      const pctSelect = String(
        ((dadosChart[rowMouseOver][1] / totalDados) * 100).toFixed(1)
      );

      if (
        percentualSelected !== undefined &&
        pctSelect !== percentualSelected
      ) {
        setPercentualSelected(pctSelect);
      }
    } else {
      setPercentualSelected('');
    }
  }, [dadosChart, percentualSelected, rowMouseOver, totalDados]);

  /*
  useEffect(() => {
    params.tipo_concentracado = switchConcentracao ? 'C' : 'S';
    setIsPending(true);
    postCanaisTrafego(params)
      .then(res => {
        let dadosChartTemp = [];
        let somaTotal = 0;
        if (res.data && res.data.length > 0) {
          dadosChartTemp.push(['Sacado', 'Percentual']);
          res.data.map(dados => {
            dados.soma = parseInt(dados.soma);
            somaTotal += parseFloat(dados.soma);
            let nome = dados.sacado.toLowerCase();
            nome = nome.charAt(0).toUpperCase() + nome.substr(1);
            dadosChartTemp.push([nome, dados.soma]);
            return dados;
          });
        }
        setTotalDados(somaTotal);
        setDadosChart(dadosChartTemp);
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('CanaisTrafego');
      });
  }, []);

  */

  return (
    <Card className="chart-card-canais-trafego elemento-desabilitado">
      <CardContent
        className="ajuste-spin-chart"
        sx={
          isPending
            ? { position: 'relative', padding: '16px 0 16px 0' }
            : { padding: '16px 0 16px 0' }
        }
      >
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        {dadosChart ? (
          dadosChart.length > 1 ? (
            <>
              <Grid container>
                <Grid item xl={8} lg={8} md={8} sm={9} xs={12}>
                  <Typography
                    variant="subtitle1"
                    className="labelChartCanaisTrafego texto-cor-cinza-escuro pad-5 texto-weight-600 pad-left-15"
                    sx={{ paddingLeft: '21px' }}
                  >
                    Principais canais de tráfego
                  </Typography>
                </Grid>
              </Grid>

              <Typography
                variant="h5"
                className="chart-pie-hole-selected-value legend-position-top"
              >
                {percentualSelected && (
                  <>
                    {percentualSelected.padStart(4, '0')}
                    <span className="percentual">%</span>
                  </>
                )}
              </Typography>
              <Chart
                className="chartCanaisTrafego legend-position-top"
                chartType="PieChart"
                width="100%"
                height="450px"
                data={dadosChart}
                options={options}
                chartLanguage="pt-Br"
                chartEvents={eventos}
              />
            </>
          ) : (
            <div className="error">
              <h1>Nenhuma concentração de sacado encontrada</h1>
            </div>
          )
        ) : (
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default CanaisTrafego;
