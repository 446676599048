import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, CardContent, Grid, LinearProgress } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextFieldMui from '@mui/material/TextField';
import moment from 'moment';
import {
  buscaCrmMotivos,
  getGerentes,
  getPlataformas,
  postAlterarTagLead,
  postDadosEmpresaReceitaFederal
} from '../../../api/crm';
import AlertMotivoMain from '../../Shared/AlertMotivoMain';
import { getDadosUsuario } from '../../../services/authentication/auth';
import { geraCorHexaDecimal } from '../../Utils/geraCorHexaDecimal';
import 'moment/locale/pt-br';
import AlertErroMain from '../../Shared/AlertErroMain';
import LoaderMain from '../../Shared/LoaderMain';
import { focusInput } from '../../Utils/utils';

const EncaminharGerente = ({
  dadosModalDashboard,
  setDadosModalDashboard,
  atualizarTodosDashboards
}) => {
  const [valuePlataforma, setValuePlataforma] = useState('');
  const [valueGerente, setValueGerente] = useState('');
  const [listaPlataformas, setListaPlataformas] = useState([]);
  const [listaGerentes, setListaGerentes] = useState([]);
  const [listaFiltradaGerentes, setListaFiltradaGerentes] = useState([]);
  // const [labelMotivoGanho, setLabelMotivoGanho] = useState(false);
  // const [tagMotivoGanho, setTagMotivoGanho] = useState(false);
  // const [obsMotivoGanho, setObsMotivoGanho] = useState(false);
  const [mostrarAlertaMotivo, setMostrarAlertaMotivo] = useState(false);
  const [lstOpcoesMotivos, setLstOpcoesMotivos] = useState([]);
  const dadosUsuario = getDadosUsuario();

  const [mostraErro, setMostraErro] = useState(false);

  useEffect(() => {
    resetValuesForm();

    getPlataformas()
      .then(res => {
        if (res.data.length > 0) {
          const newArray = res.data.map(pla => ({
            value: pla.CODAGENCIA,
            label: pla.NOME
          }));

          setListaPlataformas(newArray);
        }
      })
      .catch(() => {})
      .finally(() => {});

    getGerentes()
      .then(res => {
        setListaGerentes(res.data);
      })
      .catch(() => {})
      .finally(() => {});

    buscaCrmMotivos('ganho')
      .then(res => {
        if (res.data && res.data.length > 0) {
          const arrayGanho = [];
          res.data.map(dados => {
            arrayGanho.push({ label: dados.descricao, value: dados.tag });
            return dados;
          });
          setLstOpcoesMotivos(arrayGanho);
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  const handleOnChangePlataforma = (ev, newValue) => {
    const newArray = [];
    setValueGerente('');
    setListaFiltradaGerentes([]);

    if (newValue && newValue.value) {
      setValuePlataforma(newValue.value);

      listaGerentes.map(ger => {
        if (ger.CODAGENCIA === newValue.value) {
          newArray.push({
            value: ger.CODBROKER,
            label: ger.NOME
          });
        }
        return ger;
      });
    } else {
      setValuePlataforma('');
    }

    setListaFiltradaGerentes(newArray);
  };

  const cadastrarFormulario = (values, form) => {
    const newValues = {};
    newValues.id = dadosModalDashboard.id;
    newValues.tag = 'reservado_comercial'; // 'agendamentos';
    // newValues.tag_motivo = tagMotivoGanho;
    // newValues.observacao = obsMotivoGanho;
    newValues.cod_broker = valueGerente;
    newValues.cod_plataforma = valuePlataforma;
    newValues.envia_email = 'S';
    newValues.razao_social = dadosModalDashboard.razaoSocial;
    newValues.cnpj = dadosModalDashboard.cnpj;

    const nome = (dadosUsuario && dadosUsuario.nome_usuario) ?? '';
    const observacoesLeads = dadosModalDashboard.observacao_lead ?? [];

    if (values.observacao_gn) {
      observacoesLeads.unshift({
        nome_usuario: nome,
        data_insert: moment().format('DD/MM/YYYY HH:mm:ss'),
        observacao: values.observacao_gn,
        cor: geraCorHexaDecimal(nome)
      });
      newValues.observacao_lead = observacoesLeads;
    }

    postDadosEmpresaReceitaFederal(newValues)
      .then(resReceita => {
        if (resReceita.data && resReceita.data.dados && resReceita.data.dados.detail) {
          if (resReceita.data.dados.detail && resReceita.data.dados.status === 'APPROVED') {
            postAlterarTagLead(newValues)
              .then(res => {
                setDadosModalDashboard(false);
                atualizarTodosDashboards();
              })
              .catch(error => {
                if (error && error.response && error.response.status === 400) {
                  setMostraErro(error.response.data.mensagem ?? 'Erro não identificado.');
                } else {
                  setMostraErro(error.response ? error.response : error);
                }
              })
              .finally(() => {
                form.setSubmitting(false);
              });
          } else {
            form.setSubmitting(false);
            setMostraErro(
              'Não é possível prosseguir com a reserva, pois o CNPJ não está ativo na Receita Federal.'
            );
          }
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 400) {
          setMostraErro(
            'Não é possível prosseguir com a reserva, pois o CNPJ não existe na Receita Federal.'
          );
        } else {
          setMostraErro(error.response ? error.response : error);
        }
        form.setSubmitting(false);
      });
  };

  const handleOnChangeCidade = (ev, newValue) => {
    setValueGerente(newValue && newValue.value ? newValue.value : '');
  };

  const resetValuesForm = () => {
    setValuePlataforma('');
    setValueGerente('');
    // setLabelMotivoGanho(false);
    // setTagMotivoGanho(false);
    // setObsMotivoGanho(false);
  };

  const msgErro = 'Preencha este campo';

  // const mostraMotivosGanhos = () => {
  //   setMostrarAlertaMotivo(true);
  // };

  const alterarTagLeadMotivo = (tag_motivo, observacao) => {
    if (tag_motivo && tag_motivo !== '') {
      // let lblMtv = lstOpcoesMotivos.find(
      //   element => element.value === tag_motivo
      // ).label;
      // setTagMotivoGanho(tag_motivo);
      // setObsMotivoGanho(observacao);
      // setLabelMotivoGanho(lblMtv);
    }
  };

  return (
    <CardContent className="texto-centro pad-0">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Formik
            initialValues={{
              plataforma: '',
              gerente: '',
              observacao_gn: ''
            }}
            onSubmit={(values, form) => {
              const errors = {};

              if (!valuePlataforma) {
                setValuePlataforma(false);
                errors.plataforma = msgErro;
              } else {
                values.plataforma = valuePlataforma;
              }

              form.setErrors(errors);

              if (Object.keys(errors).length <= 0) {
                cadastrarFormulario(values, form);
              } else {
                form.setSubmitting(false);
                focusInput(Object.keys(errors)[0]);
              }
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Grid container>
                  {isSubmitting && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                  {/* <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="grid-encaminhar-esquerda"
                  >
                    <Box
                      className="texto-esquerda flex-box items-meio margin-bottom-5"
                      sx={{ background: '#F4F4F4', padding: '10px 0' }}
                    >
                      <Box className="motivo-ganho-label">Motivo do ganho:</Box>

                      <Box
                        className="motivo-ganho-combo"
                        onClick={() => mostraMotivosGanhos()}
                      >
                        {labelMotivoGanho
                          ? labelMotivoGanho
                          : 'Selecione uma opção'}
                        <ArrowDropDownIcon />
                      </Box>
                    </Box>
                  </Grid> */}

                  {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} /> */}

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="grid-encaminhar-esquerda">
                    <Box className="texto-esquerda">Plataforma</Box>
                    <Autocomplete
                      disablePortal
                      id="plataforma"
                      options={listaPlataformas || []}
                      value={
                        valuePlataforma && listaPlataformas.length > 0
                          ? listaPlataformas.find(option => option.value === valuePlataforma)
                          : ''
                      }
                      onChange={handleOnChangePlataforma}
                      name="plataforma"
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option === value || value === undefined || value === '' || value === null
                      }
                      disabled={
                        isSubmitting || listaPlataformas.length === 0 || listaGerentes.length === 0
                      }
                      renderInput={params => (
                        <TextFieldMui
                          {...params}
                          name="plataforma"
                          placeholder="Escolha a plataforma"
                          autoComplete="off"
                          error={valuePlataforma === false}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off'
                          }}
                        />
                      )}
                    />
                    {valuePlataforma === false && <div className="texto-erro-combo-box">{msgErro}</div>}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="grid-encaminhar-direita">
                    <Box className="texto-esquerda">Gerente</Box>
                    <Autocomplete
                      disablePortal
                      id="gerente"
                      className="combo-box-mui"
                      autoHighlight
                      options={listaFiltradaGerentes || []}
                      value={
                        valueGerente && listaFiltradaGerentes.length > 0
                          ? listaFiltradaGerentes.find(option => option.value === valueGerente)
                          : ''
                      }
                      onChange={handleOnChangeCidade}
                      name="gerente"
                      isOptionEqualToValue={(option, value) =>
                        option === value || value === undefined || value === '' || value === null
                      }
                      disabled={
                        isSubmitting ||
                        listaPlataformas.length === 0 ||
                        listaGerentes.length === 0 ||
                        listaFiltradaGerentes.length === 0
                      }
                      renderInput={params => (
                        <TextFieldMui
                          {...params}
                          name="gerente"
                          placeholder="Escolha o gerente"
                          error={valueGerente === false}
                          required={valueGerente === false}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off'
                          }}
                        />
                      )}
                    />
                    {valueGerente === false && <div className="texto-erro-combo-box">{msgErro}</div>}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className="texto-esquerda margin-top-20">Observação</Box>
                    <Field
                      component={TextField}
                      type="text"
                      className="input-field"
                      placeholder="Adicione as informações mais importantes como data, horário, local e contatos"
                      name="observacao_gn"
                      id="observacao_gn"
                      autoComplete="off"
                      disabled={isSubmitting}
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </Grid>

                  {isSubmitting && <LinearProgress />}

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="btn-outline-main margin-top-20"
                      disabled={isSubmitting}
                      onClick={() => setDadosModalDashboard(false)}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="btn-main margin-top-20"
                      disabled={isSubmitting || !valuePlataforma}
                      onClick={submitForm}
                    >
                      Encaminhar ao gerente
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <AlertErroMain
          exibeErro={mostraErro}
          escondeErro={setMostraErro}
          titleErro={mostraErro && mostraErro.title ? mostraErro.title : ''}
          msgErro={mostraErro && mostraErro.msg ? mostraErro.msg : ''}
          tipoAlerta={mostraErro && mostraErro.tipoAlerta ? mostraErro.tipoAlerta : 'error'}
        />
      </Grid>

      <AlertMotivoMain
        exibeAlertaMotivo={mostrarAlertaMotivo}
        setExibeAlertaMotivo={setMostrarAlertaMotivo}
        funcaoConfirmacao={alterarTagLeadMotivo}
        lstOpcoes={lstOpcoesMotivos}
      />
    </CardContent>
  );
};

export default EncaminharGerente;
