import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { focusInputRef } from '../Utils/utils';

const AlertMotivoMain = ({
  exibeAlertaMotivo = false,
  setExibeAlertaMotivo,
  lstOpcoes = [],
  funcaoConfirmacao = false
}) => {
  const [motivoRadio, setMotivoRadio] = useState('');
  const [motivoInput, setMotivoInput] = useState('');
  const [inputDisabled, setInputDisabled] = useState(true);
  const [inputError, setInputError] = useState(false);
  const [mostraInputObservacao, setMostraInputObservacao] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (lstOpcoes && lstOpcoes.length > 0) {
      const first = [...lstOpcoes].shift();
      setMotivoRadio(first.value);

      lstOpcoes.map(opcao => {
        if (opcao.value.includes('outro')) {
          setMostraInputObservacao(true);
        }
        return opcao;
      });
    }
  }, [lstOpcoes]);

  if (typeof funcaoConfirmacao !== 'function') {
    return;
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setExibeAlertaMotivo(false);
    setInputDisabled(true);
    setInputError(false);
    setMotivoRadio('');
    setMotivoInput('');
    funcaoConfirmacao('');
  };

  const handleOnclick = () => {
    if (motivoRadio.includes('outro') && motivoInput.trim() === '') {
      setInputError(true);
      focusInputRef(inputRef);
      return false;
    }
    handleClose();
    funcaoConfirmacao(motivoRadio, motivoInput);
  };

  const handleChangeRadio = event => {
    const { value } = event.target;
    setMotivoRadio(value);
    setInputError(false);

    if (value.includes('outro') && mostraInputObservacao) {
      setInputDisabled(false);
      focusInputRef(inputRef);
    } else {
      setInputDisabled(true);
      setMotivoInput('');
    }
  };

  const handleChangeInput = event => {
    setMotivoInput(event.target.value);
    setInputError(false);
  };

  return (
    <Dialog
      open={exibeAlertaMotivo}
      onClose={handleClose}
      className="alert-main"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl>
          <RadioGroup
            name="radio-buttons-group"
            value={motivoRadio}
            onChange={handleChangeRadio}
          >
            {lstOpcoes.map(opcao => (
              <FormControlLabel
                key={opcao.value}
                value={opcao.value}
                control={<Radio />}
                label={opcao.label}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {mostraInputObservacao && (
          <TextField
            id="motivo_observacao"
            label="Informe o motivo"
            className="margin-top-10"
            value={motivoInput}
            onChange={handleChangeInput}
            disabled={inputDisabled}
            inputRef={inputRef}
            error={inputError}
          />
        )}
      </DialogContent>

      <DialogActions className="items-centro btn-alert-motivo">
        <Button className="btn-outline-main full-widht" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          className="btn-main full-widht"
          onClick={handleOnclick}
          disabled={motivoRadio === ''}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertMotivoMain;
