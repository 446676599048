import './App.css';
import Rotas from './Routes';

function App() {
  return (
    <div className="wrapper" data-testid="rotas-component">
      <Rotas data-testid="rotas-component" />
    </div>
  );
}

export default App;
