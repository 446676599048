import React, { useState } from 'react';
// import { Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IsEmpty } from '../Utils/format';

const CopyValorTexto = ({ texto }) => {
  const [mostraCopyOk, setMostraCopyOk] = useState(false);

  const copiarString = () => {
    setMostraCopyOk(true);
    texto = texto.replace(/[^a-zA-Z0-9]/g, '');
    navigator.clipboard.writeText(texto);
    setTimeout(() => {
      setMostraCopyOk(false);
    }, 1000);
  };

  return (
    <>
      {texto && !IsEmpty(texto) && typeof texto === 'string' ? ( 
          mostraCopyOk ? (
            <CheckCircleIcon
              sx={{ fontSize: '16px', marginLeft: '5px', color: '#54A900', mt: '2px' }}
            />
          ) : (
            <ContentCopyIcon
              className="flex-box texto-nowrap"
              titleAccess="Clique aqui para copiar!"
              onClick={() => copiarString()}
              sx={{ fontSize: '16px', marginLeft: '5px', cursor: 'pointer', mt: '2px' }}
            />
          )
      ) : (
        <></>
      )}
    </>
  );
};

export default CopyValorTexto;
