import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { autenthicaToken } from './auth';
import LoaderMain from '../../components/Shared/LoaderMain';

const PlataformasAutenticacao = ({ setEscopoAcesso }) => {
  const [urlRedirect, setUrlRedirect] = useState(false);
  const [isPending, setPending] = useState(true);
  const { token, tokenRefresh, url } = useParams();
  useEffect(() => {
    sessionStorage.clear();
    setPending(true);
    autenthicaToken(token, tokenRefresh, setEscopoAcesso)
      .then(res => {
        if (res) {
          const urlTemp = url.replace('|', '/');
          setUrlRedirect(`/${urlTemp}`);
        } else {
          setUrlRedirect('/force-logout');
        }
      })
      .catch(err => {
        setUrlRedirect('/force-logout');
      })
      .finally(() => {
        setPending(false);
      });
  }, []);
  return (
    <>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      {urlRedirect && <Navigate to={urlRedirect} replace />}
    </>
  );
};

export default PlataformasAutenticacao;
