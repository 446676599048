export const pegaLetrasInicias = string => {
  if (string && string !== '' && string !== null && string.includes(' ')) {
    let palavra = string.toLowerCase();
    palavra = palavra.replaceAll(/\s(de|da|do|dos|das)\s/g, ' ');
    const palavras = palavra.split(' ');
    const primeiraLetra = palavras[0].charAt(0).toUpperCase();
    const segundaLetra = palavras[1].charAt(0).toUpperCase();
    return primeiraLetra + segundaLetra;
  } else if (string && string !== '' && string !== null) {
    const primeiraLetra = string.charAt(0).toUpperCase();
    const segundaLetra = string.charAt(1).toUpperCase();
    return primeiraLetra + segundaLetra;
  } else {
    return '';
  }
};
