import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import 'moment/locale/pt-br';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getLeadsGrowth } from '../../../api/crm';
import { mascararCpfCnpj } from '../../Utils/format';

const NovoLead = ({ setDadosModalDashboard, atualizarDashboard }) => {
  const [dadosUltimoLead, setDadosUltimoLead] = useState(false);
  const [dadosLead, setDadosLead] = useState(false);
  const msgVazio = 'Não informado';

  useEffect(() => {
    const params = { tag: 'novo', tipo: 'primeiro' };
    getLeadsGrowth(params)
      .then(res => {
        setDadosLead(res.data);
        setDadosUltimoLead(res.data ?? {});
      })
      .catch(() => {})
      .finally(() => {});
  }, [atualizarDashboard]);

  const mostrarModalDashBoard = value => {
    const dados = {};
    if (value === 'todos') {
      dados.titleModal = 'Novo(s) lead(s) growth';
      dados.grid = dadosLead;
    } else {
      dados.titleModal = 'Cadastro';
      dados.verCadastro = dadosUltimoLead;
    }
    setDadosModalDashboard(dados);
  };

  return (
    <Box className="card-novo-lead">
      <Box sx={{ display: 'flex' }}>
        <Box className="ultimos-lead-info w-100 margin-bottom-5">
          <InfoOutlinedIcon />
          <Typography
            className="texto-cor-principal font-bold-600"
            variant="h7"
          >
            Novo lead growth
          </Typography>
        </Box>
        <Box className="w-100">
          {dadosLead && dadosLead.length > 0 && (
            <Typography
              className="link-ver-todos"
              variant="h7"
              onClick={() => mostrarModalDashBoard('todos')}
            >
              Ver todos
            </Typography>
          )}
        </Box>
      </Box>

      {dadosUltimoLead ? (
        <Box
          className="box-cliente-lead texto-nao-selecionavel"
          onClick={() => mostrarModalDashBoard('ultimo')}
        >
          <Box className="width-box">
            <Typography
              className="text-overflow-ellipsis"
              variant="h7"
              sx={{ minWidth: '18%' }}
            >
              {dadosUltimoLead.razao_social ?? msgVazio}
            </Typography>

            <Typography
              className="text-overflow-ellipsis texto-centro"
              variant="h7"
              sx={{ minWidth: '14%' }}
            >
              {mascararCpfCnpj(String(dadosUltimoLead.cnpj))}
            </Typography>

            <Typography
              className="text-overflow-ellipsis texto-centro"
              variant="h7"
              sx={{ minWidth: '15%' }}
            >
              {dadosUltimoLead.nome ?? msgVazio}
            </Typography>

            <Typography
              className="text-overflow-ellipsis texto-centro"
              variant="h7"
              sx={{ minWidth: '15%' }}
            >
              {dadosUltimoLead.email ?? msgVazio}
            </Typography>

            <Typography
              className="text-overflow-ellipsis texto-centro"
              variant="h7"
              sx={{ minWidth: '11%' }}
            >
              {dadosUltimoLead.telefone ?? msgVazio}
            </Typography>

            <Typography
              className="text-overflow-ellipsis texto-centro"
              variant="h7"
              sx={{ minWidth: '11%' }}
            >
              {dadosUltimoLead.whatsapp ?? msgVazio}
            </Typography>

            <Typography
              className="text-overflow-ellipsis texto-centro flex-box-items-centralizados items-centralizados"
              variant="h7"
              sx={{ minWidth: '11%' }}
              title={moment(dadosUltimoLead.data_insert).format(
                'DD/MM/yyyy HH:mm:ss'
              )}
            >
              <AccessTimeIcon className="font-size-18 margin-right-3" />
              {moment(dadosUltimoLead.data_insert).local().fromNow()}
            </Typography>

            <Typography
              className="text-overflow-ellipsis texto-centro"
              variant="h7"
              sx={{ minWidth: '5%' }}
            >
              {dadosUltimoLead.origem_lead}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className="box-cliente-lead">
          <Box className="width-box">
            <Box>Nenhum lead encontrado</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NovoLead;
