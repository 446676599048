import API from './api';

const url_modulo = 'plataforma_formalizacao';

export const getUsuariosResponsaveis = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/dashboard/usuarios-responsaveis`
  );

export const getTiposPPC = () =>
  API.get(`${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/dashboard/tipos-ppc`);

export const buscaDadosStatusCards = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/dashboard/dados-status-cards`,
    { params: params }
  );

export const getDadosCardGrid = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/dashboard/dados-card-grid`,
    { params: params }
  );

export const getDadosDocumentosGrid = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-documentos/dados-grid`,
    { params: params }
  );

export const getDadosDocumentosPessoaGrid = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/dados-pessoa-documentos`,
    { params: params }
  );

export const getTiposDocumentos = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-documentos/tipos-documentos`
  );

export const alterarStatusDocumento = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-documentos/alterar-status-documento`,
    dados
  );

export const excluirDocumentoCliente = idArquivo =>
  API.delete(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-documentos/excluir-documento/${idArquivo}`
  );

export const alterarStatusDocumentoPessoa = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/alterar-status-documento`,
    dados
  );

export const excluirDocumentoPessoa = idArquivo =>
  API.delete(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/excluir-documento/${idArquivo}`
  );

// ROTAS PARA OBTER DADOS - GET
export const verificarCliente = cnpj =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente/${cnpj}`);

export const buscarDadosCliente = cod_cliente =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-dados/${cod_cliente}`);

export const buscarTiposConstituicao = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/constituicao`
  );
};

export const buscarTiposAtividade = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/atividade`
  );
};

export const buscarTiposNaturezaJuridica = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/natureza-juridica`
  );
};

export const buscarTiposRegimeTributario = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/regime-tributario`
  );
};

export const buscarTiposPorteEmpresa = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/porte-empresa`
  );
};

export const buscarTiposScoreRatings = () =>
  API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-dados/tipos/score-ratings`
  );

export const buscarTiposEstadoCivil = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/estado-civil`
  );
};

export const buscarTiposRegimeCasamento = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/regime-casamento`
  );
};

export const buscarEstadosBrasileiros = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/estados-brasileiros`
  );
};

export const buscarTiposEndereco = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/endereco`
  );
};

export const buscarTiposEmailIdentificacaoEnvio = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/email-identificacao-envio`
  );
};

export const buscarTiposContatoEmpresa = telaPai => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/tipos/contato-pfpj`
  );
};

export const buscarPessoaGeral = cpfcnpj =>
  API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-pessoas/pessoa-geral/${cpfcnpj}`
  );

export const buscarPessoaPoderes = (cod_cliente, cpfcnpj) =>
  API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-pessoas/pessoa-poderes/${cod_cliente}/${cpfcnpj}`
  );

// ROTAS PARA INCLUIR DADOS - POST
export const incluirAlterarPessoa = dados =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-pessoas/pessoa`, dados);

export const incluirAlterarTestemunhaCliente = dados =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-pessoas/testemunha`,
    dados
  );

export const incluirEndereco = (dados, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/endereco`,
    dados
  );
};

export const incluirContato = (dados, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/contato`,
    dados
  );
};

// ROTAS PARA ALTERAR DADOS - PUT
export const salvarDadosCliente = dados =>
  API.put(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-dados/informacoes-gerais`,
    dados
  );

export const alterarEndereco = (dados, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.put(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/endereco`,
    dados
  );
};

export const alterarContato = (dados, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.put(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/contato`,
    dados
  );
};

export const alterarEnderecoPrincipal = (dados, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.put(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/endereco/escolher-principal`,
    dados
  );
};

export const inativarAtivarPessoaCliente = dados =>
  API.put(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-pessoas/ativar-inativar-pessoa`,
    dados
  );

export const inativarAtivarTestemunhaCliente = dados =>
  API.put(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/cliente-pessoas/ativar-inativar-testemunha`,
    dados
  );

export const alterarContatoPrincipal = (dados, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.put(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/contato/escolher-principal`,
    dados
  );
};

// ROTAS PARA DELETAR DADOS - DELETE
export const inativarEndereco = (cpfCnpj, _id, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.delete(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/endereco/${cpfCnpj}/${_id}`
  );
};

export const inativarContato = (_id_cliente, _id, telaPai) => {
  const rotasPai = { DadosCliente: 'cliente-dados', DadosPessoa: 'cliente-pessoas' };
  return API.delete(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/${url_modulo}/${rotasPai[telaPai]}/contato/${_id_cliente}/${_id}`
  );
};

export const buscaArquivoFormalizacao = codArquivoCliente =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-documentos/download-documento/${codArquivoCliente}`
  );

export const buscaArquivoPessoaFormalizacao = codArquivoCliente =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/download-documento-pessoa/${codArquivoCliente}`
  );

export const alterarDocumentoCliente = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-documentos/alterar-documento`,
    dados
  );

export const incluirDocumentoCliente = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-documentos/incluir-documento`,
    dados
  );

export const alterarDocumentoPessoaCliente = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/alterar-documento`,
    dados
  );

export const incluirDocumentoPessoaCliente = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/incluir-documento`,
    dados
  );

export const incluirConjugeComoAvalista = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/incluir-conjuge-avalista`,
    dados
  );

export const buscaListaStatusCards = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/dashboard/lista-status-cards`
  );

export const getTiposMotivosBloqueio = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/dashboard/lista-motivos-bloqueio`
  );

export const getTiposModalidades = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/dashboard/tipos-modalidades`
  );

export const getBuscaDadosPainelAnalista = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/dados-painel-analista`,
    { params: params }
  );

export const getBuscaHistoricoResponsaveis = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/historico-responsaveis`,
    { params: params }
  );

export const getHistoricoComentarios = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/historico-comentarios`,
    { params: params }
  );

export const getInformacoesBasicas = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/informacaoes-basicas`,
    { params: params }
  );

export const alterarResponsavelCliente = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/alterar-responsaveis`,
    dados
  );

export const alterarModalidade = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/alterar-modalidade`,
    dados
  );

export const alterarStatusFormalizacao = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/alterar-status`,
    dados
  );

export const alterarMotivoBloqueio = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/alterar-motivo-bloqueio`,
    dados
  );

export const incluirComentario = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/incluir-comentario`,
    dados
  );

export const excluirComentario = id =>
  API.delete(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/excluir-comentario`,
    { params: { id: id } }
  );

export const excluirBloqueio = id =>
  API.delete(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-gestao/excluir-bloqueio`,
    { params: { id: id } }
  );

export const getDadosPessoasGrid = (cod_cliente, filtroStatus) =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/dados-grid/${cod_cliente}`,
    {
      params: { status: filtroStatus }
    }
  );

export const getDadosTestemunhasGrid = (cod_cliente, filtroStatusTestemunhas) =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/testemunhas-dados-grid/${cod_cliente}`,
    {
      params: { status: filtroStatusTestemunhas }
    }
  );

export const getDadosPessoa = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pessoas/dados-pessoa`,
    { params: params }
  );

export const getDadosContratosGrid = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/dados-contratos-grid`,
    { params: params }
  );

export const getTiposContratos = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/tipos-contratos`
  );

export const getTiposAditivos = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/tipos-aditivos`
  );

export const getTiposGarantias = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/tipos-garantias`
  );

export const getEmpresasInsereOperacao = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/empresas-insere-operacao`
  );

export const getGarantiasContrato = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/garantias-contrato`,
    { params: params }
  );

export const alterarStatusContrato = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/alterar-status-contrato`,
    dados
  );

export const incluirDadosContrato = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/incluir-dados-contrato`,
    dados
  );

export const incluirDadosGarantia = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/incluir-dados-garantia`,
    dados
  );

export const incluirDadosAditivos = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/incluir-dados-aditivos`,
    dados
  );

export const alterarDadosContrato = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/alterar-dados-contrato`,
    dados
  );

export const getBuscaContratos = dados =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/formalizacao/contratos-plataforma`, {
    params: dados
  });

export const getBuscaPerifericos = (dados, rota) =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/formalizacao/${rota}`, {
    params: dados
  });

export const alterarDadosGarantia = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/alterar-dados-garantia`,
    dados
  );

export const alterarStatusGarantia = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/alterar-status-garantia`,
    dados
  );

export const alterarStatusAditivo = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/alterar-status-aditivo`,
    dados
  );

export const getDadosAditivosGrid = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/dados-aditivos-grid`,
    { params: params }
  );

export const listarContasCorrente = (cod_cliente, filtros) =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/contas-correntes/${cod_cliente}`,
    { params: filtros }
  );

export const listarContasEscrow = (cod_cliente, filtros) =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/contas-escrow/${cod_cliente}`,
    { params: filtros }
  );

export const listarBancosAgencias = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/lista-bancos-agencias`
  );

export const incluirContaCorrente = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/incluir-conta-corrente`,
    dados
  );

export const alterarContaCorrente = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/alterar-conta-corrente`,
    dados
  );

export const incluirContaEscrow = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/incluir-conta-escrow`,
    dados
  );

export const alterarContaEscrow = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/alterar-conta-escrow`,
    dados
  );

export const listarEmpresasContaEscrow = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/lista-empresas-conta-escrow`
  );

export const listarProdutosEscrow = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/lista-produtos-escrow`
  );

export const alterarStatusContaCorrente = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/alterar-status-conta-corrente`,
    dados
  );

export const alterarStatusContaEscrow = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/alterar-status-conta-escrow`,
    dados
  );

export const alterarContaCorrentePadraoPagamento = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-dadosbancarios/alterar-conta-corrente-padrao-pagamento`,
    dados
  );

export const getPareceresClientes = (cnpj, mostrarTudo) => {
  const params = {
    mostrar_tudo: mostrarTudo
  };
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/pareceres/${cnpj}`, {
    params
  });
};

export const getTiposPareceres = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pareceres/tipos-parecer`
  );

export const incluirParecerCliente = dados =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pareceres/incluir-parecer`,
    dados
  );

export const excluirParecerCliente = id =>
  API.delete(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pareceres/excluir-parecer`,
    { params: { cod_parecer: id } }
  );

export const alterarParecerCliente = dados =>
  API.put(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pareceres/alterar-parecer`,
    dados
  );

export const getLstTemplatesParecer = () =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-pareceres/templates-email`
  );

export const getBuscaLimitesGarantias = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/limites-garantias`,
    { params }
  );

export const verificarSeExistemBoletosEscrow = params =>
  API.get(
    `${process.env.REACT_APP_API_PROPOSTA_CONTRATO_BASE_URL}/${url_modulo}/cliente-contratos/boletos-escrow`,
    { params }
  );

export const baixarParcerPdf = codParecer =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/download-parecer-pdf/${codParecer}`, {
    responseType: 'blob'
  });

export const salvarParecerDocumentos = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/crm/salvar-parecer-documentos`, params);
