import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid
} from '@mui/material';
import ButtonMain from './ButtonMain';

const DialogConfirmacao = ({
  titulo,
  mensagem,
  funcaoConfirmar,
  funcaoCancelar,
  open = true,
  setOpen = false
}) => (
  <Dialog
    open={open}
    keepMounted
    onClose={() => {
      if (setOpen) setOpen(false);
      if (funcaoCancelar) funcaoCancelar();
    }}
  >
    <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
      {titulo ?? 'Confirmação!'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" className="texto-negrito">
        {mensagem ?? 'Deseja realmente realizar esta ação?'}
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ p: '15px 24px' }}>
      <Grid container spacing={3}>
        <Grid item xl={2} lg={2} md={2} sm={2} />
        <Grid item xl={5} lg={5} md={5} sm={5} xs={6}>
          <ButtonMain
            tipoBotao="transparente-com-bordas"
            onClick={() => {
              if (setOpen) setOpen(false);
              if (funcaoCancelar) funcaoCancelar();
            }}
          >
            Cancelar
          </ButtonMain>
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={5} xs={6}>
          <ButtonMain tipoBotao="azul-escuro" onClick={() => funcaoConfirmar()} autoFocus>
            Confirmar
          </ButtonMain>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);
export default DialogConfirmacao;
