export const consultarCEP = async CEP => {
  const url = `https://viacep.com.br/ws/${CEP}/json/`;
  const options = {
    method: 'GET'
  };

  if (CEP) {
    return fetch(url, options).then(res => res.json());
  }
};
