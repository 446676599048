import React, { memo } from 'react';
import { Alert, Box, Grid, InputAdornment } from '@mui/material';
import { Field } from 'formik';
import { LoopOutlined } from '@mui/icons-material';
import TextFieldMain from '../../../../Shared/TextFieldMain';
import LoaderMain from '../../../../Shared/LoaderMain';
import SelectMain from '../../../../Shared/SelectMain';
import RadioGroupMain from '../../../../Shared/RadioGroupMain';

const FieldsPessoaJuridica = ({
  values,
  isPending,
  lstTipos,
  onChangeCpfCnpj,
  cnpjReadOnlyDisabled
}) => (
  <>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        CNPJ
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="cpfcnpj"
          name="cpfcnpj"
          type="text"
          component={TextFieldMain}
          onChangeInput={onChangeCpfCnpj}
          fullWidth
          autoComplete="off"
          tipoMascara="CNPJMask"
          disabled={isPending || cnpjReadOnlyDisabled}
          InputProps={{
            endAdornment: isPending ? (
              <InputAdornment position="end">
                <LoopOutlined className="rotating" />
              </InputAdornment>
            ) : (
              <></>
            ),
            maxLength: 18,
            readOnly: cnpjReadOnlyDisabled
          }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        Empresa
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="nome"
          name="nome"
          type="text"
          component={TextFieldMain}
          fullWidth
          autoComplete="off"
          disabled={isPending}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        Nome Fantasia:
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="fantasia"
          name="fantasia"
          type="text"
          component={TextFieldMain}
          fullWidth
          autoComplete="off"
          disabled={isPending}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        Inscrição estadual:
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="inscrEstad"
          name="inscrEstad"
          type="text"
          component={TextFieldMain}
          fullWidth
          autoComplete="off"
          disabled={isPending}
          inputProps={{ maxLength: 20 }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        NIRE:
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="nire"
          name="nire"
          type="text"
          component={TextFieldMain}
          fullWidth
          autoComplete="off"
          disabled={isPending}
          inputProps={{ maxLength: 20 }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        Data de registro na junta:
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="dataRegJunta"
          name="dataRegJunta"
          type="text"
          component={TextFieldMain}
          fullWidth
          tipoMascara="dateMask"
          autoComplete="off"
          disabled={isPending}
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        Fundação:
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="dataFundacao"
          name="dataFundacao"
          type="text"
          component={TextFieldMain}
          fullWidth
          tipoMascara="dateMask"
          autoComplete="off"
          disabled={isPending}
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        Porte da Empresa:
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        {Array.isArray(lstTipos.portesEmpresa) && lstTipos.portesEmpresa.length > 0 ? (
          <Field
            id="codTipoPorteEmpresa"
            name="codTipoPorteEmpresa"
            type="text"
            boxMain={false}
            component={SelectMain}
            placeholder="Selecione o tipo de porte da empresa"
            displayEmpty
            displayEmptyLabel="Não Definido"
            autoComplete="off"
            disabled={isPending}
            opcoes={[...lstTipos.portesEmpresa].map(tipo => ({
              label: tipo.nome,
              valor: tipo._id
            }))}
          />
        ) : Array.isArray(lstTipos.portesEmpresa) && lstTipos.portesEmpresa.length === 0 ? (
          <Alert severity="error" className="loader-box">
            Ocorreu algum erro, favor entrar em contato com o suporte
          </Alert>
        ) : (
          <Box className="loader-box">
            <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
          </Box>
        )}
      </Grid>
    </Grid>
    <Grid container spacing={3} className="input-line">
      <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
        Empresa em Recuperação Judicial (RJ):
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
        <Field
          id="recuperacaoJudicial"
          name="recuperacaoJudicial"
          component={RadioGroupMain}
          disabled={isPending}
          row
          options={[
            { label: 'Sim', value: 'S' },
            { label: 'Não', value: 'N' }
          ]}
        />
      </Grid>
    </Grid>
  </>
);
export default memo(FieldsPessoaJuridica);
