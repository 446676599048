import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const itensPadrao = [
  'heading',
  '|',
  'bold',
  'italic',
  'underline',
  '|',
  'fontSize',
  'fontFamily',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'blockQuote',
  'imageUpload',
  'insertTable',
  '|',
  'undo',
  'redo'
];

const CkEditorMain = ({
  valueCkEditor,
  setValueCkEditor,
  itensCkEditor = itensPadrao,
  textoPlaceholder = 'Descrição...'
}) => {
  const confgCkEditor = {
    toolbar: {
      items: itensCkEditor
    },
    language: 'pt-br',
    placeholder: textoPlaceholder,
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties'
      ]
    }
  };

  return (
    <CKEditor
      editor={Editor}
      config={confgCkEditor}
      data={valueCkEditor}
      onChange={(_, editor) => setValueCkEditor(editor.getData())}
    />
  );
};

export default CkEditorMain;
