import React from 'react';
import { Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SelectMainDefault = ({
  className = '',
  children = false,
  opcoes = [],
  displayEmptyLabel = 'Nenhum',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(open ? false : true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <Select
      {...props}
      open={open}
      onClick={handleClickOpen}
      onClose={handleClose}
      className={`${className ? className : ''} combobox-principal w-100`}
      IconComponent={props => {
        const classes = props.className;
        if (classes.indexOf('MuiSelect-iconOpen') !== -1) {
          return (
            <KeyboardArrowUpIcon
              className="combobox-principal-icon-opener"
              onClick={() => handleClickOpen()}
            />
          );
        } else {
          return (
            <KeyboardArrowDownIcon
              className="combobox-principal-icon-opener"
              onClick={() => handleClickOpen()}
            />
          );
        }
      }}
    >
      {props.displayEmpty && (
        <MenuItem value=""><em>{displayEmptyLabel}</em></MenuItem>
      )}
      {opcoes &&
        Object.values(opcoes).length !== 0 && 
        Object.values(opcoes).map((opt, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={`key_${opt._id ? opt._id : index}`} value={opt.valor}>
              {opt.label}
            </MenuItem>
          ))}
      {children && <>{children}</>}
    </Select>
  );
};
export default SelectMainDefault;
