import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HistoryIcon from '@mui/icons-material/History';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CommentIcon from '@mui/icons-material/Comment';
import CircleIcon from '@mui/icons-material/Circle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import ReplayIcon from '@mui/icons-material/Replay';
import HelpIcon from '@mui/icons-material/Help';
import moment from 'moment';
import 'moment/locale/pt-br';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LoaderMain from '../../../Shared/LoaderMain';

import {
  alterarModalidade,
  alterarMotivoBloqueio,
  alterarResponsavelCliente,
  buscaListaStatusCards,
  excluirBloqueio,
  excluirComentario,
  getBuscaDadosPainelAnalista,
  getBuscaLimitesGarantias,
  getHistoricoComentarios,
  getInformacoesBasicas,
  getTiposModalidades,
  getTiposMotivosBloqueio,
  incluirComentario,
  verificarSeExistemBoletosEscrow
} from '../../../../api/formalizacao';
import AlertMain from '../../../Shared/AlertMain';
import HistoricoResponsaveis from './HistoricoResponsaveis/HistoricoResponsaveis';
import StatusParecer from './StatusParecer/StatusParecer';
import { textToLowerCase } from '../../../Utils/format';
import { StatusFormalizacao } from '../../../Shared/Enums/StatusFormalizacao';
import { getDadosUsuario } from '../../../../services/authentication/auth';
import LimitesGarantias from '../FormalizacaoContratos/Modal/LimitesGarantias';

const GestaoCliente = ({
  cnpj,
  codStatusCliente,
  codRelacionamentosFormalizacao,
  lstResponsaveisFormalizacao,
  atualizarTodosDashboards,
  setModalClienteDados,
  escopoClienteDados,
  setDadosPareceres,
  handleChangeAba,
  cod_cliente,
  setAlerta
}) => {
  const [isPending, setIsPending] = useState(false);
  const [valueComentario, setValueComentario] = useState('');
  const [valueMotivoBloqueio, setValueMotivoBloqueio] = useState('');
  const [valueResponsaveis, setValueResponsaveis] = useState('');
  const [valueModalidade, setValueModalidade] = useState('');
  const [valueStatus, setValueStatus] = useState('');
  const [controleFuncaoConfirmacao, setControleFuncaoConfirmacao] = useState({});
  const [isPendingResponsaveis, setIsPendingResponsaveis] = useState(false);
  const [isPendingModalidade, setIsPendingModalidade] = useState(false);
  const [isPendingStatus, setIsPendingStatus] = useState(false);
  const [isPendingMotivoBloqueio, setIsPendingMotivoBloqueio] = useState(false);
  const [isPendingComentarios, setIsPendingComentarios] = useState(false);
  const [lstStatusCards, setLstStatusCards] = useState([]);
  const [lstTiposModalidades, setLstTiposModalidades] = useState([]);
  const [lstTiposMotivosBloqueio, setLstTiposMotivosBloqueio] = useState([]);
  const [lstDadosPainelAnalista, setLstDadosPainelAnalista] = useState([]);
  const [lstHistoricoComentarios, setLsHistoricoComentarios] = useState([]);
  const [lstInformacoesBasicas, setLsInformacoesBasicas] = useState([]);
  const dadosUsuario = getDadosUsuario();
  const [lstGarantias, setLstGarantias] = useState([]);
  const [lstBoletosEscrow, setLstBoletosEscrow] = useState([]);
  const [exibeInformacoesBoletosEscrow, setExibeInformacoesBoletosEscrow] = useState(false);
  const [exibeInformacoesGarantias, setExibeInformacoesGarantias] = useState(false);

  useEffect(() => {
    buscarGarantiasLimites();
    buscarBoletosEscrow();
    buscaStatusCards();
    bucaTiposModalidades();
    bucaTiposMotivosBloqueio();
    buscaHistoricoComentarios();
    buscaInformacoesBasicas();
    bucaDadosPainelAnalista();
  }, []);

  const buscarGarantiasLimites = () => {
    const params = {
      cod_cliente: cod_cliente
    };

    getBuscaLimitesGarantias(params)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
          const dados = res.data;
          setLstGarantias(dados);
          setExibeInformacoesGarantias(true);
        } else {
          setLstGarantias([]);
          setExibeInformacoesGarantias(false);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const buscarBoletosEscrow = () => {
    const params = {
      cod_cliente: cod_cliente
    };

    verificarSeExistemBoletosEscrow(params)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
          const dados = res.data;
          setLstBoletosEscrow(dados);
          setExibeInformacoesBoletosEscrow(true);
        } else {
          setLstBoletosEscrow([]);
          setExibeInformacoesBoletosEscrow(false);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const buscaHistoricoComentarios = () => {
    setIsPendingComentarios(true);
    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao
    };
    getHistoricoComentarios(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLsHistoricoComentarios(res.data);
        } else {
          setLsHistoricoComentarios([]);
        }
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao buscar os tipos de modalidades, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingComentarios(false);
      });
  };

  const buscaInformacoesBasicas = () => {
    setIsPendingComentarios(true);
    const params = {
      cod_cliente: cod_cliente
    };
    getInformacoesBasicas(params)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
          setLsInformacoesBasicas(res.data);
        } else {
          setLsInformacoesBasicas([]);
        }
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao buscar os as informações básicas, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingComentarios(false);
      });
  };

  const bucaTiposModalidades = () => {
    setIsPendingModalidade(true);
    getTiposModalidades()
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstTiposModalidades(res.data);
        } else {
          setLstTiposModalidades([]);
        }
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao buscar os tipos de modalidades, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingModalidade(false);
      });
  };

  const buscaStatusCards = () => {
    setIsPendingStatus(true);
    buscaListaStatusCards()
      .then(res => {
        setLstStatusCards(res.data);
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao buscar os Status, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingStatus(false);
      });
  };

  const bucaTiposMotivosBloqueio = () => {
    setIsPendingModalidade(true);
    getTiposMotivosBloqueio()
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstTiposMotivosBloqueio(res.data);
        } else {
          setLstTiposMotivosBloqueio([]);
        }
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao buscar os tipos de bloqueios, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingModalidade(false);
      });
  };

  const bucaDadosPainelAnalista = () => {
    setIsPending(true);

    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao
    };

    getBuscaDadosPainelAnalista(params)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
          const dados = res.data;
          setLstDadosPainelAnalista(dados);
          setValueMotivoBloqueio(dados.CODMOTIVOBLOQUEIO ?? '');
          setValueResponsaveis(dados.CODUSUARIOFORMALIZACAO ?? '');
          setValueModalidade(dados.CODTIPOMODALIDADE ?? '');
          setValueStatus(dados.CODSTATUSCARDFORMALIZACAO ?? '');
        } else {
          setLstDadosPainelAnalista([]);
        }
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao buscar os dados do painel do analista, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const funcaoConfirmacaoRetornoResponsaveis = (funcaoConfirmacao, valorConfirmacao) => {
    setIsPendingResponsaveis(true);

    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao,
      cod_usuario_formalizacao: valorConfirmacao
    };

    alterarResponsavelCliente(params)
      .then(() => {
        atualizarTodosDashboards();
        funcaoConfirmacao();
        setAlerta({
          mensagem: 'Responsável alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao alterar o responsável, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingResponsaveis(false);
      });
  };

  const funcaoConfirmacaoRetornoModalidade = (funcaoConfirmacao, valorConfirmacao) => {
    setIsPendingModalidade(true);

    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao,
      cod_tipo_modalidade: valorConfirmacao
    };

    alterarModalidade(params)
      .then(() => {
        atualizarTodosDashboards();
        funcaoConfirmacao();
        setAlerta({
          mensagem: 'Modalidade alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao alterar a modalidade, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingModalidade(false);
      });
  };

  const funcaoConfirmacaoRetornoMotivoBloqueio = (funcaoConfirmacao, valorConfirmacao) => {
    setIsPendingMotivoBloqueio(true);

    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao,
      cod_motivo_bloqueio: valorConfirmacao
    };

    alterarMotivoBloqueio(params)
      .then(() => {
        funcaoConfirmacao();
        setAlerta({
          mensagem: 'Motivo do bloqueio alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao alterar o motivo do bloqueio, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingMotivoBloqueio(false);
      });
  };

  const handleOnChangeResponsaveis = event => {
    setControleFuncaoConfirmacao({
      exibeAlerta: true,
      setExibeAlerta: setControleFuncaoConfirmacao,
      titleAlerta: 'Atenção',
      msgAlerta: 'Deseja realmente alterar o responsável?',
      funcaoConfirmacao: () =>
        funcaoConfirmacaoRetornoResponsaveis(
          () => setValueResponsaveis(event.target.value),
          event.target.value
        )
    });
  };

  const handleOnChangeModalidade = event => {
    setControleFuncaoConfirmacao({
      exibeAlerta: true,
      setExibeAlerta: setControleFuncaoConfirmacao,
      titleAlerta: 'Atenção',
      msgAlerta: 'Deseja realmente alterar a modalidade?',
      funcaoConfirmacao: () =>
        funcaoConfirmacaoRetornoModalidade(
          () => setValueModalidade(event.target.value),
          event.target.value
        )
    });
  };

  const handleOnChangeMotivoBloqueio = event => {
    setControleFuncaoConfirmacao({
      exibeAlerta: true,
      setExibeAlerta: setControleFuncaoConfirmacao,
      titleAlerta: 'Atenção',
      msgAlerta: 'Deseja realmente alterar o motivo do bloqueio?',
      funcaoConfirmacao: () =>
        funcaoConfirmacaoRetornoMotivoBloqueio(
          () => setValueMotivoBloqueio(event.target.value),
          event.target.value
        )
    });
  };

  const handleOnChangeStatus = event => {
    const labelSelecionado = lstStatusCards.find(option => option.value === event.target.value).label;
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Status e parecer',
      maxWidth: 'md',
      conteudoModal: (
        <StatusParecer
          cnpj={cnpj}
          codStatusCliente={codStatusCliente}
          codCliente={cod_cliente}
          codRelacionamentosFormalizacao={codRelacionamentosFormalizacao}
          codStatusFormalizacao={event.target.value}
          handleClose={() => setModalClienteDados({})}
          funcaoConfirmacao={() => setValueStatus(event.target.value)}
          setAlerta={setAlerta}
          labelSelecionado={labelSelecionado}
          setDadosPareceres={setDadosPareceres}
          handleChangeAba={handleChangeAba}
          buscaHistoricoComentarios={() => buscaHistoricoComentarios()}
          atualizarTodosDashboards={() => atualizarTodosDashboards()}
          setValueMotivoBloqueio={setValueMotivoBloqueio}
          bucaDadosPainelAnalista={bucaDadosPainelAnalista}
        />
      )
    });
  };

  const handleOnChangeComentario = event => {
    setValueComentario(event.target.value);
  };

  const descartarComentario = () => {
    setValueComentario('');
  };

  const salvarComentario = () => {
    setIsPendingComentarios(true);

    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao,
      comentario: valueComentario
    };

    incluirComentario(params)
      .then(res => {
        const novoComentario = res.data;
        setLsHistoricoComentarios([novoComentario, ...lstHistoricoComentarios]);
        buscaHistoricoComentarios();
        setValueComentario('');
        setAlerta({
          mensagem: 'Comentário salvo com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao incluir o comentário, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingComentarios(false);
      });
  };

  const funcaoConfirmacaoExcluirComentario = idComentario => {
    setIsPendingComentarios(true);

    excluirComentario(idComentario)
      .then(() => {
        setLsHistoricoComentarios(prevState => prevState.filter(obj => obj._id !== idComentario));
        buscaHistoricoComentarios();
        setValueComentario('');
        setAlerta({
          mensagem: 'Comentário excluído com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao excluir o comentário, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPendingComentarios(false);
      });
  };

  const deletarComentario = idComentario => {
    setControleFuncaoConfirmacao({
      exibeAlerta: true,
      setExibeAlerta: setControleFuncaoConfirmacao,
      titleAlerta: 'Atenção',
      msgAlerta: 'Deseja realmente excluir este comentário?',
      funcaoConfirmacao: () => funcaoConfirmacaoExcluirComentario(idComentario)
    });
  };

  const deletarBloqueado = () => {
    setControleFuncaoConfirmacao({
      exibeAlerta: true,
      setExibeAlerta: setControleFuncaoConfirmacao,
      titleAlerta: 'Atenção',
      msgAlerta: 'Deseja realmente excluir o úlimo bloqueio?',
      funcaoConfirmacao: () => funcaoConfirmacaoExcluirBloqueio()
    });
  };

  const funcaoConfirmacaoExcluirBloqueio = () => {
    setIsPending(true);

    excluirBloqueio(codRelacionamentosFormalizacao)
      .then(() => {
        bucaDadosPainelAnalista();
        atualizarTodosDashboards();
        setAlerta({
          mensagem: 'Bloqueio excluído com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao excluir o bloqueio, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {});
  };

  const abrirModalHistoricoResponsavel = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Histórico de responsáveis',
      maxWidth: 'md',
      conteudoModal: (
        <HistoricoResponsaveis
          codRelacionamentosFormalizacao={codRelacionamentosFormalizacao}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1)
    }
  }));

  const paddingRightBox = tamanho => ({
    paddingRight: {
      xs: `5px !important`,
      sm: `5px !important`,
      md: `${tamanho}px !important`,
      lg: `${tamanho}px !important`,
      xl: `${tamanho}px !important`
    }
  });

  const msgSemInformacoes = 'Não informado';

  const abrirModalLimite = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Condições de garantia por empresa',
      maxWidth: 'sm',
      conteudoModal: (
        <LimitesGarantias
          codCliente={cod_cliente}
          setAlerta={setAlerta}
          lstGarantias={lstGarantias}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  return (
    <Box>
      <Grid container className="box-paper-header cursor-auto">
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={paddingRightBox(25)}>
          <Paper
            elevation={0}
            className="box-paper pad-bot-0"
            sx={{
              ...(isPending ? { position: 'relative' } : {}),
              ...paddingRightBox(5)
            }}
          >
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Grid container spacing={2} className="box-paper-header cursor-auto pad-10">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-0">
                <Box className="font-bold flex-box items-meio">
                  <Box className="box-icone-paper-header">
                    <AdminPanelSettingsIcon />
                  </Box>
                  <Box className="margin-left-15">Painel do analista</Box>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="pad-0">
                <Grid container className="pad-10" sx={{ marginTop: '10px' }}>
                  <Grid
                    item
                    xl={6}
                    lg={7}
                    md={7}
                    sm={7}
                    xs={12}
                    className="pad-0 label-tipo-aceleracao"
                    sx={{ height: '44px' }}
                  >
                    <span>Tipo: </span>
                    <span className="texto-negrito text-transform-capitalize">
                      {textToLowerCase(lstDadosPainelAnalista.TIPOPPC)}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={5}
                    md={5}
                    sm={5}
                    xs={12}
                    className="pad-0 label-tipo-aceleracao"
                    sx={{ height: '44px' }}
                  >
                    <span>Aceleração: </span>
                    <span className="texto-negrito text-transform-capitalize">
                      {lstDadosPainelAnalista.ACELERACAO === 'S' ? 'Sim' : 'Não'}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="pad-0"
                    sx={isPendingResponsaveis ? { position: 'relative' } : {}}
                  >
                    {isPendingResponsaveis && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                    <Box className="box-label-with-button">
                      <Box className="label-select">Responsável:</Box>
                      <Box className="label-icon" onClick={() => abrirModalHistoricoResponsavel()}>
                        <HistoryIcon />
                      </Box>
                    </Box>
                    <Select
                      className="select-with-icons"
                      value={valueResponsaveis}
                      onChange={handleOnChangeResponsaveis}
                      fullWidth
                      placeholder="Nenhum responsável selecionado"
                      displayEmpty
                      renderValue={valueResponsaveis !== '' ? undefined : () => 'Nenhum selecionado'}
                      disabled={!escopoClienteDados.permite_alterar}
                    >
                      {lstResponsaveisFormalizacao.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <AccountCircleIcon />
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-0">
                    <Grid container className="pad-0">
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="pad-0"
                        sx={{
                          paddingRight: {
                            sm: '15px !important',
                            md: '15px !important',
                            lg: '15px !important',
                            xl: '15px !important'
                          }
                        }}
                      >
                        {lstDadosPainelAnalista &&
                        lstDadosPainelAnalista.DATADESBLOQUEIO &&
                        valueStatus !== StatusFormalizacao.BLOQUEADOS ? (
                          <Box className="box-datas-cliente">
                            <Box>Data desbloqueio</Box>
                            <Box
                              className="texto-negrito"
                              title={moment(lstDadosPainelAnalista.DATADESBLOQUEIO).format(
                                'DD/MM/yyyy HH:mm:ss'
                              )}
                            >
                              {moment(lstDadosPainelAnalista.DATADESBLOQUEIO).format('DD/MM/yyyy')}
                            </Box>
                          </Box>
                        ) : (
                          <Box className="box-datas-cliente">
                            <Box>Data de entrada</Box>
                            <Box
                              className="texto-negrito"
                              title={moment(lstDadosPainelAnalista.DTENTRADA).format(
                                'DD/MM/yyyy HH:mm:ss'
                              )}
                            >
                              {moment(lstDadosPainelAnalista.DTENTRADA).format('DD/MM/yyyy')}
                            </Box>
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="pad-0"
                        sx={{
                          paddingLeft: {
                            sm: '15px !important',
                            md: '15px !important',
                            lg: '15px !important',
                            xl: '15px !important'
                          }
                        }}
                      >
                        {valueStatus === StatusFormalizacao.BLOQUEADOS ? (
                          <Box className="box-datas-limite-bloqueio borda-cor-principal-2">
                            <Box>
                              <AccessTimeIcon />
                            </Box>
                            <Box>
                              <Box>Data bloqueio</Box>
                              <Box className="texto-negrito">
                                {moment(lstDadosPainelAnalista.DATABLOQUEIO).format('DD/MM/yyyy')}
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box className="box-datas-limite-bloqueio">
                            <Box>
                              <AccessTimeIcon title="20 dias úteis a partir da data inicial" />
                            </Box>
                            <Box>
                              <Box>Data limite</Box>
                              <Box
                                className="texto-negrito"
                                title="20 dias úteis a partir da data inicial"
                              >
                                {moment(lstDadosPainelAnalista.DATALIMITE).format('DD/MM/yyyy')}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="pad-0 pad-right-left-10">
                <Grid container className="pad-0">
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="pad-0"
                    sx={!isPending && isPendingModalidade ? { position: 'relative' } : {}}
                  >
                    {!isPending && isPendingModalidade && (
                      <LoaderMain tipoLoader="float" tipoImagem="circulo" />
                    )}
                    <Box className="box-label">
                      <Box className="label-select">Modalidade:</Box>
                    </Box>
                    <Select
                      className="select-with-icons"
                      value={valueModalidade}
                      onChange={handleOnChangeModalidade}
                      fullWidth
                      disabled={!escopoClienteDados.permite_alterar}
                    >
                      {lstTiposModalidades.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="pad-0"
                    sx={!isPending && isPendingStatus ? { position: 'relative' } : {}}
                  >
                    {!isPending && isPendingStatus && (
                      <LoaderMain tipoLoader="float" tipoImagem="circulo" />
                    )}

                    <Box className="box-label margin-top-10">
                      <Box className="label-select">Status:</Box>
                    </Box>
                    <Select
                      name="status-formalizacao"
                      className="select-with-icons"
                      value={valueStatus}
                      onChange={handleOnChangeStatus}
                      fullWidth
                      disabled={!escopoClienteDados.permite_alterar}
                    >
                      {lstStatusCards.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <CircleIcon sx={{ fontSize: '18px !important', color: option.cor }} />
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  {valueStatus === StatusFormalizacao.BLOQUEADOS ? (
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="pad-0"
                      sx={isPendingMotivoBloqueio ? { position: 'relative' } : {}}
                    >
                      {isPendingMotivoBloqueio && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                      <Box className="box-label" sx={{ marginTop: '17px' }}>
                        <Box className="label-select">Motivo do bloqueio:</Box>
                      </Box>
                      <Select
                        className="select-with-icons"
                        value={valueMotivoBloqueio}
                        onChange={handleOnChangeMotivoBloqueio}
                        fullWidth
                      >
                        {lstTiposMotivosBloqueio.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  ) : lstDadosPainelAnalista && lstDadosPainelAnalista.DATADESBLOQUEIO ? (
                    <Box className="margin-top-25 w-100">
                      <Box className="margin-top-5 font-bold texto-cor-cinza-2 flex-box">
                        <LockOpenIcon sx={{ marginRight: '5px' }} /> Desbloqueado
                      </Box>
                      <Box>
                        Entrada original em
                        <b> {moment(lstDadosPainelAnalista.DTENTRADAORIGINAL).format('DD/MM/yyyy')}</b>
                        <Button
                          className="texto-cor-cinza-2 float-right"
                          sx={{ marginTop: '-10px' }}
                          title="Excluir o úlimo bloqueio"
                          onClick={() => deletarBloqueado()}
                        >
                          <DeleteOutlineIcon className="texto-cor-cinza-2" />
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={0}
            className="box-paper pad-bot-0"
            sx={isPending ? { position: 'relative' } : {}}
          >
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Grid container spacing={2} className="box-paper-header cursor-auto pad-10 margin-bottom-10">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-0">
                <Box className="font-bold flex-box items-meio">
                  <Box className="box-icone-paper-header">
                    <ErrorIcon />
                  </Box>
                  <Box className="margin-left-15">Informações básicas</Box>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-0">
                <Grid container className="pad-10 margin-top-10">
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>Plataforma</Box>
                    <span className="texto-negrito font-size-14 pad-right-5">
                      {lstInformacoesBasicas.NOMEAGENCIA ?? msgSemInformacoes}
                    </span>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>Gerente regional</Box>
                    <span className="texto-negrito font-size-14 pad-right-5">
                      {lstInformacoesBasicas.NOMEGP ?? msgSemInformacoes}
                    </span>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>Gerente de Negócios</Box>
                    <span className="texto-negrito font-size-14 pad-right-5">
                      {lstInformacoesBasicas.NOMEBROKER ?? msgSemInformacoes}
                    </span>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>Rating</Box>
                    <span className="texto-negrito font-size-14 pad-right-5">
                      {lstInformacoesBasicas.RATING ?? msgSemInformacoes}
                    </span>
                  </Grid>
                </Grid>
                <Grid container className="pad-10 margin-top-10">
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>100% rígido</Box>
                    <span className="texto-negrito font-size-14 pad-right-5">
                      {lstInformacoesBasicas.RIGIDO === 'S' ? 'Sim' : 'Não'}
                    </span>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>Siga</Box>
                    <span
                      className="texto-negrito font-size-14 pad-right-5"
                      title={lstInformacoesBasicas.NOMECOR}
                      style={{ color: `${lstInformacoesBasicas.CORHEX}` }}
                    >
                      {lstInformacoesBasicas.ICONE === 'check-circle' ? (
                        <CheckCircleIcon className="font-size-20" />
                      ) : lstInformacoesBasicas.ICONE === 'times-circle' ? (
                        <CancelIcon className="font-size-20" />
                      ) : lstInformacoesBasicas.ICONE === 'exclamation-triangle' ? (
                        <WarningIcon className="font-size-20" />
                      ) : lstInformacoesBasicas.ICONE === 'repeat' ? (
                        <ReplayIcon className="font-size-20" />
                      ) : lstInformacoesBasicas.ICONE === 'exclamation-circle' ? (
                        <ErrorIcon className="font-size-20" />
                      ) : lstInformacoesBasicas.ICONE === 'question-circle' ? (
                        <HelpIcon className="font-size-20" />
                      ) : (
                        <>{msgSemInformacoes}</>
                      )}
                    </span>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>Porte da empresa</Box>
                    <span className="texto-negrito font-size-14 pad-right-5">
                      EMP {lstInformacoesBasicas.PORTE ?? msgSemInformacoes}
                    </span>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className="pad-0 margin-top-10">
                    <Box>Faturamento Anual</Box>
                    <span className="texto-negrito font-size-14 pad-right-5">
                      {lstInformacoesBasicas.FATURAMENTO_ANUAL
                        ? `R$ ${parseFloat(lstInformacoesBasicas.FATURAMENTO_ANUAL).toLocaleString(
                            'pt-BR',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )}`
                        : msgSemInformacoes}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          {/* EXIBIR BOLETO ESCROW OU GARANTIAS */}
          {exibeInformacoesBoletosEscrow || exibeInformacoesGarantias ? (
            <Paper elevation={0} className="pad-bot-0" sx={isPending ? { position: 'relative' } : {}}>
              {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
              <Grid container className="grid-garantias-escrow">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-10 margin-top-10">
                  <Typography
                    variant="subtitle1"
                    className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                  />

                  {lstGarantias && lstGarantias.length > 0 ? (
                    <Button
                      className="btn-mais-info-garantias-escrow"
                      onClick={() => abrirModalLimite()}
                    >
                      <ErrorOutlineOutlinedIcon />
                      <span>
                        {lstBoletosEscrow && lstBoletosEscrow.length > 0 && (
                          <span>
                            <span style={{ display: 'block' }} className="color-black">
                              {' '}
                              <b>Existe Boleto Escrow Aprovado!</b>{' '}
                            </span>
                          </span>
                        )}
                        <span className="color-black">
                          {' '}
                          <b> Limite para garantia aprovado. </b>
                        </span>
                        <span className="color-blue"> Mais informações </span>
                      </span>
                    </Button>
                  ) : lstBoletosEscrow && lstBoletosEscrow.length > 0 ? (
                    <Button
                      className="btn-mais-info-garantias-escrow"
                      onClick={() => abrirModalLimite()}
                    >
                      <ErrorOutlineOutlinedIcon />
                      <span>
                        <span>
                          <span style={{ display: 'block' }} className="color-black">
                            {' '}
                            <b>Existe Boleto Escrow Aprovado!</b>{' '}
                          </span>
                        </span>
                      </span>
                    </Button>
                  ) : (
                    <>
                      {lstGarantias &&
                        lstGarantias.length === 0 &&
                        lstBoletosEscrow &&
                        lstBoletosEscrow.length === 0 &&
                        isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                      {}
                      <ErrorOutlineOutlinedIcon className="color-sem-infos" />
                      <span className="margin-bottom-5">
                        {' '}
                        Não existem garantias ou produto boleto escrow cadastrados nas condições
                        operacionais{' '}
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          ) : (
            lstGarantias &&
            lstGarantias.length === 0 &&
            lstBoletosEscrow &&
            lstBoletosEscrow.length === 0 && (
              <>
                {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                <ErrorOutlineOutlinedIcon className="color-sem-infos" />
                <span className="margin-bottom-5">
                  {' '}
                  Não existem garantias ou produto boleto escrow cadastrados nas condições operacionais{' '}
                </span>
              </>
            )
          )}
          {/* END - BOLETO ESCROW - END */}
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Paper
            elevation={0}
            className="box-paper pad-bot-0 pad-right-5"
            sx={isPendingComentarios ? { position: 'relative' } : {}}
          >
            {isPendingComentarios && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Grid container spacing={2} className="box-paper-header cursor-auto pad-10">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-0">
                <Box className="font-bold flex-box items-meio">
                  <Box className="box-icone-paper-header">
                    <CommentIcon />
                  </Box>
                  <Box className="margin-left-15">Comentários</Box>
                </Box>
                <Box className="margin-top-20">
                  <TextField
                    id="outlined-multiline-static"
                    value={valueComentario}
                    onChange={handleOnChangeComentario}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Box>
              </Grid>
              <Grid item md={4} lg={4} xl={4} />
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Button className="btn-outline-main font-bold" onClick={() => descartarComentario()}>
                  Descartar
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Button
                  className="btn-main"
                  onClick={() => salvarComentario()}
                  disabled={!escopoClienteDados.permite_incluir}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-0">
                <Box className="font-bold margin-top-20 margin-bottom-10">
                  <Box>Histórico</Box>
                </Box>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pad-0 borda-top-1 borda-cor-cinza-claro-2"
                sx={{ height: '260px', overflow: 'auto' }}
              >
                {lstHistoricoComentarios.map(item => (
                  <Grid
                    key={item._id}
                    container
                    className="pad-10 borda-bottom-1 borda-cor-cinza-claro-2"
                  >
                    <Grid item xl={11} lg={11} md={11} sm={10} xs={10} className="pad-0 pad-top-10">
                      <Box className="texto-pre-wrap">{item.comentario}</Box>
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={2}
                      xs={2}
                      className="texto-centro pad-0 pad-top-10"
                    >
                      {escopoClienteDados.permite_excluir &&
                        dadosUsuario &&
                        dadosUsuario.cod_usuario === item.cod_usuario_insert && (
                          <DeleteOutlineIcon
                            className="icon-trash-comentarios"
                            onClick={() => deletarComentario(item._id)}
                          />
                        )}
                    </Grid>

                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="pad-0 texto-cor-cinza pad-bot-10"
                    >
                      <Box className="text-transform-capitalize">
                        {textToLowerCase(item.nome_usuario)}
                      </Box>
                      <Box>{`${moment(item.data_insert).format('DD/MM/yyyy')} às ${moment(item.data_insert).format('HH:mm:ss')}`}</Box>
                    </Grid>
                  </Grid>
                ))}

                {lstHistoricoComentarios && Object.keys(lstHistoricoComentarios).length < 1 && (
                  <Grid container className="pad-10">
                    <Grid item xl={11} lg={11} md={11} sm={10} xs={10} className="pad-0 pad-top-10">
                      <Box className="texto-centro">Não há comentários para exibir</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <AlertMain {...controleFuncaoConfirmacao} />
    </Box>
  );
};

export default GestaoCliente;
