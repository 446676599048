import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Main from '../Main/Main';
import NovoLead from './NovoLead/NovoLead';
import StatusLead from './StatusLead/StatusLead';
import MotivosGanho from './MotivosGanho/MotivosGanho';
import MotivosPerda from './MotivosPerda/MotivosPerda';
import LeadsQualificados from './LeadsQualificados/LeadsQualificados';
import CanaisTrafego from './CanaisTrafego/CanaisTrafego';
import PropostasEsteira from './PropostasEsteira';
import ModalDashboard from './ModalDashboard/ModalDashboard';
import { getUsuariosPlaformaCrm, getTiposOrigem } from '../../api/crm';

const Dashboard = ({ escopoAcesso }) => {
  const [dadosModalDashboard, setDadosModalDashboard] = useState(false);
  const [atualizarDashboard, setAtualizarDashboard] = useState(0);
  const [dadosCardsLeads, setDadosCardsLeads] = useState({});
  const [lstUsuariosCrm, setLstUsuariosCrm] = useState([]);
  const [lstTiposOrigem, setLstTiposOrigem] = useState([]);
  const navigate = useNavigate();

  const atualizarTodosDashboards = () => {
    const value = atualizarDashboard;
    setAtualizarDashboard(value + 1);
  };

  useEffect(() => {
    getUsuariosPlaformaCrm()
      .then(res => {
        if (res.data.length > 0) {
          const newArray = res.data.map(usu => ({
            value: usu.CODUSUARIO,
            label: usu.NOME
          }));
          newArray.push({
            value: 'NAO_ATRIBUIDOS',
            label: 'NÃO ATRIBUIDOS'
          });
          setLstUsuariosCrm(newArray);
        }
      })
      .catch(() => {})
      .finally(() => {});

    getTiposOrigem()
      .then(res => {
        if (res.data.length > 0) {
          const newArray = res.data.map(tipos => ({
            value: tipos.descricao,
            label: tipos.descricao
          }));
          setLstTiposOrigem(newArray);
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  const irParaTelaCadastro = () => navigate('/cadastro?origem=plataforma_crm');

  return (
    <Main title="Dashboard" className="tela-dashboard" escopoAcesso={escopoAcesso}>
      <Box className="dashboard">
        <Typography className="texto-cor-principal font-bold-600" variant="h6">
          CRM
        </Typography>

        <Button className="btn-novo-lead" onClick={() => irParaTelaCadastro()}>
          Cadastrar novo Lead <AddIcon />
        </Button>

        <Grid className="container-pricipal" container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <NovoLead
              setDadosModalDashboard={setDadosModalDashboard}
              atualizarDashboard={atualizarDashboard}
            />

            <StatusLead
              setDadosModalDashboard={setDadosModalDashboard}
              atualizarDashboard={atualizarDashboard}
              setDadosCardsLeads={setDadosCardsLeads}
              lstUsuariosCrm={lstUsuariosCrm}
            />

            <Grid container>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              >
                <MotivosGanho />
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingLeft: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              >
                <MotivosPerda />
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              >
                <LeadsQualificados />
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingLeft: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              >
                <CanaisTrafego />
              </Grid>

              <PropostasEsteira />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <ModalDashboard
        dadosModalDashboard={dadosModalDashboard}
        setDadosModalDashboard={setDadosModalDashboard}
        atualizarTodosDashboards={atualizarTodosDashboards}
        dadosCardsLeads={dadosCardsLeads}
        lstUsuariosCrm={lstUsuariosCrm}
        lstTiposOrigem={lstTiposOrigem}
      />
    </Main>
  );
};
export default memo(Dashboard);
