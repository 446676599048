import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  // Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
// import TextFieldMui from '@mui/material/TextField';
import CircleIcon from '@mui/icons-material/Circle';
import ArticleIcon from '@mui/icons-material/Article';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';
import 'moment/locale/pt-br';
import LoaderMain from '../../../Shared/LoaderMain';
import {
  baixarParcerPdf,
  excluirParecerCliente,
  getLstTemplatesParecer,
  getPareceresClientes,
  getTiposPareceres,
  incluirParecerCliente,
  salvarParecerDocumentos
} from '../../../../api/formalizacao';
import AlertMain from '../../../Shared/AlertMain';
import CkEditorMain from '../../../Shared/CkEditorMain';
import ModalAlterarParecer from './ModalAlterarParecer/ModalAlterarParecer';
import { TemplatesTipoParecer } from './ModalAlterarParecer/TemplatesPareces';

const Pareceres = ({ cliente, setAlerta, setModalClienteDados, dadosPareceres, escopoClienteDados }) => {
  const [isPending, setIsPending] = useState(false);
  const [isPendingDownload, setPendingDownload] = useState(false);
  const [isPendingSalvarDocumento, setPendingSalvarDocumento] = useState(false);
  const [isPendingPareceres, setIsPendingPareceres] = useState(false);
  const [valueEncaminharPara, setValueEncaminharPara] = useState('');
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [msgTitleAlert, setMsgTitleAlert] = useState('');
  const [msgAlert, setMsgAlert] = useState('');
  const [checked, setChecked] = useState(false);
  const [valueTipoParecer, setValueTipoParecer] = useState('none');
  const [lstTipoParecer, setLstTipoParecer] = useState([]);
  const [mostrarTodosPareceres, setMostrarTodosPareceres] = useState('N');
  const [dadosObsPareceres, setDadosObsPareceres] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [valueCkEditor, setValueCkEditor] = useState(null);
  const [emailsDe, setEmailsDe] = useState([]);
  const [emailsPara, setEmailsPara] = useState([]);
  const [emailsCopia, setEmailsCopia] = useState([]);
  const [lstTemplatesParecer, setLstTemplatesParecer] = useState([]);
  const [valueTemplate, setValueTemplate] = useState('');
  const [valueAssunto, setValueAssunto] = useState('');
  const [dadosTemplateSelecionado, setDadosTemplateSelecionado] = useState(false);
  const [funcaoSucessoAlert, setFuncaoSucessoAlert] = useState(false);
  // COMENTADO PARA POSTERIORMENTE TER A OPCAO DE ENCAMINHAR EMAIL
  // const [lstEncaminharPara, setLstEncaminharPara] = useState([]);

  useEffect(() => {
    bucaLstTemplates();
    bucaLstEncaminhar();
    buscaTiposPareceres();
    buscaParecesCliente();
    mudaTipoParecer();
  }, []);

  useEffect(() => {
    mudaTipoParecer();
  }, [dadosPareceres]);

  useEffect(() => {
    buscaParecesCliente();
  }, [mostrarTodosPareceres]);

  useEffect(() => {
    if (checked) {
      resetarParametros();
    }
  }, [checked]);

  const resetarParametros = () => {
    setEmailsDe(['formalizacao@one7.com.vc']);
    setDadosTemplateSelecionado(false);
    setValueEncaminharPara('');
    setValueTemplate('');
    setValueAssunto('');
    setEmailsCopia([]);
    setEmailsPara([]);
  };

  const mudaTipoParecer = () => {
    if (dadosPareceres && Object.keys(dadosPareceres).length > 0) {
      setValueTipoParecer(dadosPareceres.cod_tipo_parecer);
    }
  };

  const buscaParecesCliente = () => {
    setIsPendingPareceres(true);
    getPareceresClientes(cliente.cnpj, mostrarTodosPareceres)
      .then(res => {
        if (res && res.data) {
          setDadosObsPareceres(res.data);
        }
      })
      .finally(() => {
        setIsPendingPareceres(false);
      });
  };

  const bucaLstTemplates = () => {
    getLstTemplatesParecer()
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstTemplatesParecer(res.data);
        } else {
          setLstTemplatesParecer([]);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const bucaLstEncaminhar = () => {
    // AINDA FALTA BUSCAR A LISTA DE USUARIOS
    // getTiposDocumentos()
    //   .then(res => {
    //     if (res.data && res.data.length > 0) {
    //       setLstEncaminharPara(res.data);
    //     } else {
    //       setLstEncaminharPara([]);
    //     }
    //   })
    //   .catch(() => {})
    //   .finally(() => {});
  };

  // COMENTADO PARA POSTERIORMENTE TER A OPCAO DE ENCAMINHAR EMAIL
  // const handleOnChangeEncaminharPara = (ev, newValue) => {
  //   if (newValue && newValue.value) {
  //     setValueEncaminharPara(newValue.value);
  //   } else {
  //     setValueEncaminharPara('');
  //   }
  // };

  const incluiParecerCliente = () => {
    if (!escopoClienteDados.permite_incluir) {
      return;
    }

    setIsPending(true);
    const params = {
      cod_cliente: cliente.cod_cliente,
      cpf_cnpj: cliente.cnpj,
      cod_tipo_parecer: valueTipoParecer,
      encaminhar: valueEncaminharPara,
      observacao: valueCkEditor
    };

    let msgSuccess = 'Parecer incluído com sucesso!';
    let preencherCampo = false;

    if (checked) {
      if (emailsDe.length === 0) {
        preencherCampo = 'emails-de';
      } else if (emailsPara.length === 0) {
        preencherCampo = 'emails-para';
      } else if (valueAssunto === '' || valueAssunto.trim() === '') {
        preencherCampo = 'assunto';
      }

      if (preencherCampo) {
        setIsPending(false);
        setTimeout(() => {
          document.getElementById(preencherCampo).focus();
        }, 100);
        return;
      }

      params.envia_email = 'S';
      if (Object.keys(dadosTemplateSelecionado).length > 0) {
        params.template_selecionado = dadosTemplateSelecionado;
        params.assunto_email = valueAssunto;
        params.corpo_email = valueCkEditor;
        params.titulo_email = dadosTemplateSelecionado.TITULO;
        params.de_email = emailsDe;
        params.para_email = emailsPara;
        params.copia_email = emailsCopia;
        msgSuccess = 'Parecer incluído, e email enviado com sucesso!';
      }
    }

    incluirParecerCliente(params)
      .then(() => {
        buscaParecesCliente();
        setValueCkEditor('');
        setValueTipoParecer('none');
        resetarParametros();
        setChecked(false);
        setAlerta({
          mensagem: msgSuccess,
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const perguntaExcluirParecer = codParecer => {
    if (escopoClienteDados.permite_excluir) {
      setFuncaoSucessoAlert(() => () => excluiParecerCliente(codParecer));
      setMsgTitleAlert('Atenção');
      setMsgAlert('Deseja realmente excluir este parecer?');
      setMostrarAlerta(true);
    }
  };

  const alterarParecer = dados => {
    if (escopoClienteDados.permite_alterar) {
      setModalClienteDados({
        openModal: true,
        tituloModal: 'Alterar parecer',
        maxWidth: 'md',
        conteudoModal: (
          <ModalAlterarParecer
            dadosParecer={dados}
            funcaoSucesso={() => buscaParecesCliente()}
            handleClose={() => setModalClienteDados({})}
          />
        )
      });
    }
  };

  const excluiParecerCliente = codParecer => {
    setIsPendingPareceres(true);
    excluirParecerCliente(codParecer)
      .then(() => {
        buscaParecesCliente();
        setAlerta({
          mensagem: 'Parecer excluído com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        // irá continuar o loader para o proxima requisição
      });
  };

  const buscaTiposPareceres = () => {
    setIsPending(true);

    getTiposPareceres()
      .then(res => {
        setLstTipoParecer(res.data);
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao buscar os Status, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleChangeCheckBox = event => {
    setChecked(event.target.checked);
  };

  const handleOnChangeTipoParecer = ev => {
    setValueTipoParecer(ev.target.value);

    const result = TemplatesTipoParecer(ev.target.value);
    if (result !== '') {
      setValueCkEditor(result);
    }
  };

  const handleClearButtonClick = () => {
    setValueCkEditor('');
    resetarParametros();
  };

  const handleExpand = id => () => {
    setExpanded(expanded !== id ? id : false);
  };

  const handleInputChange = (event, newValue) => {
    if (Array.isArray(newValue)) {
      const inputString = newValue.join(',');
      const emailsArray = inputString.split(/[;,]/).map(email => email.trim());
      const validEmails = emailsArray.filter(email => isValidEmail(email));
      setEmailsDe(validEmails);
    } else if (typeof newValue === 'string') {
      const emailsArray = newValue.split(/[;,]/).map(email => email.trim());
      const validEmails = emailsArray.filter(email => isValidEmail(email));
      setEmailsDe(validEmails);
    }
  };

  const handleInputChangePara = (event, newValue) => {
    if (Array.isArray(newValue)) {
      const inputString = newValue.join(',');
      const emailsArray = inputString.split(/[;,]/).map(email => email.trim());
      const validEmails = emailsArray.filter(email => isValidEmail(email));
      setEmailsPara(validEmails);
    } else if (typeof newValue === 'string') {
      const emailsArray = newValue.split(/[;,]/).map(email => email.trim());
      const validEmails = emailsArray.filter(email => isValidEmail(email));
      setEmailsPara(validEmails);
    }
  };

  const handleInputChangeCopia = (event, newValue) => {
    if (Array.isArray(newValue)) {
      const inputString = newValue.join(',');
      const emailsArray = inputString.split(/[;,]/).map(email => email.trim());
      const validEmails = emailsArray.filter(email => isValidEmail(email));
      setEmailsCopia(validEmails);
    } else if (typeof newValue === 'string') {
      const emailsArray = newValue.split(/[;,]/).map(email => email.trim());
      const validEmails = emailsArray.filter(email => isValidEmail(email));
      setEmailsCopia(validEmails);
    }
  };

  const isValidEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleChangeTemplate = event => {
    const { value } = event.target;
    setValueTemplate(value);
    const dadosTemplate = lstTemplatesParecer.find(template => template.value === value);

    if (dadosTemplate && Object.keys(dadosTemplate).length > 0) {
      setDadosTemplateSelecionado(dadosTemplate);
      setValueAssunto(dadosTemplate.ASSUNTO ?? '');
      setValueCkEditor(dadosTemplate.CORPO ?? '');
    }
  };

  const handleChangeAssunto = event => {
    const { value } = event.target;
    setValueAssunto(value);
  };

  const handleOnCLickPDF = (tipo, codParecer, tipoParecer) => {
    const tituloAlerta = 'Atenção';
    let mensagemAlerta = 'Deseja realmente ';

    if (tipo === 'PDF') {
      mensagemAlerta += 'realizar o download do parecer em PDF?';
      setFuncaoSucessoAlert(() => () => baixarParecerPDF(codParecer, tipoParecer));
    } else {
      mensagemAlerta += 'salvar o PDF em documentos?';
      setFuncaoSucessoAlert(() => () => salvarPDFDocumento(codParecer));
    }

    setMsgTitleAlert(tituloAlerta);
    setMsgAlert(mensagemAlerta);
    setMostrarAlerta(true);
  };

  const baixarParecerPDF = (codParecer, tipoParecer) => {
    setPendingDownload(true);
    baixarParcerPdf(codParecer)
      .then(res => {
        const nomeArquivo = `PARECER_${tipoParecer.replaceAll(' ', '_')}.pdf`;
        const dados = res.data;
        const url = window.URL.createObjectURL(new Blob([dados]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nomeArquivo);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao realizar o downaload do PDF, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingDownload(false);
      });
  };

  const salvarPDFDocumento = codParecer => {
    setPendingSalvarDocumento(true);
    const params = {
      cod_cliente: cliente.cod_cliente,
      cod_cliente_arquivos_tipo: 178, // ATA DE CREDITO
      cod_parecer: codParecer,
      cpf_cnpj: cliente.cnpj
    };
    salvarParecerDocumentos(params)
      .then(() => {
        setAlerta({
          mensagem: 'Parecer salvo com sucesso nos documentos!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao salvar o PDF, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingSalvarDocumento(false);
      });
  };

  const iconeLoadProgress = () => (
    <CircularProgress
      size={24}
      sx={{
        color: '#1976d2',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px'
      }}
    />
  );

  return (
    <Box className="fundo-cor-branca">
      <Paper elevation={0} className="box-paper" sx={isPending ? { position: 'relative' } : {}}>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        <Grid container spacing={2} className="box-paper-header cursor-auto">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={3} className="flex-box-items-direita">
            <Box className="box-icone-paper-header">
              <ArticleIcon />
            </Box>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={8} xs={9} className="titulo flex-box-items-esquerda">
            Relatório de pareceres
          </Grid>
        </Grid>

        <Grid
          container
          className="margin-bottom-20 margin-top-30"
          spacing={2}
          sx={{ pr: '10px', alignItems: 'end' }}
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-left-5 font-size-1-2-rem texto-negrito margin-bottom-15"
            >
              Incluir parecer
            </Typography>

            <FormControlLabel
              className="pad-5"
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChangeCheckBox}
                  sx={{
                    color: '#1A284D',
                    '&.Mui-checked': {
                      color: '#1A284D'
                    }
                  }}
                />
              }
              label="Enviar email com parecer"
              labelPlacement="end"
            />
          </Grid>

          {checked && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box className="box-email-parecer">
                <Grid container>
                  <Grid item xl={1} lg={1} md={1} sm={3} xs={3}>
                    <Typography
                      variant="subtitle1"
                      className="texto-cor-cinza-escuro pad-left-5 texto-direita texto-negrito margin-bottom-5 margin-top-15"
                    >
                      De:
                    </Typography>
                  </Grid>

                  <Grid item xl={11} lg={11} md={11} sm={9} xs={9}>
                    <Box className="pad-5 margin-left-10 margin-top-5">
                      <Autocomplete
                        id="emails-de"
                        clearIcon={false}
                        value={emailsDe}
                        options={[]}
                        freeSolo
                        onChange={handleInputChange}
                        multiple
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <Chip key={index} label={option} {...props({ index })} />
                          ))
                        }
                        renderInput={params => (
                          <TextField
                            variant="standard"
                            placeholder="ENTER para inserir o email"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item xl={1} lg={1} md={1} sm={3} xs={3}>
                    <Typography
                      variant="subtitle1"
                      className="texto-cor-cinza-escuro pad-left-5 texto-direita texto-negrito margin-bottom-5 margin-top-15"
                    >
                      Para:
                    </Typography>
                  </Grid>

                  <Grid item xl={11} lg={11} md={11} sm={9} xs={9}>
                    <Box className="pad-5 margin-left-10 margin-top-5">
                      <Autocomplete
                        id="emails-para"
                        clearIcon={false}
                        value={emailsPara}
                        options={[]}
                        freeSolo
                        onChange={handleInputChangePara}
                        multiple
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <Chip key={index} label={option} {...props({ index })} />
                          ))
                        }
                        renderInput={params => (
                          <TextField
                            variant="standard"
                            placeholder="ENTER para inserir o email"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item xl={1} lg={1} md={1} sm={3} xs={3}>
                    <Typography
                      variant="subtitle1"
                      className="texto-cor-cinza-escuro pad-left-5 texto-direita texto-negrito margin-bottom-5 margin-top-15"
                    >
                      Cc:
                    </Typography>
                  </Grid>

                  <Grid item xl={11} lg={11} md={11} sm={9} xs={9}>
                    <Box className="pad-5 margin-left-10 margin-top-5">
                      <Autocomplete
                        clearIcon={false}
                        value={emailsCopia}
                        options={[]}
                        freeSolo
                        onChange={handleInputChangeCopia}
                        multiple
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <Chip key={index} label={option} {...props({ index })} />
                          ))
                        }
                        renderInput={params => (
                          <TextField
                            variant="standard"
                            placeholder="ENTER para inserir o email"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item xl={1} lg={1} md={1} sm={3} xs={3}>
                    <Typography
                      variant="subtitle1"
                      className="texto-cor-cinza-escuro pad-left-5 texto-direita texto-negrito margin-bottom-5 margin-top-15"
                    >
                      Template:
                    </Typography>
                  </Grid>

                  <Grid item xl={6} lg={6} md={6} sm={9} xs={9}>
                    <Box className="pad-5 margin-left-10">
                      <FormControl fullWidth>
                        <Select
                          labelId="select-label"
                          id="select-template"
                          value={valueTemplate}
                          displayEmpty
                          className="select-template"
                          placeholder="Selecione um template"
                          onChange={handleChangeTemplate}
                          sx={{ background: 'white', height: '42px' }}
                        >
                          <MenuItem disabled value="">
                            Escolha um template...
                          </MenuItem>
                          {lstTemplatesParecer.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xl={5} lg={5} md={5} />

                  <Grid item xl={1} lg={1} md={1} sm={3} xs={3}>
                    <Typography
                      variant="subtitle1"
                      className="texto-cor-cinza-escuro pad-left-5 texto-direita texto-negrito margin-bottom-5 margin-top-15"
                    >
                      Assunto:
                    </Typography>
                  </Grid>

                  <Grid item xl={11} lg={11} md={11} sm={9} xs={9}>
                    <Box className="pad-5 margin-left-10 margin-top-10">
                      <TextField
                        id="assunto"
                        variant="standard"
                        value={valueAssunto}
                        onChange={handleChangeAssunto}
                        fullWidth
                        placeholder="Informe o assunto do email"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {/* COMENTADO PARA POSTERIORMENTE TER A OPCAO DE ENCAMINHAR EMAIL */}
          {/* {checked && (
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5 margin-top-15"
              >
                Encaminhar para:
              </Typography>

              <Autocomplete
                disablePortal
                id="TiposDocumentos"
                options={lstEncaminharPara || []}
                value={
                  valueEncaminharPara && lstEncaminharPara.length > 0
                    ? lstEncaminharPara.find(option => option.value === valueEncaminharPara)
                    : ''
                }
                onChange={handleOnChangeEncaminharPara}
                name="TiposDocumentos"
                className="select-documentos-formalizacao ajuste-height pad-left-5"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                forcePopupIcon
                freeSolo
                disabled={lstEncaminharPara && lstEncaminharPara.length === 0}
                renderInput={params => (
                  <TextFieldMui
                    {...params}
                    name="TiposDocumentos"
                    placeholder="Selecione para encaminhar..."
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off'
                    }}
                  />
                )}
              />
            </Grid>
          )} */}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box className="box-editor-parecer">
              <CkEditorMain
                valueCkEditor={valueCkEditor}
                setValueCkEditor={setValueCkEditor}
                textoPlaceholder="Descrição do parecer..."
              />
            </Box>
          </Grid>

          <Grid container sx={{ marginLeft: '20px' }}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

              <Box className="box-label margin-top-10">
                <Box className="label-select">Tipo:</Box>
              </Box>
              <Select
                className="select-with-icons"
                value={valueTipoParecer}
                onChange={handleOnChangeTipoParecer}
                fullWidth
                disabled={!escopoClienteDados.permite_alterar}
              >
                <MenuItem value="none" disabled>
                  Selecione um tipo de parecer
                </MenuItem>
                {lstTipoParecer.map(option => (
                  <MenuItem key={option.CODTIPOPARECER} value={option.CODTIPOPARECER}>
                    <CircleIcon
                      sx={{ fontSize: '18px !important', color: option.COR, marginRight: '10px' }}
                    />
                    {option.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xl={4} lg={2} md={2} sm={6} />

            <Grid item xl={1} lg={2} md={2} sm={6} xs={12}>
              <Button
                className="btn-outline-main"
                sx={{ height: '42px !important', marginTop: '40px' }}
                onClick={handleClearButtonClick}
              >
                Descartar
              </Button>
            </Grid>

            <Grid item xl={1} lg={2} md={2} sm={6} xs={12}>
              <Button
                className="btn-main"
                onClick={() => incluiParecerCliente()}
                sx={{ height: '42px !important', marginTop: '40px' }}
                disabled={
                  valueTipoParecer === 'none' ||
                  !valueCkEditor ||
                  valueCkEditor === '' ||
                  isPending ||
                  isPendingPareceres ||
                  !escopoClienteDados.permite_incluir
                }
              >
                Incluir Parecer
              </Button>
            </Grid>
          </Grid>

          <Grid container className="container-historico-pareceres">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-20">
              <Typography variant="subtitle1" className="font-size-1-2-rem texto-negrito">
                Histórico
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="row-historico-pareceres"
              sx={isPendingPareceres ? { position: 'relative' } : {}}
            >
              {isPendingPareceres && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
              {dadosObsPareceres.map(parecer => (
                <Accordion
                  key={parecer.CODPARECER}
                  expanded={expanded === parecer.CODPARECER}
                  className="accordion-pareceres"
                >
                  <AccordionSummary
                    className="cursor-auto items-centro-verticalmente"
                    sx={{ userSelect: 'auto' }}
                    expandIcon={
                      <ExpandMoreIcon
                        onClick={handleExpand(parecer.CODPARECER)}
                        className="cursor-pointer"
                      />
                    }
                  >
                    <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                      <Grid container>
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                          className="flex-box items-meio"
                          title={`${
                            escopoClienteDados.permite_excluir
                              ? 'Excluir parecer'
                              : 'Você não pode excluír este parecer'
                          }`}
                        >
                          <DeleteIcon
                            className={`${
                              escopoClienteDados.permite_excluir
                                ? 'cursor-pointer'
                                : 'elemento-desabilitado'
                            } texto-cor-principal`}
                            onClick={() => perguntaExcluirParecer(parecer.CODPARECER)}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                          className="flex-box items-meio"
                          title={`${
                            escopoClienteDados.permite_alterar
                              ? 'Alterar parecer'
                              : 'Você não pode alterar este parecer'
                          }`}
                        >
                          <EditIcon
                            className={`${
                              escopoClienteDados.permite_alterar
                                ? 'cursor-pointer'
                                : 'elemento-desabilitado'
                            } texto-cor-principal`}
                            onClick={() => alterarParecer(parecer)}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                          className="flex-box items-meio"
                          title={
                            parecer.DATAENVIOEMAIL
                              ? `Email enviado em: ${moment(parecer.DATAENVIOEMAIL).format(
                                  'DD/MM/yyyy [às] hh:mm:ss'
                                )}`
                              : 'Nenhum email enviado por este canal'
                          }
                        >
                          {parecer.DATAENVIOEMAIL ? (
                            <MarkEmailReadIcon className="texto-cor-principal" />
                          ) : (
                            <MailOutlineIcon className="texto-cor-principal" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={3}
                      xs={6}
                      className="flex-box items-meio text-overflow"
                      title={parecer.TIPOPARECER}
                    >
                      <CircleIcon
                        sx={{
                          fontSize: '18px !important',
                          color: parecer.CORPARECER,
                          marginRight: '10px'
                        }}
                      />{' '}
                      <b>{parecer.TIPOPARECER}</b>
                    </Grid>
                    <Grid item xl={4} lg={4} md={3} sm={3} xs={12} className="text-overflow camel-case">
                      Responsável:{' '}
                      <b title={parecer.USUAINCLUSAO}>{parecer.USUAINCLUSAO.toLowerCase()}</b>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className="texto-centro">
                      Data inclusão:{' '}
                      <b
                        title={`Data inclusão ${parecer.DATAINCLUSAOFORMATADA} ${
                          parecer.USUAALTERACAO
                            ? `\nÚltima alteração: ${parecer.DATAENTRADAFORMATADA} \npor ${parecer.USUAALTERACAO}`
                            : ''
                        }`}
                      >
                        {moment(parecer.DATAINCLUSAO).format('DD/MM/yyyy')}
                      </b>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="margin-bottom-10 texto-direita">
                      <Box className="mt-3">
                        <Button
                          className="btn-main hover-box-shadow"
                          sx={{
                            maxWidth: '190px',
                            width: '130px !important',
                            height: '42px !important',
                            margin: '0px 5px'
                          }}
                          disabled={isPendingDownload}
                          onClick={() =>
                            handleOnCLickPDF('PDF', parecer.CODPARECER, parecer.TIPOPARECER)
                          }
                        >
                          Baixar PDF
                          {isPendingDownload && <>{iconeLoadProgress()}</>}
                        </Button>
                        <Button
                          className="btn-primary"
                          sx={{
                            maxWidth: '220px',
                            height: '42px !important',
                            fontSize: '16px',
                            marginLeft: '5px',
                            borderRadius: '8px !important'
                          }}
                          disabled={isPendingSalvarDocumento}
                          onClick={() =>
                            handleOnCLickPDF('DOCUMENTO', parecer.CODPARECER, parecer.TIPOPARECER)
                          }
                        >
                          Salvar em Documentos
                          {isPendingSalvarDocumento && <>{iconeLoadProgress()}</>}
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      className="box-parecer-observacao"
                      dangerouslySetInnerHTML={{ __html: parecer.OBS }}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}

              {dadosObsPareceres.length >= 6 && mostrarTodosPareceres === 'N' ? (
                <Box className="texto-centro">
                  <Button
                    className="btn-main"
                    sx={{ maxWidth: '190px', height: '42px !important', marginTop: '15px' }}
                    onClick={() => setMostrarTodosPareceres('S')}
                  >
                    Mostrar todos
                  </Button>
                </Box>
              ) : (
                <Box className="texto-centro">
                  <Button
                    className="btn-main"
                    sx={{ maxWidth: '190px', height: '42px !important', marginTop: '15px' }}
                    onClick={() => setMostrarTodosPareceres('N')}
                  >
                    Mostrar menos
                  </Button>
                </Box>
              )}
            </Grid>
            {Object.keys(dadosObsPareceres).length === 0 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="row-historico-pareceres">
                <Box className="box-historico-pareceres">
                  <Typography variant="subtitle1" className="font-size-1-rem texto-cinza texto-centro">
                    Não há histórico de pareces
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      <AlertMain
        exibeAlerta={mostrarAlerta}
        setExibeAlerta={setMostrarAlerta}
        titleAlerta={msgTitleAlert}
        msgAlerta={msgAlert}
        funcaoConfirmacao={() => {
          if (funcaoSucessoAlert) {
            funcaoSucessoAlert();
          }
        }}
      />
    </Box>
  );
};

export default Pareceres;
