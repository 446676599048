import React from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { mascararCpfCnpj, textToLowerCase } from '../../../Utils/format';
import CopyValorTexto from '../../../Shared/CopyValorTexto';

const definicaoDeColunas = setMostrarCliente => {
  const config = [
    {
      field: 'CNPJ',
      headerName: 'CNPJ',
      flex: 1,
      minWidth: 150,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray',
      renderCell: params => (
        <Box className="flex-box texto-nowrap" title="Cliente desbloqueado">
          {params.row.DATADESBLOQUEIO && <LockOpenIcon sx={{ fontSize: '18px', mt: '-4px' }} />}
          {mascararCpfCnpj(String(params.row.CNPJ))}
          {CopyValorTexto({ texto: params.row.CNPJ })}
        </Box>
      )
    },
    {
      field: 'CLIENTE',
      headerName: 'Cliente',
      flex: 1,
      minWidth: 160,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados-esquerda data-grid-light-gray camel-case border-radius-left',
      renderCell: params => (
        <>
          {params.row.BLOQUEIOSWARNING === 'EXIBIR_BLOQUEIO' ? (
            <NotificationImportantIcon sx={{ fontSize: '24px', mt: '-4px', color: 'red' }} />
          ) : (
            params.row.BLOQUEIOSWARNING === 'EXIBIR_WARNING_BLOQUEIO' && (
              <NotificationImportantIcon sx={{ fontSize: '24px', mt: '-4px', color: 'orange' }} />
            )
          )}
          {'   '}
          {params.row.CLIENTE}
        </>
      )
    },
    {
      field: 'PLATAFORMA',
      headerName: 'Plataforma',
      flex: 1,
      minWidth: 140,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray'
    },
    {
      field: 'NOMEBROKER',
      headerName: 'Gerente',
      flex: 1,
      minWidth: 140,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray text-transform-capitalize',
      renderCell: params => <>{textToLowerCase(params.row.NOMEBROKER)}</>
    },
    {
      field: 'NOMERESPONSAVEL',
      headerName: 'Responsável',
      flex: 1,
      minWidth: 140,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray text-transform-capitalize',
      renderCell: params => <>{textToLowerCase(params.row.NOMERESPONSAVEL)}</>
    },
    {
      field: 'TIPOMODALIDADE',
      headerName: 'Modalidade Op.',
      flex: 1,
      minWidth: 120,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray text-transform-capitalize',
      renderCell: params => <>{textToLowerCase(params.row.TIPOMODALIDADE)}</>
    },
    {
      field: 'ACELERACAO',
      headerName: 'Aceleração',
      flex: 1,
      minWidth: 100,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray text-transform-capitalize',
      renderCell: params => <>{textToLowerCase(params.row.ACELERACAO === 'S' ? 'Sim' : 'Não')}</>
    },
    {
      field: 'DIASETAPAATUAL',
      headerName: 'Etapa Atual',
      flex: 1,
      minWidth: 100,
      headerClassName: 'data-grid-header-centralizado-esquerda font-bold-header',
      cellClassName: 'data-grid-light-gray',
      renderCell: params => (
        <Typography
          className="texto-centro flex-box-items-centralizados items-centralizados"
          variant="h7"
          title={moment(params.row.DATAETAPAATUAL).format('DD/MM/yyyy HH:mm:ss')}
        >
          {`há ${params.row.DIASETAPAATUAL} dia(s)`}
        </Typography>
      )
    },
    {
      field: 'DTENTRADA',
      headerName: 'Data Entrada',
      flex: 1,
      minWidth: 100,
      headerClassName: 'data-grid-header-centralizado-esquerda font-bold-header',
      cellClassName: 'data-grid-light-gray',
      renderCell: params => (
        <Typography
          className="texto-centro flex-box-items-centralizados items-centralizados"
          variant="h7"
          title={moment(params.row.DTENTRADA).format('DD/MM/yyyy HH:mm:ss')}
        >
          {moment(params.row.DTENTRADA).format('DD/MM/yyyy')}
        </Typography>
      )
    },
    {
      field: 'TEMPO',
      headerName: 'SLA Total',
      flex: 1,
      minWidth: 100,
      headerClassName: 'data-grid-header-centralizado-esquerda font-bold-header',
      cellClassName: 'data-grid-light-gray',
      renderCell: params => (
        <Typography
          className="texto-centro flex-box-items-centralizados items-centralizados"
          variant="h7"
          DTENTRADA
          title={
            params.row.DIASDECORRIDOS < 0
              ? `${params.row.DIASDECORRIDOS} dia(s)`
              : `há ${params.row.TEMPODECORRIDO} dia(s)`
          }
        >
          {params.row.DIASDECORRIDOS < 0
            ? `${params.row.DIASDECORRIDOS} dia(s)`
            : `há ${params.row.TEMPODECORRIDO} dia(s)`}
        </Typography>
      )
    },
    {
      field: 'TIPO',
      headerName: 'Tipo',
      flex: 1,
      maxWidth: 150,
      minWidth: 120,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName:
        'items-centralizados data-grid-light-gray border-radius-right font-bold text-transform-capitalize',
      renderCell: params => <>{textToLowerCase(params.row.TIPO)}</>
    },
    {
      field: 'abrir_cliente',
      headerName: '',
      flex: 1,
      maxWidth: 100,
      minWidth: 80,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray border-radius-right',
      renderCell: params => (
        <Typography
          className="novas-entradas-abrir"
          variant="h7"
          onClick={() =>
            setMostrarCliente({
              cnpj: params.row.CNPJ,
              codRelacionamentosFormalizacao: params.row.id
            })
          }
        >
          Abrir
          <ChevronRightIcon sx={{ fontSize: '20px', mt: '-2px' }} />
        </Typography>
      )
    }
  ];
  return config;
};

export default definicaoDeColunas;
