import React, { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, Grid, Radio, TextField, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import 'moment/locale/pt-br';
import { styled } from '@mui/system';
import LoaderMain from '../../../../Shared/LoaderMain';
import { alterarStatusFormalizacao, getTiposMotivosBloqueio } from '../../../../../api/formalizacao';
import { StatusFormalizacao } from '../../../../Shared/Enums/StatusFormalizacao';
import { TIPOSPARECER } from '../../../../Shared/Enums/TiposParecer';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&::before': {
      display: 'none'
    }
  })
);

const AccordionSummary = styled(props => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderBottom: '1px solid rgba(0, 0, 0, .125)'
}));

const StatusParecer = ({
  cnpj,
  codStatusCliente,
  codCliente,
  codRelacionamentosFormalizacao,
  buscaHistoricoComentarios,
  atualizarTodosDashboards,
  bucaDadosPainelAnalista,
  setValueMotivoBloqueio,
  codStatusFormalizacao,
  funcaoConfirmacao,
  setDadosPareceres,
  labelSelecionado,
  handleChangeAba,
  handleClose,
  setAlerta
}) => {
  const [isPending, setIsPending] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [valueComentario, setValueComentario] = useState('');
  const [expandedBloqueado, setExpandedBloqueado] = useState(false);
  const [mostraBtnVoltar, setMostraBtnVoltar] = useState(false);
  const [mostraMotivosBloqueados, setMostraMotivosBloqueados] = useState(false);
  const [lstMotivosBloqueios, setLstMotivosBloqueios] = useState([]);

  useEffect(() => {
    if (codStatusFormalizacao === StatusFormalizacao.BLOQUEADOS) {
      setIsPending(true);
      setMostraMotivosBloqueados(true);

      getTiposMotivosBloqueio()
        .then(res => {
          setLstMotivosBloqueios(res.data);
        })
        .catch(() => {
          setAlerta({
            mensagem: 'Ocorreu um erro ao buscar os motivos de bloqueio, contate o suporte!',
            tipo: 'error'
          });
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, []);

  useEffect(() => {
    if (expanded && expanded !== 'panel2') {
      setValueComentario('');
    }
  }, [expanded]);

  const salvarStatus = () => {
    setIsPending(true);

    const params = {
      cod_relacionamentos_formalizacao: codRelacionamentosFormalizacao,
      cliente_status_formalizacao: codStatusFormalizacao,
      cod_cliente: codCliente,
      cpf_cnpj_cliente: cnpj,
      cod_status_cliente: codStatusCliente
    };

    if (valueComentario && valueComentario.length > 0) {
      params.comentario = valueComentario;
    }

    if (expandedBloqueado) {
      params.cod_motivo_bloqueio = expandedBloqueado;
      setValueMotivoBloqueio(expandedBloqueado);
    }

    alterarStatusFormalizacao(params)
      .then(() => {
        bucaDadosPainelAnalista();
        atualizarTodosDashboards();
        funcaoConfirmacao();
        handleClose();

        if (expanded === 'panel2') {
          buscaHistoricoComentarios();
        }

        if (expanded === 'panel3') {
          setDadosPareceres({
            cliente_status_formalizacao: codStatusFormalizacao,
            cod_tipo_parecer: TIPOSPARECER.MUDANCA_DE_STATUS
          });
          handleChangeAba('', 6);
        }
        setAlerta({
          mensagem: 'Status alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro ao mudar o status, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleChangeAccordion = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeAccordionBloqueado = panel => (event, newExpanded) => {
    setExpandedBloqueado(newExpanded ? panel : false);
  };

  const handleOnChangeComentario = event => {
    setValueComentario(event.target.value);
  };

  const mostraTelaStatusPareceres = () => {
    setExpanded(false);
    setMostraMotivosBloqueados(false);
    setMostraBtnVoltar(true);
  };

  const mostraTelaBloqueados = () => {
    setMostraMotivosBloqueados(true);
    setMostraBtnVoltar(false);
  };

  return (
    <Box sx={isPending ? { position: 'relative', minHeight: '220px' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      {mostraMotivosBloqueados ? (
        <Grid container className="margin-bottom-10">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="margin-top-10">
            Escolha o motivo do bloqueio:
            <Box className="font-size-15 margin-top-10">
              {lstMotivosBloqueios.map(motivo => (
                <Accordion
                  key={motivo.value}
                  expanded={expandedBloqueado === motivo.value}
                  onChange={handleChangeAccordionBloqueado(motivo.value)}
                >
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Radio
                      checked={expandedBloqueado === motivo.value}
                      value={motivo.value}
                      name="radio-buttons"
                      sx={{
                        color: '#1A284D',
                        '&.Mui-checked': {
                          color: '#1A284D'
                        }
                      }}
                    />
                    <Box className="flex-box flex-box items-centro-verticalmente">
                      <Box className="font-bold">{motivo.label}</Box>
                    </Box>
                  </AccordionSummary>
                </Accordion>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pad-10">
            <Button
              className="btn-outline-main font-bold margin-top-20"
              disabled={isPending}
              onClick={() => {
                handleClose();
                setExpanded(false);
              }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pad-10">
            <Button
              className="btn-main margin-top-20"
              onClick={() => mostraTelaStatusPareceres()}
              disabled={!expandedBloqueado}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="margin-bottom-10">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="margin-top-10">
            Defina o comportamento e visibilidade do status atual
            <Box className="font-size-15 margin-top-10">
              <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Radio
                    checked={expanded === 'panel1'}
                    value="a"
                    name="radio-buttons"
                    sx={{
                      color: '#1A284D',
                      '&.Mui-checked': {
                        color: '#1A284D'
                      }
                    }}
                  />
                  <Box>
                    <Box className="font-bold">Mudar status</Box>
                    <Box>
                      Use essa opção se você quer mover o cliente para o status selecionado sem adicionar
                      uma descrição
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="pad-10" sx={{ marginLeft: '40px' }}>
                    <Typography className="font-bold text-uppercase" sx={{ color: '#0085FF' }}>
                      MUDAR STATUS PARA {labelSelecionado}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Radio
                    checked={expanded === 'panel2'}
                    value="a"
                    name="radio-buttons"
                    sx={{
                      color: '#1A284D',
                      '&.Mui-checked': {
                        color: '#1A284D'
                      }
                    }}
                  />
                  <Box>
                    <Box className="font-bold">Mudar status e incluir comentário</Box>
                    <Box>
                      Use essa opção se você quer mover o cliente para o status selecionado e incluir
                      comentários para o seu time
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="pad-10" sx={{ marginLeft: '40px' }}>
                    <Typography className="font-bold text-uppercase" sx={{ color: '#0085FF' }}>
                      MUDAR STATUS PARA {labelSelecionado}
                    </Typography>
                    <Box className="margin-top-10">
                      <TextField
                        id="outlined-multiline-comentario"
                        value={valueComentario}
                        onChange={handleOnChangeComentario}
                        placeholder="Comentários"
                        fullWidth
                        multiline
                        rows={4}
                        error={valueComentario.length < 1}
                        inputRef={input => input && input.focus()}
                      />
                      {valueComentario.length < 1 && (
                        <FormHelperText error>Campo obrigatório!</FormHelperText>
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Radio
                    checked={expanded === 'panel3'}
                    value="a"
                    name="radio-buttons"
                    sx={{
                      color: '#1A284D',
                      '&.Mui-checked': {
                        color: '#1A284D'
                      }
                    }}
                  />
                  <Box>
                    <Box className="font-bold">Mudar status e incluir parecer</Box>
                    <Box>
                      Use essa opção se você quer mover o cliente para o status selecionado e adicionar
                      um parecer para todos
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="pad-10" sx={{ marginLeft: '40px' }}>
                    <Typography className="font-bold text-uppercase" sx={{ color: '#0085FF' }}>
                      MUDAR STATUS PARA {labelSelecionado}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pad-10">
            {mostraBtnVoltar ? (
              <Button
                className="btn-outline-main font-bold margin-top-20"
                disabled={isPending}
                onClick={() => mostraTelaBloqueados()}
              >
                Voltar
              </Button>
            ) : (
              <Button
                className="btn-outline-main font-bold margin-top-20"
                disabled={isPending}
                onClick={() => {
                  handleClose();
                  setExpanded(false);
                }}
              >
                Cancelar
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pad-10">
            <Button
              className="btn-main margin-top-20"
              onClick={() => salvarStatus()}
              disabled={isPending || !expanded || (expanded === 'panel2' && valueComentario.length < 1)}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default StatusParecer;
