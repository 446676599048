import React from 'react';
import { Box, Grid } from '@mui/material';
import { ContactsOutlined } from '@mui/icons-material';
import Contatos from '../../shared/Contatos';

const DadosContatos = ({
  lstDados,
  setDadosAposIncluirAlterar,
  setAlerta,
  setModalClienteDados,
  escopoClientePessoas
}) => (
  <Box className="enderecos-pessoa">
    <Grid container spacing={2} className="box-paper-header" sx={{ mb: '15px', mt: '10px' }}>
      <Grid item xl={4} lg={4} md={4} sm={3} xs={3} className="flex-box-items-direita">
        <Box className="box-icone-paper-header icone-gray">
          <ContactsOutlined />
        </Box>
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={9} className="titulo titulo-gray flex-box-items-esquerda">
        Contatos
      </Grid>
    </Grid>
    <Contatos
      dadosContatos={lstDados.contatos ?? {}}
      dadosGerais={lstDados}
      setDadosGerais={setDadosAposIncluirAlterar}
      setAlerta={setAlerta}
      setModalClienteDados={setModalClienteDados}
      escopoContato={escopoClientePessoas}
      telaPai="DadosPessoa"
    />
  </Box>
);
export default DadosContatos;
