import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import BoxInputMain from './BoxInputMain';

const CheckBoxMain = ({ field: { ...fields }, form: { errors }, ...props }) => {
  const {
    controlLabelCheckbox,
    labelCheckbox,
    corCheckBox,
    classLabelCheckBox,
    children,
    className,
    erro = '',
    field,
    onChangeChkbx,
    onBlurChkbx,
    classNameLabel = '',
    ...restProps
  } = props;

  const onChangeChkbxField = event => {
    if (typeof fields.onChange === 'function') {
      fields.onChange(event);
    }
    if (typeof onChangeChkbx === 'function') {
      onChangeChkbx(event);
    }
  };

  const onBlurChkbxField = event => {
    if (typeof fields.onBlur === 'function') {
      fields.onBlur(event);
    }
    if (typeof onBlurChkbx === 'function') {
      onBlurChkbx(event);
    }
  };

  const valorChkbx =
    fields.value && fields.value !== '' ? fields.value : 'true';
  const nomeInput = fields.name;
  let erroTexto = errors[nomeInput] ? errors[nomeInput] : '';
  erroTexto = erro !== '' ? erro : erroTexto;
  const erroExiste = erroTexto !== '' ? true : false;
  const corChkBox =
    corCheckBox && corCheckBox !== '' ? corCheckBox : 'checkbox-cor-principal';
  let paddingCheckBox = '';
  let classeLabelPrincipal = '';
  if (corChkBox === 'checkbox-cor-principal') {
    paddingCheckBox = ' pad-0 ';
    classeLabelPrincipal = ' label-classe-principal ';
  }

  return (
    <BoxInputMain
      {...restProps}
      boxClassName={labelCheckbox ? 'margin-top-10 ' : ` ${paddingCheckBox}`}
    >
      {controlLabelCheckbox ? (
        <FormControlLabel
          className={classNameLabel}
          control={
            <Checkbox
              {...restProps}
              value={valorChkbx}
              name={fields.name}
              onChange={onChangeChkbxField}
              onBlur={onBlurChkbxField}
              className={`${className ? className : ''} ${corChkBox}`}
            />
          }
          label={
            <Typography
              variant="subtitle1"
              className={`${
                erroExiste ? 'texto-danger' : 'texto-cor-principal'
              } ${classLabelCheckBox} display-inline-block ${classeLabelPrincipal}`}
            >
              {controlLabelCheckbox}
            </Typography>
          }
        />
      ) : (
        <Checkbox
          {...restProps}
          value={valorChkbx}
          name={fields.name}
          onChange={onChangeChkbxField}
          onBlur={onBlurChkbxField}
          className={`${className ? className : ''} ${corChkBox} margin-neg-topleft-10`}
        />
      )}
      {labelCheckbox ? (
        <Typography
          variant="subtitle1"
          className={`${
            erroExiste ? 'texto-danger' : 'texto-cor-principal'
          } ${classLabelCheckBox} display-inline-block ${classeLabelPrincipal}`}
        >
          {labelCheckbox}
        </Typography>
      ) : (
        ''
      )}
      {erroTexto ? (
        <Typography className="texto-danger label-padding">
          {erroTexto}
        </Typography>
      ) : (
        ''
      )}
    </BoxInputMain>
  );
};
export default CheckBoxMain;
