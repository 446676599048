import React from 'react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';
import ChevronLeftIcon from '@mui/icons-material/ArrowBackIos';
import one7LogoCollapsed from '../../assets/img/one7-logo_collapsed.png';
import one7LogoWhite from '../../assets/img/logo-one7-white.svg';

const Navbar = ({ menuOpen, handleDrawerToggle, onlySmallScreen }) => (
  <AppBar
    position="fixed"
    className="nav-bar-principal fundo-cor-principal"
    sx={{
      zIndex: 2000,
      minHeight: '68px'
    }}
  >
    <Toolbar>
      <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
        {onlySmallScreen ? (
          <MenuIcon />
        ) : (
          <>
            <img
              className={menuOpen ? 'img-logo-main-one7' : 'img-logo-main-collapsed-one7'}
              src={menuOpen ? one7LogoWhite : one7LogoCollapsed}
              alt="logo one7"
            />
            {menuOpen ? (
              <ChevronLeftIcon sx={{ fontSize: '20px' }} />
            ) : (
              <ChevronRightIcon sx={{ fontSize: '20px' }} />
            )}
          </>
        )}
      </IconButton>
    </Toolbar>
  </AppBar>
);

export default Navbar;
