import React, { memo, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid, Typography } from '@mui/material';
import TextFieldMui from '@mui/material/TextField';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Add, Search } from '@mui/icons-material';
import BoxPaper from './BoxPaper';
import { alterarStatusAditivo, getDadosAditivosGrid } from '../../../../api/formalizacao';
import LoaderMain from '../../../Shared/LoaderMain';
import AlertMain from '../../../Shared/AlertMain';
import FormGarantia from './Formularios/FormGarantia';
import DefinicaoDeColunasAditivos from './definicoes-grid-aditivos';
import AdicionarAditivo from './Formularios/AdicionarAditivo';

const Aditivos = ({
  dadosContrato,
  lstTipos,
  escopoClienteDados,
  setAlerta,
  setModalClienteDados,
  setMostraDetalhesAditivo
}) => {
  const [isPending, setIsPending] = useState(false);
  const [lstDadosGrid, setLstDadosGrid] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [paginationModelGridLeads, setPaginationModelGridLeads] = useState({
    pageSize: 5,
    page: 0
  });

  const [dadosAlteracao, setDadosAlteracao] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [msgTitleAlert, setMsgTitleAlert] = useState('');
  const [valueStatus, setValueStatus] = useState('A');
  const [dadosRow, setDadosRow] = useState(null);
  const [msgAlert, setMsgAlert] = useState('');

  useEffect(() => {
    bucaDadosGridFiltro();
  }, []);

  const bucaDadosGridFiltro = () => {
    const params = {};
    params.cod_cliente_formalizacao = dadosContrato.id;
    params.status = valueStatus;
    buscarDadosGrid(params);
  };

  const buscarDadosGrid = (params = false) => {
    if (!params) {
      return;
    }

    setIsPending(true);
    getDadosAditivosGrid(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstDadosGrid(res.data);
        } else {
          setLstDadosGrid([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleOnChangeAditivo = (event, idValue) => {
    if (escopoClienteDados && escopoClienteDados.permite_alterar) {
      const statusValue = event.target.checked ? 'A' : 'I';
      setDadosAlteracao({ status: statusValue, cod_cliente_formalizacao_aditivos: idValue });

      setMsgTitleAlert('Atenção');
      setMsgAlert(`Deseja realmente ${event.target.checked ? 'ativar' : 'inativar'} este aditivo?`);
      setMostrarAlerta(true);
    }
  };

  const alteraStatusAditivo = () => {
    if (!dadosAlteracao) {
      return;
    }

    setIsPending(true);
    alterarStatusAditivo(dadosAlteracao)
      .then(() => {
        bucaDadosGridFiltro();
        setDadosAlteracao(false);
        setAlerta({
          mensagem: 'Status do aditivo alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro, contate o suporte!',
          tipo: 'error'
        });
        setIsPending(false);
      })
      .finally(() => {
        // IRA CONTINUAR O LOAD ATE O PROXIMO CARREGAMENTO
        // setIsPending(false);
      });
  };

  const modalAdicionarAditivo = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Adicionar aditivo',
      maxWidth: 'md',
      conteudoModal: (
        <AdicionarAditivo
          dadosContrato={dadosContrato}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          handleClose={() => setModalClienteDados({})}
          funcaoSucesso={() => bucaDadosGridFiltro()}
        />
      )
    });
  };

  const modalIncluirGarantia = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Adicionar garantia',
      maxWidth: 'md',
      conteudoModal: (
        <FormGarantia
          dadosAditivo={dadosRow}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  const mostrarDetalhesAditivo = () => {
    sessionStorage.setItem('dados-row-aditivos', JSON.stringify(dadosRow));
    setMostraDetalhesAditivo(true);
  };

  const handleOnChangeStatus = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueStatus(newValue.value);
    } else {
      setValueStatus('');
    }
  };

  const labelIconeAditivo = () => (
    <>
      Adicionar <Add />
    </>
  );

  const lstStatus = [
    { label: 'Ativo', value: 'A' },
    { label: 'Inativo', value: 'I' }
  ];

  return (
    <Box className="box-paper-body-detalhes">
      <Box className="box-paper-header-detalhes cursor-pointer">
        <BoxPaper
          iconeTitulo={<AssignmentTurnedInIcon />}
          labelTitulo="Aditivos"
          mostrarBotoes={true}
          textButton={labelIconeAditivo()}
          funcaoIncluirContratos={modalAdicionarAditivo}
        >
          <Grid
            container
            className="margin-bottom-20 margin-top-30"
            spacing={2}
            sx={{ pr: '10px', alignItems: 'end', cursor: 'auto' }}
          >
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
              >
                Status:
              </Typography>

              <Autocomplete
                disablePortal
                id="plataformas"
                options={lstStatus || []}
                value={
                  valueStatus && lstStatus.length > 0
                    ? lstStatus.find(option => option.value === valueStatus)
                    : ''
                }
                onChange={handleOnChangeStatus}
                name="plataformas"
                className="select-documentos-formalizacao ajuste-height pad-left-5"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                forcePopupIcon
                freeSolo
                disabled={lstStatus.length === 0}
                renderInput={params => (
                  <TextFieldMui
                    {...params}
                    name="plataformas"
                    placeholder="Todos"
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off'
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
              />
              <Button
                className="btn-main"
                onClick={() => bucaDadosGridFiltro()}
                sx={{ maxWidth: '140px', height: '43px !important' }}
              >
                Filtrar &nbsp; <Search />
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            className="margin-top-30"
            spacing={2}
            sx={
              isPending
                ? { position: 'relative', pr: '10px', alignItems: 'end' }
                : { pr: '10px', alignItems: 'end' }
            }
          >
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <DataGrid
                className="data-grid-formalizacao-documentos"
                columns={DefinicaoDeColunasAditivos(
                  handleOnChangeAditivo,
                  escopoClienteDados,
                  modalIncluirGarantia,
                  mostrarDetalhesAditivo,
                  dadosRow,
                  setDadosRow
                )}
                rows={lstDadosGrid ?? []}
                disableRowSelectionOnClick
                getRowHeight={() => 'auto'}
                autoHeight
                disableColumnMenu
                sx={{
                  margin: '15px 0 0 0',
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'inherit !important'
                  }
                }}
                localeText={{
                  ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: 'Não há aditivos cadastrados'
                }}
                sortingOrder={['desc', 'asc']}
                sortModel={sortModel}
                onSortModelChange={model => setSortModel(model)}
                pageSizeOptions={[5, 25, 100]}
                paginationModel={paginationModelGridLeads}
                onPaginationModelChange={setPaginationModelGridLeads}
              />
            </Grid>
          </Grid>

          <AlertMain
            exibeAlerta={mostrarAlerta}
            setExibeAlerta={setMostrarAlerta}
            titleAlerta={msgTitleAlert}
            msgAlerta={msgAlert}
            funcaoConfirmacao={alteraStatusAditivo}
          />
        </BoxPaper>
      </Box>
    </Box>
  );
};
export default memo(Aditivos);
