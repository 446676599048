import React, { memo, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Main from '../Main/Main';
import StatusRelacionamentos from './StatusRelacionamentos/StatusRelacionamentos';
import ModalDashboardRelacionamentos from './ModalDashboardRelacionamentos/ModalDashboardRelacionamentos';
import NovoRelacionamento from './NovoRelacionamento/NovoRelacionamento';
import { getUsuariosPlaformaCrmContato } from '../../api/relacionamentos';

const DashboardRelacionamentos = ({ escopoAcesso }) => {
  const [dadosModalDashboardRelacionamentos, setDadosModalDashboardRelacionamentos] = useState(false);
  const [atualizarDashboard, setAtualizarDashboard] = useState(0);
  const [dadosCardsRelacionamentos, setDadosCardsRelacionamentos] = useState({});
  const [lstUsuariosCrm, setLstUsuariosCrm] = useState([]);

  const atualizarTodosDashboards = () => {
    const value = atualizarDashboard;
    setAtualizarDashboard(value + 1);
  };

  useEffect(() => {
    getUsuariosPlaformaCrmContato()
      .then(res => {
        if (res.data.length > 0) {
          const newArray = res.data.map(usu => ({
            value: usu.CODUSUARIO,
            label: usu.NOME
          }));
          newArray.push({
            value: 'NAO_ATRIBUIDOS',
            label: 'NÃO ATRIBUIDOS'
          });
          setLstUsuariosCrm(newArray);
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  return (
    <Main title="DashboardRelacionamentos" className="tela-dashboard" escopoAcesso={escopoAcesso}>
      <Box className="dashboard">
        <Typography className="texto-cor-principal font-bold-600" variant="h6">
          CRM Relacionamentos
        </Typography>

        <Grid className="container-pricipal" container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <NovoRelacionamento
              setDadosModalDashboardRelacionamentos={setDadosModalDashboardRelacionamentos}
              atualizarDashboard={atualizarDashboard}
            />

            <StatusRelacionamentos
              setDadosModalDashboardRelacionamentos={setDadosModalDashboardRelacionamentos}
              atualizarDashboard={atualizarDashboard}
              setDadosCardsRelacionamentos={setDadosCardsRelacionamentos}
              lstUsuariosCrm={lstUsuariosCrm}
            />
            <Grid container>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              />

              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingLeft: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              />

              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              />

              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingLeft: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ModalDashboardRelacionamentos
        dadosModalDashboardRelacionamentos={dadosModalDashboardRelacionamentos}
        setDadosModalDashboardRelacionamentos={setDadosModalDashboardRelacionamentos}
        atualizarTodosDashboards={atualizarTodosDashboards}
        dadosCardsRelacionamentos={dadosCardsRelacionamentos}
        lstUsuariosCrm={lstUsuariosCrm}
      />
    </Main>
  );
};
export default memo(DashboardRelacionamentos);
