import { Alert, Box, Chip, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { Add, CurrencyExchange, ModeEditOutlined } from '@mui/icons-material';
import LoaderMain from '../../../../Shared/LoaderMain';
import {
  alterarContaCorrentePadraoPagamento,
  alterarStatusContaCorrente,
  listarContasCorrente
} from '../../../../../api/formalizacao';
import LinhaDados from '../../PessoasCliente/DadosPessoa/LinhaDados';
import BoxPaper from '../../shared/BoxPaper';
import { IsEmpty, NotIsEmpty } from '../../../../Utils/format';
import ButtonMain from '../../../../Shared/ButtonMain';
import IOSSwitch from '../../shared/IOSSwitch';
import FormContaCorrente from './FormContaCorrente';

const ContasCorrente = ({
  cod_cliente,
  lstBancosAgencia,
  setAlerta,
  setAlertConfirm,
  setModalClienteDados,
  escopoDadosBancarios
}) => {
  const [lstContasCorrentes, setLstContasCorrentes] = useState([]);
  const [filtroAtivo, setFiltroAtivo] = useState(true);
  const [isPending, setPending] = useState(false);

  const valorIndefinido = <em className="texto-cor-cinza">-</em>;

  const camposContaCorrente = [
    { key: 'descrAgencia', label: 'Agência:' },
    { key: 'conta', label: 'Conta:' },
    { key: 'digitoConta', label: 'Digito:' },
    { key: 'descrTipoConta', label: 'Tipo de conta:' },
    { key: 'inclusao', label: 'Inclusão:' },
    { key: 'alteracao', label: 'Última alteração:' }
  ];

  useEffect(() => {
    buscarContasCorrente();
  }, [filtroAtivo]);

  const buscarContasCorrente = () => {
    const filtros = { ativa: filtroAtivo ? 'S' : 'N' };
    setPending(true);
    listarContasCorrente(cod_cliente, filtros)
      .then(res => {
        setLstContasCorrentes(res.data ?? []);
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => setPending(false));
  };

  const incluirAlterarContaCorrente = params => {
    setModalClienteDados({
      openModal: true,
      tituloModal: NotIsEmpty(params._id) ? 'Alterar Conta Corrente' : 'Incluir Conta Corrente',
      conteudoModal: (
        <FormContaCorrente
          formDados={params}
          cod_cliente={cod_cliente}
          buscarContasCorrente={buscarContasCorrente}
          lstBancosAgencia={lstBancosAgencia}
          setAlerta={setAlerta}
          handleClose={() => setModalClienteDados({})}
        />
      ),
      onClose: false
    });
  };

  const alteraContaCorrenteAtiva = conta => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: `Deseja realmente ${conta.ativa === 'S' ? 'inativar' : 'ativar'} esta conta corrente do cliente?`,
      funcaoConfirmacao: () => {
        const params = {
          cod_cliente: cod_cliente,
          ativa: conta.ativa === 'S' ? 'N' : 'S',
          _id: conta._id
        };
        setPending(true);
        alterarStatusContaCorrente(params)
          .then(res => {
            buscarContasCorrente();
            setAlerta({
              mensagem: `Conta ${conta.ativa === 'S' ? 'Inativada' : 'Ativada'} com sucesso`,
              tipo: 'success'
            });
          })
          .catch(err => {
            setPending(false);
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          });
      }
    });
  };

  const selecionarPadraoPagamento = _id => {
    setAlertConfirm({
      show: true,
      title: 'Atenção',
      msg: `Deseja realmente definir esta conta corrente como conta padrão de pagamento do cliente?`,
      funcaoConfirmacao: () => {
        setAlertConfirm(false);
        setPending(true);
        alterarContaCorrentePadraoPagamento({ _id, cod_cliente })
          .then(res => {
            buscarContasCorrente();
            setAlerta({
              mensagem: 'Conta corrente atribuída com sucesso como conta padrão de pagamento',
              tipo: 'success'
            });
          })
          .catch(err => {
            setPending(false);
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          });
      }
    });
  };

  return (
    <BoxPaper
      iconeTitulo={<CurrencyExchange />}
      labelTitulo="Contas Corrente"
      sx={isPending ? { position: 'relative' } : {}}
    >
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      <Box sx={{ mt: '-85px', float: 'left' }}>
        <Typography variant="subtitle1">Filtrar Ativas:</Typography>
        <FormControlLabel
          control={<IOSSwitch />}
          onClick={e => setFiltroAtivo(!filtroAtivo)}
          checked={filtroAtivo}
          sx={{ ml: '20px', mr: 0 }}
          disabled={isPending}
        />
      </Box>
      {escopoDadosBancarios && escopoDadosBancarios.permite_incluir && (
        <ButtonMain
          size="medium"
          tipoBotao="azul-escuro"
          className="w-auto float-right"
          sx={{ mt: '-70px', mr: '60px' }}
          onClick={() => incluirAlterarContaCorrente({})}
          disabled={isPending || lstBancosAgencia === false}
        >
          Adicionar <Add />
        </ButtonMain>
      )}
      {lstContasCorrentes && lstContasCorrentes.length > 0 ? (
        lstContasCorrentes.map(conta => (
          <Box className="box-registro" key={conta._id}>
            <LinhaDados
              label="Banco:"
              valor={
                conta.descrBanco && NotIsEmpty(conta.descrBanco) ? conta.descrBanco : valorIndefinido
              }
            >
              <Box className="flex-box-items-direita w-100 pad-top-10">
                <FormControlLabel
                  control={<IOSSwitch disabled={isPending || !escopoDadosBancarios.permite_alterar} />}
                  onClick={e => alteraContaCorrenteAtiva(conta)}
                  checked={conta.ativa === 'S'}
                  sx={{ ml: 0, mr: 0 }}
                />
                {conta.ativa === 'S' && escopoDadosBancarios && escopoDadosBancarios.permite_alterar && (
                  <ButtonMain
                    size="small"
                    variant="outlined"
                    className="botao-alterar-excluir"
                    onClick={() => incluirAlterarContaCorrente(conta)}
                    sx={{ ml: '20px' }}
                    disabled={isPending || lstBancosAgencia === false}
                  >
                    <ModeEditOutlined />
                  </ButtonMain>
                )}
              </Box>
            </LinhaDados>
            {camposContaCorrente.map(info => (
              <LinhaDados
                key={info.key}
                label={info.label}
                valor={
                  conta[info.key] && NotIsEmpty(conta[info.key]) ? conta[info.key] : valorIndefinido
                }
                boxClass={info.key === 'alteracao' && IsEmpty(conta.usuarioAlteracao) ? 'hidden' : ''}
              />
            ))}
            {conta.ativa === 'S' && escopoDadosBancarios && escopoDadosBancarios.permite_alterar && (
              <Grid container spacing={3} className="info-line">
                <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label" />
                <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                  {conta.padraoPagamento && conta.padraoPagamento === 'S' ? (
                    <Chip label="Conta padrão de pagamento" className="badge-cor-suporte" />
                  ) : (
                    <ButtonMain
                      tipoBotao="transparente-com-bordas-preto"
                      className="w-auto"
                      onClick={() => selecionarPadraoPagamento(conta._id)}
                      disabled={isPending}
                    >
                      Definir como conta padrão de pagamento
                    </ButtonMain>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        ))
      ) : (
        <Box className="w-100 flex-box items-centro-verticalmente items-centro">
          <Alert severity="info" className="texto-centro">
            Não há contas correntes cadastradas.
          </Alert>
        </Box>
      )}
    </BoxPaper>
  );
};
export default memo(ContasCorrente);
