import React from 'react';
import { Box, Grid } from '@mui/material';
import { Group, Man, ModeEditOutlined, Person } from '@mui/icons-material';
import LinhaDados from './LinhaDados';
import { NotIsEmpty } from '../../../../Utils/format';
import ButtonMain from '../../../../Shared/ButtonMain';

const valorIndefinido = <em className="texto-cor-cinza">-</em>;
const tiposPessoa = { PF: 'Pessoa física', PJ: 'Pessoa jurídica' };

const DadosBasicos = ({
  dadosBasicos,
  alterarPessoa,
  cadastrarConjugeAvalista,
  abaAtiva,
  escopoClientePessoas,
  ...demaisDados
}) => {
  const tipoPessoa =
    dadosBasicos && dadosBasicos.CPFCNPJ && dadosBasicos.CPFCNPJ.length > 11 ? 'PJ' : 'PF';

  return (
    <Box className="poderes-assinaturas">
      <Grid container spacing={2} className="box-paper-header" sx={{ mb: '15px', mt: '10px' }}>
        <Grid item xl={4} lg={4} md={4} sm={3} xs={3} className="flex-box-items-direita">
          <Box className="box-icone-paper-header icone-gray">
            {abaAtiva === 'conjuge' ? <Group /> : abaAtiva === 'procurador' ? <Man /> : <Person />}
          </Box>
        </Grid>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={6}
          xs={6}
          className="titulo titulo-gray flex-box-items-esquerda"
        >
          {`Dados ${abaAtiva === 'conjuge' ? 'do cônjuge' : abaAtiva === 'procurador' ? 'do representante legal (procurador)' : ''}`}
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={6} className="flex-box-items-direita pad-0">
          {abaAtiva === 'conjuge' &&
            dadosBasicos &&
            NotIsEmpty(dadosBasicos.CPFCNPJ) &&
            escopoClientePessoas &&
            escopoClientePessoas.permite_incluir && (
              <ButtonMain
                size="small"
                tipoBotao="transparente-com-bordas"
                onClick={() => cadastrarConjugeAvalista()}
                sx={{
                  fontSize: '0.8rem !important',
                  lineHeight: '1.3 !important',
                  minWidth: '140px !important',
                  height: '40px !important',
                  float: 'right',
                  mr: '10px'
                }}
              >
                Cadastrar cônjuge como avalista
              </ButtonMain>
            )}
          {escopoClientePessoas && escopoClientePessoas.permite_alterar && (
            <ButtonMain
              size="small"
              variant="outlined"
              className="botao-alterar-excluir"
              onClick={() => alterarPessoa('dados_basicos')}
            >
              <ModeEditOutlined />
            </ButtonMain>
          )}
        </Grid>
      </Grid>
      {abaAtiva === 'propria' && (
        <LinhaDados
          label="Tipo:"
          valor={`${dadosBasicos && NotIsEmpty(dadosBasicos.TIPO) ? tiposPessoa[dadosBasicos.TIPO] : valorIndefinido} (${tipoPessoa})`}
        />
      )}
      {(abaAtiva === 'conjuge' || abaAtiva === 'procurador') && (
        <>
          <LinhaDados
            label={tipoPessoa === 'PF' ? 'Nome:' : 'Empresa:'}
            valor={dadosBasicos && NotIsEmpty(dadosBasicos.NOME) ? dadosBasicos.NOME : valorIndefinido}
          />
          <LinhaDados
            label={tipoPessoa === 'PF' ? 'CPF:' : 'CNPJ:'}
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.CPFCNPJ) ? dadosBasicos.CPFCNPJ : valorIndefinido
            }
            copyText={true}
          />
        </>
      )}

      {/* PESSOA FISICA */}
      {tipoPessoa === 'PF' ? (
        <>
          <LinhaDados
            label="Data de nascimento:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.DATANASCIMENTO)
                ? dadosBasicos.DATANASCIMENTO
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Gênero:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.GENERO) ? dadosBasicos.GENERO : valorIndefinido
            }
          />
          <LinhaDados
            label="Estado civil:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.ESTADOCIVIL)
                ? dadosBasicos.ESTADOCIVIL
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Regime casamento:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.REGIMECASAMENTO)
                ? dadosBasicos.REGIMECASAMENTO
                : valorIndefinido
            }
          />
          <LinhaDados
            label="RG:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.RG)
                ? dadosBasicos.RG 
                : valorIndefinido
            }
            copyText={true}            
          />
          <LinhaDados
            label="Órgão expedidor:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.ORGAORG) ? dadosBasicos.ORGAORG : valorIndefinido
            }
          />
          <LinhaDados
            label="Data de emissão:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.DATARG) ? dadosBasicos.DATARG : valorIndefinido
            }
          />
          <LinhaDados
            label="Nacionalidade:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.NACIONALIDADE)
                ? dadosBasicos.NACIONALIDADE
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Naturalidade:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.NATURALIDADE)
                ? dadosBasicos.NATURALIDADE
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Nome da mãe:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.NOMEMAE) ? dadosBasicos.NOMEMAE : valorIndefinido
            }
          />
          <LinhaDados
            label="Nome do pai:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.NOMEPAI) ? dadosBasicos.NOMEPAI : valorIndefinido
            }
          />
          <LinhaDados
            label="Profissão:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.PROFISSAO)
                ? dadosBasicos.PROFISSAO
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Pessoa Politicamente Exposta (PPE):"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.POLITICAMENTEEXPOSTA)
                ? dadosBasicos.POLITICAMENTEEXPOSTA
                : valorIndefinido
            }
          />
          {abaAtiva === 'propria' && (
            <LinhaDados
              label="Possui eCPF:"
              valor={
                dadosBasicos && NotIsEmpty(dadosBasicos.POSSUIECPF)
                  ? dadosBasicos.POSSUIECPF
                  : valorIndefinido
              }
            />
          )}
        </>
      ) : (
        // PESSOA JURIDICA
        <>
          <LinhaDados
            label="Nome fantasia:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.FANTASIA) ? dadosBasicos.FANTASIA : valorIndefinido
            }
          />
          <LinhaDados
            label="Inscrição estadual:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.INSCRESTAD)
                ? dadosBasicos.INSCRESTAD
                : valorIndefinido
            }
            copyText={true}
          />
          <LinhaDados
            label="NIRE:"
            valor={dadosBasicos && NotIsEmpty(dadosBasicos.NIRE) ? dadosBasicos.NIRE : valorIndefinido}
            copyText={true}
          />
          <LinhaDados
            label="Data de registro na junta:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.DATAREGJUNTA)
                ? dadosBasicos.DATAREGJUNTA
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Fundação"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.DATAFUNDACAO)
                ? dadosBasicos.DATAFUNDACAO
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Porte Empresa:"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.RECUPERACAO_JUDICIAL)
                ? dadosBasicos.RECUPERACAO_JUDICIAL
                : valorIndefinido
            }
          />
          <LinhaDados
            label="Empresa em Recuperação Judicial (RJ):"
            valor={
              dadosBasicos && NotIsEmpty(dadosBasicos.RECUPERACAO_JUDICIAL)
                ? dadosBasicos.RECUPERACAO_JUDICIAL
                : valorIndefinido
            }
          />
        </>
      )}

      <LinhaDados
        label="Inclusão:"
        valor={
          <>
            {dadosBasicos && NotIsEmpty(demaisDados.dataInc) ? demaisDados.dataInc : valorIndefinido}
            {dadosBasicos && NotIsEmpty(demaisDados.usuaInc) ? ` por ${demaisDados.usuaInc}` : ''}
          </>
        }
      />
      {demaisDados && demaisDados.dataAlt && demaisDados.usuaAlt && (
        <LinhaDados
          label="Última alteração:"
          valor={
            <>
              {dadosBasicos && NotIsEmpty(demaisDados.dataAlt) ? demaisDados.dataAlt : valorIndefinido}
              {dadosBasicos && NotIsEmpty(demaisDados.usuaAlt) ? ` por ${demaisDados.usuaAlt}` : ''}
            </>
          }
        />
      )}
    </Box>
  );
};
export default DadosBasicos;
