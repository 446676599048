import React from 'react';
import { Grid, Typography } from '@mui/material';
import { mascararCpfCnpj } from '../Utils/format';

// eslint-disable-next-line import/prefer-default-export
export const ClienteNomeCnpj = params => (
  <Grid container>
    <Grid
      item
      xs={12}
      className="texto-left"
      title={params.value !== 'Total geral' ? params.value : ''}
    >
      <Typography
        className={
          params.value !== 'Total geral'
            ? 'texto-nowrap-overflow-dotted'
            : 'font-bold pad-topbot-20'
        }
      >
        {params.value}
      </Typography>
    </Grid>
    {params.row.cnpjCliente && (
      <Grid item xs={12} title={mascararCpfCnpj(params.row.cnpjCliente)}>
        <Typography
          variant="subtitle1"
          className="texto-nowrap-overflow-dotted font-size-0-8rem texto-cinza-medio"
        >
          {mascararCpfCnpj(params.row.cnpjCliente)}
        </Typography>
      </Grid>
    )}
  </Grid>
);
