import React, { memo, useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import 'moment/locale/pt-br';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR as DatePtBr } from '@mui/x-date-pickers/locales';
import LoaderMain from '../../../../Shared/LoaderMain';
import ButtonMain from '../../../../Shared/ButtonMain';
import { NotIsEmpty } from '../../../../Utils/format';
import { incluirDadosAditivos } from '../../../../../api/formalizacao';

const AdicionarAditivo = ({ setAlerta, lstTipos, dadosContrato, handleClose, funcaoSucesso }) => {
  const [isPending, setIsPending] = useState(false);
  const [valueVigencia, setValueVigencia] = useState(null);
  const [lstTiposAditivos, setlstTiposAditivos] = useState([]);
  const [valueTiposAditivos, setValueTiposAditivos] = useState('');
  const [liberarBtnSalvar, setLiberarBtnSalvar] = useState(false);
  const localizacaoBrasil = DatePtBr.components.MuiLocalizationProvider.defaultProps.localeText;

  useEffect(() => {
    if (lstTipos && lstTipos.tiposAditivos) {
      setlstTiposAditivos(lstTipos.tiposAditivos);
    }
  }, []);

  useEffect(() => {
    if (NotIsEmpty(valueTiposAditivos) && NotIsEmpty(valueVigencia)) {
      setLiberarBtnSalvar(true);
    } else {
      setLiberarBtnSalvar(false);
    }
  }, [valueTiposAditivos, valueVigencia]);

  const submitFormulario = () => {
    const params = {};
    params.cod_tipo_aditivo = valueTiposAditivos;
    params.cod_cliente_formalizacao = dadosContrato.id;

    if (valueVigencia && valueVigencia.isValid()) {
      params.vigencia = valueVigencia.format('YYYY-MM-DD');
    } else {
      setValueVigencia(null);
    }

    setIsPending(true);
    incluirDadosAditivos(params)
      .then(() => {
        funcaoSucesso();
        handleClose();
        setAlerta({
          mensagem: 'Aditivo adicionado sucesso',
          tipo: 'success'
        });
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleOnChangeTiposAditivos = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueTiposAditivos(newValue.value);
    } else {
      setValueTiposAditivos('');
    }
  };

  return (
    <Box sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      <Grid
        container
        className="margin-bottom-20 margin-top-10"
        spacing={2}
        sx={{
          pr: '10px',
          alignItems: {
            xs: 'end',
            sm: 'end',
            md: 'center',
            lg: 'center',
            xl: 'center'
          }
        }}
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            textAlign: {
              md: 'right',
              lg: 'right',
              xl: 'right'
            }
          }}
        >
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5 texto-negrito"
          >
            Tipo:
          </Typography>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Autocomplete
            disablePortal
            disableClearable
            autoSelect
            blurOnSelect
            id="TiposAditivos"
            options={lstTiposAditivos || []}
            value={
              valueTiposAditivos && lstTiposAditivos.length > 0
                ? lstTiposAditivos.find(option => option.value === valueTiposAditivos)
                : ''
            }
            onChange={handleOnChangeTiposAditivos}
            name="TiposAditivos"
            className="select-documentos-formalizacao ajuste-height pad-left-5"
            isOptionEqualToValue={(option, value) => option.value === value.value}
            forcePopupIcon
            freeSolo
            disabled={lstTiposAditivos && lstTiposAditivos.length === 0}
            error="erro"
            renderInput={params => (
              <TextField
                {...params}
                name="TiposAditivos"
                placeholder="Escolha o tipo de aditivo"
                autoComplete="off"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off'
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            textAlign: {
              md: 'right',
              lg: 'right',
              xl: 'right'
            }
          }}
        >
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5 texto-negrito"
          >
            Vigência:
          </Typography>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
            <DatePicker
              name="data_agendamento_ini_filtro"
              className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
              value={valueVigencia}
              onChange={dataMoment => setValueVigencia(dataMoment)}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                field: {
                  clearable: true,
                  onClear: () => setValueVigencia(null)
                }
              }}
              sx={{ paddingLeft: '5px' }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="margin-top-20">
        <Grid item xl={2} lg={2} md={2} />
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <ButtonMain
            size="medium"
            tipoBotao="transparente-com-bordas"
            disabled={isPending}
            onClick={handleClose}
          >
            Cancelar
          </ButtonMain>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <ButtonMain
            size="medium"
            tipoBotao="azul-escuro"
            disabled={isPending || !liberarBtnSalvar}
            onClick={() => submitFormulario()}
            sx={{ color: 'white !important' }}
          >
            Adicionar
          </ButtonMain>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
      </Grid>
    </Box>
  );
};
export default memo(AdicionarAditivo);
