import React from 'react';
import { Box, FormControlLabel, Switch, Tooltip, Typography, styled } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1A284D',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const DefinicaoDeColunasAditivos = (
  handleOnChangeDocumento,
  escopoClienteDados,
  modalIncluirGarantia,
  mostrarDetalhesAditivo,
  dadosRow,
  setDadosRow
) => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);

  const abrirModalGarantia = () => {
    handleClose();
    modalIncluirGarantia(dadosRow);
  };

  const abrirDetalhesAditivo = () => {
    handleClose();
    setTimeout(() => {
      mostrarDetalhesAditivo(dadosRow);
    }, 500);
  };

  const handleClick = (event, row) => {
    setDadosRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const config = [
    {
      field: 'STATUS',
      headerName: 'Status',
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray texto-centro',
      renderCell: params => (
        <FormControlLabel
          className="margin-zero"
          control={<IOSSwitch disabled={!escopoClienteDados.permite_alterar} />}
          onClick={e => handleOnChangeDocumento(e, params.row.id)}
          checked={params.row.STATUS === 'A'}
        />
      )
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray camel-case border-radius-left',
      renderCell: params => <>{params.row.id}</>
    },
    {
      field: 'NOMETIPOADITIVO',
      headerName: 'Tipo',
      flex: 1,
      minWidth: 250,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray',
      renderCell: params => <>{params.row.NOMETIPOADITIVO}</>
    },
    {
      field: 'DTHRINSERT',
      headerName: 'Inclusão',
      flex: 1,
      minWidth: 80,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray text-transform-capitalize',
      renderCell: params => {
        const updateInfo =
          params.row.DTHRUPDATE && params.row.NOMEALTERACAO
            ? `Alterado em ${moment(params.row.DTHRUPDATE).format('DD/MM/yyyy [às] HH:mm:ss')} por ${params.row.NOMEALTERACAO}`
            : '';
        return (
          <Tooltip
            title={`Incluído em ${moment(params.row.DTHRINSERT).format('DD/MM/yyyy [às] HH:mm:ss')} por ${params.row.NOMEINCLUSAO} ${updateInfo}`}
          >
            <Typography
              className="texto-centro flex-box-items-centralizados items-centralizados"
              variant="h7"
            >
              {moment(params.row.DTHRINSERT).format('DD/MM/yyyy')}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'VIGENCIA',
      headerName: 'Vigência',
      flex: 1,
      minWidth: 120,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray text-transform-capitalize',
      renderCell: params => (
        <Typography
          className="texto-centro flex-box-items-centralizados items-centralizados"
          variant="h7"
        >
          {params.row.VIGENCIA ? moment(params.row.VIGENCIA).format('DD/MM/yyyy') : '- -'}
        </Typography>
      )
    }
  ];

  if (escopoClienteDados && escopoClienteDados.permite_alterar) {
    const abrirModal = {
      field: 'abrir_cliente',
      headerName: 'Ações',
      flex: 1,
      maxWidth: 100,
      minWidth: 80,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray border-radius-right',
      renderCell: params => (
        <Box>
          <Button
            aria-controls={open ? 'basic-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={e => handleClick(e, params.row)}
            className="btn-actions-etc"
            aria-haspopup="true"
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className="menu-actions-etc"
            sx={{
              '& .MuiPaper-root': {
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem onClick={() => abrirModalGarantia()}>Adicionar garantia</MenuItem>
            <MenuItem onClick={() => abrirDetalhesAditivo()}>Detalhes do aditivo</MenuItem>
          </Menu>
        </Box>
      )
    };

    config.push(abrirModal);
  }

  return config;
};

export default DefinicaoDeColunasAditivos;
