export const TIPOSPARECER = {
  MUDANCA_STATUS_AUTO: 1,
  RELATORIO_DE_VISITA: 2,
  CONCLUSIVO: 3,
  PRIVATIVO: 4,
  MANUTENCAO: 5,
  ALTERACAO: 6,
  DIRETORIA_OPER: 7,
  CREDITO: 8,
  COBRANCA: 9,
  COMITE_SUPERIOR: 12,
  COMITE_ELETRONICO: 13,
  MUDANCA_DE_STATUS: 14,
  AUDITORIA_EXTERNA: 15,
  GESTAO_DE_RISCO: 16,
  INFORMACOES_MERCADO: 17,
  ACAO_COMERCIAL: 18,
  COMITE_FIDC: 19,
  RESERVA: 20,
  RENOVACAO: 21,
  INFORMACAO_OPERACAO: 10,
  DIRETORIA_OP_ALCADA: 11,
  ANALISTA_ALCADA: 23,
  DIRETORIA_CO_ALCADA_: 24,
  COBRANCA_O: 25,
  ALCADA_TAXA: 27,
  ALCADA_CM: 28,
  ALCADA_NO: 29,
  ALCADA_GR: 30,
  ALCADA_IC: 31,
  SOLICITA_ALCADA: 32,
  PARECER_CLIENTE: 33
};
