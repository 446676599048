import React from 'react';
import { Autocomplete } from 'formik-mui';
import { TextField, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { IsEmpty } from '../Utils/format';

const AutocompleteMainDefault = ({
  children,
  className = '',
  paramLabel = 'label',
  propsInput = {},
  form, 
  field,
  erro = '',
  ...props
}) => {
  const nomeInput = field && field.name ? field.name : '';
  let erroTexto = form && form.errors && form.errors[nomeInput] ? form.errors[nomeInput] : '';
  erroTexto = erro !== '' ? erro : erroTexto;

  return (
    <>
      <Autocomplete
        {...props}
        form={form}
        field={field}
        disablePortal
        autoSelect
        clearOnBlur
        className={`${className ? className : ''} autocomplete-principal w-100`}
        isOptionEqualToValue={(option, value) =>
          IsEmpty(value) || IsEmpty(value._id) || option._id === value._id
        }
        getOptionLabel={option => option[paramLabel] ?? ''}
        renderInput={params => <TextField {...params} name={`${props.name}_text`} {...propsInput} />}
        popupIcon={<KeyboardArrowDown className="autocomplete-principal-icon-opener" />}
      >
        {children}
      </Autocomplete>
      {erroTexto ? <Typography className="texto-danger label-padding">{erroTexto}</Typography> : ''}
    </>
  );
};
export default AutocompleteMainDefault;
