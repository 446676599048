import React, { memo, useEffect, useState } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR as DatePtBr } from '@mui/x-date-pickers/locales';
import { focusInput } from '../../../../Utils/utils';
import LoaderMain from '../../../../Shared/LoaderMain';
import TextFieldMain from '../../../../Shared/TextFieldMain';
import CheckBoxMain from '../../../../Shared/CheckBoxMain';
import ButtonMain from '../../../../Shared/ButtonMain';
import { IsEmpty, NotIsEmpty } from '../../../../Utils/format';
import SelectMain from '../../../../Shared/SelectMain';
import { alterarDadosContrato, incluirDadosContrato } from '../../../../../api/formalizacao';
import AlertMain from '../../../../Shared/AlertMain';

const FormContrato = ({
  formDados = {},
  setAlerta,
  lstTipos,
  codCliente,
  handleClose,
  setDadosDetalhesContrato,
  funcaoSucesso = false
}) => {
  const [isPendingSalvar, setPendingSalvar] = useState(false);
  const [valueAssinatura, setValueAssinatura] = useState(null);
  const [valueEmissao, setValueEmissao] = useState(null);
  const [valueVigencia, setValueVigencia] = useState(null);
  const [mostraPerguntaEnvioEmail, setMostraPerguntaEnvioEmail] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [msgTitleAlert, setMsgTitleAlert] = useState('');
  const [msgAlert, setMsgAlert] = useState('');
  const [dadosAlteracao, setDadosAlteracao] = useState(false);
  const localizacaoBrasil = DatePtBr.components.MuiLocalizationProvider.defaultProps.localeText;

  useEffect(() => {
    if (formDados) {
      if (formDados.DATAASSINATURACERTIFICADORA) {
        const dataAssinatura = moment(formDados.DATAASSINATURACERTIFICADORA);
        setValueAssinatura(dataAssinatura);
      }

      if (formDados.DATAEMISSAOCONTRATO) {
        const dataEmissao = moment(formDados.DATAEMISSAOCONTRATO);
        setValueEmissao(dataEmissao);
      }

      if (formDados.VIGENCIA) {
        const dataVigencia = moment(formDados.VIGENCIA);
        setValueVigencia(dataVigencia);
      }

      if (
        Object.keys(formDados).length <= 0 ||
        (formDados.EMAILFORMALIZADO && formDados.EMAILFORMALIZADO === 'N')
      ) {
        setMostraPerguntaEnvioEmail(true);
      }
    }
  }, []);

  const verificaEnviaEmail = (values, form) => {
    if (mostraPerguntaEnvioEmail && values.contrato_formalizado) {
      setDadosAlteracao({ values: values, form: form });
      setMsgTitleAlert('Atenção');
      setMsgAlert('Deseja enviar o email de formalização ao cliente?');
      setMostrarAlerta(true);
    } else {
      submitFormulario(values, form);
    }
  };

  const funcaoSucessoAlert = () => {
    if (dadosAlteracao) {
      const dados = dadosAlteracao.values;
      dados.envia_email_cliente = true;
      submitFormulario(dados, dadosAlteracao.form);
    }
  };

  const funcaoCancelarAlert = () => {
    if (dadosAlteracao) {
      submitFormulario(dadosAlteracao.values, dadosAlteracao.form);
    }
  };

  const submitFormulario = (values, form) => {
    setPendingSalvar(true);

    const params = { ...values };
    params.cod_cliente = codCliente;
    params.cod_empresa = NotIsEmpty(params.codEmpresa) ? params.codEmpresa : null;
    params.cod_tipo_contrato = NotIsEmpty(params.codTipoContrato) ? params.codTipoContrato : null;
    params.cod_certificadora = NotIsEmpty(params.codCertificadora) ? params.codCertificadora : null;

    if (valueAssinatura && valueAssinatura.isValid()) {
      params.data_assinatura = valueAssinatura.format('YYYY-MM-DD');
    } else {
      setValueAssinatura(null);
    }

    if (valueEmissao && valueEmissao.isValid()) {
      params.data_emissao = valueEmissao.format('YYYY-MM-DD');
    } else {
      setValueEmissao(null);
    }

    if (valueVigencia && valueVigencia.isValid()) {
      params.data_vigencia = valueVigencia.format('YYYY-MM-DD');
    } else {
      setValueVigencia(null);
    }

    setPendingSalvar(true);
    const msgEmpresaAtiva =
      'Já existe um cadastro ativo para esta empresa, por favor inative o cadastro anterior!';

    if (formDados && Object.keys(formDados).length > 0) {
      alterarDadosContrato(params)
        .then(res => {
          if (res && res.data) {
            const novosDados = res.data;

            if (novosDados.empresa_ativa) {
              setAlerta({
                mensagem: msgEmpresaAtiva,
                tipo: 'warning'
              });
              return;
            }

            setDadosDetalhesContrato(novosDados);

            if (funcaoSucesso) {
              funcaoSucesso();
            }

            let msgRetornoEnviado = '!';
            if (
              (novosDados.EMAIL_ENVIADO && novosDados.MSG_ENVIO_EMAIL) ||
              (novosDados.EMAIL_NAO_ENVIADO && novosDados.MSG_ENVIO_EMAIL)
            ) {
              msgRetornoEnviado = novosDados.MSG_ENVIO_EMAIL;
            }

            handleClose();
            setAlerta({
              mensagem: `Contrato atualizado com sucesso${msgRetornoEnviado}`,
              tipo: 'success'
            });
          }
        })
        .catch(err => {
          console.error(err);
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        })
        .finally(() => {
          setPendingSalvar(false);
          form.setSubmitting(false);
        });
    } else {
      incluirDadosContrato(params)
        .then(res => {
          const resutl = res.data;

          if (resutl.empresa_ativa) {
            setAlerta({
              mensagem: msgEmpresaAtiva,
              tipo: 'warning'
            });
            return;
          }

          if (funcaoSucesso) {
            funcaoSucesso();
          }
          handleClose();
          setAlerta({
            mensagem: 'Contrato incluído sucesso',
            tipo: 'success'
          });
        })
        .catch(err => {
          console.error(err);
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        })
        .finally(() => {
          setPendingSalvar(false);
          form.setSubmitting(false);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          cod_contrato: NotIsEmpty(formDados.id) ? formDados.id : '',
          codEmpresa: NotIsEmpty(formDados.CODEMPRESA) ? formDados.CODEMPRESA : '',
          codTipoContrato: NotIsEmpty(formDados.CODTIPOCONTRATO) ? formDados.CODTIPOCONTRATO : '',
          contrato_formalizado:
            NotIsEmpty(formDados.FORMALIZADA) && formDados.FORMALIZADA === 'S' ? true : false,
          contrato_novo:
            NotIsEmpty(formDados.CONTRATONOVO) && formDados.CONTRATONOVO === 'S' ? true : false,
          codCertificadora: NotIsEmpty(formDados.INDENTIFICACAOCERTIFICADORA)
            ? formDados.INDENTIFICACAOCERTIFICADORA
            : '',
          cod_contrato_certificadora: NotIsEmpty(formDados.CODCONTRATOCERTIFICADORA)
            ? formDados.CODCONTRATOCERTIFICADORA
            : '',
          numero_assinaturas: NotIsEmpty(formDados.MINIMOASSINATURAS) ? formDados.MINIMOASSINATURAS : '',
          ...formDados
        }}
        onSubmit={(values, form) => {
          const errors = {};
          const { ...valores } = values;

          if (IsEmpty(valores.codEmpresa)) {
            errors.codEmpresa = 'Escolha uma empresa';
          }

          if (IsEmpty(valores.codTipoContrato)) {
            errors.codTipoContrato = 'Escolha um tipo de contrato';
          }

          form.setErrors(errors);
          if (Object.keys(errors).length <= 0) {
            // submitFormulario(valores, form);
            verificaEnviaEmail(valores, form);
          } else {
            form.setSubmitting(false);
            focusInput(Object.keys(errors)[0]);
          }
        }}
      >
        {({ values, submitForm, isSubmitting }) => (
          <Form>
            {isPendingSalvar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

            {formDados && Object.keys(formDados).length > 0 && (
              <Grid container spacing={3} className="input-line">
                <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                  ID:
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                  <Field
                    id="cod_contrato"
                    name="cod_contrato"
                    type="text"
                    component={TextFieldMain}
                    fullWidth
                    tipoMascara="number-inteiro"
                    autoComplete="off"
                    disabled={true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Empresa:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                {Array.isArray(lstTipos.empresas) && lstTipos.empresas.length > 0 ? (
                  <Field
                    id="codEmpresa"
                    name="codEmpresa"
                    type="text"
                    boxMain={false}
                    component={SelectMain}
                    placeholder="Selecione uma opção"
                    displayEmpty
                    displayEmptyLabel="Não Definido"
                    autoComplete="off"
                    disabled={isSubmitting}
                    opcoes={[...lstTipos.empresas].map(tipo => ({
                      label: tipo.nome,
                      valor: tipo._id
                    }))}
                  />
                ) : Array.isArray(lstTipos.empresas) && lstTipos.empresas.length === 0 ? (
                  <Alert severity="error" className="loader-box">
                    Ocorreu algum erro, favor entrar em contato com o suporte
                  </Alert>
                ) : (
                  <Box className="loader-box">
                    <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Tipo de contrato:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                {Array.isArray(lstTipos.tiposContratos) && lstTipos.tiposContratos.length > 0 ? (
                  <Field
                    id="codTipoContrato"
                    name="codTipoContrato"
                    type="text"
                    boxMain={false}
                    component={SelectMain}
                    placeholder="Selecione uma opção"
                    displayEmpty
                    displayEmptyLabel="Não Definido"
                    autoComplete="off"
                    disabled={isSubmitting}
                    opcoes={[...lstTipos.tiposContratos].map(tipo => ({
                      label: tipo.nome,
                      valor: tipo._id
                    }))}
                  />
                ) : Array.isArray(lstTipos.tiposContratos) && lstTipos.tiposContratos.length === 0 ? (
                  <Alert severity="error" className="loader-box">
                    Ocorreu algum erro, favor entrar em contato com o suporte
                  </Alert>
                ) : (
                  <Box className="loader-box">
                    <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input" />
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                <Field
                  controlLabelCheckbox="Contrato está formalizado"
                  id="contrato_formalizado"
                  name="contrato_formalizado"
                  component={CheckBoxMain}
                  checked={values.contrato_formalizado ? true : false}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input" />
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                <Field
                  controlLabelCheckbox="Contrato é novo"
                  id="contrato_novo"
                  name="contrato_novo"
                  component={CheckBoxMain}
                  checked={values.contrato_novo ? true : false}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Data de assinatura da certificadora:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                  <DatePicker
                    name="data_assinatura"
                    className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
                    value={valueAssinatura}
                    onChange={dataMoment => setValueAssinatura(dataMoment)}
                    slotProps={{
                      inputAdornment: {
                        position: 'start'
                      },
                      field: {
                        clearable: true,
                        onClear: () => setValueAssinatura(null)
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Data de emissão do contrato:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                  <DatePicker
                    name="data_emissao"
                    className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
                    value={valueEmissao}
                    onChange={dataMoment => setValueEmissao(dataMoment)}
                    slotProps={{
                      inputAdornment: {
                        position: 'start'
                      },
                      field: {
                        clearable: true,
                        onClear: () => setValueEmissao(null)
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Identificação da certificadora:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                {Array.isArray(lstTipos.certificadoras) && lstTipos.certificadoras.length > 0 ? (
                  <Field
                    id="codCertificadora"
                    name="codCertificadora"
                    type="text"
                    boxMain={false}
                    component={SelectMain}
                    placeholder="Selecione uma opção"
                    displayEmpty
                    displayEmptyLabel="Não Definido"
                    autoComplete="off"
                    disabled={isSubmitting}
                    opcoes={[...lstTipos.certificadoras].map(tipo => ({
                      label: tipo.nome,
                      valor: tipo._id
                    }))}
                  />
                ) : Array.isArray(lstTipos.certificadoras) && lstTipos.certificadoras.length === 0 ? (
                  <Alert severity="error" className="loader-box">
                    Ocorreu algum erro, favor entrar em contato com o suporte
                  </Alert>
                ) : (
                  <Box className="loader-box">
                    <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Código do contrato na certificadora:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                <Field
                  id="cod_contrato_certificadora"
                  name="cod_contrato_certificadora"
                  type="text"
                  component={TextFieldMain}
                  fullWidth
                  autoComplete="off"
                  disabled={isSubmitting}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Número mínimo de assinaturas:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                <Field
                  id="numero_assinaturas"
                  name="numero_assinaturas"
                  type="text"
                  component={TextFieldMain}
                  fullWidth
                  tipoMascara="number-inteiro"
                  autoComplete="off"
                  disabled={isSubmitting}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="input-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
                Vigência:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
                <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                  <DatePicker
                    name="data_vigencia"
                    className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
                    value={valueVigencia}
                    onChange={dataMoment => setValueVigencia(dataMoment)}
                    slotProps={{
                      inputAdornment: {
                        position: 'start'
                      },
                      field: {
                        clearable: true,
                        onClear: () => setValueVigencia(null)
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ m: '20px 0' }}>
              <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
              <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                <ButtonMain
                  size="medium"
                  tipoBotao="transparente-com-bordas"
                  disabled={isSubmitting}
                  onClick={handleClose}
                >
                  Cancelar
                </ButtonMain>
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                <ButtonMain
                  size="medium"
                  tipoBotao="azul-escuro"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {formDados && Object.keys(formDados).length > 0 ? 'Alterar' : 'Adicionar'}
                </ButtonMain>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
            </Grid>
          </Form>
        )}
      </Formik>

      <AlertMain
        exibeAlerta={mostrarAlerta}
        setExibeAlerta={setMostrarAlerta}
        titleAlerta={msgTitleAlert}
        msgAlerta={msgAlert}
        funcaoConfirmacao={funcaoSucessoAlert}
        funcaoCancelar={funcaoCancelarAlert}
      />
    </>
  );
};
export default memo(FormContrato);
