import React, { memo, useEffect, useState } from 'react';
import { Autocomplete, Button, Grid, Typography } from '@mui/material';
import { Feed, Search } from '@mui/icons-material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextFieldMui from '@mui/material/TextField';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { ptBR as DatePtBr } from '@mui/x-date-pickers/locales';
import BoxPaper from './BoxPaper';
import {
  alterarStatusContrato,
  getBuscaContratos,
  getBuscaLimitesGarantias,
  getDadosContratosGrid,
  getEmpresasInsereOperacao,
  getTiposAditivos,
  getTiposContratos,
  getTiposGarantias
} from '../../../../api/formalizacao';
import DefinicaoDeColunas from './definicoes-grid';
import LoaderMain from '../../../Shared/LoaderMain';
import AlertMain from '../../../Shared/AlertMain';
import FormContrato from './Formularios/FormContrato';
import GerarContratos from './Modal/GerarContratos';
import AdicionarAditivo from './Formularios/AdicionarAditivo';
import FormGarantia from './Formularios/FormGarantia';
import DetalhesContrato from './DetalhesContrato';
import { mascararCpfCnpj } from '../../../Utils/format';
import Aditivos from './Aditivos';
import DetalhesAditivo from './DetalhesAditivo';
import { INDENTIFICACAOCERTIFICADORA } from '../../../Shared/Enums/IdentificacaoCertificadora';
import { CATEGORIASGARANTIAS } from '../../../Shared/Enums/CategoriasGarantias';
import { STATUSIMOVELGARANTIAS } from '../../../Shared/Enums/StatusImovelGarantias';
import LimitesGarantias from './Modal/LimitesGarantias';
import { TIPOSCONTRATO } from '../../../Shared/Enums/TiposContrato';

const Contratos = ({
  dadosGerais,
  escopoClienteDados,
  setAlerta,
  setModalClienteDados,
  codCliente,
  setMostrarTela,
  cliente,
  operaConnect,
  setEsconderTelaComplementar
}) => {
  const [isPending, setIsPending] = useState(false);
  const [lstDadosGrid, setLstDadosGrid] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [paginationModelGridLeads, setPaginationModelGridLeads] = useState({
    pageSize: 5,
    page: 0
  });
  const [lstTiposContratos, setLstTiposContratos] = useState([]);
  const [valueTiposContratos, setValueTiposContratos] = useState('');
  const [valueStatus, setValueStatus] = useState('A');
  const [inclusaoInicial, setInclusaoInicial] = useState(null);
  const [inclusaoFinal, setInclusaoFinal] = useState(null);
  const [vigenciaInicial, setVigenciaInicial] = useState(null);
  const [vigenciaFinal, setVigenciaFinal] = useState(null);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [msgTitleAlert, setMsgTitleAlert] = useState('');
  const [msgAlert, setMsgAlert] = useState('');
  const localizacaoBrasil = DatePtBr.components.MuiLocalizationProvider.defaultProps.localeText;
  const [dadosAlteracao, setDadosAlteracao] = useState(false);
  const [lstTipos, setLstTipos] = useState(false);
  const [dadosRow, setDadosRow] = useState(null);
  const [lstGarantias, setLstGarantias] = useState([]);

  useEffect(() => {
    bucaDadosFormalizacaoFiltro();
    buscarGarantiasLimites();
    buscarListasTipos();
  }, []);

  const buscarListasTipos = async () => {
    setIsPending(true);
    const lstTiposTemp = {};

    lstTiposTemp.empresas =
      lstTipos === false || lstTipos.empresas.length === 0
        ? await getEmpresasInsereOperacao()
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.empresas;

    lstTiposTemp.tiposGarantia =
      lstTipos === false || lstTipos.tiposGarantia.length === 0
        ? await getTiposGarantias()
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.tiposGarantia;

    lstTiposTemp.tiposAditivos =
      lstTipos === false || lstTipos.tiposAditivos.length === 0
        ? await getTiposAditivos()
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.tiposAditivos;

    lstTiposTemp.tiposContratos =
      lstTipos === false || lstTipos.tiposContratos.length === 0
        ? await getTiposContratos()
            .then(res => {
              const data = res.data || [];
              setLstTiposContratos(data);
              return data.map(item => ({ nome: item.label, _id: item.value }));
            })
            .catch(() => [])
        : lstTipos.tiposContratos;

    // PEGA OS TIPOS DE CERTIFICADORAS DO ENUM
    const lstCertificadoras = Object.entries(INDENTIFICACAOCERTIFICADORA).map(([chave, valor]) => ({
      nome: valor,
      _id: chave
    }));
    lstTiposTemp.certificadoras = lstCertificadoras;

    // PEGA OS TIPOS DE CATEGORIAS DA GARANTIA DO ENUM
    const lstCategoriasGarantias = Object.entries(CATEGORIASGARANTIAS).map(([chave, valor]) => ({
      nome: valor,
      _id: chave
    }));
    lstTiposTemp.categorias = lstCategoriasGarantias;

    // PEGA OS STATUS DO IMOVEL DO ENUM
    const lstStatusImovel = Object.entries(STATUSIMOVELGARANTIAS).map(([chave, valor]) => ({
      nome: valor,
      _id: chave
    }));
    lstTiposTemp.statusImovel = lstStatusImovel;

    setLstTipos(lstTiposTemp);
    setIsPending(false);
    return lstTiposTemp;
  };

  const bucaDadosFormalizacaoFiltro = () => {
    let dataInicial = null;
    let dataFinal = null;
    let vigInicial = null;
    let vigFinal = null;

    if (inclusaoInicial && inclusaoInicial.isValid()) {
      dataInicial = inclusaoInicial.format('YYYY-MM-DD');
    } else {
      setInclusaoInicial(null);
    }

    if (inclusaoFinal && inclusaoFinal.isValid()) {
      dataFinal = inclusaoFinal.format('YYYY-MM-DD');
    } else {
      setInclusaoFinal(null);
    }

    if (vigenciaInicial && vigenciaInicial.isValid()) {
      vigInicial = vigenciaInicial.format('YYYY-MM-DD');
    } else {
      setVigenciaInicial(null);
    }

    if (vigenciaFinal && vigenciaFinal.isValid()) {
      vigFinal = vigenciaFinal.format('YYYY-MM-DD');
    } else {
      setVigenciaFinal(null);
    }

    const params = {};
    params.inclusao_inicial = dataInicial;
    params.inclusao_final = dataFinal;
    params.vigencia_inicial = vigInicial;
    params.vigencia_final = vigFinal;
    params.cod_tipos_contratos = valueTiposContratos;
    params.cod_cliente = codCliente;
    params.status = valueStatus;
    buscarDadosGrid(params);
  };

  const buscarDadosGrid = (params = false) => {
    if (!params) {
      return;
    }

    setIsPending(true);
    getDadosContratosGrid(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstDadosGrid(res.data);
        } else {
          setLstDadosGrid([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        if (lstTipos && Object.keys(lstTipos).length > 0) {
          setIsPending(false);
        }
      });
  };

  const buscarGarantiasLimites = () => {
    const params = {
      cod_cliente: codCliente
    };

    getBuscaLimitesGarantias(params)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
          const dados = res.data;
          setLstGarantias(dados);
        } else {
          setLstGarantias([]);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleOnChangeTiposContratos = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueTiposContratos(newValue.value);
    } else {
      setValueTiposContratos('');
    }
  };

  const handleOnChangeStatus = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueStatus(newValue.value);
    } else {
      setValueStatus('');
    }
  };

  const handleOnChangeContrato = (event, idValue) => {
    if (escopoClienteDados && escopoClienteDados.permite_alterar) {
      const statusValue = event.target.checked ? 'A' : 'I';
      setDadosAlteracao({ status: statusValue, cod_cliente_formalizacao_empresas: idValue });

      setMsgTitleAlert('Atenção');
      setMsgAlert(
        `Esta ação deixará essa informação ${
          event.target.checked ? 'ativada' : 'desabilitada'
        } em todo o sistema. Você quer mesmo continuar?`
      );
      setMostrarAlerta(true);
    }
  };

  const alteraStatusContrato = () => {
    if (!dadosAlteracao) {
      return;
    }

    setIsPending(true);
    alterarStatusContrato(dadosAlteracao)
      .then(res => {
        const novosDados = res.data;

        if (novosDados.empresa_ativa) {
          setAlerta({
            mensagem:
              'Já existe um cadastro ativo para esta empresa, por favor inative o cadastro anterior!',
            tipo: 'warning'
          });
          return;
        }

        bucaDadosFormalizacaoFiltro();
        setDadosAlteracao({});
        setAlerta({
          mensagem: 'Status do contrato alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const modalIncluirContratos = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Adicionar contrato',
      maxWidth: 'md',
      conteudoModal: (
        <FormContrato
          formDados={dadosGerais}
          codCliente={codCliente}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          funcaoSucesso={() => bucaDadosFormalizacaoFiltro()}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  const modalGerarContratos = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Contratos',
      maxWidth: 'lg',
      conteudoModal: (
        <GerarContratos
          formDados={dadosGerais}
          codCliente={codCliente}
          operaConnect={operaConnect}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  const modalAdicionarAditivo = dados => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Adicionar aditivo',
      maxWidth: 'md',
      conteudoModal: (
        <AdicionarAditivo
          formDados={dados}
          codCliente={codCliente}
          dadosContrato={dadosRow}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          handleClose={() => setModalClienteDados({})}
          funcaoSucesso={() => bucaDadosFormalizacaoFiltro()}
        />
      )
    });
  };

  const modalIncluirGarantia = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Adicionar garantia',
      maxWidth: 'md',
      conteudoModal: (
        <FormGarantia
          formDados={dadosGerais}
          dadosContrato={dadosRow}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  function downloadWordDocument(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  function capitalizarPrimeiraLetra(sentence) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
  }

  const gerarContratos = tipoArquivo => {
    setIsPending(true);

    const permitegarantia =
      dadosRow.CODTIPOCONTRATO === TIPOSCONTRATO.COM_COOBRIGACAO_E_GARANTIA_IMOVEL ? true : false;
    const params = {
      codCliente: codCliente,
      validaDocumento: false,
      permitegarantia: permitegarantia,
      coobrigacao: dadosRow.COOBRIGACAO,
      codEmpresa: dadosRow.CODEMPRESA,
      tipoArquivo: tipoArquivo,
      operaConnect: operaConnect,
      salvarDocumento: false
    };

    getBuscaContratos(params)
      .then(res => {
        const dados = res.data;
        const base64regex = /^[A-Za-z0-9+/=]+\s*$/;
        if (tipoArquivo === 'pdf') {
          if (typeof dados === 'string' && base64regex.test(dados)) {
            const binaryString = atob(dados);
            const blob = new Blob(
              [new Uint8Array(binaryString.length).map((_, i) => binaryString.charCodeAt(i))],
              { type: 'application/pdf' }
            );
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          }
        } else if (tipoArquivo === 'word') {
          const nomeContratoFormatado = dadosRow.NOMETIPOCONTRATO.split(' ')
            .map(palavra => capitalizarPrimeiraLetra(palavra))
            .join('');
          const nomeArquivo = `Contrato${nomeContratoFormatado}.docx`;
          downloadWordDocument(dados, nomeArquivo);
        }
      })
      .catch(err => {
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const abrirModalLimite = () => {
    setModalClienteDados({
      openModal: true,
      tituloModal: 'Condições de garantia por empresa',
      maxWidth: 'sm',
      conteudoModal: (
        <LimitesGarantias
          codCliente={codCliente}
          setAlerta={setAlerta}
          lstGarantias={lstGarantias}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  const mostrarDetalhesContrato = () => {
    setMostrarTela({
      titulo: `${mascararCpfCnpj(String(cliente.cnpj))} - ${cliente.nome_cliente}`,
      subTitulo: `Formalização e contratos > ${dadosRow.NOMEEMPRESA}`,
      conteudoTela: (
        <DetalhesContrato
          lstTipos={lstTipos}
          dadosContrato={dadosRow}
          escopoClienteDados={escopoClienteDados}
          setModalClienteDados={setModalClienteDados}
          setAlerta={setAlerta}
          codCliente={codCliente}
          setDadosRow={setDadosRow}
          funcaoSucesso={() => bucaDadosFormalizacaoFiltro()}
        />
      )
    });
  };

  const mostrarAditivos = dados => {
    setMostrarTela({
      titulo: `${mascararCpfCnpj(String(cliente.cnpj))} - ${cliente.nome_cliente}`,
      subTitulo: `Formalização e contratos > ${dados.NOMEEMPRESA}`,
      subTituloSubNivel: `Formalização e contratos > Aditivos > ${dados.NOMEEMPRESA}`,
      corFundo: 'white',
      corFundoSubNivel: 'inherit',
      bordaMain: 'none !important',
      conteudoTela: (
        <Aditivos
          lstTipos={lstTipos}
          dadosContrato={dados}
          setAlerta={setAlerta}
          setMostrarTela={setMostrarTela}
          escopoClienteDados={escopoClienteDados}
          setModalClienteDados={setModalClienteDados}
          setMostraDetalhesAditivo={setEsconderTelaComplementar}
        />
      ),
      conteudoTelaSubNivel: (
        <DetalhesAditivo
          lstTipos={lstTipos}
          dadosContrato={dados}
          setAlerta={setAlerta}
          escopoClienteDados={escopoClienteDados}
          setModalClienteDados={setModalClienteDados}
        />
      )
    });
  };

  const lstStatus = [
    { label: 'Ativo', value: 'A' },
    { label: 'Inativo', value: 'I' }
  ];

  return (
    <BoxPaper
      iconeTitulo={<Feed />}
      labelTitulo="Contratos"
      mostrarBotoes={true}
      textButton="Incluir contrato"
      funcaoIncluirContratos={modalIncluirContratos}
      funcaoGerarContratos={modalGerarContratos}
      isPending={isPending}
    >
      <Grid
        container
        className="margin-top-30"
        spacing={2}
        sx={
          isPending
            ? { position: 'relative', pr: '10px', alignItems: 'end' }
            : { pr: '10px', alignItems: 'end' }
        }
      >
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5">
            Tipos de contrato:
          </Typography>

          <Autocomplete
            disablePortal
            id="TiposContratos"
            options={lstTiposContratos || []}
            value={
              valueTiposContratos && lstTiposContratos.length > 0
                ? lstTiposContratos.find(option => option.value === valueTiposContratos)
                : ''
            }
            onChange={handleOnChangeTiposContratos}
            name="TiposContratos"
            className="select-documentos-formalizacao ajuste-height pad-left-5"
            isOptionEqualToValue={(option, value) => option.value === value.value}
            forcePopupIcon
            freeSolo
            disabled={lstTiposContratos && lstTiposContratos.length === 0}
            renderInput={params => (
              <TextFieldMui
                {...params}
                name="TiposContratos"
                placeholder="Todos"
                autoComplete="off"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off'
                }}
              />
            )}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5">
            Inclusão:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
            <DatePicker
              name="data_agendamento_ini_filtro"
              className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-formalizacao"
              value={inclusaoInicial}
              maxDate={inclusaoFinal}
              disableFuture
              onChange={dataMoment => setInclusaoInicial(dataMoment)}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                field: {
                  clearable: true,
                  onClear: () => setInclusaoInicial(null)
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5">
            Até:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
            <DatePicker
              name="data_agendamento_filtro"
              className="w-100 date-time-picker-intervalo data-intervalo-fim date-picker-formalizacao"
              value={inclusaoFinal}
              minDate={inclusaoInicial}
              onChange={dataMoment => setInclusaoFinal(dataMoment)}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                field: {
                  clearable: true,
                  onClear: () => setInclusaoFinal(null)
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5">
            Vigência:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
            <DatePicker
              name="data_agendamento_ini_filtro"
              className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-formalizacao"
              value={vigenciaInicial}
              maxDate={vigenciaFinal}
              disableFuture
              onChange={dataMoment => setVigenciaInicial(dataMoment)}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                field: {
                  clearable: true,
                  onClear: () => setVigenciaInicial(null)
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5">
            Até:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
            <DatePicker
              name="data_agendamento_filtro"
              className="w-100 date-time-picker-intervalo data-intervalo-fim date-picker-formalizacao"
              value={vigenciaFinal}
              minDate={vigenciaInicial}
              onChange={dataMoment => setVigenciaFinal(dataMoment)}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                field: {
                  clearable: true,
                  onClear: () => setVigenciaFinal(null)
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xl={1} lg={2} md={2} sm={6} xs={12}>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5">
            Status:
          </Typography>

          <Autocomplete
            disablePortal
            id="plataformas"
            options={lstStatus || []}
            value={
              valueStatus && lstStatus.length > 0
                ? lstStatus.find(option => option.value === valueStatus)
                : ''
            }
            onChange={handleOnChangeStatus}
            name="plataformas"
            className="select-documentos-formalizacao ajuste-height pad-left-5"
            isOptionEqualToValue={(option, value) => option.value === value.value}
            forcePopupIcon
            freeSolo
            disabled={lstStatus.length === 0}
            renderInput={params => (
              <TextFieldMui
                {...params}
                name="plataformas"
                placeholder="Todos"
                autoComplete="off"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off'
                }}
              />
            )}
          />
        </Grid>

        <Grid item xl={1} lg={2} md={2} sm={12} xs={12} sx={{ paddingLeft: '21px !important' }}>
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
          />
          <Button
            className="btn-main"
            onClick={() => bucaDadosFormalizacaoFiltro()}
            sx={{ maxWidth: '140px', height: '43px !important' }}
          >
            Filtrar &nbsp; <Search />
          </Button>
        </Grid>

        <Grid item xl={12} lg={10} md={10} sm={12} xs={12} sx={{ paddingLeft: '21px !important' }}>
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
          />

          {lstGarantias && lstGarantias.length > 0 && (
            <Button className="btn-mais-info" onClick={() => abrirModalLimite()}>
              <ErrorOutlineOutlinedIcon />
              <span>
                <span className="color-black"> Limite para garantia aprovado. </span>
                <span className="color-blue"> Mais informações </span>
              </span>
            </Button>
          )}
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataGrid
            className="data-grid-formalizacao-documentos"
            columns={DefinicaoDeColunas(
              handleOnChangeContrato,
              escopoClienteDados,
              modalAdicionarAditivo,
              modalIncluirGarantia,
              gerarContratos,
              mostrarDetalhesContrato,
              mostrarAditivos,
              dadosRow,
              setDadosRow,
              codCliente
            )}
            rows={lstDadosGrid ?? []}
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            autoHeight
            disableColumnMenu
            sx={{
              margin: '15px 0 0 0',
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit !important'
              }
            }}
            localeText={{
              ...ptBR.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: 'Não há contratos cadastrados'
            }}
            sortingOrder={['desc', 'asc']}
            sortModel={sortModel}
            onSortModelChange={model => setSortModel(model)}
            pageSizeOptions={[5, 25, 100]}
            paginationModel={paginationModelGridLeads}
            onPaginationModelChange={setPaginationModelGridLeads}
          />
        </Grid>
      </Grid>

      <AlertMain
        exibeAlerta={mostrarAlerta}
        setExibeAlerta={setMostrarAlerta}
        titleAlerta={msgTitleAlert}
        msgAlerta={msgAlert}
        funcaoConfirmacao={alteraStatusContrato}
      />
    </BoxPaper>
  );
};
export default memo(Contratos);
