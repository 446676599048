import React, { useEffect, useState } from 'react';
import { Box, Drawer } from '@mui/material';
import ListMenuItems from './ListMenuItems';

const Sidebar = ({ menuOpen, handleDrawerToggle, drawerWidth, window, escopoAcesso }) => {
  const container = window !== undefined ? () => window().document.body : undefined;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!menuOpen) {
      setExpanded(false);
    }
  }, [menuOpen]);

  const handleChangeAccordion = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      component="nav"
      sx={{ width: { md: 70 }, flexShrink: { md: 0 } }}
      aria-label="mailbox folders"
      className="sidebar-nav"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={menuOpen}
        onClose={handleDrawerToggle}
        className="sidebar-nav"
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          },
          '& .sidebar-icon': {
            marginLeft: '-2px !important'
          }
        }}
      >
        <ListMenuItems
          menuOpen={menuOpen}
          expanded={expanded}
          setExpanded={setExpanded}
          handleDrawerToggle={handleDrawerToggle}
          escopoAcesso={escopoAcesso}
          handleChangeAccordion={handleChangeAccordion}
        />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
        open
      >
        <ListMenuItems
          menuOpen={menuOpen}
          expanded={expanded}
          setExpanded={setExpanded}
          handleDrawerToggle={handleDrawerToggle}
          escopoAcesso={escopoAcesso}
          handleChangeAccordion={handleChangeAccordion}
        />
      </Drawer>
    </Box>
  );
};

export default Sidebar;
