import React from 'react';
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const URL = 'https://wa.me';

const WhatsAppContact = ({ number, message, onClick }) => {
  let url = `${URL}/${number}`;

  if (message) {
    url += `?text=${encodeURI(message)}`;
  }

  return number && (number.length === 10 || number.length === 11) ? (
    <Link
      to={url}
      target="_blank"
      variant="text"
      className="whats-app-link"
      onClick={e => {
        window.open(url);

        if (onClick) {
          onClick(e);
        }
      }}
    >
      <WhatsAppIcon className="whats-app-color" />
      {`+55${number}`}
    </Link>
  ) : (
    number ?? 'Não informado'
  );
};

export default WhatsAppContact;
