import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import TextFieldMui from '@mui/material/TextField';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ptBR as ptBRDataPickers } from '@mui/x-date-pickers/locales';
import { Close, Search } from '@mui/icons-material';
import definicaoDeColunas from './definicoes-grid';
import LoaderMain from '../../../Shared/LoaderMain';
import { getDadosCardGrid } from '../../../../api/formalizacao';

const ModalDashboard = ({
  dadosModalDashboard,
  setDadosModalDashboard,
  lstResponsaveisFormalizacao,
  lstTiposPpc,
  lstPlataformas,
  mostrarCliente,
  setMostrarCliente,
  atualizarDashboard
}) => {
  const [isPending, setIsPending] = useState(false);
  const [lstDadosGrid, setLstDadosGrid] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [paginationModelGridLeads, setPaginationModelGridLeads] = useState({
    pageSize: 5,
    page: 0
  });
  const [valueResponsavel, setValueResponsavel] = useState('');
  const [valueTiposPpc, setValueTiposPpc] = useState('');
  const [valuePlataforma, setValuePlataforma] = useState('');
  const [valueDesbloqueio, setValueDesbloqueio] = useState('');
  const [nomeClienteValue, setNomeClienteValue] = useState('');
  const [cnpjValue, setValueCNPJ] = useState('');
  const [periodoEtapaInicio, setPeriodoEtapaInicio] = useState(null);
  const [periodoEtapaFinal, setPeriodoEtapaFinal] = useState(null);
  const localizacaoBrasil = ptBRDataPickers.components.MuiLocalizationProvider.defaultProps.localeText;

  useEffect(() => {
    setIsPending(true);
    setLstDadosGrid(dadosModalDashboard.grid ?? false);
    setIsPending(false);

    if (dadosModalDashboard === false) {
      setValueResponsavel('');
      setValueTiposPpc('');
      setValuePlataforma('');
      setLstDadosGrid(false);
      setIsPending(false);
      setNomeClienteValue('');
      setValueCNPJ('');
      setPaginationModelGridLeads({ pageSize: 5, page: 0 });
      setSortModel([]);
    }

    if (dadosModalDashboard && dadosModalDashboard.buscarDadosCard) {
      if (dadosModalDashboard.cod_responsavel !== '') {
        setValueResponsavel(dadosModalDashboard.cod_responsavel);
      }

      if (dadosModalDashboard.cod_tipo_ppc !== '') {
        setValueTiposPpc(dadosModalDashboard.cod_tipo_ppc);
      }

      if (dadosModalDashboard.nome_cliente && dadosModalDashboard.nome_cliente !== '') {
        setNomeClienteValue(dadosModalDashboard.nome_cliente);
      }

      if (dadosModalDashboard.cnpj && dadosModalDashboard.cnpj !== '') {
        const cnpjFormatado = formatCNPJ(dadosModalDashboard.cnpj);
        setValueCNPJ(cnpjFormatado);
      }

      buscarDadosCard();
    }
  }, [dadosModalDashboard, atualizarDashboard]);

  const bucaDadosFormalizacaoFiltro = () => {
    const params = dadosModalDashboard;
    params.cod_responsavel = valueResponsavel;
    params.cod_tipo_ppc = valueTiposPpc;
    params.cod_plataforma = valuePlataforma;
    params.desbloqueio = valueDesbloqueio;
    params.nome_cliente = nomeClienteValue;
    params.cnpj = cnpjValue ? cnpjValue.replace(/\D/g, '') : '';

    if (periodoEtapaInicio && periodoEtapaInicio.isValid()) {
      params.data_etapa_inicial = periodoEtapaInicio.format('YYYY-MM-DD');
    } else {
      setPeriodoEtapaInicio(null);
      params.data_etapa_inicial = null;
    }

    if (periodoEtapaFinal && periodoEtapaFinal.isValid()) {
      params.data_etapa_final = periodoEtapaFinal.format('YYYY-MM-DD');
    } else {
      setPeriodoEtapaFinal(null);
      params.data_etapa_final = null;
    }

    buscarDadosCard(params);
  };

  const buscarDadosCard = (filtro = false) => {
    setIsPending(true);

    let params = dadosModalDashboard;
    if (filtro) {
      params = filtro;
    }

    getDadosCardGrid(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLstDadosGrid(res.data);
        } else {
          setLstDadosGrid([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleOnChangeResponsaveis = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueResponsavel(newValue.value);
    } else {
      setValueResponsavel('');
    }
  };

  const handleOnChangeTiposPpc = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueTiposPpc(newValue.value);
    } else {
      setValueTiposPpc('');
    }
  };

  const handleOnChangePlataformas = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValuePlataforma(newValue.value);
    } else {
      setValuePlataforma('');
    }
  };

  const handleOnChangeDesbloqueio = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueDesbloqueio(newValue.value);
    } else {
      setValueDesbloqueio('');
    }
  };

  const lstTiposDesbloqueio = [
    {
      label: 'Sim',
      value: 'S'
    },
    {
      label: 'Não',
      value: 'N'
    }
  ];

  const handleChangeNomeCliente = event => {
    const { value } = event.target;
    setNomeClienteValue(value);
  };

  const handleChangeCnpj = event => {
    const { value } = event.target;
    const cnpj = formatCNPJ(value);
    setValueCNPJ(cnpj ?? '');
  };

  const formatCNPJ = value => {
    let cleaned = value.replace(/\D/g, '');

    if (cleaned.length < 1) {
      return '';
    }

    if (cleaned.length > 14) {
      cleaned = cleaned.substring(0, 14);
    }

    let formatted = '';
    for (let i = 0; i < cleaned.length; i++) {
      if (i === 2 || i === 5) {
        formatted += '.';
      } else if (i === 8) {
        formatted += '/';
      } else if (i === 12) {
        formatted += '-';
      }
      formatted += cleaned[i];
    }
    return formatted;
  };

  return (
    <Dialog
      open={dadosModalDashboard !== false && mostrarCliente === false}
      fullWidth={true}
      className="modal-dashboard modal-formalizacao"
      maxWidth="xl"
      keepMounted
      onClose={() => setDadosModalDashboard(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="borda-bottom-2-gray pad-top-10">
          <Box sx={{ marginTop: '-15px', height: '35px' }}>
            <IconButton onClick={() => setDadosModalDashboard(false)} className="float-right">
              <Close />
            </IconButton>
          </Box>
          <Box className="margin-top-10">
            <Typography variant="h5" className="texto-cor-principal texto-negrito margin-bottom-5">
              {dadosModalDashboard.titleModal}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        {lstDadosGrid ? (
          <>
            <Grid container className="margin-bottom-20" spacing={2} sx={{ pr: '10px' }}>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  CNPJ:
                </Typography>

                <TextFieldMui
                  className="input-formalizacao w-100"
                  variant="outlined"
                  value={cnpjValue}
                  placeholder="Digite o CNPJ"
                  onChange={handleChangeCnpj}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  Cliente:
                </Typography>

                <TextFieldMui
                  className="input-formalizacao w-100"
                  variant="outlined"
                  value={nomeClienteValue}
                  placeholder="Digite o nome"
                  onChange={handleChangeNomeCliente}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  Tipo:
                </Typography>

                <Autocomplete
                  disablePortal
                  id="tiposPPC"
                  options={lstTiposPpc || []}
                  value={
                    valueTiposPpc && lstTiposPpc.length > 0
                      ? lstTiposPpc.find(option => option.value === valueTiposPpc)
                      : ''
                  }
                  onChange={handleOnChangeTiposPpc}
                  name="tiposPPC"
                  className="select-responsaveis-formalizacao ajuste-height pad-left-5"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  forcePopupIcon
                  freeSolo
                  disabled={lstTiposPpc && lstTiposPpc.length === 0}
                  renderInput={params => (
                    <TextFieldMui
                      {...params}
                      name="tiposPPC"
                      placeholder="Todos"
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off'
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  Plataforma:
                </Typography>

                <Autocomplete
                  disablePortal
                  id="plataformas"
                  options={lstPlataformas || []}
                  value={
                    valuePlataforma && lstPlataformas.length > 0
                      ? lstPlataformas.find(option => option.value === valuePlataforma)
                      : ''
                  }
                  onChange={handleOnChangePlataformas}
                  name="plataformas"
                  className="select-responsaveis-formalizacao ajuste-height pad-left-5"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  forcePopupIcon
                  freeSolo
                  disabled={lstPlataformas && lstPlataformas.length === 0}
                  renderInput={params => (
                    <TextFieldMui
                      {...params}
                      name="plataformas"
                      placeholder="Todos"
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off'
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  Responsável:
                </Typography>

                <Autocomplete
                  disablePortal
                  id="responsaveisFormalizacao"
                  options={lstResponsaveisFormalizacao || []}
                  value={
                    valueResponsavel && lstResponsaveisFormalizacao.length > 0
                      ? lstResponsaveisFormalizacao.find(option => option.value === valueResponsavel)
                      : ''
                  }
                  onChange={handleOnChangeResponsaveis}
                  name="responsaveisFormalizacao"
                  className="select-responsaveis-formalizacao ajuste-height pad-left-5"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  forcePopupIcon
                  freeSolo
                  disabled={lstResponsaveisFormalizacao && lstResponsaveisFormalizacao.length === 0}
                  renderInput={params => (
                    <TextFieldMui
                      {...params}
                      name="responsaveisFormalizacao"
                      placeholder="Todos"
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off'
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  Desbloqueados:
                </Typography>

                <Autocomplete
                  disablePortal
                  id="responsaveisFormalizacao"
                  options={lstTiposDesbloqueio || []}
                  value={
                    valueDesbloqueio && lstTiposDesbloqueio.length > 0
                      ? lstTiposDesbloqueio.find(option => option.value === valueDesbloqueio)
                      : ''
                  }
                  onChange={handleOnChangeDesbloqueio}
                  name="responsaveisFormalizacao"
                  className="select-responsaveis-formalizacao ajuste-height pad-left-5"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  forcePopupIcon
                  freeSolo
                  disabled={lstTiposDesbloqueio && lstTiposDesbloqueio.length === 0}
                  renderInput={params => (
                    <TextFieldMui
                      {...params}
                      name="responsaveisFormalizacao"
                      placeholder="Selecione uma opção"
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off'
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  Etapa atual (início):
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                  <DatePicker
                    name="data_agendamento_ini_filtro"
                    className="w-100 data-intervalo-ini date-picker-formalizacao"
                    value={periodoEtapaInicio}
                    maxDate={periodoEtapaFinal}
                    disableFuture
                    onChange={dataMoment => setPeriodoEtapaInicio(dataMoment)}
                    slotProps={{
                      inputAdornment: {
                        position: 'start'
                      },
                      field: {
                        clearable: true,
                        onClear: () => setPeriodoEtapaInicio(null)
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  Etapa atual (até):
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                  <DatePicker
                    name="data_agendamento_filtro"
                    className="w-100 data-intervalo-fim date-picker-formalizacao"
                    value={periodoEtapaFinal}
                    minDate={periodoEtapaInicio}
                    onChange={dataMoment => setPeriodoEtapaFinal(dataMoment)}
                    slotProps={{
                      inputAdornment: {
                        position: 'start'
                      },
                      field: {
                        clearable: true,
                        onClear: () => setPeriodoEtapaFinal(null)
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-left-5 margin-bottom-5"
                >
                  &nbsp;
                </Typography>
                <Button
                  className="btn-main pad-5tb-10lr"
                  onClick={() => bucaDadosFormalizacaoFiltro()}
                  sx={{ maxWidth: '140px', maxHeight: '42px', marginLeft: '5px' }}
                >
                  Filtrar &nbsp; <Search />
                </Button>
              </Grid>
            </Grid>

            <DataGrid
              className="data-grid-leads data-grid-formalizacao data-grid-separator-header-hidden data-grid-borderless"
              columns={definicaoDeColunas(setMostrarCliente)}
              rows={lstDadosGrid ?? []}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              getRowId={lstDadosGrid => lstDadosGrid.id}
              autoHeight
              disableColumnMenu
              sx={{ margin: '15px 0 30px 0' }}
              localeText={{
                ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: 'Não há resultados encontrados'
              }}
              sortingOrder={['desc', 'asc']}
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              pageSizeOptions={[5, 25, 100]}
              paginationModel={paginationModelGridLeads}
              onPaginationModelChange={setPaginationModelGridLeads}
            />
          </>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalDashboard;
