import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { pegaLetrasInicias } from '../../Utils/pegaLetrasInicias';

const ObservacoesLead = ({ lstObservacoesLead }) => (
  <Box className="texto-centro pad-0 margin-top-20">
    {lstObservacoesLead && Object.keys(lstObservacoesLead).length > 0 && (
      <Box className="box-obs-lead">
        <Box className="pad-5 texto-centro sub-titulo border-radius-8 margin-top-10">
          <Typography
            variant="subtitle1"
            className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
          >
            Observações
          </Typography>
        </Box>
        <Grid className="time-line">
          {lstObservacoesLead.map(obs => (
            <Grid container key={obs.data_insert}>
              <Grid
                className="border-line-right"
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
              />
              <Grid
                className="border-line-left time-card"
                item
                xl={11}
                lg={11}
                md={11}
                sm={11}
                xs={11}
              >
                <Box className="timeline-badge" sx={{ background: obs.cor }}>
                  {pegaLetrasInicias(obs.nome_usuario)}
                </Box>
                <Box className="timeline-card">
                  <Box>
                    <Typography
                      variant="subtitle1"
                      className="timeline-title texto-cor-cinza-escuro font-bold"
                    >
                      <span className="timeline-inclusao">
                        {`${obs.data_insert} por ${obs.nome_usuario}`}
                      </span>
                    </Typography>
                  </Box>

                  <Field
                    component={TextField}
                    type="text"
                    className="input-field input-observacao-leads"
                    placeholder="Escreva aqui sua observação"
                    name={`${obs.data_insert.replaceAll(' ', '')}_observacao_lead`}
                    id={`${obs.data_insert.replaceAll(' ', '')}_observacao_lead`}
                    autoComplete="off"
                    value={obs.observacao}
                    disabled={true}
                    multiline
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    )}
  </Box>
);

export default ObservacoesLead;
