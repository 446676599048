import React from 'react';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import { RadioGroup } from 'formik-mui';

const RadioGroupMain = ({
  className = 'radio-cor-principal',
  form,
  disabled,
  field,
  erro = '',
  onChangeRadio = null,
  options = [],
  ...props
}) => {
  const nomeInput = field && field.name ? field.name : '';
  let erroTexto = form && form.errors && form.errors[nomeInput] ? form.errors[nomeInput] : '';
  erroTexto = erro !== '' ? erro : erroTexto;

  return (
    <>
      <RadioGroup
        {...props}
        form={form}
        field={{
          name: field.name,
          value: field.value,
          onBlur: field.onBlur,
          onChange: event => {
            if (field && typeof field.onChange === 'function') {
              field.onChange(event);
            }
            if (typeof onChangeRadio === 'function') {
              onChangeRadio(event, field.value);
            }
          }
        }}
        className={className}
      >
        {options &&
          options.map((option, index) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio disabled={disabled} />}
              label={option.label}
              disabled={disabled}
            />
          ))}
      </RadioGroup>
      {erroTexto ? <Typography className="texto-danger label-padding">{erroTexto}</Typography> : ''}
    </>
  );
};
export default RadioGroupMain;
