import React, { memo, useEffect, useState } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR as DatePtBr } from '@mui/x-date-pickers/locales';
import { focusInput } from '../../../../Utils/utils';
import LoaderMain from '../../../../Shared/LoaderMain';
import TextFieldMain from '../../../../Shared/TextFieldMain';
import CheckBoxMain from '../../../../Shared/CheckBoxMain';
import { salvarDadosCliente } from '../../../../../api/formalizacao';
import ButtonMain from '../../../../Shared/ButtonMain';
import { unmaskValue, IsEmpty, NotIsEmpty, dateFormat } from '../../../../Utils/format';
import SelectMain from '../../../../Shared/SelectMain';
import AutocompleteMain from '../../../../Shared/AutocompleteMain';

const FormInformacoesGerais = ({
  formDados = {},
  dadosCliente,
  setDadosCliente,
  setAlerta,
  lstTipos,
  handleClose
}) => {
  const [isPendingSalvar, setPendingSalvar] = useState(false);
  const [valueDataJunta, setValueDataJunta] = useState(null);
  const [valueDataFundacao, setValueDataFundacao] = useState(null);
  const localizacaoBrasil = DatePtBr.components.MuiLocalizationProvider.defaultProps.localeText;

  useEffect(() => {
    if (formDados && formDados.data_junta_orig) {
      const dataRegJunta = moment(formDados.data_junta_orig);
      setValueDataJunta(dataRegJunta);
    }
    if (formDados && formDados.fundacao_orig) {
      const dataFundacao = moment(formDados.fundacao_orig);
      setValueDataFundacao(dataFundacao);
    }
  }, []);

  const submitFormulario = (values, form) => {
    const params = { ...values };
    params.constituicao = NotIsEmpty(params.constituicao) ? params.constituicao : null;
    params.regime_tributario = NotIsEmpty(params.regime_tributario) ? params.regime_tributario : null;
    params.codTipoPorteEmpresa = NotIsEmpty(params.codTipoPorteEmpresa)
      ? params.codTipoPorteEmpresa
      : null;
    params.rating = NotIsEmpty(params.rating) ? params.rating : null;
    params.natureza_juridica =
      NotIsEmpty(params.natureza_juridica) && NotIsEmpty(params.natureza_juridica._id)
        ? params.natureza_juridica._id
        : null;
    params.atividade =
      NotIsEmpty(params.atividade) && NotIsEmpty(params.atividade._id) ? params.atividade._id : null;
    params.capital_social = unmaskValue(params.capital_social);
    params.cnpj = formDados.cnpj;
    params._id = formDados._id;

    if (valueDataJunta && valueDataJunta.isValid()) {
      params.data_junta = valueDataJunta.format('YYYY-MM-DD');
    } else {
      setValueDataJunta(null);
      params.data_junta = null;
    }

    if (valueDataFundacao && valueDataFundacao.isValid()) {
      params.fundacao = valueDataFundacao.format('YYYY-MM-DD');
    } else {
      setValueDataFundacao(null);
      params.fundacao = null;
    }

    setPendingSalvar(true);
    salvarDadosCliente(params)
      .then(res => {
        if (res && res.data && res.data.informacoes_gerais) {
          const dadosClienteTemp = { ...dadosCliente };
          dadosClienteTemp.informacoes_gerais = res.data.informacoes_gerais;
          setDadosCliente(dadosClienteTemp);
          handleClose();
          setAlerta({
            mensagem: 'Dados do cliente foram salvos com sucesso',
            tipo: 'success'
          });
        } else {
          console.error('Retorno vazio');
          setAlerta({
            mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
            tipo: 'error'
          });
        }
      })
      .catch(err => {
        console.error(err);
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setPendingSalvar(false);
        form.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        fundacao: dateFormat(formDados.fundacao_orig),
        constituicao:
          NotIsEmpty(formDados.dados_constituicao) && formDados.dados_constituicao._id
            ? formDados.dados_constituicao._id
            : '',
        codTipoPorteEmpresa:
          NotIsEmpty(formDados.dados_porte_empresa) && formDados.dados_porte_empresa._id
            ? formDados.dados_porte_empresa._id
            : '',
        rating: NotIsEmpty(formDados.rating) ? formDados.rating : '',
        regime_tributario:
          NotIsEmpty(formDados.dados_regime_tributario) && formDados.dados_regime_tributario._id
            ? formDados.dados_regime_tributario._id
            : '',
        natureza_juridica:
          NotIsEmpty(formDados.dados_natureza_juridica) &&
          NotIsEmpty(formDados.dados_natureza_juridica._id)
            ? [...lstTipos.naturezaJuridica].filter(
                tipo => String(tipo._id) === String(formDados.dados_natureza_juridica._id)
              )[0]
            : null,
        atividade:
          NotIsEmpty(formDados.dados_atividade) && NotIsEmpty(formDados.dados_atividade._id)
            ? [...lstTipos.atividade].filter(
                tipo => String(tipo._id) === String(formDados.dados_atividade._id)
              )[0]
            : null,
        ...formDados
      }}
      onSubmit={(values, form) => {
        const errors = {};
        const {
          dados_constituicao,
          dados_regime_tributario,
          dados_porte_empresa,
          dados_natureza_juridica,
          dados_atividade,
          fundacao_orig,
          open,
          ...valores
        } = values;

        if (IsEmpty(valores.nome)) {
          errors.nome = 'Nome da empresa obrigatório';
        }

        if (NotIsEmpty(valores.fundacao)) {
          valores.fundacao = moment(valores.fundacao, 'DD/MM/YYYY');
          if (valores.fundacao.isValid() === false) {
            errors.fundacao = 'Data da fundação invalida';
          } else {
            valores.fundacao = valores.fundacao.format('YYYY-MM-DD');
          }
        }

        form.setErrors(errors);
        if (Object.keys(errors).length <= 0) {
          submitFormulario(valores, form);
        } else {
          form.setSubmitting(false);
          focusInput(Object.keys(errors)[0]);
        }
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          {isPendingSalvar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              CNPJ:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="cnpj"
                name="cnpj"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                tipoMascara="CNPJMask"
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Empresa:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="nome"
                name="nome"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Nome fantasia:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="nome_fantasia"
                name="nome_fantasia"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Inscrição estadual:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="inscricao_estadual"
                name="inscricao_estadual"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Inscrição municipal:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="inscricao_municipal"
                name="inscricao_municipal"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              NIRE:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="nire"
                name="nire"
                type="text"
                component={TextFieldMain}
                fullWidth
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Data de registro na junta:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                <DatePicker
                  name="data_junta"
                  className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
                  value={valueDataJunta}
                  onChange={dataMoment => setValueDataJunta(dataMoment)}
                  slotProps={{
                    inputAdornment: {
                      position: 'start'
                    },
                    field: {
                      clearable: true,
                      onClear: () => setValueDataJunta(null)
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Fundação:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <LocalizationProvider dateAdapter={AdapterMoment} localeText={localizacaoBrasil}>
                <DatePicker
                  name="fundacao"
                  className="w-100 date-time-picker-intervalo data-intervalo-ini date-picker-contrato"
                  value={valueDataFundacao}
                  onChange={dataMoment => setValueDataFundacao(dataMoment)}
                  slotProps={{
                    inputAdornment: {
                      position: 'start'
                    },
                    field: {
                      clearable: true,
                      onClear: () => setValueDataFundacao(null)
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Constituição:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.constituicao) && lstTipos.constituicao.length > 0 ? (
                <Field
                  id="constituicao"
                  name="constituicao"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione o tipo de constituição"
                  displayEmpty
                  displayEmptyLabel="Não definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.constituicao].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.constituicao) && lstTipos.constituicao.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Natureza jurídica:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.naturezaJuridica) && lstTipos.naturezaJuridica.length > 0 ? (
                <Field
                  id="natureza_juridica"
                  name="natureza_juridica"
                  component={AutocompleteMain}
                  boxMain={false}
                  propsInput={{
                    placeholder: 'Selecione o tipo de natur. juridica'
                  }}
                  disabled={isSubmitting ? true : false}
                  paramLabel="nome"
                  options={[...lstTipos.naturezaJuridica]}
                />
              ) : Array.isArray(lstTipos.naturezaJuridica) && lstTipos.naturezaJuridica.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Atividade:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.atividade) && lstTipos.atividade.length > 0 ? (
                <Field
                  id="atividade"
                  name="atividade"
                  component={AutocompleteMain}
                  propsInput={{
                    placeholder: 'Selecione o tipo de atividade'
                  }}
                  disabled={isSubmitting}
                  paramLabel="nome"
                  options={[...lstTipos.atividade]}
                />
              ) : Array.isArray(lstTipos.atividade) && lstTipos.atividade.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Regime tributário:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.regimeTributario) && lstTipos.regimeTributario.length > 0 ? (
                <Field
                  id="regime_tributario"
                  name="regime_tributario"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione o tipo de regime tributário"
                  displayEmpty
                  displayEmptyLabel="Não Definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.regimeTributario].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.regimeTributario) && lstTipos.regimeTributario.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Capital social:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                id="capital_social"
                name="capital_social"
                type="text"
                component={TextFieldMain}
                fullWidth
                tipoMascara="number-money"
                autoComplete="off"
                disabled={isSubmitting}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Porte da Empresa:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.portesEmpresa) && lstTipos.portesEmpresa.length > 0 ? (
                <Field
                  id="codTipoPorteEmpresa"
                  name="codTipoPorteEmpresa"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione o tipo de porte da empresa"
                  displayEmpty
                  displayEmptyLabel="Não Definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.portesEmpresa].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.portesEmpresa) && lstTipos.portesEmpresa.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input">
              Rating:
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              {Array.isArray(lstTipos.scoreRatings) && lstTipos.scoreRatings.length > 0 ? (
                <Field
                  id="rating"
                  name="rating"
                  type="text"
                  boxMain={false}
                  component={SelectMain}
                  placeholder="Selecione o tipo de rating do cliente"
                  displayEmpty
                  displayEmptyLabel="Não Definido"
                  autoComplete="off"
                  disabled={isSubmitting}
                  opcoes={[...lstTipos.scoreRatings].map(tipo => ({
                    label: tipo.nome,
                    valor: tipo._id
                  }))}
                />
              ) : Array.isArray(lstTipos.scoreRatings) && lstTipos.scoreRatings.length === 0 ? (
                <Alert severity="error" className="loader-box">
                  Ocorreu algum erro, favor entrar em contato com o suporte
                </Alert>
              ) : (
                <Box className="loader-box">
                  <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className="input-line">
            <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="label-input" />
            <Grid item xl={8} lg={8} md={8} sm={9} xs={10}>
              <Field
                controlLabelCheckbox="Empresa em Recuperação Judicial (RJ)"
                id="recuperacao_judicial"
                name="recuperacao_judicial"
                component={CheckBoxMain}
                checked={values.recuperacao_judicial ? true : false}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ m: '20px 0' }}>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="transparente-com-bordas"
                disabled={isSubmitting}
                onClick={handleClose}
              >
                Cancelar
              </ButtonMain>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
              <ButtonMain
                size="medium"
                tipoBotao="azul-escuro"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Salvar
              </ButtonMain>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default memo(FormInformacoesGerais);
