import React from 'react';
import { Box, Grid } from '@mui/material';
import { LocationOnOutlined } from '@mui/icons-material';
import Enderecos from '../../shared/Enderecos';

const DadosEnderecos = ({
  lstDados,
  setDadosAposIncluirAlterar,
  setAlerta,
  setModalClienteDados,
  escopoClientePessoas
}) => (
  <Box className="enderecos-pessoa">
    <Grid container spacing={2} className="box-paper-header" sx={{ mb: '15px', mt: '10px' }}>
      <Grid item xl={4} lg={4} md={4} sm={3} xs={3} className="flex-box-items-direita">
        <Box className="box-icone-paper-header icone-gray">
          <LocationOnOutlined />
        </Box>
      </Grid>
      <Grid item xl={8} lg={8} md={8} sm={9} xs={9} className="titulo titulo-gray flex-box-items-esquerda">
        Endereços
      </Grid>
    </Grid>
    <Enderecos
      dadosEnderecos={lstDados.enderecos ?? []}
      dadosGerais={lstDados}
      setDadosGerais={setDadosAposIncluirAlterar}
      setAlerta={setAlerta}
      setModalClienteDados={setModalClienteDados}
      escopoEndereco={escopoClientePessoas}
      telaPai="DadosPessoa"
    />
    {lstDados && lstDados.enderecos && lstDados.enderecos.length === 0 && (
      <Box className="borda-bottom-2 borda-cor-cinza-claro-5 margin-topbot-25" />
    )}
  </Box>
);
export default DadosEnderecos;
