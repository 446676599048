import React, { memo, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Main from '../../Main/Main';
import NovasEntradas from './NovasEntradas/NovasEntradas';
import ModalDashboard from './ModalDashboard/ModalDashboard';
import MotivosGanho from './MotivosGanho/MotivosGanho';
import MotivosPerda from './MotivosPerda/MotivosPerda';
import StatusLead from './StatusCards/StatusCards';
import { getTiposPPC, getUsuariosResponsaveis } from '../../../api/formalizacao';
import { getPlataformas } from '../../../api/crm';
import Cliente from '../Cliente';

const Dashboard = ({ escopoAcesso }) => {
  const [permissoesFormalizacao, setPermissoesFormalizacao] = useState(false);
  const [dadosModalDashboard, setDadosModalDashboard] = useState(false);
  const [atualizarDashboard, setAtualizarDashboard] = useState(0);
  const [dadosCardsLeads, setDadosCardsLeads] = useState({});
  const [lstResponsaveisFormalizacao, setLstResponsaveisFormalizacao] = useState([]);
  const [lstTiposPpc, setLstTiposPpc] = useState([]);
  const [lstPlataformas, setLstPlataformas] = useState([]);
  const [mostrarCliente, setMostrarCliente] = useState(false);

  useEffect(() => {
    const dashFormalizacao = escopoAcesso['plataforma_formalizacao'].rota.dashboard ?? null;

    if (dashFormalizacao) {
      setPermissoesFormalizacao(dashFormalizacao);
    }
  }, [escopoAcesso]);

  useEffect(() => {
    getTiposPPC()
      .then(res => {
        if (res.data.length > 0) {
          const newArray = res.data.map(usu => ({
            value: usu.CODPPCTIPO,
            label: usu.NOME
          }));
          setLstTiposPpc(newArray);
        }
      })
      .catch(() => {})
      .finally(() => {});

    getUsuariosResponsaveis()
      .then(res => {
        if (res.data.length > 0) {
          const newArray = res.data.map(usu => ({
            value: usu.CODUSUARIOFORMALIZACAO,
            label: usu.NOME
          }));
          setLstResponsaveisFormalizacao(newArray);
        }
      })
      .catch(() => {})
      .finally(() => {});

    getPlataformas()
      .then(res => {
        if (res.data.length > 0) {
          const newArray = res.data.map(pla => ({
            value: pla.CODAGENCIA,
            label: pla.NOME
          }));

          setLstPlataformas(newArray);
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  const atualizarTodosDashboards = () => {
    const value = atualizarDashboard;
    setAtualizarDashboard(value + 1);
  };

  return (
    <Main title="Dashboard" className="tela-dashboard" escopoAcesso={escopoAcesso}>
      <Box className={`dashboard ${mostrarCliente ? 'hidden' : ''}`}>
        <Typography className="texto-cor-principal font-bold-600" variant="h6">
          Formalização
        </Typography>

        <Grid className="container-pricipal" container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <NovasEntradas
              setDadosModalDashboard={setDadosModalDashboard}
              dadosModalDashboard={dadosModalDashboard}
              atualizarDashboard={atualizarDashboard}
              permissoesFormalizacao={permissoesFormalizacao}
              atualizarTodosDashboards={atualizarTodosDashboards}
              setMostrarCliente={setMostrarCliente}
            />

            <StatusLead
              setDadosModalDashboard={setDadosModalDashboard}
              atualizarDashboard={atualizarDashboard}
              setDadosCardsLeads={setDadosCardsLeads}
              permissoesFormalizacao={permissoesFormalizacao}
              lstResponsaveisFormalizacao={lstResponsaveisFormalizacao}
              lstTiposPpc={lstTiposPpc}
            />

            <Grid container>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              >
                <MotivosGanho />
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  paddingLeft: {
                    xs: '0',
                    sm: '0',
                    md: '0.5%',
                    lg: '0.5%',
                    xl: '0.5%'
                  },
                  marginTop: '30px'
                }}
              >
                <MotivosPerda />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <ModalDashboard
        dadosModalDashboard={dadosModalDashboard}
        setDadosModalDashboard={setDadosModalDashboard}
        atualizarDashboard={atualizarDashboard}
        dadosCardsLeads={dadosCardsLeads}
        setDadosCardsLeads={setDadosCardsLeads}
        permissoesFormalizacao={permissoesFormalizacao}
        lstResponsaveisFormalizacao={lstResponsaveisFormalizacao}
        lstTiposPpc={lstTiposPpc}
        lstPlataformas={lstPlataformas}
        mostrarCliente={mostrarCliente}
        setMostrarCliente={setMostrarCliente}
      />

      {mostrarCliente && (
        <Cliente
          cnpj={mostrarCliente.cnpj ?? ''}
          escopoAcesso={escopoAcesso}
          setMostrarCliente={setMostrarCliente}
          lstResponsaveisFormalizacao={lstResponsaveisFormalizacao}
          codRelacionamentosFormalizacao={mostrarCliente.codRelacionamentosFormalizacao ?? ''}
          atualizarTodosDashboards={atualizarTodosDashboards}
        />
      )}
    </Main>
  );
};
export default memo(Dashboard);
