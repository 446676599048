import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, FormControlLabel, Grid, Switch, styled } from '@mui/material';
import { ModeEditOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ButtonMain from '../../../Shared/ButtonMain';
import FormGarantia from './Formularios/FormGarantia';
import LoaderMain from '../../../Shared/LoaderMain';
import { alterarStatusGarantia, getGarantiasContrato } from '../../../../api/formalizacao';
import AlertMain from '../../../Shared/AlertMain';
import { CATEGORIASGARANTIAS } from '../../../Shared/Enums/CategoriasGarantias';
import { STATUSIMOVELGARANTIAS } from '../../../Shared/Enums/StatusImovelGarantias';

const DetalhesGarantias = ({
  escopoClienteDados,
  setAlerta,
  setModalClienteDados,
  lstTipos,
  dadosContrato = false,
  dadosAditivo = false
}) => {
  const [isPending, setIsPending] = useState(false);
  const [lstGarantias, setLsGarantias] = useState([]);
  const [dadosAlteracao, setDadosAlteracao] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [msgTitleAlert, setMsgTitleAlert] = useState('');
  const [msgAlert, setMsgAlert] = useState('');
  const valorIndefinido = <em className="texto-cor-cinza">- -</em>;

  useEffect(() => {
    incluirGarantiaInicial();
    buscaGarantias();
  }, []);

  const buscaGarantias = () => {
    let params = {};
    if (dadosAditivo && Object.keys(dadosAditivo).length > 0) {
      params = {
        cod_cliente_formalizacao_aditivos: dadosAditivo.id
      };
    } else if (dadosContrato && Object.keys(dadosContrato).length > 0) {
      params = {
        cod_cliente_formalizacao_empresas: dadosContrato.id
      };
    }

    setIsPending(true);
    getGarantiasContrato(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setLsGarantias(res.data);
        } else {
          incluirGarantiaInicial();
        }
      })
      .catch(() => {})
      .finally(() => {
        setTimeout(() => {
          setIsPending(false);
        }, 1000);
      });
  };

  const incluirGarantiaInicial = () => {
    const dadosVazios = {
      id: '',
      tipo: '',
      valor: '',
      matricula: '',
      cartorio: '',
      data: '',
      validade: '',
      statusImovel: '',
      dataInclusao: '',
      responsavelInclusao: '',
      observacao: '',
      valor_leilao: '',
      descricao_imovel: ''
    };

    setLsGarantias([dadosVazios]);
  };

  const modalIncluirAlterarGarantia = dados => {
    let titulo = 'Adicionar';
    if (dados && dados.id) {
      titulo = 'Editar';
    }

    setModalClienteDados({
      openModal: true,
      tituloModal: `${titulo} garantia`,
      maxWidth: 'md',
      conteudoModal: (
        <FormGarantia
          formDados={dados}
          dadosContrato={dadosContrato}
          dadosAditivo={dadosAditivo}
          funcaoSucesso={() => buscaGarantias()}
          setAlerta={setAlerta}
          lstTipos={lstTipos}
          handleClose={() => setModalClienteDados({})}
        />
      )
    });
  };

  const handleOnChangeGarantia = (event, idValue) => {
    if (escopoClienteDados && escopoClienteDados.permite_alterar) {
      const statusValue = event.target.checked ? 'A' : 'I';
      setDadosAlteracao({ status: statusValue, cod_cliente_formalizacao_garantia: idValue });
      setMsgTitleAlert('Atenção');
      setMsgAlert(`Deseja realmente ${event.target.checked ? 'ativar' : 'inativar'} esta garantia?`);
      setMostrarAlerta(true);
    }
  };

  const alteraStatusGarantia = () => {
    if (!dadosAlteracao) {
      return;
    }

    setIsPending(true);
    alterarStatusGarantia(dadosAlteracao)
      .then(() => {
        buscaGarantias();
        setDadosAlteracao({});
        setAlerta({
          mensagem: 'Status da garantia alterado com sucesso!',
          tipo: 'success'
        });
      })
      .catch(() => {
        setAlerta({
          mensagem: 'Ocorreu um erro, contate o suporte!',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const IOSSwitch = styled(props => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#1A284D',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  }));

  return (
    <Box>
      <hr />

      <Grid container spacing={3} className="box-paper-header-detalhes margin-top-20">
        <Grid item xl={4} lg={4} md={4} sm={3} xs={6} className="flex-box-items-direita">
          <Box className="box-icone-paper-header">
            <WorkspacePremiumIcon />
          </Box>
        </Grid>
        <Grid item xl={7} lg={6} md={6} sm={5} xs={6} className="titulo">
          Garantia
        </Grid>
        <Grid item xl={1} lg={2} md={2} sm={4} xs={12} className="flex-box-items-direita pad-0">
          {escopoClienteDados && escopoClienteDados.permite_incluir && (
            <ButtonMain
              size="small"
              tipoBotao="azul-escuro"
              disabled={isPending}
              onClick={() => modalIncluirAlterarGarantia()}
              sx={{ color: 'white !important', marginTop: '25px' }}
            >
              Adicionar <AddIcon />
            </ButtonMain>
          )}
        </Grid>
      </Grid>

      <Box sx={isPending ? { position: 'relative', pr: '10px', alignItems: 'end' } : {}}>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

        {lstGarantias.map(garantia => (
          <Box key={garantia.id} className="margin-bottom-10">
            <Grid container spacing={3} className="margin-top-10" sx={{ padding: '10px 0 10px 10px' }}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="flex-box-items-direita texto-negrito"
              >
                Categoria:
              </Grid>
              <Grid item xl={7} lg={7} md={5} sm={5} xs={8} className="info-valor">
                {garantia && garantia.CATEGORIA
                  ? CATEGORIASGARANTIAS[garantia.CATEGORIA]
                  : valorIndefinido}
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={2}
                sm={2}
                xs={2}
                className="pad-0 margin-top-10 flex-box-items-direita"
              >
                {garantia && garantia.id && escopoClienteDados && escopoClienteDados.permite_alterar && (
                  <>
                    <FormControlLabel
                      control={<IOSSwitch disabled={!escopoClienteDados.permite_alterar} />}
                      onClick={e => handleOnChangeGarantia(e, garantia.id)}
                      className="pad-right-left-5"
                      checked={garantia.STATUS === 'A'}
                    />

                    <ButtonMain
                      size="small"
                      variant="outlined"
                      className="botao-alterar-excluir"
                      onClick={() => modalIncluirAlterarGarantia(garantia)}
                    >
                      <ModeEditOutlined />
                    </ButtonMain>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Tipo de garantia:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.NOMEGARANTIA ? garantia.NOMEGARANTIA : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Valor do imóvel:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.VALOR
                  ? parseFloat(garantia.VALOR).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Matricula:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.MATRICULA ? garantia.MATRICULA : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Cartório:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.CARTORIO ? garantia.CARTORIO : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Valor estimado pelo leilão:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.VALORLEILAO
                  ? parseFloat(garantia.VALORLEILAO).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Data:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.DATA
                  ? moment(garantia.DATA).format('DD/MM/yyyy')
                  : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Validade:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.VALIDADE
                  ? moment(garantia.VALIDADE).format('DD/MM/yyyy')
                  : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Status do imóvel:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.STATUSIMOVEL
                  ? STATUSIMOVELGARANTIAS[garantia.STATUSIMOVEL]
                  : valorIndefinido}
              </Grid>
            </Grid>

            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Inclusão:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.DTHRINSERT
                  ? `${moment(garantia.DTHRINSERT).format('DD/MM/yyyy')} às ${moment(garantia.DTHRINSERT).format('HH:mm:ss')} por ${garantia.NOMEINSERT}`
                  : valorIndefinido}
              </Grid>
            </Grid>

            {garantia && garantia.NOMEUPDATE && (
              <Grid container spacing={3} className="pad-10">
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={3}
                  xs={2}
                  className="texto-negrito flex-box-items-direita"
                >
                  Última alteração:
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                  {`${moment(garantia.DTHRUPDATE).format('DD/MM/yyyy')} às ${moment(garantia.DTHRUPDATE).format('HH:mm:ss')} por ${garantia.NOMEUPDATE}`}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3} className="pad-10">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Descrição do imóvel:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.DESCRICAOIMOVEL ? garantia.DESCRICAOIMOVEL : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="pad-10 margin-bottom-25">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={3}
                xs={2}
                className="texto-negrito flex-box-items-direita"
              >
                Observação:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {garantia && garantia.OBSERVACAO ? garantia.OBSERVACAO : valorIndefinido}
              </Grid>
            </Grid>

            <hr />
          </Box>
        ))}

        <AlertMain
          exibeAlerta={mostrarAlerta}
          setExibeAlerta={setMostrarAlerta}
          titleAlerta={msgTitleAlert}
          msgAlerta={msgAlert}
          funcaoConfirmacao={alteraStatusGarantia}
        />
      </Box>
    </Box>
  );
};
export default memo(DetalhesGarantias);
