import React from 'react';
import { Box, Grid } from '@mui/material';
import { AssignmentInd, ModeEditOutlined } from '@mui/icons-material';
import { NotIsEmpty } from '../../../../Utils/format';
import ButtonMain from '../../../../Shared/ButtonMain';
import {
  avalista_tipos_assinaturas,
  procurador_tipos_assinaturas,
  representante_tipos_assinaturas
} from '../../shared/TiposAssinaturas';
import LinhaDados from './LinhaDados';

const valorIndefinido = <em className="texto-cor-cinza">-</em>;

const PoderesAssinaturas = ({
  avalista,
  socio,
  representante,
  alterarPessoa,
  tipoCadastro,
  escopoClientePessoas,
  ...demaisDados
}) => {
  const alterarPoderesAssinaturas = () => {
    const avalistaAssinaturas = [];
    if (avalista && avalista.CODCONTRATORESPONSAVELSOLIDARIO) {
      if (NotIsEmpty(avalista.ASSINACONTRATO) && avalista.ASSINACONTRATO === 'S')
        avalistaAssinaturas.push(avalista_tipos_assinaturas.ASSINACONTRATO);
      if (NotIsEmpty(avalista.ASSINAOPERACAO) && avalista.ASSINAOPERACAO === 'S')
        avalistaAssinaturas.push(avalista_tipos_assinaturas.ASSINAOPERACAO);
      if (NotIsEmpty(avalista.ASSINACCB) && avalista.ASSINACCB === 'S')
        avalistaAssinaturas.push(avalista_tipos_assinaturas.ASSINACCB);
    }

    let representanteAssinaturas = [];
    if (representante && representante.REPRESENTANTE_ASSINATURAS)
      representanteAssinaturas = representante.REPRESENTANTE_ASSINATURAS.map(campo =>
        representante_tipos_assinaturas[campo] ? representante_tipos_assinaturas[campo] : campo
      );

    let procuradorAssinaturas = [];
    if (representante && representante.PROCURADOR_ASSINATURAS)
      procuradorAssinaturas = representante.PROCURADOR_ASSINATURAS.map(campo =>
        procurador_tipos_assinaturas[campo] ? procurador_tipos_assinaturas[campo] : campo
      );

    alterarPessoa('poderes_assinaturas', {
      nome: demaisDados.dadosBasicos.NOME,
      CODCONTRATOSOCIODONO: socio && socio.CODCONTRATOSOCIODONO ? socio.CODCONTRATOSOCIODONO : null,
      CODCONTRATORESPONSAVELSOLIDARIO:
        avalista && avalista.CODCONTRATORESPONSAVELSOLIDARIO
          ? avalista.CODCONTRATORESPONSAVELSOLIDARIO
          : null,
      CODCONTRATOREPRESENTANTE:
        representante && representante.CODCONTRATOREPRESENTANTE
          ? representante.CODCONTRATOREPRESENTANTE
          : null,
      representante: representante && representante.CODCONTRATOREPRESENTANTE ? 'S' : 'N',
      representante_tipos_assinaturas: representanteAssinaturas,
      avalista: avalista && avalista.CODCONTRATORESPONSAVELSOLIDARIO ? 'S' : 'N',
      avalista_tipos_assinaturas: avalistaAssinaturas,
      socio: socio && socio.CODCONTRATOSOCIODONO ? 'S' : 'N',
      tipo_socio: socio && socio.TIPOSOCIO ? socio.TIPOSOCIO : 'SC',
      signatario: socio && socio.SIGNATARIO ? socio.SIGNATARIO : 'N',
      percentual_societario:
        socio && socio.PERCENTUALPARTICIPACAO
          ? parseFloat(socio.PERCENTUALPARTICIPACAO).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          : 0,
      procurador_tipos_assinaturas: procuradorAssinaturas,
      possuiProcurador:
        (avalista && avalista.POSSUIPROCURADOR === 'S') ||
        (socio && socio.POSSUIPROCURADOR === 'S') ||
        (representante && representante.POSSUIPROCURADOR === 'S')
          ? 'S'
          : 'N'
    });
  };

  return (
    <Box className="poderes-assinaturas">
      <Grid container spacing={2} className="box-paper-header" sx={{ mb: '15px', mt: '10px' }}>
        <Grid item xl={4} lg={4} md={4} sm={3} xs={3} className="flex-box-items-direita">
          <Box className="box-icone-paper-header icone-gray">
            <AssignmentInd />
          </Box>
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={8}
          xs={8}
          className="titulo titulo-gray flex-box-items-esquerda"
        >
          Poderes e assinaturas
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="flex-box-items-direita pad-0">
          {escopoClientePessoas && escopoClientePessoas.permite_alterar && (
            <ButtonMain
              size="small"
              variant="outlined"
              className="botao-alterar-excluir"
              onClick={alterarPoderesAssinaturas}
            >
              <ModeEditOutlined />
            </ButtonMain>
          )}
        </Grid>
      </Grid>
      {tipoCadastro === 'propria' ? (
        <>
          <LinhaDados
            label="Representante:"
            valor={representante && NotIsEmpty(representante.CODCONTRATOREPRESENTANTE) ? 'Sim' : 'Não'}
          />
          {representante && representante.CODCONTRATOREPRESENTANTE && (
            <LinhaDados
              label="Tipo de assinatura do representante:"
              valor={
                representante.REPRESENTANTE_ASSINATURAS &&
                representante.REPRESENTANTE_ASSINATURAS.length > 0 ? (
                  <b>
                    {representante.REPRESENTANTE_ASSINATURAS.join(', ')
                      .replace('ASSINATERMOCESSAO', 'Assina termo de cessão')
                      .replace('ASSINAPORENDOSSO', 'Assina por endosso')
                      .replace('ASSINASOZINHO', 'Assina sozinho')
                      .replace('EMITEDUPLICATA', 'Emite Duplicata')}
                  </b>
                ) : (
                  valorIndefinido
                )
              }
            />
          )}
          <LinhaDados
            label="Sócio:"
            valor={socio && NotIsEmpty(socio.CODCONTRATOSOCIODONO) ? 'Sim' : 'Não'}
          />
          {socio && NotIsEmpty(socio.CODCONTRATOSOCIODONO) && (
            <>
              <LinhaDados
                label="Porcentagem de sócio:"
                valor={
                  NotIsEmpty(socio.PERCENTUALPARTICIPACAO) && !isNaN(socio.PERCENTUALPARTICIPACAO)
                    ? `${parseFloat(socio.PERCENTUALPARTICIPACAO).toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })} %`
                    : valorIndefinido
                }
              />
              <LinhaDados
                label="Sócio Signatario:"
                valor={NotIsEmpty(socio.SIGNATARIO) && socio.SIGNATARIO === 'S' ? 'Sim' : 'Não'}
              />
              <LinhaDados
                label="Tipo Sócio:"
                valor={
                  NotIsEmpty(socio.TIPOSOCIO)
                    ? socio.TIPOSOCIO === 'SD'
                      ? 'Sócio Dono'
                      : socio.TIPOSOCIO === 'DN'
                        ? 'Dono'
                        : 'Sócio'
                    : valorIndefinido
                }
              />
            </>
          )}
          <LinhaDados
            label="Avalista:"
            valor={avalista && NotIsEmpty(avalista.CODCONTRATORESPONSAVELSOLIDARIO) ? 'Sim' : 'Não'}
          />
          {avalista && NotIsEmpty(avalista.CODCONTRATORESPONSAVELSOLIDARIO) && (
            <>
              <LinhaDados
                label="Assina contrato:"
                valor={
                  NotIsEmpty(avalista.ASSINACONTRATO) && avalista.ASSINACONTRATO === 'S' ? 'Sim' : 'Não'
                }
              />
              <LinhaDados
                label="Assina operação:"
                valor={
                  NotIsEmpty(avalista.ASSINAOPERACAO) && avalista.ASSINAOPERACAO === 'S' ? 'Sim' : 'Não'
                }
              />
              <LinhaDados
                label="Assina CCB:"
                valor={NotIsEmpty(avalista.ASSINACCB) && avalista.ASSINACCB === 'S' ? 'Sim' : 'Não'}
              />
            </>
          )}
          <LinhaDados
            label="Possui procurador:"
            valor={
              (avalista && avalista.POSSUIPROCURADOR === 'S') ||
              (socio && socio.POSSUIPROCURADOR === 'S') ||
              (representante && representante.POSSUIPROCURADOR === 'S')
                ? 'Sim'
                : 'Não'
            }
          />
        </>
      ) : tipoCadastro === 'procurador' ? (
        <>
          <LinhaDados
            label="Tipo de assinatura do procurador:"
            valor={
              representante &&
              representante.PROCURADOR_ASSINATURAS &&
              representante.PROCURADOR_ASSINATURAS.length > 0 ? (
                <b>
                  {representante.PROCURADOR_ASSINATURAS.join(', ')
                    .replace('ASSINATERMOCESSAO', 'Assina termo de cessão')
                    .replace('ASSINAPORENDOSSO', 'Assina por endosso')
                    .replace('ASSINASOZINHO', 'Assina sozinho')
                    .replace('EMITEDUPLICATA', 'Emite Duplicata')}
                </b>
              ) : (
                valorIndefinido
              )
            }
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default PoderesAssinaturas;
