/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LoaderMain from '../../Shared/LoaderMain';
import { buscaGestaoRelacionamentos } from '../../../api/relacionamentos';

const StatusRelacionamentos = ({
  setDadosModalDashboardRelacionamentos,
  atualizarDashboard,
  setDadosCardsRelacionamentos
}) => {
  const [isPending, setIsPending] = useState(false);
  const [cardsLeads, setCardsLeads] = useState([]);
  const [valorPeriodo, setValorPeriodo] = useState(1);

  useEffect(() => {
    buscarFunilVendas();
  }, [atualizarDashboard]);

  const buscarFunilVendas = periodo => {
    const params = {
      filtro_meses: periodo ?? valorPeriodo
    };

    setIsPending(true);

    buscaGestaoRelacionamentos(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          const dadosRelacionamentosTemp = [];
          const lstNomesTagsTemp = {};
          let indexPrincipal = 0;
          res.data.map((dados, index) => {
            if (index !== 0 && index % 5 === 0) indexPrincipal++;
            if (typeof dadosRelacionamentosTemp[indexPrincipal] === 'undefined')
              dadosRelacionamentosTemp[indexPrincipal] = [];
            dadosRelacionamentosTemp[indexPrincipal].push(dados);
            lstNomesTagsTemp[dados.tag] = dados;
            return dados;
          });
          setCardsLeads(dadosRelacionamentosTemp);
          setDadosCardsRelacionamentos(lstNomesTagsTemp);
        } else {
          setCardsLeads([]);
          setDadosCardsRelacionamentos({});
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleOnChangePeriodo = event => {
    setValorPeriodo(event.target.value);
    buscarFunilVendas(event.target.value);
  };

  const mostrarModalDashBoard = dadosRelacionamentos => {
    const dados = {};
    dados.titleModal =
      (dadosRelacionamentos && dadosRelacionamentos.descricao) ??
      'Relacionamentos';
    dados.buscarRelacionamentos = dadosRelacionamentos.tag;
    dados.filtro_meses = valorPeriodo;

    if (dadosRelacionamentos && dadosRelacionamentos.permite_acoes) {
      setDadosModalDashboardRelacionamentos(dados);
    }
  };

  const opcoesSelect = [
    { label: 'Últimos 30 dias', valor: 1 },
    { label: 'Últimos 3 meses', valor: 3 },
    { label: 'Últimos 6 meses', valor: 6 },
    { label: 'Últimos 12 meses', valor: 12 }
  ];

  return (
    <Card className="chart-card status-lead">
      <CardContent
        className="ajuste-spin-chart ajuste-card-lead"
        sx={isPending ? { position: 'relative' } : {}}
      >
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

        <Grid container className="margin-bottom-20">
          <Grid item lg={9} md={9} sm={9} xs={12}>
            <Typography
              variant="subtitle1"
              className="texto-cor-cinza-escuro pad-7 texto-weight-600"
            >
              Gestão de Relacionamentos
            </Typography>
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={12} className="texto-direita">
            <Select
              size="small"
              className="select-com-icone-padrao"
              value={valorPeriodo}
              onChange={handleOnChangePeriodo}
              input={<OutlinedInput label="" />}
            >
              {opcoesSelect.map(item => (
                <MenuItem key={item.label} value={item.valor}>
                  <DateRangeIcon className="icone-option" />
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Box className="box-principal-group overflow-x">
          {cardsLeads.map((blocoCards, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} className="box-principal-status-lead">
              {blocoCards.map(card => (
                <Box key={card.tag} className="box-card-status-lead">
                  <Box
                    className={`box-status-card ${card.cor_card} ${
                      card.permite_acoes ? 'cursor-pointer' : ''
                    }`}
                    onClick={() => mostrarModalDashBoard(card)}
                  >
                    <Grid container>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={5}
                        xs={5}
                        style={{ height: '25px' }}
                      >
                        <Box
                          className="cor-status-card"
                          style={{ borderColor: card.color }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={8}
                        md={8}
                        sm={7}
                        xs={7}
                        className="texto-direita"
                      />
                      <Grid
                        className="descricao-status"
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <span className="quantidade-status">
                          {String(card.quantidade).padStart(2, '0')}
                        </span>
                        <span className="nome-status">{card.descricao}</span>
                        <hr />
                        <Box className="valor-status">
                          {parseFloat(card.porcentagem).toLocaleString(
                            'pt-BR',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )}
                          <span>%</span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
              {/* Criado um loop abaixo para criar caixas vazias que irão complementar a linha, pois precisa sempre ter 5 caixas, para manter o tamanho igual das caixas */}
              {blocoCards.length < 5 &&
                (() => {
                  const boxs = [];
                  for (let i = 0; i < 5 - blocoCards.length; i++) {
                    boxs.push(<Box key={i} className="box-card-status-lead" />);
                  }
                  return boxs;
                })()}
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatusRelacionamentos;
