import React from 'react';
import { Grid } from '@mui/material';
import CopyValorTexto from '../../../../Shared/CopyValorTexto';


const LinhaDados = ({ label, valor, boxClass = '', children, copyText = false }) => (
  <Grid container spacing={3} className={`info-line ${boxClass}`}>
    <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
      {label}
    </Grid>
    <Grid item xl={6} lg={6} md={6} sm={7} xs={8} className="info-valor">
      {valor}
      {copyText && (CopyValorTexto({ texto: valor }))}       
    </Grid>
    <Grid item xl={2} lg={2} md={2} sm={3} xs={3} className="pad-0">
      {children ?? null}
    </Grid>
  </Grid>
);
export default LinhaDados;
