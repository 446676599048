import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { mascararCpfCnpj } from '../../Utils/format';

const definicaoDeColunas = dadosBuscaRelacionamento => {
  const config = [
    {
      field: 'cpf_cnpj',
      headerName: 'CPF / CNPJ',
      flex: 1,
      minWidth: 160,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      renderCell: params => <>{mascararCpfCnpj(String(params.row.cpf_cnpj))}</>
    },
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1,
      minWidth: 150,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray'
    },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 1,
      minWidth: 150,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray'
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      flex: 1,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray'
    },
    {
      field: 'whatsapp',
      headerName: 'WhatsApp',
      flex: 1,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray'
    },
    {
      field: 'plataforma',
      headerName: 'Plataforma',
      flex: 1,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray'
    },
    {
      field: 'unidade',
      headerName: 'Unidade',
      flex: 1,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray'
    },
    {
      field: 'data_insert',
      headerName: 'Entrada',
      flex: 1,
      headerClassName:
        'data-grid-header-centralizado-esquerda font-bold-header',
      cellClassName: 'data-grid-light-gray',
      renderCell: params => (
        <Typography
          className="texto-centro flex-box-items-centralizados items-centralizados"
          variant="h7"
          title={moment(params.row.data_insert).format('DD/MM/yyyy HH:mm:ss')}
        >
          <AccessTimeIcon className="font-size-18 margin-right-3" />
          {moment(params.row.data_insert).format('DD/MM/yyyy')}
        </Typography>
      )
    },
    {
      field: 'origem_relacionamentos',
      headerName: 'Origem',
      flex: 1,
      maxWidth: 100,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName:
        'items-centralizados data-grid-light-gray border-radius-right'
    },
    {
      field: 'nome_usuario_reserva',
      headerName: 'Responsável',
      flex: 1,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName:
        'items-centralizados data-grid-light-gray border-radius-right',
      valueFormatter: params => (params.value ? params.value : '---')
    }
  ];
  if (
    dadosBuscaRelacionamento &&
    dadosBuscaRelacionamento.permitir_agendamento
  ) {
    config.push({
      field: 'data_hora_agendamento',
      headerName: 'Agendamento',
      flex: 1,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName:
        'items-centralizados data-grid-light-gray border-radius-right',
      renderCell: params =>
        params.row.data_hora_agendamento ? (
          <span
            title={moment(params.row.data_hora_agendamento).format('HH:mm:ss')}
          >
            {moment(params.row.data_hora_agendamento).format('DD/MM/YYYY')}
          </span>
        ) : (
          '---'
        )
    });
  }
  return config;
};

export default definicaoDeColunas;
