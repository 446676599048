import React, { memo, useState } from 'react';
import { Alert, Box, Chip, Grid } from '@mui/material';
import { Add, DeleteOutlined, ModeEditOutlined } from '@mui/icons-material';
import ButtonMain from '../../../Shared/ButtonMain';
import FormEnderecos from './FormEnderecos';
import LoaderMain from '../../../Shared/LoaderMain';
import { NotIsEmpty } from '../../../Utils/format';
import {
  alterarEnderecoPrincipal,
  buscarEstadosBrasileiros,
  buscarTiposEndereco,
  inativarEndereco
} from '../../../../api/formalizacao';
import DialogConfirmacao from '../../../Shared/DialogConfirmacao';

const Enderecos = ({
  dadosEnderecos,
  dadosGerais,
  setDadosGerais,
  setAlerta,
  setModalClienteDados,
  escopoEndereco,
  telaPai
}) => {
  const [isPending, setPending] = useState(false);
  const [lstTipos, setLstTipos] = useState(false);
  const [confirmacaoModal, setConfirmacaoModal] = useState(false);
  const valorIndefinido = <em className="texto-cor-cinza">-</em>;

  const buscarListasTipos = async () => {
    const lstTiposTemp = {};
    // BUSCA TIPOS DE ENDERECO
    lstTiposTemp.enderecoTipo =
      lstTipos === false || lstTipos.enderecoTipo.length === 0
        ? await buscarTiposEndereco(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.enderecoTipo;
    // BUSCA ESTADOS DO BRASIL
    lstTiposTemp.estadosBrasileiros =
      lstTipos === false || lstTipos.estadosBrasileiros.length === 0
        ? await buscarEstadosBrasileiros(telaPai)
            .then(res => (res && res.data ? res.data : []))
            .catch(() => [])
        : lstTipos.estadosBrasileiros;
    setLstTipos(lstTiposTemp);
    return lstTiposTemp;
  };

  const alterarIncluirEndereco = async dadosEnderecoEscolhido => {
    let listasTipos = lstTipos;
    if (
      lstTipos === false ||
      lstTipos.enderecoTipo.length === 0 ||
      lstTipos.estadosBrasileiros.length === 0
    ) {
      setModalClienteDados({
        openModal: true,
        tituloModal: 'Endereços da empresa',
        conteudoModal: <LoaderMain tipoLoader="float" tipoImagem="circulo" />
      });
      listasTipos = await buscarListasTipos();
    }

    setModalClienteDados({
      openModal: true,
      tituloModal: 'Endereços da empresa',
      conteudoModal: (
        <FormEnderecos
          formDados={dadosEnderecoEscolhido}
          dadosGerais={dadosGerais}
          setDadosGerais={setDadosGerais}
          setAlerta={setAlerta}
          lstTipos={listasTipos}
          handleClose={() => setModalClienteDados({})}
          telaPai={telaPai}
        />
      )
    });
  };

  const selecionarPrincipal = _id => {
    const params = {
      _id: _id,
      cpfcnpj: telaPai === 'DadosCliente' ? dadosGerais.informacoes_gerais.cnpj : dadosGerais.cpfcnpj
    };

    setConfirmacaoModal({
      mensagem: 'Deseja realmente definir este endereço como principal?',
      funcaoConfirmar: () => {
        setConfirmacaoModal(false);
        setPending(true);
        alterarEnderecoPrincipal(params, telaPai)
          .then(res => {
            if (res && res.data && res.data) {
              const dadosGeraisTemp = { ...dadosGerais };
              dadosGeraisTemp.enderecos = res.data;
              setDadosGerais(dadosGeraisTemp);
              setAlerta({
                mensagem: 'Endereço principal alterado com sucesso',
                tipo: 'success'
              });
            } else {
              console.error('Retorno vazio');
              setAlerta({
                mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
                tipo: 'error'
              });
            }
          })
          .catch(err => {
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          })
          .finally(() => {
            setPending(false);
          });
      },
      funcaoCancelar: () => setConfirmacaoModal(false)
    });
  };

  const excluirEndereco = _id => {
    setConfirmacaoModal({
      mensagem: 'Deseja realmente excluir este endereço?',
      funcaoConfirmar: () => {
        setConfirmacaoModal(false);
        const cpfcnpj =
          telaPai === 'DadosCliente' ? dadosGerais.informacoes_gerais.cnpj : dadosGerais.cpfcnpj;
        setPending(true);
        inativarEndereco(cpfcnpj, _id, telaPai)
          .then(res => {
            if (res && res.data && res.data.principal) {
              setAlerta({
                mensagem: 'Este endereço é o principal, por isso ele não pode ser excluído!',
                tipo: 'warning'
              });
            } else if (res && res.data && res.data) {
              const dadosGeraisTemp = { ...dadosGerais };
              dadosGeraisTemp.enderecos = res.data;
              setDadosGerais(dadosGeraisTemp);
              setAlerta({
                mensagem: 'Endereço excluído com sucesso',
                tipo: 'success'
              });
            } else {
              console.error('Retorno vazio');
              setAlerta({
                mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
                tipo: 'error'
              });
            }
          })
          .catch(err => {
            console.error(err);
            setAlerta({
              mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
              tipo: 'error'
            });
          })
          .finally(() => {
            setPending(false);
          });
      },
      funcaoCancelar: () => setConfirmacaoModal(false)
    });
  };

  return (
    <>
      {telaPai === 'DadosPessoa' && escopoEndereco && escopoEndereco.permite_incluir && (
        <Grid container spacing={3} sx={{ mt: '-55px', pb: '15px' }}>
          <Grid item xl={10} lg={10} md={9} sm={8} xs={6} className="pad-0" />
          <Grid item xl={2} lg={2} md={3} sm={4} xs={6} className="pad-0">
            <ButtonMain size="medium" tipoBotao="azul-escuro" onClick={() => alterarIncluirEndereco({})}>
              Adicionar <Add />
            </ButtonMain>
          </Grid>
        </Grid>
      )}
      {dadosEnderecos && dadosEnderecos.length > 0 ? (
        dadosEnderecos.map(endereco => (
          <Box
            key={endereco._id}
            className="box-registro"
            sx={isPending ? { position: 'relative' } : {}}
          >
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Tipo:
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="info-valor">
                {endereco.dados_tipo && NotIsEmpty(endereco.dados_tipo.nome)
                  ? endereco.dados_tipo.nome
                  : valorIndefinido}
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="flex-box-items-direita pad-0">
                {escopoEndereco && escopoEndereco.permite_alterar && (
                  <ButtonMain
                    size="small"
                    variant="outlined"
                    className="botao-alterar-excluir"
                    onClick={() => alterarIncluirEndereco(endereco)}
                  >
                    <ModeEditOutlined />
                  </ButtonMain>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                CEP:
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={7} xs={8} className="info-valor">
                {endereco.cep ?? valorIndefinido}
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="flex-box-items-direita pad-0">
                {escopoEndereco && escopoEndereco.permite_excluir && (
                  <ButtonMain
                    size="small"
                    variant="outlined"
                    className="botao-alterar-excluir"
                    onClick={() => excluirEndereco(endereco._id)}
                    disabled={endereco.principal}
                  >
                    <DeleteOutlined />
                  </ButtonMain>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Endereço:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {NotIsEmpty(endereco.rua) ? endereco.rua : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Número:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {NotIsEmpty(endereco.numero) ? endereco.numero : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Complemento:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {NotIsEmpty(endereco.complemento) ? endereco.complemento : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Bairro:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {NotIsEmpty(endereco.bairro) ? endereco.bairro : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                Cidade:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {NotIsEmpty(endereco.cidade) ? endereco.cidade : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label">
                UF:
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {endereco.dados_uf && NotIsEmpty(endereco.dados_uf.nome)
                  ? endereco.dados_uf.nome
                  : valorIndefinido}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="info-line">
              <Grid item xl={4} lg={4} md={4} sm={3} xs={2} className="info-label" />
              <Grid item xl={8} lg={8} md={8} sm={9} xs={10} className="info-valor">
                {endereco.principal ? (
                  <Chip label="Principal" className="badge-cor-suporte" />
                ) : (
                  <ButtonMain
                    tipoBotao="transparente-com-bordas-preto"
                    className="w-auto"
                    onClick={() => selecionarPrincipal(endereco._id)}
                  >
                    Definir como principal
                  </ButtonMain>
                )}
              </Grid>
            </Grid>
          </Box>
        ))
      ) : (
        <Grid container>
          <Grid item xl={4} lg={4} md={4} sm={3} xs={3} />
          <Grid item xl={5} lg={5} md={5} sm={5} xs={6} className="flex-box-items-esquerda">
            <Alert severity="info" className="w-auto">
              Nenhum endereço cadastrado.
            </Alert>
          </Grid>
        </Grid>
      )}

      {telaPai === 'DadosCliente' && escopoEndereco && escopoEndereco.permite_incluir && (
        <Grid container spacing={3} className="margin-top-10">
          <Grid item xl={4} lg={4} md={4} sm={3} xs={2} />
          <Grid item xl={2} lg={2} md={3} sm={4} xs={8} className="info-valor">
            <ButtonMain size="medium" tipoBotao="azul-escuro" onClick={() => alterarIncluirEndereco({})}>
              Adicionar <Add />
            </ButtonMain>
          </Grid>
          <Grid item xl={6} lg={6} md={5} sm={5} xs={2} />
        </Grid>
      )}

      {confirmacaoModal && <DialogConfirmacao {...confirmacaoModal} />}
    </>
  );
};
export default memo(Enderecos);
