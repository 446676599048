import React, { useEffect } from 'react';
import ForceLogout from '../../components/ForceLogout/ForceLogout';
import { isAuthenticated, setTelaAberta } from './auth';
import NotFound from '../../components/NotFound/NotFound';

const ProtectedRoute = ({
  children,
  escopoAcesso,
  modulo,
  contexto,
  ignorarEscopo = false
}) => {
  useEffect(() => {
    if (isAuthenticated() && modulo && contexto)
      setTelaAberta(`/${modulo}/${contexto}`);
  }, []);
  return (
    <>
      {isAuthenticated() ? (
        <>
          {(escopoAcesso &&
            escopoAcesso[modulo] &&
            escopoAcesso[modulo].rota &&
            escopoAcesso[modulo].rota[contexto]) ||
          ignorarEscopo ? (
            children
          ) : (
            <NotFound escopoAcesso={escopoAcesso} />
          )}
        </>
      ) : (
        <ForceLogout />
      )}
    </>
  );
};
export default ProtectedRoute;
