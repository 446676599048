import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import ImportExcelInputMain from '../../Shared/ImportExcelInputMain';
import { postImportarRelacionamentos } from '../../../api/relacionamentos';
import LoaderMain from '../../Shared/LoaderMain';

const ImportarRelacionamentos = ({
  exibeImportarRelacionamentos = false,
  setExibeImportarRelacionamentos,
  lstUsuariosCrm = [],
  funcaoConfirmacao = false,
  tagRelacionamentos = 'novo'
}) => {
  const [valueUsuarioResponsavel, setValueUsuarioResponsavel] = useState('');
  const [nomeUsuarioResponsavel, setNomeUsuarioResponsavel] = useState('');
  const [dadosExcel, setDadosExcel] = useState(false);
  const [origemArquivo, setOrigemArquivo] = useState('');
  const [formDataFile, setFormDataFile] = useState(false);
  const [isPending, setIsPending] = useState(false);

  if (typeof funcaoConfirmacao !== 'function') {
    return;
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setValueUsuarioResponsavel('');
    setNomeUsuarioResponsavel('');
    setExibeImportarRelacionamentos(false);
    setFormDataFile(false);
    setDadosExcel(false);
  };

  const handleOnclick = () => {
    enviaPostImportarRelacionamentos();
  };

  const handleOnChangeImportarLeadsUsuariosCrm = (ev, newValue) => {
    if (newValue && newValue.value) {
      setValueUsuarioResponsavel(newValue.value);
      setNomeUsuarioResponsavel(newValue.label);
    } else {
      setValueUsuarioResponsavel('');
      setNomeUsuarioResponsavel('');
    }
  };

  const enviaPostImportarRelacionamentos = () => {
    setIsPending(true);

    const params = new FormData();
    params.append('tag', tagRelacionamentos);
    params.append('relacionamentos', JSON.stringify(dadosExcel));
    params.append('origem', 'PLANILHA');
    params.append('arquivo', formDataFile);
    params.append('url_origem', origemArquivo);
    params.append('cod_usuario_reserva', valueUsuarioResponsavel);
    params.append('nome_usuario_reserva', nomeUsuarioResponsavel);

    postImportarRelacionamentos(params)
      .then(dados => {
        handleClose();
        funcaoConfirmacao(dados.data.ja_importado);
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  };
  return (
    <Dialog
      open={exibeImportarRelacionamentos}
      onClose={handleClose}
      className="alert-main"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h7" className="texto-cor-cinza-escuro texto-weight-600">
              Responsável:
            </Typography>
            <Autocomplete
              disablePortal
              id="usuariosCrm"
              options={lstUsuariosCrm || []}
              value={
                valueUsuarioResponsavel && lstUsuariosCrm.length > 0
                  ? lstUsuariosCrm.find(option => option.value === valueUsuarioResponsavel)
                  : ''
              }
              onChange={handleOnChangeImportarLeadsUsuariosCrm}
              name="usuariosCrm"
              className="select-status-lead ajuste-height"
              disableClearable
              isOptionEqualToValue={(option, value) =>
                option === value || value === undefined || value === '' || value === null
              }
              disabled={lstUsuariosCrm.length === 0}
              renderInput={params => (
                <TextField
                  {...params}
                  name="usuariosCrm"
                  placeholder="Escolha o responsável"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off'
                  }}
                />
              )}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className="margin-top-15">
            <Typography variant="h7" className="texto-cor-cinza-escuro texto-weight-600">
              Selecione o arquivo excel:
            </Typography>

            <Box>
              <ImportExcelInputMain
                setArrayRetornoExcel={setDadosExcel}
                setNomeArquivoExcel={setOrigemArquivo}
                setFormDataFile={setFormDataFile}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="items-centro btn-alert-motivo">
        <Button className="btn-outline-main full-widht" onClick={handleClose}>
          Cancelar
        </Button>
        <Button className="btn-main full-widht" onClick={handleOnclick} disabled={dadosExcel === false}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportarRelacionamentos;
