export const DOCUMENTOPERIFERICO = {
  DECLARACAO_ESTADO_CIVIL: {
    nome: 'Declaração de Estado Civil',
    idDocumento: 'declaracaoEstadoCivil',
    rota: 'declaracao-estado-civil',
    tipoPeriferico: 'cliente'
  },
  DECLARACAO_PESSOA_POLITICAMENTE_EXPOSTA: {
    nome: 'Declaração de Pessoa Politicamente Exposta',
    idDocumento: 'declaracaoPessoaPoliticamenteExposta',
    rota: 'declaracao-pessoa-politicamente-exposta',
    tipoPeriferico: 'cliente'
  },
  DECLARACAO_PESSOA_NAO_POLITICAMENTE_EXPOSTA: {
    nome: 'Declaração de Pessoa não Politicamente Exposta',
    idDocumento: 'declaracaoPessoaNaoPoliticamenteExposta',
    rota: 'declaracao-pessoa-nao-politicamente-exposta',
    tipoPeriferico: 'cliente'
  },
  PROCURACAO_PARTICULAR_EMPRESA: {
    nome: 'Procuração Particular para representar a Empresa',
    idDocumento: 'procuracaoParticularEmpresa',
    rota: 'procuracao-particular-aval-pj',
    tipoPeriferico: 'cliente'
  },
  AUTORIZACAO_AVAL_PESSOA_FISICA: {
    nome: 'Autorização de Aval Pessoa física',
    idDocumento: 'autorizacaoAvalPessoaFisica',
    rota: 'autorizacao-aval-pf',
    tipoPeriferico: 'cliente'
  },
  PROCURACAO_PARTICULAR_AVAL_PESSOA_FISICA: {
    nome: 'Procuração Particular aval pessoa fisica',
    idDocumento: 'procuracaoParticularAvalPessoaFisica',
    rota: 'procuracao-particular-aval-pf',
    tipoPeriferico: 'cliente'
  },
  AUTORIZACAO_AVAL_PESSOA_JURIDICA: {
    nome: 'Autorização de Aval Pessoa Jurídica',
    idDocumento: 'autorizacaoAvalPessoaJuridica',
    rota: 'autorizacao-aval-pj',
    tipoPeriferico: 'empresa'
  },
  PROCURACAO_PARTICULAR_AVAL_PESSOA_FISICA_CONJUGE: {
    nome: 'Procuração Particular Aval de Pessoa física e Cônjuge',
    idDocumento: 'procuracaoParticularAvalPessoaFisicaConjuge',
    rota: 'procuracao-particular-aval-pf-conjugue',
    tipoPeriferico: 'cliente'
  },
  PROCURACAO_PARTICULAR_AVAL_CONJUGE: {
    nome: 'Procuração Particular Aval do Cônjuge',
    idDocumento: 'procuracaoParticularAvalConjuge',
    rota: 'procuracao-particular-aval-conjugue',
    tipoPeriferico: 'cliente'
  },
  DECLARACAO_RESIDENCIA_PESSOA_FISICA: {
    nome: 'Declaração de Residência Pessoa física',
    idDocumento: 'declaracaoResidenciaPessoaFisica',
    rota: 'declaracao-residencia-pf',
    tipoPeriferico: 'cliente'
  },
  DECLARACAO_RELACIONAMENTO_BANCARIO: {
    nome: 'Declaração de relacionamento bancário',
    idDocumento: 'declaracaoRelacionamentoBancario',
    rota: 'declaracao-relacionamento-bancario',
    tipoPeriferico: 'empresa'
  }
};
