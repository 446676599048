import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import 'moment/locale/pt-br';
import LoaderMain from '../../../../Shared/LoaderMain';
import CkEditorMain from '../../../../Shared/CkEditorMain';
import { alterarParecerCliente } from '../../../../../api/formalizacao';

const ModalAlterarParecer = ({ dadosParecer, handleClose, funcaoSucesso }) => {
  const [isPending, setPending] = useState(false);
  const [valueCkEditor, setValueCkEditor] = useState(null);

  useEffect(() => {
    if (dadosParecer && dadosParecer.OBS) {
      setValueCkEditor(dadosParecer.OBS);
    } else {
      setValueCkEditor('');
    }
  }, []);

  const salvarEditarParecer = () => {
    setPending(true);

    const params = {
      cod_parecer: dadosParecer.CODPARECER,
      observacao: valueCkEditor
    };

    alterarParecerCliente(params)
      .then(() => {
        if (funcaoSucesso && typeof funcaoSucesso === 'function') {
          funcaoSucesso();
        }
        handleClose();
      })
      .catch(() => {})
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Box sx={isPending ? { position: 'relative', minHeight: '220px' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      <Grid container className="margin-bottom-10">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box className="box-editor-parecer">
            <CkEditorMain
              valueCkEditor={valueCkEditor}
              setValueCkEditor={setValueCkEditor}
              sx={{ maxHeight: '150px' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Button className="btn-outline-main margin-top-30" disabled={isPending} onClick={handleClose}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Button
            className="btn-main margin-top-30"
            disabled={isPending || !valueCkEditor || valueCkEditor === ''}
            onClick={() => salvarEditarParecer()}
          >
            Salvar parecer
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default memo(ModalAlterarParecer);
