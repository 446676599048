import React, { useState } from 'react';
import { Box, FormControlLabel, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { DeleteOutlined } from '@mui/icons-material';
import { textToLowerCase } from '../../../../Utils/format';
import LoaderMain from '../../../../Shared/LoaderMain';
import IOSSwitch from '../../shared/IOSSwitch';
import ButtonMain from '../../../../Shared/ButtonMain';

const DefinicaoDeColunas = (
  excluirInativarDocumento,
  downloadArquivoPessoa,
  abrirModalDocumentos,
  cliente,
  escopoClientePessoas
) => {
  const [isPendingGrid, setIsPendingGrid] = useState([]);

  const config = [
    {
      field: 'STATUS',
      headerName: cliente.formalizado ? 'Status' : 'Excluir',
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-border-gray texto-centro',
      renderCell: params => (
        <>
          {cliente.formalizado ? (
            <FormControlLabel
              className="margin-zero"
              control={<IOSSwitch disabled={!escopoClientePessoas.permite_alterar} />}
              onClick={e => excluirInativarDocumento(params.row)}
              checked={params.row.STATUS === 'A'}
            />
          ) : (
            <ButtonMain
              size="small"
              variant="outlined"
              className="botao-alterar-excluir"
              onClick={() => excluirInativarDocumento(params.row)}
              disabled={!escopoClientePessoas.permite_excluir}
            >
              <DeleteOutlined />
            </ButtonMain>
          )}
        </>
      )
    },
    {
      field: 'NOMETIPOARQUIVO',
      headerName: 'Tipo de documento',
      flex: 1,
      minWidth: 150,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'data-grid-border-gray camel-case',
      renderCell: params => (
        <Box className="texto-nowrap-overflow-dotted w-100">
          <FilePresentIcon sx={{ mb: '-6px' }} />
          {params.row.NOMETIPOARQUIVO}
        </Box>
      )
    },
    {
      field: 'ENVIARFUNDO',
      headerName: 'Enviar para Fundo',
      flex: 1,
      minWidth: 140,
      maxWidth: 140,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-border-gray',
      renderCell: params => <>{params.row.ENVIARFUNDO === 'S' ? 'Sim' : 'Não'}</>
    },
    {
      field: 'NOMEARQUIVO',
      headerName: 'Arquivo',
      flex: 1,
      minWidth: 200,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-border-gray',
      renderCell: params => (
        <Box
          className="icone-arquivo-download w-100"
          onClick={() => downloadArquivoPessoa(params.row.id, setIsPendingGrid, isPendingGrid)}
          sx={isPendingGrid[params.row.id] ? { position: 'relative' } : {}}
        >
          {isPendingGrid[params.row.id] && (
            <LoaderMain tipoLoader="float" tipoImagem="circulo" tamanho="md" percentTop="5%" />
          )}
          <Box className="badge-hover texto-nowrap-overflow-dotted w-95 pad-5 cursor-pointer">
            <AttachFileIcon sx={{ fontSize: '20px', mb: '-5px' }} />
            {textToLowerCase(params.row.NOMEARQUIVO)}
          </Box>
        </Box>
      )
    },
    {
      field: 'DTHRINSERT',
      headerName: 'Inclusão',
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-border-gray text-transform-capitalize',
      renderCell: params => (
        <Typography
          className="texto-centro flex-box-items-centralizados items-centralizados"
          variant="h7"
          title={moment(params.row.DTHRINSERT).format('DD/MM/yyyy HH:mm:ss')}
        >
          {moment(params.row.DTHRINSERT).format('DD/MM/yyyy')}
        </Typography>
      )
    }
  ];

  if (escopoClientePessoas && escopoClientePessoas.permite_alterar) {
    const abrirModal = {
      field: 'abrir_cliente',
      headerName: '',
      flex: 1,
      maxWidth: 120,
      minWidth: 120,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-border-gray',
      renderCell: params => (
        <Typography
          className="novas-entradas-abrir"
          variant="h7"
          onClick={() => abrirModalDocumentos(false, params.row)}
        >
          Editar
          <ChevronRightIcon sx={{ fontSize: '20px' }} />
        </Typography>
      )
    };

    config.push(abrirModal);
  }

  return config;
};

export default DefinicaoDeColunas;
