import React, { memo, useState } from 'react';
import { Box, Collapse, Grid, Paper } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material/';

const BoxPaper = ({
  iconeTitulo,
  labelTitulo,
  children,
  collapse = true,
  initialCollapse = false,
  sx = {}
}) => {
  const [blocoAberto, setBlocoAberto] = useState(collapse ? initialCollapse : true);
  return (
    <Paper elevation={0} className="box-paper" sx={sx}>
      <Grid
        container
        spacing={2}
        className={`box-paper-header ${collapse ? 'cursor-pointer' : ''}`}
        onClick={() => {
          if (collapse) setBlocoAberto(!blocoAberto);
        }}
      >
        <Grid item xl={4} lg={4} md={4} sm={3} xs={3} className="flex-box-items-direita">
          <Box className="box-icone-paper-header">{iconeTitulo}</Box>
        </Grid>
        <Grid item xl={7} lg={7} md={7} sm={7} xs={7} className="titulo flex-box-items-esquerda">
          {labelTitulo}
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="titulo flex-box-items-direita">
          {collapse &&
            (blocoAberto ? (
              <ExpandLess className="font-size-2-3rem" />
            ) : (
              <ExpandMore className="font-size-2-3rem" />
            ))}
        </Grid>
      </Grid>
      <Collapse in={blocoAberto} className="box-paper-body">
        <Box sx={{ m: '30px 0' }}>{children}</Box>
      </Collapse>
    </Paper>
  );
};
export default memo(BoxPaper);
