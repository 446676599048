import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  LinearProgress
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import TextFieldMui from '@mui/material/TextField';
import TextFieldMain from '../Shared/TextFieldMain';
import Strip from '../Utils/strip';
import CheckBoxMain from '../Shared/CheckBoxMain';
import {
  getEstadosBrasil,
  getMunicipiosBrasil,
  postCadastrarFormulario,
  postCadastrarVisitaFormulario
} from '../../api/cadastroExterno';
import { validaCnpj } from '../Utils/validaCnpj';
import { validaTelefone } from '../Utils/validaTelefone';
import RedirectExterno from './RedirectExterno';
import { focusInput } from '../Utils/utils';

const redirectString = 'https://one7.com.vc/obrigado';

const arrayFaturamentos = [
  'Menos de 360 mil por mês',
  'Entre 360 mil e 1 milhão por mês',
  'Entre 1 milhão e 2,5 milhões por mês',
  'Entre 2,5 milhões e 5 milhões por mês',
  'Entre 5 milhões e 12 milhões por mês',
  'Mais que 12 milhões por mês'
];

const faturamento = [
  { label: arrayFaturamentos[0], value: arrayFaturamentos[0] },
  { label: arrayFaturamentos[1], value: arrayFaturamentos[1] },
  { label: arrayFaturamentos[2], value: arrayFaturamentos[2] },
  { label: arrayFaturamentos[3], value: arrayFaturamentos[3] },
  { label: arrayFaturamentos[4], value: arrayFaturamentos[4] },
  { label: arrayFaturamentos[5], value: arrayFaturamentos[5] }
];

const arrayTempoFundacao = [
  'Menos de 1 ano',
  'De 1 a 2 anos',
  'Mais de 2 anos'
];

const tempo_fundacao = [
  { label: arrayTempoFundacao[0], value: arrayTempoFundacao[0] },
  { label: arrayTempoFundacao[1], value: arrayTempoFundacao[1] },
  { label: arrayTempoFundacao[2], value: arrayTempoFundacao[2] }
];

const Cadastro = () => {
  const [valueEstado, setValueEstado] = useState('');
  const [valueCidade, setValueCidade] = useState('');
  const [valueTempoEmpresa, setValueTempoEmpresa] = useState('');
  const [valueFaturamento, setValueFaturamento] = useState('');
  const [estadosBrasileiros, setEstadosBrasileiros] = useState([]);
  const [municipiosBrasileiros, setMunicipiosBrasileiros] = useState([]);
  const [municipiosFiltradosUF, setMunicipiosFiltradosUF] = useState([]);
  const [bannerAgradecimento, setBannerAgradecimento] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const resumido = params.get('resumido') === 'S';
  const url_origem = params.get('origem') ?? '';
  const url_pai =
    (window.location.ancestorOrigins && window.location.ancestorOrigins[0]) ??
    window.location.origin + window.location.pathname;

  useEffect(() => {
    if (!resumido) {
      getMunicipiosBrasil()
        .then(res => {
          setMunicipiosBrasileiros(res.data);
        })
        .catch(() => {})
        .finally(() => {});
      getEstadosBrasil()
        .then(res => {
          const newArray = res.data.map(uf => ({
            value: uf.id,
            label: uf.nome,
            sigla: uf.sigla
          }));
          newArray.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else {
              return true;
            }
          });
          setEstadosBrasileiros(newArray);
        })
        .catch(() => {})
        .finally(() => {});
    }
    cadastrarVisitaFormulario();
  }, [resumido]);

  const handleOnChangeEstado = (ev, newValue) => {
    const newArray = [];
    setValueCidade('');
    setMunicipiosFiltradosUF([]);

    if (newValue && newValue.value) {
      setValueEstado(newValue.value);

      municipiosBrasileiros.map(mun => {
        if (mun.microrregiao.mesorregiao.UF.id === newValue.value) {
          newArray.push({
            value: mun.id,
            label: mun.nome
          });
        }
        return mun;
      });
    } else {
      setValueEstado('');
    }

    setMunicipiosFiltradosUF(newArray);
  };

  const cadastrarFormulario = (values, form) => {
    values.cnpj = Strip(values.cnpj);
    values.telefone = Strip(values.telefone);
    values.whatsapp = Strip(values.whatsapp);
    values.privacidade = 'S';
    values.id_uf = values.estado;
    values.id_cidade = values.cidade;
    values.uf =
      estadosBrasileiros.length > 0
        ? estadosBrasileiros.find(
            item => parseInt(item.value, 10) === parseInt(values.id_uf)
          ).sigla
        : '';
    values.estado =
      estadosBrasileiros.length > 0
        ? estadosBrasileiros.find(
            item => parseInt(item.value) === parseInt(values.id_uf)
          ).label
        : '';
    values.cidade =
      municipiosFiltradosUF.length > 0
        ? municipiosFiltradosUF.find(
            item => parseInt(item.value) === parseInt(values.cidade)
          ).label
        : '';
    values.parametro_origem = url_origem;
    values.url_origem =
      url_origem !== '' ? `${url_pai}?origem=${url_origem}` : url_pai;
    values.origem = 'LP';

    postCadastrarFormulario(values)
      .then(() => {
        setBannerAgradecimento(true);
      })
      .catch(() => {})
      .finally(() => {
        resetValuesForm();
        form.resetForm();
        form.setSubmitting(false);
      });
  };

  const cadastrarVisitaFormulario = () => {
    const params = {
      url_origem:
        window !== window.parent ? document.referrer : window.location.href
    };

    postCadastrarVisitaFormulario(params)
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  };

  const handleOnChangeCidade = (ev, newValue) => {
    setValueCidade(newValue && newValue.value ? newValue.value : '');
  };

  const handleOnChangeTempoEmpresa = (ev, newValue) => {
    setValueTempoEmpresa(newValue && newValue.value ? newValue.value : '');
  };

  const handleOnChangeFaturamento = (ev, newValue) => {
    setValueFaturamento(newValue && newValue.value ? newValue.value : '');
  };

  const resetValuesForm = () => {
    setValueEstado('');
    setValueCidade('');
    setValueTempoEmpresa('');
    setValueFaturamento('');
  };

  const msgErro = 'Preencha este campo';

  return (
    <CardContent className="cadastro-externo texto-centro">
      <Grid container>
        <Grid item xs={1} sm={1} md={2} lg={2} xl={3} />
        <Grid item xs={10} sm={10} md={8} lg={8} xl={6} sx={{ p: '40px 0' }}>
          {!bannerAgradecimento ? (
            <Formik
              initialValues={{
                cnpj: '',
                razao_social: '',
                estado: '',
                cidade: '',
                nome: '',
                email: '',
                telefone: '',
                whatsapp: '',
                tempo_empresa: '',
                faturamento: '',
                privacidade: ''
              }}
              onSubmit={(values, form) => {
                const errors = {};

                if (!values.cnpj || Strip(values.cnpj).length < 14) {
                  errors.cnpj = msgErro;
                } else if (!validaCnpj(Strip(values.cnpj))) {
                  errors.cnpj = 'CNPJ inválido';
                }

                if (!values.razao_social && !resumido) {
                  errors.razao_social = msgErro;
                }

                if (!valueEstado && !resumido) {
                  setValueEstado(false);
                  errors.estado = msgErro;
                } else {
                  values.estado = valueEstado;
                }

                if (!valueCidade && !resumido) {
                  setValueCidade(false);
                  errors.cidade = msgErro;
                } else {
                  values.cidade = valueCidade;
                }

                if (!values.nome && !resumido) {
                  errors.nome = msgErro;
                } else if (values.nome && values.nome.length > 0 && !resumido) {
                  const input_nome = values.nome.trim();
                  if (!input_nome.includes(' ')) {
                    errors.nome = 'Preencha nome e sobrenome';
                  }
                }

                if (!values.email) {
                  errors.email = msgErro;
                } else if (values.email && values.email.length > 0) {
                  const result = /\S+@\S+\.\S+/;
                  const input_email = values.email.trim();
                  if (!result.test(values.email) || input_email.includes(' ')) {
                    errors.email = 'Email inválido';
                  }
                }

                if (!values.telefone && !resumido) {
                  errors.telefone = msgErro;
                } else if (!resumido) {
                  const celPhone = Strip(values.telefone);
                  const tel = Strip(celPhone);
                  const ddd = parseInt(tel.substring(0, 2), 10);

                  if (ddd <= 10 || ddd >= 100) {
                    errors.telefone = 'DDD inválido';
                  }

                  if (!validaTelefone(celPhone)) {
                    errors.telefone = 'Telefone inválido';
                  }
                }

                if (values.whatsapp) {
                  const celPhone = Strip(values.whatsapp);
                  const tel = Strip(celPhone);
                  const ddd = parseInt(tel.substring(0, 2), 10);

                  if (ddd <= 10 || ddd >= 100) {
                    errors.whatsapp = 'DDD inválido';
                  }

                  if (!validaTelefone(celPhone)) {
                    errors.whatsapp = 'Telefone inválido';
                  }
                }

                if (!valueTempoEmpresa) {
                  setValueTempoEmpresa(false);
                  errors.tempo_empresa = msgErro;
                } else {
                  values.tempo_empresa = valueTempoEmpresa;
                }

                if (!valueFaturamento && !resumido) {
                  setValueFaturamento(false);
                  errors.faturamento = msgErro;
                } else {
                  values.faturamento = valueFaturamento;
                }

                if (!values.privacidade || values.privacidade.length < 1) {
                  errors.privacidade =
                    'É necessário aceitar a politica de privacidade';
                }

                form.setErrors(errors);

                if (Object.keys(errors).length <= 0) {
                  cadastrarFormulario(values, form);
                } else {
                  form.setSubmitting(false);
                  focusInput(Object.keys(errors)[0]);
                }
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Field
                        type="text"
                        name="cnpj"
                        titulo="CPF"
                        id="cnpj"
                        label="CNPJ Empresa"
                        inputClassName="input-field input-field-main"
                        component={TextFieldMain}
                        autoComplete="off"
                        variant="filled"
                        tipoMascara="CNPJMask"
                        inputProps={{ maxLength: 18 }}
                        disabled={isSubmitting}
                      />
                    </Grid>

                    {!resumido && (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Field
                            component={TextField}
                            variant="filled"
                            type="text"
                            className="input-field"
                            label="Razão Social"
                            name="razao_social"
                            id="razao_social"
                            autoComplete="off"
                            disabled={isSubmitting}
                            inputProps={{ maxLength: 150 }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="grid-esquerda"
                        >
                          <Autocomplete
                            disablePortal
                            id="estado"
                            className="combo-box-mui"
                            options={estadosBrasileiros || []}
                            value={
                              valueEstado && estadosBrasileiros.length > 0
                                ? estadosBrasileiros.find(
                                    option => option.value === valueEstado
                                  )
                                : ''
                            }
                            onChange={handleOnChangeEstado}
                            name="estado"
                            disableClearable
                            isOptionEqualToValue={(option, value) =>
                              option === value ||
                              value === undefined ||
                              value === '' ||
                              value === null
                            }
                            disabled={
                              isSubmitting ||
                              estadosBrasileiros.length === 0 ||
                              municipiosBrasileiros.length === 0
                            }
                            renderInput={params => (
                              <TextFieldMui
                                {...params}
                                name="estado"
                                variant="filled"
                                label="Estado"
                                autoComplete="off"
                                error={valueEstado === false}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'none'
                                }}
                              />
                            )}
                          />
                          {valueEstado === false && (
                            <div className="texto-erro-combo-box">
                              {msgErro}
                            </div>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="grid-direita"
                        >
                          <Autocomplete
                            disablePortal
                            id="cidade"
                            className="combo-box-mui"
                            options={municipiosFiltradosUF || []}
                            value={
                              valueCidade && municipiosFiltradosUF.length > 0
                                ? municipiosFiltradosUF.find(
                                    option => option.value === valueCidade
                                  )
                                : ''
                            }
                            onChange={handleOnChangeCidade}
                            name="cidade"
                            disableClearable
                            isOptionEqualToValue={(option, value) =>
                              option === value ||
                              value === undefined ||
                              value === '' ||
                              value === null
                            }
                            disabled={
                              isSubmitting ||
                              estadosBrasileiros.length === 0 ||
                              municipiosBrasileiros.length === 0 ||
                              municipiosFiltradosUF.length === 0
                            }
                            renderInput={params => (
                              <TextFieldMui
                                {...params}
                                name="cidade"
                                variant="filled"
                                label="Cidade"
                                error={valueCidade === false}
                                required={valueCidade === false}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'none'
                                }}
                              />
                            )}
                          />
                          {valueCidade === false && (
                            <div className="texto-erro-combo-box">
                              {msgErro}
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Field
                            component={TextField}
                            variant="filled"
                            type="text"
                            className="input-field"
                            label="Seu nome"
                            name="nome"
                            id="nome"
                            autoComplete="off"
                            disabled={isSubmitting}
                            inputProps={{ maxLength: 150 }}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Field
                        component={TextField}
                        variant="filled"
                        type="email"
                        className="input-field"
                        label="E-mail para contato"
                        name="email"
                        id="email"
                        autoComplete="off"
                        disabled={isSubmitting}
                        inputProps={{ maxLength: 150 }}
                      />
                    </Grid>

                    {!resumido && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="grid-esquerda"
                      >
                        <Field
                          component={TextFieldMain}
                          type="text"
                          name="telefone"
                          id="telefone"
                          label="Telefone para contato"
                          inputClassName="input-field input-field-main"
                          autoComplete="none"
                          variant="filled"
                          tipoMascara="phoneMask"
                          inputProps={{ maxLength: 15 }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={resumido ? 12 : 6}
                      md={resumido ? 12 : 6}
                      lg={resumido ? 12 : 6}
                      xl={resumido ? 12 : 6}
                      className={resumido ? '' : 'grid-direita'}
                    >
                      <Field
                        component={TextFieldMain}
                        type="text"
                        name="whatsapp"
                        id="whatsapp"
                        label="WhatsApp (opcional)"
                        inputClassName="input-field input-field-main"
                        autoComplete="none"
                        variant="filled"
                        tipoMascara="phoneMask"
                        inputProps={{ maxLength: 15 }}
                        disabled={isSubmitting}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Autocomplete
                        disablePortal
                        id="tempo_empresa"
                        className="combo-box-mui"
                        options={tempo_fundacao || []}
                        value={
                          valueTempoEmpresa && tempo_fundacao.length > 0
                            ? tempo_fundacao.find(
                                option => option.value === valueTempoEmpresa
                              )
                            : ''
                        }
                        onChange={handleOnChangeTempoEmpresa}
                        name="tempo_empresa"
                        disableClearable
                        isOptionEqualToValue={(option, value) =>
                          option === value ||
                          value === undefined ||
                          value === '' ||
                          value === null
                        }
                        disabled={isSubmitting}
                        renderInput={params => (
                          <TextFieldMui
                            {...params}
                            name="tempo_empresa"
                            variant="filled"
                            label="Tempo de fundação da empresa"
                            error={valueTempoEmpresa === false}
                            required={valueTempoEmpresa === false}
                            autoComplete="off"
                          />
                        )}
                      />
                      {valueTempoEmpresa === false && (
                        <div className="texto-erro-combo-box">{msgErro}</div>
                      )}
                    </Grid>

                    {!resumido && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Autocomplete
                          disablePortal
                          id="faturamento"
                          className="combo-box-mui"
                          options={faturamento || []}
                          value={
                            valueFaturamento && faturamento.length > 0
                              ? faturamento.find(
                                  option => option.value === valueFaturamento
                                )
                              : ''
                          }
                          onChange={handleOnChangeFaturamento}
                          name="faturamento"
                          disableClearable
                          isOptionEqualToValue={(option, value) =>
                            option === value ||
                            value === undefined ||
                            value === '' ||
                            value === null
                          }
                          disabled={isSubmitting}
                          renderInput={params => (
                            <TextFieldMui
                              {...params}
                              name="faturamento"
                              variant="filled"
                              label="Faturamento mensal"
                              error={valueFaturamento === false}
                              required={valueFaturamento === false}
                              autoComplete="off"
                            />
                          )}
                        />
                        {valueFaturamento === false && (
                          <div className="texto-erro-combo-box">{msgErro}</div>
                        )}
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Field
                        type="checkbox"
                        name="privacidade"
                        id="privacidade"
                        value="S"
                        classNameLabel="label-link-privacidade"
                        component={CheckBoxMain}
                        corCheckBox="checkbox-cor-principal"
                        className="link-politica-privacidade"
                        disabled={isSubmitting}
                        controlLabelCheckbox={
                          <div>
                            <>Li e aceito a </>
                            <a
                              href="https://files.one7.com.vc/visaocliente/AVISO_DE_PRIVACIDADE.pdf"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Politica de privacidade.
                            </a>
                            <> </>
                            Dou consentimento para que a One7 realize a coleta e
                            o tratamento dos meus dados pessoais na forma
                            descrita.
                          </div>
                        }
                      />
                    </Grid>

                    {isSubmitting && <LinearProgress />}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="btn-enviar-formulario"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Concordar e enviar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <RedirectExterno url={redirectString} />
          )}
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default Cadastro;
