import React from 'react';
import { Grid, Typography } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export const GerenteAvatar = params => {
  let tituloGerente = params.value;

  if (params.row && params.row.nomePlataforma)
    tituloGerente += `\nPlataf.: ${params.row.nomePlataforma}`;

  if (params.row && params.row.nomeUnidadeNegocio)
    tituloGerente += `\nUnd.Neg.: ${params.row.nomeUnidadeNegocio}`;

  return (
    <>
      {params.value && params.value === '-' ? (
        <HorizontalRuleIcon className="texto-cor-cinza-claro" />
      ) : (
        <Grid container title={tituloGerente}>
          <Grid item xs={12} className="pad-top-5">
            <AccountCircleOutlinedIcon
              sx={{ fontSize: '28px', color: '#a7a7a7' }}
            />
            <br />
            <Typography
              variant="subtitle1"
              className="texto-cor-preto font-size-14 text-uppercase"
              sx={{ maxHeight: '50px', lineHeight: '1.15' }}
            >
              {params.value}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
