import React from 'react';
import { Box, Grid, List, ListItem, ListItemText } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { mascararCpfCnpj } from '../../../../Utils/format';

const CamposInvalidosPerifericos = ({ CamposFaltantes }) => (
  <Box className="">
    <Box>Itens faltantes:</Box>
    <Grid container className="flex-box">
      {CamposFaltantes.map(({ descricao, campo_faltante }) => (
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12} key={descricao} className="margin-top-15">
          <Box className="font-bold font-size-1rem ">{descricao}</Box>
          <List className="font-size-0-875rem">
            {campo_faltante.length === 0 ? (
              <ListItem className="pad-0">
                <ListItemText primary="Não encontrado" className="margin-zero pad-0" />
              </ListItem>
            ) : (
              campo_faltante.map((item, index) => (
                <React.Fragment key={index}>
                  {typeof item === 'object' && item !== null ? (
                    Object.entries(item).map((subItem, subIndex) => (
                      <List key={subIndex}>
                        <ListItem className="margin-neg-top-20">
                          <ListItemText primary={mascararCpfCnpj(subItem[0])} />
                        </ListItem>
                        <ListItem className="margin-neg-top-20">
                          <List component="div" disablePadding>
                            {subItem[1].map((subItemValor, subSubIndex) => (
                              <ListItem key={subSubIndex} className="pad-0">
                                <ArrowRightIcon />
                                <ListItemText primary={subItemValor} className="margin-zero pad-0" />
                              </ListItem>
                            ))}
                          </List>
                        </ListItem>
                      </List>
                    ))
                  ) : (
                    <ListItem key={index} className="pad-0">
                      <ArrowRightIcon />
                      <ListItemText primary={item} className="margin-zero pad-0" />{' '}
                    </ListItem>
                  )}
                </React.Fragment>
              ))
            )}
          </List>
        </Grid>
      ))}
    </Grid>
  </Box>
);
export default CamposInvalidosPerifericos;
