import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Cadastro from './components/CadastroExterno';
import DashboardCRM from './components/CRM/Dashboard';
import DashboardFormalizacao from './components/Formalizacao/Dashboard/Dashboard';
import ForceLogout from './components/ForceLogout/ForceLogout';
import DashboardRelacionamentos from './components/Relacionamentos/DashboardRelacionamentos';
import PlataformasAutenticacao from './services/authentication/PlataformasAutenticacao';
import ProtectedRoute from './services/authentication/ProtectedRoute';
import NotFound from './components/NotFound/NotFound';
import { getEscopoComponentes, isAuthenticated } from './services/authentication/auth';

const Rotas = () => {
  const [escopoAcesso, setEscopoAcesso] = useState(isAuthenticated() ? getEscopoComponentes() : false);

  return (
    <Routes>
      <Route
        path="/plataformas-autenticacao/:url/:tokenRefresh/:token"
        element={<PlataformasAutenticacao setEscopoAcesso={setEscopoAcesso} />}
      />

      <Route path="/cadastro" element={<Cadastro />} />

      <Route path="/force-logout" element={<ForceLogout />} />

      <Route
        path="/plataforma_crm/dashboard"
        element={
          <ProtectedRoute modulo="plataforma_crm" contexto="dashboard" escopoAcesso={escopoAcesso}>
            <DashboardCRM escopoAcesso={escopoAcesso} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/plataforma_formalizacao/dashboard"
        element={
          <ProtectedRoute
            modulo="plataforma_formalizacao"
            contexto="dashboard"
            escopoAcesso={escopoAcesso}
          >
            <DashboardFormalizacao escopoAcesso={escopoAcesso} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/plataforma_relacionamento/dashboard"
        element={
          <ProtectedRoute
            modulo="plataforma_relacionamento"
            contexto="dashboard"
            escopoAcesso={escopoAcesso}
          >
            <DashboardRelacionamentos escopoAcesso={escopoAcesso} />
          </ProtectedRoute>
        }
      />

      <Route path="/not-found" element={<NotFound escopoAcesso={escopoAcesso} />} />

      <Route
        path="*"
        element={
          <ProtectedRoute ignorarEscopo={true}>
            <NotFound erro="Rota não encontrada." escopoAcesso={escopoAcesso} />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Rotas;
