import React, { memo, useState } from 'react';
import { Box, Grid, Checkbox } from '@mui/material';
import 'moment/locale/pt-br';
import { textToLowerCase } from '../../../../Utils/format';
import iconPdf from '../../../../../assets/img/icon-pdf.svg';
import iconWord from '../../../../../assets/img/icon-word.svg';
import { getBuscaContratos } from '../../../../../api/formalizacao';
import LoaderMain from '../../../../Shared/LoaderMain';
import { DOCUMENTOCONTRATO } from '../../../../Shared/Enums/DocumentosContrato';

const GerarContratos = ({ codCliente, operaConnect, setAlerta }) => {
  const [isPending, setIsPending] = useState(false);

  const [coobrigacaoStates, setCoobrigacaoStates] = useState(
    Array(Object.keys(DOCUMENTOCONTRATO)).fill(false)
  );
  const [garantiaStates, setGarantiaStates] = useState(
    Array(Object.keys(DOCUMENTOCONTRATO)).fill(false)
  );

  const handleCoobrigacaoChange = index => {
    const newCoobrigacaoStates = [...coobrigacaoStates];
    newCoobrigacaoStates[index] = !newCoobrigacaoStates[index];
    setCoobrigacaoStates(newCoobrigacaoStates);
  };

  const handleGarantiaChange = index => {
    const newGarantiaStates = [...garantiaStates];
    newGarantiaStates[index] = !newGarantiaStates[index];
    setGarantiaStates(newGarantiaStates);
  };

  function downloadWordDocument(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const verContrato = (codEmpresa, nome, tipoArquivo, index, operaConnect) => {
    setIsPending(true);

    const coobrigacao = coobrigacaoStates[index] ? 'S' : 'C';
    const garantia = garantiaStates[index] ? garantiaStates[index] : false;
    const params = {
      codCliente: codCliente,
      validaDocumento: false,
      permitegarantia: garantia,
      coobrigacao: coobrigacao,
      codEmpresa: codEmpresa,
      tipoArquivo: tipoArquivo,
      salvarDocumento: false,
      operaConnect: operaConnect
    };

    getBuscaContratos(params)
      .then(res => {
        const dados = res.data;
        const base64regex = /^[A-Za-z0-9+/=]+\s*$/;
        if (typeof dados === 'string' && base64regex.test(dados)) {
          if (tipoArquivo === 'pdf') {
            const binaryString = atob(dados);
            const blob = new Blob(
              [new Uint8Array(binaryString.length).map((_, i) => binaryString.charCodeAt(i))],
              { type: 'application/pdf' }
            );
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          } else if (tipoArquivo === 'word') {
            const nomeArquivo = `${nome.replace(/\s/g, '')}${
              coobrigacao === 'C' ? 'Com' : 'Sem'
            }Coobrigacao${garantia ? 'ComGarantia' : ''}.docx`;
            downloadWordDocument(dados, nomeArquivo);
          }
        }
      })
      .catch(err => {
        setAlerta({
          mensagem: 'Ocorreu algum erro, favor entrar em contato com o suporte',
          tipo: 'error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <Box sx={isPending ? { position: 'relative', minHeight: '220px' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

      <Box className="margin-bottom-25">Contratos</Box>

      {Object.values(DOCUMENTOCONTRATO).map((obj, index) => {
        const { nome, codEmpresa, permitegarantia, coobrigacao, connect } = obj;
        if (operaConnect === connect) {
          return (
            <Grid container key={index} className="margin-bottom-10 items-centro-verticalmente">
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Box className="texto-negrito camel-case">{textToLowerCase(nome)}</Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} container justifyContent="flex-end">
                <Box className="flex-box">
                  <Box className="flex-box">
                    {permitegarantia && (
                      <Box>
                        <Checkbox
                          color="primary"
                          checked={garantiaStates[index]}
                          onChange={() => handleGarantiaChange(index)}
                        />
                        Anexar Garantia
                      </Box>
                    )}
                    {coobrigacao && (
                      <Box className="margin-right-30">
                        <Checkbox
                          color="primary"
                          checked={coobrigacaoStates[index]}
                          onChange={() => handleCoobrigacaoChange(index)}
                        />
                        S/ coobrigação
                      </Box>
                    )}
                  </Box>
                  <Box
                    className="margin-left-10 margin-right-10 cursor-pointer"
                    onClick={() => verContrato(codEmpresa, nome, 'pdf', index, operaConnect)}
                  >
                    <img src={iconPdf} alt="icone" />
                  </Box>
                  <Box
                    className="margin-left-10 margin-right-10 cursor-pointer"
                    onClick={() => verContrato(codEmpresa, nome, 'word', index, operaConnect)}
                  >
                    <img src={iconWord} alt="icone" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        }
        return null;
      })}
    </Box>
  );
};
export default memo(GerarContratos);
