export const PRODUTOS = {
  NORMAL: 1,
  COMISSARIA: 2,
  GIRO_RAPIDO: 3,
  INTERCOMPANY: 4,
  GIRO_PARCELADO: 5,
  FIANCA_BANCARIA: 6,
  TRUSTEE: 7,
  CCB_RENEGOCIACAO: 8,
  CCB_CONSIGNADO: 9,
  CESSAO_CREDITO: 10,
  RENEGOCIACAO: 11,
  GIRO_PARCELADO_XPONE7_BNDES: 12,
  ANTECIPACAO_BNDES: 16,
  BOLETO_ESCROW: 18,
  ESCROW_SIMPLES: 19
};
